import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {SALES_PROFILE_GET_ENDPOINT} from 'src/data/constants/endpoints/sales-profiles-endppints';
import {SalesProfile} from 'src/data/models/sales-profile/SalesProfile';

async function getSalesProfiles(
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<SalesProfile>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<SalesProfile>>(SALES_PROFILE_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getSalesProfiles,
};
