import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const TICKETS_CREATE_FILE_LINK_ENDPOINT = (ticketId: string, fileId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}/files/${fileId}/download-link`;

export const TICKETS_DELETE_FILE_ENDPOINT = (ticketId: string, fileId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}/files/${fileId}/delete`;

export const TICKETS_UPDATE_ENDPOINT = (ticketId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}/update`;

export const TICKETS_DELETE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/tickets/delete`;

export const TICKETS_CHANGE_SEAT_ENDPOINT = (ticketId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}/change-seat`;

export const TICKETS_GET_BY_ID_ENDPOINT = (ticketId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}`;

export const TICKETS_BATCH_EDIT_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/tickets/update-tickets`;

export const TICKETS_LOCK_TICKET_BY_ID_ENDPOINT = (ticketId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/tickets/${ticketId}/lock`;

export const TICKETS_LOCK_MULTIPLE_TICKETS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2_1}/tickets/lock`;

export const TICKETS_UNLOCK_MULTIPLE_TICKETS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2_1}/tickets/unlock`;

export const TICKETS_UNLOCK_TICKET_BY_ID_ENDPOINT = (ticketId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}/unlock`;

export const TICKETS_SALES_CAPS_GET_BY_ID_ENDPOINT = (eventId: string, id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/ticket-sales-caps/${id}`;

export const TICKETS_SALES_CAPS_CREATE_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/ticket-sales-caps/create`;

export const TICKETS_SALES_CAPS_UPDATE_ENDPOINT = (eventId: string, id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/ticket-sales-caps/${id}/update`;

export const TICKETS_SALES_CAPS_DELETE_ENDPOINT = (eventId: string, id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/ticket-sales-caps/${id}/delete`;

// tickets management endpoints
export const TICKETS_MANAGEMENT_GET_ENDPOINT = `/ParsedTicket/`;

export const TICKETS_MANAGEMENT_GET_TICKETS_FILE_ENDPOINT = `/ParsedTicket/downloadzip`;

export const TICKETS_MANAGEMENT_GET_TICKET_LINK_ENDPOINT = (ticketId: string) =>
    `/ParsedTicket/${ticketId}/link`;

// tickets file endpoints
export const TICKETS_UPDATE_FILE_ENDPOINT = (ticketId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/tickets/${ticketId}/file`;

export const TICKETS_DELETE_TICKETS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/tickets/delete-files`;
