import {Box} from '@mui/material';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import LinkTicketFileForm from 'src/app/components/forms/event/LinkTicketFileForm';
import TtiFilterForm, {statusLabelMap} from 'src/app/components/forms/TtiFilterForm';
import UnprocessedTicketFilesTable from 'src/app/components/tables/UnprocessedTicketFilesTable';
import {config} from 'src/app/constants/config/config';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import FilterOption from 'src/data/api/common/FilterOption';
import UnprocessedTicketFile, {
    TicketFileStatus,
} from 'src/data/models/tickets/unprocessedTicketFile';
import unprocessedTicketFileService from 'src/data/services/unprocessedTicketFileService';
import Button from 'src/view/components/button/Button';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import {FilterAutoCompleteOption} from 'src/view/components/filters/AutoComplete/AutoComplete';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {TableColumnSorting} from 'src/view/components/table/table/Types';

interface UnprocessedTicketFilesFeatureProps {
    eventId: string;
    pageSize?: number;
    disableUrlWriting?: boolean;
    defaultSorting?: TableColumnSorting[];
    defaultFilters?: FilterOption[];
}

const useStyles = makeStyles((theme) => ({
    iframe: {
        width: '100%',
        height: '80vh',
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
    },
    deleteItems: {
        background: theme.palette.grey[300],
        maxHeight: '50vh',
        overflow: 'auto',
    },
}));

export const UNPROCESSED_TICKET_FILES_QUERY_KEY = 'unprocessedTicketFiles';

export default function UnprocessedTicketFilesTableFeature({
                                                               eventId,
                                                           }: UnprocessedTicketFilesFeatureProps): JSX.Element {
    const classes = useStyles();
    const [linkingIndex, setLinkingIndex] = useState<number>();
    const [ticketsPendingDelete, setTicketsPendingDelete] =
        useState<{ id: string; fileName: string }[]>();
    const [ticketFields, setTicketFields] = useState<{
        block: string;
        row: string;
        seat: string;
    }>({block: '', row: '', seat: ''});
    const queryClient = useQueryClient();

    const [selectedTickets, setSelectedTickets] = useState<RowIdResolver<UnprocessedTicketFile>[]>(
        []
    );

    const invalidateEventUnprocessedTicketFiles = useCallback(
        () =>
            queryClient.invalidateQueries({
                queryKey: [UNPROCESSED_TICKET_FILES_QUERY_KEY, eventId],
            }),
        [queryClient, eventId]
    );

    const {
        values: {customFields, filterOptions},
        setCustomFields,
        setFilterOptions,
    } = useQueryParams('tti');

    const currentPage = Number(customFields.page ?? 1);

    const {
        isLoading,
        isError,
        data: response,
        error,
    } = useQuery({
        queryKey: [UNPROCESSED_TICKET_FILES_QUERY_KEY, eventId, currentPage, filterOptions],
        queryFn: async () =>
            unprocessedTicketFileService.fetchUnprocessedTicketFilesOverView(eventId, {
                pageSize: config.ITEMS_PER_PAGE_STANDARD,
                page: currentPage,
                filter: filterOptions,
            }),
    });

    const {
        data: matchingTicketsResponse,
        isFetching: matchingTicketsIsFetching,
        isError: matchingTicketsIsError,
        error: matchingTicketsError,
    } = useQuery({
        queryKey: [
            'matchingTickets',
            eventId,
            ticketFields.block,
            ticketFields.row,
            ticketFields.seat,
        ],
        queryFn: async ({signal}) =>
            unprocessedTicketFileService.findMatchingTickets(
                eventId,
                ticketFields.block,
                ticketFields.row,
                ticketFields.seat,
                signal
            ),
        enabled: !!(ticketFields.block && ticketFields.row && ticketFields.seat),
    });

    const {
        mutate: linkTicket,
        isLoading: isLoadingLinkingTicket,
        isError: isLinkTicketError,
        error: linkTicketError,
        reset,
    } = useMutation({
        mutationFn: ({
                         ticketId,
                         unprocessedTicketFileId,
                     }: {
            ticketId: string;
            unprocessedTicketFileId: string;
        }) =>
            unprocessedTicketFileService.linkUnprocessedTicketFile(
                ticketId,
                unprocessedTicketFileId
            ),
        onSuccess: () => {
            setLinkingIndex(getNextUnlinkedIndex);
            invalidateEventUnprocessedTicketFiles();
        },
    });

    const {
        mutate: deleteTickets,
        isLoading: isLoadingDeleteTickets,
        isError: isDeleteTicketsError,
        error: deleteError,
    } = useMutation({
        mutationFn: (ticketIds: string[]) => unprocessedTicketFileService.deleteTickets(ticketIds),
        onSuccess: () => {
            invalidateEventUnprocessedTicketFiles();
        },
    });

    const {
        mutate: linkTickets,
        isLoading: isLoadingLinkTickets,
        isError: isLinkTicketsError,
        error: linkTicketsError,
    } = useMutation({
        mutationFn: (ticketIds: string[]) =>
            unprocessedTicketFileService.linkTickets(eventId, ticketIds),
        onSuccess: () => {
            invalidateEventUnprocessedTicketFiles();
            setSelectedTickets([]);
        },
    });

    const data = response?.data;

    const linkingTicket = linkingIndex === undefined ? undefined : data?.data?.[linkingIndex];

    const {
        data: ticketFileResponse,
        isError: isTicketFileError,
        error: ticketFileError,
        isFetching: isTicketFileFetching,
    } = useQuery({
        queryKey: ['ticketFile', linkingTicket?.id],
        queryFn: () => unprocessedTicketFileService.getTicketFile(linkingTicket?.id ?? ''),
        enabled: !!linkingTicket,
    });

    useEffect(() => {
        reset();
    }, [linkingTicket, reset]);

    const defaultStatus: FilterAutoCompleteOption | null = useMemo(() => {
        const opt = filterOptions.find((filter) => filter.property === 'processStatus');

        if (!opt) return null;
        const label = statusLabelMap.get(opt.value as TicketFileStatus);

        if (!label) return null;

        return {
            label,
            value: opt.value,
        };
    }, [filterOptions]);

    const getNextUnlinkedIndex = useCallback((): number | undefined => {
        const index = (data?.data ?? []).findIndex(
            (item, index) => item.status !== 'Linked' && index > (linkingIndex ?? 0)
        );

        return index !== -1 ? index : undefined;
    }, [linkingIndex, data]);

    if (isError) {
        return <ErrorsList errors={parseErrors(error)}/>;
    }

    return (
        <>
            {(isLoading || isLoadingDeleteTickets || isLoadingLinkTickets) && <LoadingOverlay/>}
            {isDeleteTicketsError && <ErrorsList errors={parseErrors(deleteError)}/>}
            {isLinkTicketsError && <ErrorsList errors={parseErrors(linkTicketsError)}/>}
            {data && (
                <>
                    <TtiFilterForm
                        defaultValues={{
                            processStatus: defaultStatus,
                        }}
                        onFiltersChange={setFilterOptions}
                    />
                    <TableHeaderTotalFeature title="Ticket Files" totalResults={data.meta.total}/>
                    <UnprocessedTicketFilesTable
                        unprocessedTicketFiles={data.data}
                        pagination={{
                            currentPage: data.meta.currentPage || 1,
                            totalPages: data.meta.totalPages || 1,
                            onPaginate: (page) => {
                                setCustomFields({page: page.toString()});
                            },
                        }}
                        initialSelectedRows={selectedTickets}
                        onChangeSelectedRows={setSelectedTickets}
                        onLinkTicketClick={(_, index) =>
                            setLinkingIndex(() => {
                                return index;
                            })
                        }
                        onDeleteClick={(rows) =>
                            setTicketsPendingDelete(
                                rows
                                    .filter((row) => !!row.data)
                                    .map((row) => ({
                                        id: row.id,
                                        fileName: row.data?.fileName ?? '',
                                    }))
                            )
                        }
                        onMassLinkClick={(rows) => {
                            linkTickets(rows.filter((row) => !!row.data).map((row) => row.id));
                        }}
                    />
                </>
            )}
            <Modal
                title="Assign Ticket Values"
                width="fluid"
                open={!!linkingTicket}
                onClose={() => setLinkingIndex(undefined)}
            >
                {linkingTicket ? (
                    <Box display="flex" flexDirection="row">
                        <Box width="50%" pr={2}>
                            {isLinkTicketError && (
                                <ErrorsList errors={parseErrors(linkTicketError)}/>
                            )}
                            <LinkTicketFileForm
                                key={linkingTicket.id}
                                ticketFile={linkingTicket}
                                onSkip={() => setLinkingIndex(getNextUnlinkedIndex)}
                                onSubmit={(values) => {
                                    if (!matchingTicketsResponse?.data.data) return;

                                    const ticket =
                                        matchingTicketsResponse.data.data[values.ticketIndex];

                                    linkTicket({
                                        ticketId: ticket.id,
                                        unprocessedTicketFileId: linkingTicket.id,
                                    });
                                }}
                                onTicketDataChange={setTicketFields}
                                matchingTickets={{
                                    data: matchingTicketsResponse?.data.data,
                                    isError: matchingTicketsIsError,
                                    isLoading: matchingTicketsIsFetching,
                                    error: matchingTicketsError,
                                }}
                                submitting={isLoadingLinkingTicket}
                                file={{
                                    name: linkingTicket.fileName,
                                    link: ticketFileResponse
                                        ? `${ticketFileResponse.data.data}#toolbar=0`
                                        : '#',
                                }}
                            />
                        </Box>
                        <Box width="50%">
                            {isTicketFileError && (
                                <ErrorsList errors={parseErrors(ticketFileError)}/>
                            )}
                            {isTicketFileFetching && (
                                <Skeleton variant="rectangular" width="100%" height="80vh"/>
                            )}
                            {!isTicketFileFetching && ticketFileResponse?.data?.data && (
                                <iframe
                                    className={classes.iframe}
                                    src={`${ticketFileResponse.data.data}#toolbar=0`}
                                />
                            )}
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
            </Modal>
            <Modal
                open={!!ticketsPendingDelete && ticketsPendingDelete.length > 0}
                onClose={() => setTicketsPendingDelete([])}
                title="Confirm your action"
                subTitle="You are about to delete unlinked ticket files. Are you sure you want to continue?"
            >
                <div>
                    <Box p={2} mb={2} className={classes.deleteItems}>
                        {ticketsPendingDelete?.map((item, index) => (
                            <Box
                                display="flex"
                                alignItems="center"
                                key={item.id}
                                pb={index + 1 < ticketsPendingDelete.length ? 2 : 0}
                            >
                                <Box pr={1} fontWeight="bold">
                                    {index + 1}
                                </Box>
                                <Box flexGrow="1">
                                    <Card>{item.fileName}</Card>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Button
                        type="button"
                        onClick={() => {
                            deleteTickets((ticketsPendingDelete ?? []).map((ticket) => ticket.id));
                            setTicketsPendingDelete([]);
                            setSelectedTickets([]);
                        }}
                    >
                        Delete Selected Files
                    </Button>
                </div>
            </Modal>
        </>
    );
}
