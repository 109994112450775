import {CancelOutlined} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {DeleteTicketFileByIdDataWrapper} from 'src/app/components/data-wrappers/tickets/DeleteTicketFileByIdDataWrapper';
import TicketFile from 'src/data/models/common/ticketFile';
import {useGenericStyles} from 'src/shared/styles/genericStyles';
import Table from 'src/view/components/table/table/Table';
import {NewTableColumn, TABLE_CELL_HEIGHT} from 'src/view/components/table/table/Types';

const useStyles = makeStyles(() => ({
    fileRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
}));

export interface TicketUploadedFilesTableProps {
    ticketId: string;
    files?: TicketFile[];
    loading: boolean;
    onFileDeleted?: () => void;
    onClickFile?: (fileId: string) => void;
}

export default function TicketUploadedFilesTable({
                                                     ticketId,
                                                     files,
                                                     loading,
                                                     onFileDeleted,
                                                     onClickFile,
                                                 }: TicketUploadedFilesTableProps): JSX.Element {
    const classes = useStyles();
    const genericClasses = useGenericStyles();

    const cols: NewTableColumn<TicketFile>[] = [
        {
            key: 'name',
            title: 'Name',
            cellRenderer: (rowData) => (
                <div className={classes.fileRow}>
                    <span className={genericClasses.link} onClick={() => onClickFile?.(rowData.id)}>
                        {rowData.name}
                    </span>
                    <DeleteTicketFileByIdDataWrapper onSuccess={onFileDeleted}>
                        {({deleteTicketFile, loading}) => (
                            <>
                                {loading ? (
                                    <CircularProgress size={16}/>
                                ) : (
                                    <CancelOutlined
                                        className={genericClasses.clickable}
                                        color="disabled"
                                        onClick={() => deleteTicketFile(ticketId, rowData.id)}
                                        fontSize="small"
                                    />
                                )}
                            </>
                        )}
                    </DeleteTicketFileByIdDataWrapper>
                </div>
            ),
        },
    ];

    return (
        <Table<TicketFile>
            data={files}
            rowIdResolver={({id}) => ({id})}
            columns={cols}
            loading={loading}
            maxWidth={600}
            rowHeight={TABLE_CELL_HEIGHT.NONE}
        />
    );
}
