import {useCallback, useMemo} from 'react';
import TicketsFiltersForm, {availableOptions, lockedTicketFilterOptions,} from 'src/app/components/forms/TicketsTableFiltersFormValues';
import {useFetchEventSuppliers} from 'src/app/hooks/events/useFetchEventSuppliers';
import {useFetchSeatingPlanCategories} from 'src/app/hooks/useFetchSeatingPlanCategories';
import {purchaseStatusOptions} from 'src/app/utilities/helpers/filter-options/purchase';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import FilterOption from 'src/data/api/common/FilterOption';
import {FilterAutoCompleteOptions} from 'src/view/components/filters/AutoComplete/AutoComplete';
import {useGetBookingOptionsDropdownOptions} from '../../features/event-booking-options/hooks/use-get-booking-options-dropdown-options';

interface Props {
    eventId: string;
    initialOptions: FilterOption[];
    initialSearch?: string;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
}

export default function TicketsTableFiltersDataWrapper({
                                                           eventId,
                                                           initialOptions,
                                                           initialSearch,
                                                           onChangeFilterOptions,
                                                           onChangeSearchTerm,
                                                       }: Props): JSX.Element {
    const {categories, suppliers, isLoadingFilters, bookingOptions} =
        useFetchTicketFilterOptions(eventId);
    const filters = useTicketsFilters({initialOptions, initialSearch, eventId});

    return (
        <TicketsFiltersForm
            eventId={eventId}
            supplierOptions={suppliers}
            defaultValues={filters}
            categoriesOptions={categories}
            bookingOptions={bookingOptions}
            availableOptions={availableOptions}
            onChangeFilterOptions={onChangeFilterOptions}
            onChangeSearchTerm={onChangeSearchTerm}
            loading={isLoadingFilters}
        />
    );
}

type ArchivableFilterOption = {
    id: string;
    name: string;
    isArchived: boolean;
};

function transformToArchivableFilterOptions(options: ArchivableFilterOption[] | undefined) {
    return (options ?? []).map((option) => ({
        label: option.name,
        value: option.id,
        isArchived: option.isArchived,
    }));
}
type TicketFiltersProps = {
    initialOptions: FilterOption[];
    initialSearch?: string;
    eventId: string;
};

const useTicketsFilters = ({initialOptions, initialSearch, eventId}: TicketFiltersProps) => {
    const {categories, suppliers, bookingOptions} = useFetchTicketFilterOptions(eventId);

    /** These are the 'hidden' filters which means they don't have the controls in the UI
     * We only apply those filters based on the URL params when user navigates specifcially to this page
     * Handy for filtering out by purchaseId, ticketId, orderId, splitId
     */
    const filtersFromUrl = useMemo(() => {
        const options = new Map();

        initialOptions.forEach((option) => {
            options.set(option.property, option.value);
        });

        return {
            purchaseId: options.get('purchaseId'),
            ticketId: options.get('ticketId'),
            orderId: options.get('orderId'),
            splitId: options.get('splitId'),
            bookingOptionIds: options.get('bookingOptionIds'),
        };
    }, [initialOptions]);

    /** Helper function to find the initial value from the URL for the dropdown filters */
    const findFilterValueInDropdown = useCallback(
        (optionKey: string, options: FilterAutoCompleteOptions) => {
            return tableFilterFormHelper.getInitialAutocompleteValues(
                optionKey,
                initialOptions,
                options
            );
        },
        [initialOptions]
    );

    /** These are the filters which are controlled from the UI via dropdowns */
    const dropdownFilters = useMemo(() => {
        return {
            supplierIds: findFilterValueInDropdown('supplierIds', suppliers),
            purchaseStatus: findFilterValueInDropdown('purchaseStatus', purchaseStatusOptions),
            category: findFilterValueInDropdown('seatingPlanCategoryId', categories),
            bookingOptionIds: findFilterValueInDropdown('bookingOptionIds', bookingOptions),
            available: findFilterValueInDropdown('available', availableOptions)?.[0],
            isLocked: findFilterValueInDropdown('isLocked', lockedTicketFilterOptions)?.[0],
        };
    }, [initialOptions]);

    return {
        ...filtersFromUrl,
        ...dropdownFilters,
        searchTerm: initialSearch,
    };
};

/** Is used to fetch ticket filter options such as seating plan categories, event suppliers, etc. */
const useFetchTicketFilterOptions = (eventId: string) => {
    const {data, isLoading: isFetchingSeatingPlanCategories} =
        useFetchSeatingPlanCategories(eventId);
    const {data: eventSuppliersData, isLoading: isFetchingEventSuppliers} =
        useFetchEventSuppliers(eventId, {
            pageSize: 1000,
        });

    const seatingPlansCategoriesOptions = useMemo(() => {
        return transformToArchivableFilterOptions(data?.data?.data.categories);
    }, [data]);

    const eventSuppliersOptions = useMemo(() => {
        return transformToArchivableFilterOptions(eventSuppliersData?.data?.data);
    }, [eventSuppliersData]);

    const {bookingOptionsDropdownOptions, isFetching} =
        useGetBookingOptionsDropdownOptions(eventId);

    return {
        categories: seatingPlansCategoriesOptions,
        suppliers: eventSuppliersOptions,
        isLoadingFilters: isFetchingSeatingPlanCategories || isFetchingEventSuppliers || isFetching,
        bookingOptions: bookingOptionsDropdownOptions,
    };
};
