import {getInternalAxiosInstance} from 'src/data/api/api';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import type QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import type PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {AUDIT_LOGS_GET_ENDPOINT} from 'src/data/constants/endpoints/audit-log-endpoints';
import type AuditLog from 'src/data/models/audit-log/AuditLog';

async function getAuditLogs(groupEntityId: string, options?: BaseFetchOptions) {
    const extraQueryParams: QueryParam[] = [
        {
            key: 'groupEntityId',
            value: groupEntityId,
        },
    ];

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<AuditLog>>(AUDIT_LOGS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getAuditLogs,
};
