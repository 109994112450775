import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, FormHelperText, Grid} from '@mui/material';
import {useTheme} from '@mui/styles';
import classNames from 'classnames';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {FetchSeriesDataWrapper} from 'src/app/components/data-wrappers/series/FetchSeriesDataWrapper';
import {config} from 'src/app/constants/config/config';
import {FormProps} from 'src/app/interfaces/form/formProps';
import BasicAdministrationUrlService from 'src/app/pages/basic-administration/basicAdministrationUrlService';
import {seriesUpdateRoles} from 'src/app/utilities/helpers/userPermissions';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import z from 'zod';

interface EditEventSeriesFormProps extends FormProps<EditEventSeriesFormValuesType> {
    canEditSeries?: boolean;
}

const validationSchema = z.object({
    series: zodRequiredDropdownSelectSchema.nullable(),
});

export type EditEventSeriesFormValuesType = z.infer<typeof validationSchema>;

export const EditEventSeriesForm = ({
                                        loading,
                                        onSubmit,
                                        defaultValues,
                                        values,
                                        canEditSeries,
                                    }: EditEventSeriesFormProps) => {
    const spacingClasses = useSpacingStyles();
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        formState: {isDirty},
    } = useForm<EditEventSeriesFormValuesType>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues,
    });

    const isSubmitButtonDisabled = loading || !isDirty || !canEditSeries;

    useEffect(() => {
        if (!values) return;

        reset(values);
    }, [values]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2
                        className={classNames(
                            spacingClasses.noMarginBottom,
                            spacingClasses.noMarginTop
                        )}
                    >
                        Change event series
                    </h2>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel url={BasicAdministrationUrlService.series()} urlText="Create series">
                        Series*
                    </FormLabel>
                    <FetchSeriesDataWrapper pageSize={config.ITEMS_PER_PAGE_LARGE}>
                        {({data}) => (
                            <Controller
                                name="series"
                                control={control}
                                render={({
                                             field: {name, onChange, value},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <AutoComplete
                                            options={
                                                data?.data.map((o) => ({
                                                    label: o.name,
                                                    value: o.id,
                                                })) || []
                                            }
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                        />

                                        <FormHelperText>
                                            Only employees with the following rights can change the
                                            series of an event:{' '}
                                            <strong>{seriesUpdateRoles.join(', ')}</strong>. If you
                                            have questions about your role(s), please contact the
                                            system admin.
                                        </FormHelperText>
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        )}
                    </FetchSeriesDataWrapper>
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: 'Update series',
                        startIcon: loading && (
                            <CircularProgress size={theme.layout.loader.sizes.small}/>
                        ),
                        disabled: isSubmitButtonDisabled,
                        onClick: () => handleSubmit(onSubmit)(),
                    },
                ]}
            />
        </>
    );
};
