import {useMsal} from '@azure/msal-react';
import {useCallback, useEffect} from 'react';

/** Attempts to login via SSO if any active user is found within Azure AD */
export const useMsalSso = () => {
    const {instance} = useMsal();

    const requestSso = useCallback(async () => {
        const request = {
            scopes: ['User.Read'],
        };
        try {
            const loginResponse = await instance.ssoSilent(request);
            instance.setActiveAccount(loginResponse.account);
        } catch (err) {
            /* eslint-disable-next-line no-console */
            console.error(err);
        }
    }, [instance]);

    useEffect(() => {
        requestSso();
    }, [requestSso]);
};
