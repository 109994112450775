import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {
    PERFORMERS_CREATE_ENDPOINT,
    PERFORMERS_GET_ENDPOINT,
} from 'src/data/constants/endpoints/performers-endpoints';

export interface EventPerformer {
    id: string;
    name: string;
}

async function getPerformers(
    options?: BaseFetchOptions
): Promise<ApiResponse<ApiResponseBody<EventPerformer[]>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<ApiResponseBody<EventPerformer[]>>(PERFORMERS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createPerformer(
    name: string,
    type: string
): Promise<ApiResponse<ApiResponseBody<EventPerformer>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EventPerformer>>(PERFORMERS_CREATE_ENDPOINT, {name, type})
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getPerformers,
    createPerformer,
};
