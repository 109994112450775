import {useQueries} from '@tanstack/react-query';
import {useMemo} from 'react';
import FilterOption from 'src/data/api/common/FilterOption';
import contractService from 'src/data/services/contractService';

export const GET_CONTRACTS_QUERY_KEY = 'CONTRACTS';
export const GET_CONTRACT_TEMPLATES_QUERY_KEY = 'CONTRACT_TEMPLATES';
export const GET_TICKET_TEMPLATE_ITEMS_QUERY_KEY = 'TICKET_TEMPLATE_ITEMS';

interface Props {
    eventId: string;
    contractId?: string;
    templateId?: string;
}

export const useCreateContractData = ({eventId, contractId, templateId}: Props) => {
    const contractsFilter: FilterOption[] = [
        {
            property: 'availableForEventId',
            value: eventId,
        },
    ];

    const [contractsData, templatesData, templateTicketsData] = useQueries({
        queries: [
            {
                queryKey: [GET_CONTRACTS_QUERY_KEY, contractsFilter],
                queryFn: () =>
                    contractService.getContracts({
                        filter: contractsFilter,
                    }),
            },
            {
                queryKey: [GET_CONTRACT_TEMPLATES_QUERY_KEY, contractId, contractsFilter],
                queryFn: () =>
                    contractService.getTemplatesByContractId(contractId ?? '', {
                        filter: contractsFilter,
                    }),
                enabled: !!contractId,
            },
            {
                queryKey: [GET_TICKET_TEMPLATE_ITEMS_QUERY_KEY, contractId, templateId],
                queryFn: () =>
                    contractService.getTicketTemplateItems(contractId ?? '', templateId ?? ''),
                enabled: !!contractId && !!templateId,
            },
        ],
    });

    const contractOptions = useMemo(
        () =>
            (contractsData?.data?.data.data ?? []).map((c) => ({
                label: c.name,
                value: c.id,
            })),
        [contractsData]
    );

    const templatesOptions = useMemo(
        () =>
            (templatesData?.data?.data.data ?? []).map((c) => ({
                label: c.name,
                value: c.id,
            })),
        [templatesData]
    );

    const notes = useMemo(
        () => templatesData?.data?.data.data.find((t) => templateId === t.id)?.notes,
        [templateId, templatesData]
    );

    return {
        contractOptions,
        templatesOptions,
        notes,
        queriesData: {contractsData, templatesData, templateTicketsData},
    };
};
