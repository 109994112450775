import {zodResolver} from '@hookform/resolvers/zod';
import {Alert, Grid, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import groupBy from 'lodash/groupBy';
import React, {useEffect} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import TicketTemplatesTable from 'src/app/components/tables/TicketTemplatesTable';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import {zodRequiredNumberInputSchema} from 'src/app/utilities/zod/zodRequiredNumberInputSchema';
import TicketTemplateItem from 'src/data/models/contracts/ticketTemplateItem';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import Note from 'src/view/components/notes/Note';
import z from 'zod';

const useStyles = makeStyles((theme: Theme) => ({
    titleDisabled: {
        color: theme.colors.grey,
    },
    loadingSpinner: {
        marginLeft: theme.spacing(1),
    },
    priceInput: {
        marginBottom: '.8rem',
    },
    ticketTemplatesTable: {
        width: '100%',
    },
    note: {
        marginTop: theme.spacing(2),
    },
    totalBar: {
        width: '100%',
        'font-weight': theme.typography.fontWeightBold,
        margin: theme.spacing(2, 0),
        fontSize: '1.2rem',
    },
    subTotalBar: {
        color: theme.colors.grey,
        paddingLeft: theme.spacing(2),
        'font-weight': theme.typography.fontWeightBold,
    },
    totalItem: {
        padding: theme.spacing(2),
    },
    totalItemAlignRight: {
        textAlign: 'right',
    },
    totalAmount: {
        width: 103,
    },
    pricesAlert: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
}));

export interface CategoryPrice {
    categoryName: string;
    numberOfTickets: number;
    currency: AutoCompleteOption;
    price?: string;
}

export interface AddContractFormValues {
    contract?: AutoCompleteOption;
    ticketTemplateSet?: AutoCompleteOption;
    categoryPrices: CategoryPrice[];
}

export const addContractFormInitialValues: AddContractFormValues = {
    contract: undefined,
    ticketTemplateSet: undefined,
    categoryPrices: [],
};

export const addContractValidationSchema = z.object({
    contract: zodRequiredDropdownSelectSchema,
    ticketTemplateSet: zodRequiredDropdownSelectSchema,
    categoryPrices: z.array(
        z.object({
            categoryName: z.string(),
            numberOfTickets: zodRequiredNumberInputSchema,
            currency: zodRequiredDropdownSelectSchema,
            price: zodRequiredNumberInputSchema,
        })
    ),
});

interface Props {
    loading?: boolean;
    defaultValues?: AddContractFormValues;
    ticketTemplateOptions: AutoCompleteOptions;
    contractOptions: AutoCompleteOptions;
    onChangeTemplate?: (option: AutoCompleteValueOption) => void;
    onChangeContract?: (option: AutoCompleteValueOption) => void;
    onFormSubmit: (values: AddContractFormValues, templateItems?: TicketTemplateItem[]) => void;
    notes?: string;
    ticketTemplateItems?: TicketTemplateItem[];
    isFinishedFetchingContracts: boolean;
    isFinishedFetchingTemplates: boolean;
}

export default function AddContractForm({
                                            loading,
                                            defaultValues,
                                            contractOptions,
                                            ticketTemplateOptions,
                                            onChangeTemplate,
                                            onChangeContract,
                                            onFormSubmit,
                                            notes,
                                            ticketTemplateItems,
                                            isFinishedFetchingContracts,
                                            isFinishedFetchingTemplates,
                                        }: Props) {
    const classes = useStyles();

    const form = useForm<AddContractFormValues>({
        mode: 'onChange',
        defaultValues: addContractFormInitialValues,
        resolver: zodResolver(addContractValidationSchema),
    });

    const {watch, control, formState, reset, setValue, clearErrors, handleSubmit} = form;

    const {fields: categoryPrices, append} = useFieldArray({
        control,
        name: 'categoryPrices',
    });

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    const contract = watch('contract');
    const ticketTemplateSetWatch = watch('ticketTemplateSet');
    const categoryPricesWatch = watch('categoryPrices');

    useEffect(() => {
        if (!ticketTemplateItems) return;

        setValue('categoryPrices', []);

        const groupedCategories = groupBy(ticketTemplateItems, (t) => t.seatingPlanCategoryName);

        const categories = Object.keys(groupedCategories);

        categories.forEach((categoryName) => {
            append({
                categoryName,
                numberOfTickets: groupedCategories[categoryName].length,
                currency: currencyOptions[0],
            });
        });
    }, [ticketTemplateItems]);

    const renderCategoryTotals = () => {
        return categoryPricesWatch.map((c, index) => {
            if (!c.price) return;

            const parsedPrice = parseFloat(c.price);

            if (isNaN(parsedPrice)) return;

            const totalPerCategory = new BigNumber(parsedPrice).times(c.numberOfTickets);

            return (
                <Grid
                    container
                    spacing={2}
                    className={classes.subTotalBar}
                    key={`${c.categoryName}-${index}`}
                >
                    <Grid item xs={6}>
                        {c.categoryName}
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        {c.currency?.label} {totalPerCategory.toString()}
                    </Grid>
                </Grid>
            );
        });
    };

    const renderTotal = () => {
        let total = new BigNumber(0);

        categoryPricesWatch.forEach((c) => {
            if (!c.price) return;

            const parsedPrice = parseFloat(c.price);

            if (isNaN(parsedPrice)) return;

            const totalPerCategory = new BigNumber(c.numberOfTickets).times(c.price);

            total = total.plus(totalPerCategory);
        });

        return (
            <Grid container spacing={2} className={classes.totalBar}>
                <Grid item xs={6}>
                    Total
                </Grid>
                <Grid item xs={6} textAlign="right">
                    {total.toString()}
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container>
            {loading && <LoadingOverlay/>}
            <Grid item xs={12}>
                <ModalSubTitle>Contract</ModalSubTitle>
                {contractOptions?.length === 0 && isFinishedFetchingContracts && (
                    <Alert severity="warning">There are no available contract for the event</Alert>
                )}

                {contractOptions?.length > 0 && (
                    <Controller
                        name="contract"
                        control={control}
                        render={({field: {onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <AutoComplete
                                        name="contract"
                                        onChange={(option) => {
                                            setValue('ticketTemplateSet', undefined);
                                            clearErrors();
                                            onChange(option);
                                            onChangeContract?.(option);
                                        }}
                                        value={value}
                                        options={contractOptions}
                                        placeholder="Select contract"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {contract?.value && (
                    <ModalSubTitle className={classNames(!contract && classes.titleDisabled)}>
                        Ticket template set
                    </ModalSubTitle>
                )}
                {contract?.value &&
                    ticketTemplateOptions.length === 0 &&
                    isFinishedFetchingTemplates && (
                        <Alert severity="warning">There are no available template</Alert>
                    )}
                {contract?.value && ticketTemplateOptions.length > 0 && (
                    <Controller
                        name="ticketTemplateSet"
                        control={control}
                        render={({field: {onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <AutoComplete
                                        name="ticketTemplateSet"
                                        onChange={(option) => {
                                            clearErrors();
                                            onChange(option);
                                            onChangeTemplate?.(option);
                                        }}
                                        value={value}
                                        options={ticketTemplateOptions}
                                        placeholder="Select ticket template set"
                                        disabled={!contract}
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                )}
            </Grid>

            <div className={classes.ticketTemplatesTable}>
                {ticketTemplateSetWatch &&
                    contract &&
                    ticketTemplateItems &&
                    ticketTemplateItems.length > 0 && (
                        <>
                            {categoryPrices.map((categoryPrice, categoryPricesIndex) => (
                                <React.Fragment
                                    key={`${categoryPrice.categoryName}-${categoryPricesIndex}`}
                                >
                                    <ModalSubTitle>
                                        Price per ticket ({categoryPrice.categoryName})
                                    </ModalSubTitle>
                                    <div className={classes.priceInput}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <Controller
                                                    name={`categoryPrices.${categoryPricesIndex}.currency`}
                                                    control={control}
                                                    render={({
                                                                 field: {value, onChange, name},
                                                             }) => (
                                                        <AutoComplete
                                                            name={name}
                                                            value={value}
                                                            onChange={onChange}
                                                            options={currencyOptions}
                                                            isClearable={false}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Controller
                                                    name={`categoryPrices.${categoryPricesIndex}.price`}
                                                    control={control}
                                                    render={({
                                                                 field: {value, onChange, name},
                                                                 fieldState: {error},
                                                             }) => (
                                                        <>
                                                            <Input
                                                                value={value}
                                                                onChange={onChange}
                                                                name={name}
                                                                type="number"
                                                            />
                                                            <FormFieldError
                                                                message={error?.message}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </React.Fragment>
                            ))}

                            <TicketTemplatesTable
                                ticketTemplateItems={ticketTemplateItems}
                                categoryPrices={categoryPricesWatch}
                            />

                            {notes && contract && ticketTemplateItems && (
                                <Note className={classes.note} title="Notes" message={notes}/>
                            )}

                            {renderTotal()}
                            {renderCategoryTotals()}
                        </>
                    )}
            </div>

            <FormButtons
                buttons={[
                    {
                        children: 'Add contract ticket(s)',
                        disabled: !formState.isValid || !ticketTemplateItems,
                        onClick: handleSubmit((values) =>
                            onFormSubmit(values, ticketTemplateItems)
                        ),
                    },
                ]}
            />
        </Grid>
    );
}
