import {Alert} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FetchOrderlinesDataWrapper} from 'src/app/components/data-wrappers/orderline/FetchOrderlinesDataWrapper';
import OrderlinesFiltersFormDataWrapper from 'src/app/components/data-wrappers/orderline/OrderlinesFiltersDataWrapper';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import OrderlinesTable from 'src/app/components/tables/OrderlinesTable';
import {config} from 'src/app/constants/config/config';
import useQueryParams from 'src/app/hooks/useQueryParams';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import Orderline, {OrderlineTableDataResolver} from 'src/data/models/orderline/orderline';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import UnassignAllTicketsFeature from './UnassignAllTicketsFeature';
import {useQueryClient} from '@tanstack/react-query';
import {FETCH_SEATING_PLAN_CATEGORIES_QUERY_KEY} from 'src/app/hooks/useFetchSeatingPlanCategories';

type Params = {
    eventId: string;
};

export interface OrderLinesFeatureProps {
    invalidatedAt?: number;
    onClickDetails: (o: Orderline) => void;
    onClickAssign: (o: Orderline) => void;
}

export default function OrderLineTableFeatureDataWrapper({
                                                             invalidatedAt,
                                                             onClickDetails,
                                                             onClickAssign,
                                                         }: OrderLinesFeatureProps): JSX.Element {
    const {eventId} = useParams<Params>();
    const [selectedRows, setSelectedRows] = useState<RowIdResolver<OrderlineTableDataResolver>[]>(
        []
    );
    const queryClient = useQueryClient();
    const [showSendOrdersManuallyModal, setShowSendOrdersManuallyModal] = useState(false);

    const {
        values: {filterOptions, customFields, sortingOptions},
        setFilterOptions,
        setCustomFields,
        setSortingOptions,
    } = useQueryParams('orderLine');

    const [orderLinesInvalidatedAt, setOrderLinesInvalidatedAt] = useState<number | undefined>();

    useEffect(() => {
        if (!invalidatedAt) return;

        setOrderLinesInvalidatedAt(invalidatedAt);
    }, [invalidatedAt]);

    const isUnassignEnabled = useCallback(
        (selectedRows: RowIdResolver<OrderlineTableDataResolver>[]) => {
            return (
                !!selectedRows.length &&
                selectedRows.every(
                    ({data}) => data?.canChangeAssignedTickets && data?.assignedTickets.length
                )
            );
        },
        []
    );

    const unassignTicketAction = useCallback(
        (selectedRows: RowIdResolver<OrderlineTableDataResolver>[]) => ({
            label: 'Unassign selected tickets',
            disabled: !isUnassignEnabled(selectedRows),
            callback: () => {
                setSelectedRows(selectedRows);
                setShowSendOrdersManuallyModal(true);
            },
        }),
        [isUnassignEnabled]
    );

    const onUnassignSuccess = useCallback(() => {
        setShowSendOrdersManuallyModal(false);
        setOrderLinesInvalidatedAt(Date.now());
        setSelectedRows([]);
    }, []);

    const refreshSeatingPlanCategories = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [FETCH_SEATING_PLAN_CATEGORIES_QUERY_KEY],
        });
    }, []);

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    return (
        <>
            <Modal
                open={showSendOrdersManuallyModal}
                title="Are you sure you want to unassign all the assigned tickets for selected orderlines?"
                onClose={() => setShowSendOrdersManuallyModal(false)}
            >
                <UnassignAllTicketsFeature
                    orderLineIds={selectedRows.map((t) => t.id)}
                    onSuccess={onUnassignSuccess}
                />
            </Modal>
            <OrderlinesFiltersFormDataWrapper
                eventId={eventId}
                initialOptions={filterOptions}
                onChangeFilterOptions={setFilterOptions}
                initialSearchTerm={customFields.q}
                onChangeSearchTerm={(q: string) => {
                    setCustomFields({
                        ...customFields,
                        q: q?.length > 0 ? q : '',
                    });
                }}
            />

            <FetchOrderlinesDataWrapper
                eventId={eventId}
                filter={filterOptions}
                q={customFields.q}
                invalidatedAt={orderLinesInvalidatedAt}
                sorting={sortingOptions.map(tableSortingToSortingOptionMapper)}
                page={Number(customFields['page'] || 1)}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
                onSuccess={refreshSeatingPlanCategories}
            >
                {({loading, data}) => (
                    <>
                        {loading && <LoadingOverlay/>}
                        <TableHeaderTotalFeature
                            title="Orderlines"
                            totalResults={data?.meta.total}
                        />
                        <OrderlinesTable
                            eventId={eventId}
                            orderlines={data?.data || []}
                            loading={loading}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({...customFields, page: page.toString()}),
                            }}
                            onSortingChange={(tableSorting) => setSortingOptions(tableSorting)}
                            onClickDetails={onClickDetails}
                            onClickAssign={onClickAssign}
                            initialSelectedRows={selectedRows}
                            tableToolbarActions={(selectedRows) => [
                                unassignTicketAction(selectedRows),
                            ]}
                        />
                    </>
                )}
            </FetchOrderlinesDataWrapper>
        </>
    );
}
