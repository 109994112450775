import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import ticketService from 'src/data/services/ticketService';

interface UploadTicketFileDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    uploadTicketFile: (ticketId: string, file: File) => Promise<ApiResponseBody<EmptyBody>>;
}

type UploadTicketFileDataWrapperProps = DataWrapperProps<
    UploadTicketFileDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useUploadTicketFileDataWrapper = ({
                                                   onError,
                                                   onSuccess,
                                                   disableDefaultSuccessMessage,
                                                   useDefaultErrorHandler = true,
                                               }: Omit<UploadTicketFileDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleUploadTicketFile] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const uploadTicketFile = (ticketId: string, file: File) => {
        return handleUploadTicketFile(ticketService.updateTicketPdf(ticketId, file), {
            defaultSuccessMessage: disableDefaultSuccessMessage ? null : 'Ticket has been uploaded',
            useDefaultErrorHandler,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        uploadTicketFile,
        data,
        loading,
        errors,
        resetData,
    };
};
