import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    EVENT_CATEGORIES_ARCHIVE_CATEGORY_ENDPOINT,
    EVENT_CATEGORIES_CREATE_ENDPOINT,
    EVENT_CATEGORIES_GET_ENDPOINT,
    EVENT_CATEGORIES_UNARCHIVE_CATEGORY_ENDPOINT,
} from 'src/data/constants/endpoints/event-categories-endpoints';
import ApiResponseBody from '../api/responses/ApiResponseBody';
import {EmptyBody} from '../models/common/emptyBody';
import {IdResponse} from '../models/common/idResponse';

export interface EventCategory {
    id: string;
    name: string;
    isArchived: boolean;
    key: string;
}

export async function getEventCategories(options?: BaseFetchOptions) {
    const extraQueryParams: QueryParam[] = [];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance().get<PaginatedApiResponseBody<EventCategory>>(
        EVENT_CATEGORIES_GET_ENDPOINT,
        {
            params: options && getQueryParams(options, extraQueryParams),
        }
    );
}

export interface CreateEventCategoryDto {
    key: string;
    name: string;
    parentId?: string;
}

export async function createEventCategory(
    dto: CreateEventCategoryDto
): Promise<ApiResponseBody<IdResponse>> {
    return await getInternalAxiosInstance().post<IdResponse>(EVENT_CATEGORIES_CREATE_ENDPOINT, dto);
}

export async function archiveEventCategory(id: string) {
    return await getInternalAxiosInstance().post<EmptyBody>(
        EVENT_CATEGORIES_ARCHIVE_CATEGORY_ENDPOINT(id)
    );
}

export async function unarchiveEventCategory(id: string) {
    return await getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        EVENT_CATEGORIES_UNARCHIVE_CATEGORY_ENDPOINT(id)
    );
}
