import {PURCHASE_STATUS} from 'src/data/enums/purchase';
import {FilterAutoCompleteOptions} from 'src/view/components/filters/AutoComplete/AutoComplete';

export const purchaseStatusOptions: FilterAutoCompleteOptions = Object.keys(PURCHASE_STATUS).map(
    (key) => ({
        value: key,
        label: key,
    })
);

export const purchaseFinalizedStatusOptions: FilterAutoCompleteOptions = [
    {
        value: 'true',
        label: 'Finalized',
    },
    {
        value: 'false',
        label: 'Non-finalized',
    },
];
