import {useEffect, useState} from 'react';
import {Alert, Grid} from '@mui/material';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {TicketsTableDataResolver} from 'src/app/components/tables/TicketsTable';
import {useFetchTicketsDataWrapper} from 'src/app/components/data-wrappers/tickets/FetchTicketsDataWrapper';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import Ticket from 'src/data/models/tickets/ticket';
import {useUpdateSplitDataWrapper} from 'src/app/components/data-wrappers/splits/UpdateSplitDataWrapper';
import {useFetchTicketByIdDataWrapper} from 'src/app/components/data-wrappers/tickets/FetchTicketByIdDataWrapper';
import {LoadingRow} from 'src/view/components/loading-row/LoadingRow';
import {SplitTicketsTableForm} from 'src/app/components/forms/tickets/splits/SplitTicketsTableForm';
import {config} from 'src/app/constants/config/config';

interface DeleteTicketsFromSplitFeatureProps {
    eventId: string;
    selectedTickets: RowIdResolver<TicketsTableDataResolver>[];
    onSuccess?: () => void;
}

export const DeleteTicketsFromSplitFeature = ({
                                                  eventId,
                                                  selectedTickets,
                                                  onSuccess,
                                              }: DeleteTicketsFromSplitFeatureProps) => {
    const [ticketsInvalidatedAt, setTicketsInvalidatedAt] = useState<number>();
    const [fetchedSelectedTickets, setFetchedSelectedTicket] = useState<Ticket[]>([]);
    const splitName = selectedTickets[0]?.data?.splitName || '';

    const {updateSplit, loading: updateSplitIsLoading} = useUpdateSplitDataWrapper({
        onSuccess: () => {
            setTicketsInvalidatedAt(Date.now());
            onSuccess?.();
        },
    });

    const {
        loading: fetchTicketsByIdIsLoading,
        fetchTicketById,
        errors: FetchTicketByIdErrors,
    } = useFetchTicketByIdDataWrapper({});

    const {
        data: fetchedSplitTickets,
        loading,
        errors,
        fetchTickets,
    } = useFetchTicketsDataWrapper({
        eventId,
        includes: ['splitStatistics'],
        pageSize: config.ITEMS_PER_PAGE_LARGE,
        invalidatedAt: ticketsInvalidatedAt,
        skipInitialCall: true,
        filter: [{property: 'splitName', value: splitName}],
    });

    const ticketsToRemoveIDs = () => {
        return fetchedSelectedTickets.map((t) => t.id);
    };

    const fetchSelectedTickets = async () => {
        return await Promise.all(selectedTickets.map((t) => fetchTicketById(t.id)));
    };

    useEffect(() => {
        const handleFetchedSelectedTickets = async () => {
            const resultTickets = await fetchSelectedTickets();
            const mappedTickets = resultTickets.map((t) => t.data);
            setFetchedSelectedTicket(mappedTickets);
        };

        handleFetchedSelectedTickets();
    }, [ticketsInvalidatedAt]);

    useEffect(() => {
        setFetchedSelectedTicket([]);

        fetchTickets({
            filter: [{property: 'splitName', value: splitName}],
        });
    }, []);

    const getRemainingSplitTickets = () => {
        if (!fetchedSplitTickets || !fetchedSplitTickets.data.length) return [];

        return fetchedSplitTickets.data
            .filter((ticket) => !ticketsToRemoveIDs().includes(ticket.id))
            .sort((a, b) => a.sortOrder - b.sortOrder);
    };

    const onFormSubmit = (tickets: Ticket[]) => {
        updateSplit({
            splitId: fetchedSplitTickets?.data[0].splitId || '',
            tickets: tickets.map((ticket) => ({
                ticketId: ticket.id,
                sortOrder: ticket.sortOrder,
            })),
        });
    };

    return (
        <Grid container spacing={2}>
            {loading && <LoadingRow/>}

            <Grid item xs={12}>
                <Alert severity="warning">
                    You are about to delete <strong>{selectedTickets.length} ticket(s)</strong> from
                    the split. The position order will be automatically re-ordered starting from{' '}
                    <strong>1</strong>. Adjust the position order if needed and then click{' '}
                    <strong>Confirm</strong>.
                </Alert>
            </Grid>

            {getRemainingSplitTickets().length === 0 && !loading && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        No split tickets will be left after confirming this deletion action, and the
                        split will be removed.
                    </Alert>
                </Grid>
            )}

            {errors && (
                <Grid item xs={12}>
                    <ErrorsList errors={errors}/>
                </Grid>
            )}

            {fetchedSplitTickets &&
                fetchedSplitTickets.data.length !== 0 &&
                FetchTicketByIdErrors.length === 0 && (
                    <Grid item xs={12}>
                        <SplitTicketsTableForm
                            tableHeaderText="Remaining Split Tickets"
                            splitTickets={getRemainingSplitTickets()}
                            isTableLoading={
                                loading || updateSplitIsLoading || fetchTicketsByIdIsLoading
                            }
                            isSubmitDisabled={updateSplitIsLoading || loading}
                            submitButtonText="Confirm"
                            onFormSubmit={onFormSubmit}
                            preserveSortOrder
                        />
                    </Grid>
                )}

            {FetchTicketByIdErrors.length > 0 && (
                <Grid item xs={12}>
                    <Alert severity="error">Something went wrong while getting the ticket.</Alert>
                </Grid>
            )}
        </Grid>
    );
};
