import React from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import {ChildlessBaseComponent} from 'src/view/interfaces/BaseComponent';

interface CheckIconProps extends ChildlessBaseComponent {
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    success?: boolean;
    height?: number;
    width?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    checkIcon: {
        height: (props: CheckIconProps) => props.height,
        width: (props: CheckIconProps) => props.width,
        color: theme.palette.action.disabled,
    },
    success: {
        color: theme.palette.success.main,
    },
}));

const CheckIcon = ({
                       className,
                       onClick,
                       success = false,
                       height = 20,
                       width = 20,
                   }: CheckIconProps): JSX.Element => {
    const classes = useStyles({height, width});

    return (
        <CheckCircleIcon
            className={`
                ${classNames({
                [classes.checkIcon]: true,
                [classes.success]: success,
            })}
                ${className ? className : ''}
            `}
            onClick={onClick}
        />
    );
};

export default CheckIcon;
