import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Variant = 'error' | 'success' | 'warning' | 'completed';

export interface StatusLabelProps {
    label: string;
    variant: Variant;
}

const getBackgroundColor = (theme: Theme, variant?: Variant) => {
    const variantColorMap: Record<Variant, string> = {
        error: theme.palette.error.main,
        success: theme.palette.success.main,
        warning: theme.palette.secondary.main,
        completed: theme.colors.cyanBlue?.toString() || "",
    };

    return variant ? variantColorMap[variant] : theme.colors.mediumGrey;
};

const useStyles = makeStyles((theme: Theme) => ({
    statusLabelContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    statusLabel: (props: Partial<StatusLabelProps>) => ({
        backgroundColor: getBackgroundColor(theme, props.variant),
        borderRadius: theme.layout.borderRadius.rounded,
        height: '.625rem',
        width: '.625rem',
        marginRight: theme.spacing(1),
    }),
}));

export default function StatusLabel({label, variant}: StatusLabelProps): JSX.Element {
    const classes = useStyles({label, variant});

    return (
        <div className={classes.statusLabelContainer}>
            <div className={classes.statusLabel}></div>
            <div>
                <span>{label}</span>
            </div>
        </div>
    );
}
