import {useMutation, useQueryClient} from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import {useConfirm} from 'material-ui-confirm';
import {useCallback, useMemo} from 'react';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import purchaseService, {DeleteTicketsFromDTO} from 'src/data/services/purchaseService';
import {Divider} from 'src/view/components/divider/Divider';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import EditPurchasePricesForm, {
    EditPurchasePriceFormValuesProps,
    EditPurchasePricesFormSubmitValues,
    PurchasePricesPerCategory,
} from '../../forms/purchase/EditPurchasePricesForm';
import {TicketsTableDataResolver} from '../../tables/TicketsTable';
import {PURCHASE_PRICES_QUERY} from './AdvancedEditPurchaseFormFeature';
import {PURCHASE_TICKETS_QUERY} from './PurchaseTicketsTableFeature';

interface DeletePurchaseTicketsModalFeatureProps {
    selectedTickets: RowIdResolver<TicketsTableDataResolver>[];
    purchaseId: string;
    purchasePrices: PurchasePricesPerCategory[];
    onCloseModal: () => void;
    onSuccessfulDeletion: (followUpModalData: EditPurchasePriceFormValuesProps[]) => void;
}

const DeletePurchaseTicketsModalFeature = ({
                                               selectedTickets,
                                               onCloseModal,
                                               purchaseId,
                                               purchasePrices,
                                               onSuccessfulDeletion,
                                           }: DeletePurchaseTicketsModalFeatureProps) => {
    const queryClient = useQueryClient();
    const confirm = useConfirm();

    const {
        mutateAsync: deletePurchaseTickets,
        error,
        isError,
        isLoading,
    } = useMutation({
        mutationKey: ['deleteTickets'],
        mutationFn: (dto: DeleteTicketsFromDTO) =>
            purchaseService.deleteTicketsFromPurchase(purchaseId, dto),
    });

    const refetchInvalidatedData = useCallback(async () => {
        queryClient.invalidateQueries({queryKey: [PURCHASE_PRICES_QUERY]});
        queryClient.invalidateQueries({queryKey: [PURCHASE_TICKETS_QUERY]});
    }, [queryClient]);

    const selectedTicketsGroupedByEventAndCategory = useMemo(() => {
        return groupBy(
            selectedTickets,
            (item) =>
                `${item.data?.eventName} - ${item.data?.seatingplanCategoryName} - ${item.data?.bookingOptionName}`
        );
    }, [selectedTickets]);

    const formattedTicketsToBeDeletedData = useMemo(() => {
        const updatedCategoryPrices = purchasePrices.map((existingCategory) => {
            const {seatingPlanCategoryName, quantity, eventName, bookingOptionName} =
                existingCategory;
            const existingCategoryTickets =
                selectedTicketsGroupedByEventAndCategory[
                eventName + ' - ' + seatingPlanCategoryName + ' - ' + bookingOptionName
                    ] || [];

            const updatedTicketQuantity = quantity - existingCategoryTickets.length; // Updates the amount of tickets which will be left upon potential deletion
            const updatedCategory = {
                ...existingCategory,
                quantity: updatedTicketQuantity >= 0 ? updatedTicketQuantity : 0, // Fallback
                calculation: existingCategoryTickets.length
                    ? `${quantity} existing - ${existingCategoryTickets.length} removed`
                    : null,
            };

            return updatedCategory;
        });

        return updatedCategoryPrices;
    }, [purchasePrices, selectedTicketsGroupedByEventAndCategory]);

    const onConfirmDeletion = useCallback(
        async (values: EditPurchasePricesFormSubmitValues) => {
            try {
                await confirm({
                    title: 'Are you sure you want delete the purchase tickets?',
                    description: 'You can add more tickets later if necessary.',
                });

                await deletePurchaseTickets({
                    ticketIds: selectedTickets.map((t) => t.id),
                    ...values,
                }).then(async () => {
                    await refetchInvalidatedData();
                    onCloseModal();
                    onSuccessfulDeletion(
                        uniqBy(values.SeatingPlanCategoryBookingOptionPrices, 'eventId')
                    );
                });
            } catch (err) {
                /* Empty */
            }
        },
        [confirm, deletePurchaseTickets]
    );

    return (
        <Modal open title="Delete selected tickets" onClose={onCloseModal} width="fluid">
            <>
                {isError && <ErrorsList errors={parseErrors(error)}/>}
                {isLoading && <LoadingOverlay/>}

                <>
                    <Divider/>
                    <strong>Following tickets are gonna be deleted:</strong>
                    {Object.entries(selectedTicketsGroupedByEventAndCategory).map(
                        ([categoryName, tickets]) => {
                            const ticketsCount = tickets.length;
                            return (
                                <p key={categoryName}>
                                    <strong>{ticketsCount}</strong> ticket(s) from{' '}
                                    <strong>{categoryName}</strong>
                                </p>
                            );
                        }
                    )}
                    <Divider/>
                    <strong>This leaves the total purchase with the following:</strong>
                    {!!formattedTicketsToBeDeletedData.length && (
                        <EditPurchasePricesForm
                            purchasePrices={formattedTicketsToBeDeletedData}
                            onSubmit={onConfirmDeletion}
                        />
                    )}
                </>
            </>
        </Modal>
    );
};

export default DeletePurchaseTicketsModalFeature;
