import {compact} from 'lodash';
import {useMemo} from 'react';
import {Restrictions} from 'src/app/components/events/event-details-page/event-restrictions';
import urlService, {defaultCustomFields} from 'src/app/services/urlService';
import {filterOptionsToQueryParams} from 'src/app/utilities/helpers/filter';
import {EVENT_TYPE, EventDetails} from 'src/data/models/events/event';
import Chip from 'src/view/components/chip/Chip';
import DictionaryGrid from 'src/view/components/dictionary-grid/DictionaryGrid';

export default function EventDetailsDictionary({eventDetails}: { eventDetails: EventDetails }) {
    const dictionaryData = useMemo(() => {
        const {
            eventType,
            dateConfirmed,
            eventCategory,
            matches,
            performerNames,
            homeTeam,
            awayTeam,
            venueName,
            tags,
            description,
            seriesName,
            organizerName,
            seatingPlanName,
            restrictions,
        } = eventDetails;

        return compact([
            {label: 'Event Type', value: eventType},
            eventType === EVENT_TYPE.TeamSports && {
                label: 'Home Team',
                renderCustomValue: () => <Chip label={homeTeam?.name} rounded/>,
            },
            eventType === EVENT_TYPE.TeamSports && {
                label: 'Away Team',
                renderCustomValue: () => <Chip label={awayTeam?.name} rounded/>,
            },
            eventType === EVENT_TYPE.Tennis && {
                label: 'Matches',
                renderCustomValue: () => createChipItems(matches),
            },
            (eventType === EVENT_TYPE.Concert || eventType === EVENT_TYPE.Sports) && {
                label: eventType === EVENT_TYPE.Concert ? 'Artists' : 'Contestants',
                renderCustomValue: () => createChipItems(performerNames),
            },
            {
                label: 'Date Confirmed',
                renderCustomValue: () => (dateConfirmed ? 'Yes' : 'No'),
            },
            {label: 'Description', value: description},
            {label: 'Event Category', value: eventCategory},
            {label: 'Series', value: seriesName},
            {label: 'Organizer', value: organizerName},
            {
                label: 'Venue',
                value: venueName,
                hyperlink: getVenueUrl(venueName),
            },
            {label: 'Seating Plan', value: seatingPlanName},
            {
                label: 'Tags',
                renderCustomValue: () => createChipItems(tags.map((tag) => tag.name)),
            },
            restrictions && {
                label: 'Restrictions',
                renderCustomValue: () => <Restrictions restrictions={restrictions}/>,
            },
        ]);
    }, [eventDetails]);

    return (
        <>
            <h3>Event Details</h3>
            <DictionaryGrid data={dictionaryData} boldStyling/>
        </>
    );
}

const createChipItems = (items: string[] | null) =>
    items?.map((item) => <Chip key={item} label={item} rounded/>);

const getVenueUrl = (venueName: string) =>
    urlService.getBasicAdministration(
        `/venues${filterOptionsToQueryParams({
            customFields: {...defaultCustomFields, q: venueName},
            suffix: 'venues',
        })}`
    );
