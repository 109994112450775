import {useMemo} from 'react';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import FilterOption from 'src/data/api/common/FilterOption';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import SplitFiltersForm from 'src/app/components/forms/SplitFiltersForm';
import {useFetchSeatingPlanCategories} from 'src/app/hooks/useFetchSeatingPlanCategories';
import {parseErrors} from 'src/app/utilities/helpers/errors';

interface Props {
    eventId: string;
    initialOptions: FilterOption[];
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    enableInitialSelection?: boolean;
    onChangeSearchTerm?: (q: string) => void;
    initialSearchTerm?: string;
}

export function SplitFiltersFormFeature({
                                            eventId,
                                            initialOptions,
                                            onChangeFilterOptions,
                                            onChangeSearchTerm,
                                            initialSearchTerm,
                                        }: Props) {
    const {data, isLoading, error: errors, isError} = useFetchSeatingPlanCategories(eventId);

    const categoryOptions = useMemo(() => {
        if (!data) return [];

        return data.data.data.categories.map((c) => ({
            label: c.name,
            value: c.id,
        }));
    }, [data]);

    const formDefaultValues = useMemo(() => {
        if (!data) return {};

        const selectedSeatingPlanCategoryOptions =
            tableFilterFormHelper.getInitialAutocompleteValues(
                'seatingPlanCategoryId',
                initialOptions,
                categoryOptions
            )?.[0];

        return {
            category: selectedSeatingPlanCategoryOptions,
            searchTerm: '',
        };
    }, [data, initialOptions, categoryOptions]);

    if (isLoading && !data) {
        return <LoadingOverlay/>;
    }

    if (isError) {
        return <ErrorsList errors={parseErrors(errors)}/>;
    }

    return (
        <SplitFiltersForm
            categoriesOptions={categoryOptions}
            defaultValues={formDefaultValues}
            onChangeFilterOptions={onChangeFilterOptions}
            onChangeSearchTerm={onChangeSearchTerm}
            initialSearchTerm={initialSearchTerm}
        />
    );
}
