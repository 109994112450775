import {useMutation} from '@tanstack/react-query';
import ticketsService from 'src/data/services/ticketsService';

type TData = Awaited<ReturnType<typeof ticketsService.unlockTickets>>;
type TVariables = string[];

export const useUnlockTickets = (options: {
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown;
}) => {
    return useMutation({
        mutationFn: (dto: TVariables) => ticketsService.unlockTickets(dto),
        ...options,
    });
};
