import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import eventService from 'src/data/services/eventService';
import {EmptyBody} from 'src/data/models/common/emptyBody';

interface UpdateEventOrganizerDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    updateEventOrganizer: (organizerId: string) => Promise<void | ErrorReturn>;
}

interface UpdateEventOrganizerDataWrapperProps
    extends DataWrapperProps<UpdateEventOrganizerDataWrapperChildrenParams> {
    eventId: string;
}

export const useUpdateEventOrganizerDataWrapper = ({
                                                       eventId,
                                                       onError,
                                                       onSuccess,
                                                   }: Omit<UpdateEventOrganizerDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{loading, errors, resetData, data}, handleUpdateOrganizer] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const updateEventOrganizerCall = async (organizerId: string) => {
        await handleUpdateOrganizer(eventService.updateEventOrganizer(eventId, organizerId), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        updateEventOrganizer: updateEventOrganizerCall,
        data,
        loading,
        errors,
        resetData,
    };
};
