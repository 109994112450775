import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import seatingPlanService, {UpdateSpcOrderDto} from 'src/data/services/seatingPlanService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';

interface useUpdateSeatingplanSpcOrderDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    updateSpcOrder: (dto: UpdateSpcOrderDto) => Promise<void | ErrorReturn> | undefined;
}

interface UpdateSeatingplanSpcOrderDataWrapperProps
    extends DataWrapperProps<
        useUpdateSeatingplanSpcOrderDataWrapperChildrenParams,
        ApiResponseBody<IdResponse>
    >,
        BaseFetchOptions {
    seatingplanId?: string;
}

export const useUpdateSeatingplanSpcOrderDataWrapper = ({
                                                            onError,
                                                            onSuccess,
                                                            seatingplanId,
                                                        }: Omit<UpdateSeatingplanSpcOrderDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleUpdateSeatingplanSpcOrder] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const updateSpcOrderCall = (dto: UpdateSpcOrderDto) => {
        if (!seatingplanId) return;

        return handleUpdateSeatingplanSpcOrder(
            seatingPlanService.updateSpcOrder(seatingplanId, dto),
            {
                useDefaultErrorHandler: true,
                onSuccess,
                onFail: onError,
            }
        );
    };

    return {
        updateSpcOrder: updateSpcOrderCall,
        data,
        loading,
        errors,
        resetData,
    };
};
