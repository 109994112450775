import WarningIcon from '@mui/icons-material/Warning';
import {Box, Tooltip} from '@mui/material';
import {useTheme} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import BigNumber from 'bignumber.js';
import sumBy from 'lodash/sumBy';
import OrderPriceWarningPopover from 'src/app/components/pop-overs/orders/OrderPriceWarningPopover';
import Price from 'src/data/models/common/price';
import {EventSeatingPlanCategory} from 'src/data/models/events/event';
import {SIZES} from 'src/shared/theme/enums';
import HelpPopover from 'src/view/components/help-popover/HelpPopover';
import Popover from 'src/view/components/popover/Popover';
import {Price as PriceComponent} from 'src/view/components/price/Price';
import Table from 'src/view/components/table/table/Table';
import {NewTableColumn, TABLE_CELL_WIDTH} from 'src/view/components/table/table/Types';
import {TextCopier} from 'src/view/components/text-copier/TextCopier';
import {WarningText} from 'src/view/components/warning-text/WarningText';
import IconComponent from 'src/view/icons/IconComponent';

const useStyles = makeStyles((theme) => ({
    copyContainer: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
    },
    textCopier: {
        marginRight: theme.spacing(1),
    },
    nameLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    additionalCurrencies: {
        fontSize: '.875rem',
        color: theme.colors.grey,
    },
}));

export interface DetailsSalesTableProps {
    eventSeatingplanCategories?: EventSeatingPlanCategory[];
    loading?: boolean;
}

export default function DetailsSalesTable({
                                              loading,
                                              eventSeatingplanCategories = [],
                                          }: DetailsSalesTableProps): JSX.Element {
    const classes = useStyles();
    const theme = useTheme();

    const sumPrices = (prices: Price[], totalPrices: Price[]) => {
        prices.forEach((c) => {
            const priceForCurrencyIndex = totalPrices.findIndex((p) => p.currency === c.currency);

            if (priceForCurrencyIndex === -1) {
                totalPrices.push(c);

                return;
            }

            const priceForCurrency = totalPrices[priceForCurrencyIndex]?.value ?? 0;
            const categoryPrice = c.value ?? 0;

            totalPrices[priceForCurrencyIndex] = {
                currency: c.currency,
                value: priceForCurrency + categoryPrice,
            };
        });

        return totalPrices;
    };

    const sumTotalTicketsPrices = (seatingplanCategories: EventSeatingPlanCategory[]): Price[] => {
        const totalPrices: Price[] = [];

        seatingplanCategories.forEach((s) => {
            if (!s.totalTicketPricesPerCurrency) return;

            sumPrices(s.totalTicketPricesPerCurrency, totalPrices);
        });

        return totalPrices;
    };

    const sumTotalSalesPrices = (seatingplanCategories: EventSeatingPlanCategory[]): Price[] => {
        const totalPrices: Price[] = [];

        seatingplanCategories.forEach((s) => {
            if (!s.totalSalesPricesPerCurrency) return;

            sumPrices(s.totalSalesPricesPerCurrency, totalPrices);
        });

        return totalPrices;
    };

    const purchaseTotal = sumBy(eventSeatingplanCategories, (e) => e.ticketsTotal);
    const soldTotal = sumBy(eventSeatingplanCategories, (e) => e.ticketsSold);
    const sellableAvailabilityTotal = sumBy(
        eventSeatingplanCategories,
        (e) => e.ticketsTotal - e.ticketsSold
    );
    const assignableAvailabilityTotal = sumBy(
        eventSeatingplanCategories,
        (e) => e.ticketsTotal - e.ticketsAssigned
    );
    const ticketsTotalPrice = sumTotalTicketsPrices(eventSeatingplanCategories);
    const ticketsTotalSalesPrice = sumTotalSalesPrices(eventSeatingplanCategories);
    const TicketsTotalAssigned = sumBy(eventSeatingplanCategories, (e) => e.ticketsAssigned);

    const getInventoryTotalColor = (total: number) => {
        if (total < 0) return theme.palette.error.main;
        if (total > 0) return theme.palette.success.main;

        return;
    };

    const columns: NewTableColumn<EventSeatingPlanCategory>[] = [
        {
            key: 'bookingOptionName',
            title: 'Booking Option',
            cellRenderer: (eventSeatingplanCategory) => (
                <span>{eventSeatingplanCategory.bookingOptionName}</span>
            ),
            width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            total: 'Totals',
        },
        {
            key: 'name',
            title: 'Seatingplan category',
            cellRenderer: (eventSeatingplanCategory) => (
                <div className={classes.nameLabel}>
                    <Tooltip title={eventSeatingplanCategory.seatingPlanCategoryId}>
                        <div className={classes.copyContainer}>
                            <TextCopier
                                value={eventSeatingplanCategory.seatingPlanCategoryId}
                                hideLabel
                                className={classes.textCopier}
                            />
                        </div>
                    </Tooltip>
                    <div>
                        <span>
                            {eventSeatingplanCategory.seatingPlanCategoryName}
                            {eventSeatingplanCategory.isArchived && <WarningText text="Archived"/>}
                        </span>
                    </div>
                </div>
            ),
            width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            total: 'Totals',
        },
        {
            key: 'ticketsTotal',
            title: (
                <Box display="flex">
                    Tickets
                    <HelpPopover
                        muted
                        content={{
                            text: 'Total initial tickets per category.',
                        }}
                    />
                </Box>
            ),
            cellRenderer: (eventSeatingplanCategory) => eventSeatingplanCategory.ticketsTotal,
            width: TABLE_CELL_WIDTH.SMALL,
            total: (
                <span style={{color: getInventoryTotalColor(purchaseTotal)}}>
                    {purchaseTotal}
                </span>
            ),
        },
        {
            key: 'ticketsSold',
            title: (
                <Box display="flex">
                    Sales
                    <HelpPopover
                        muted
                        content={{
                            text: 'Number of tickets initially sold.',
                        }}
                    />
                </Box>
            ),
            cellRenderer: (eventSeatingplanCategory) => eventSeatingplanCategory.ticketsSold,
            width: TABLE_CELL_WIDTH.SMALL,
            total: <span style={{color: getInventoryTotalColor(soldTotal)}}>{soldTotal}</span>,
        },

        {
            key: 'ticketsAssigned',
            title: (
                <Box display="flex">
                    Assigned
                    <HelpPopover
                        muted
                        content={{
                            text: 'Tickets assigned per category.',
                        }}
                    />
                </Box>
            ),
            cellRenderer: (eventSeatingplanCategory) => {
                return (
                    <>
                        {eventSeatingplanCategory.ticketsAssigned}
                        {eventSeatingplanCategory.isAssignedDifferently && (
                            <Popover
                                text={
                                    <IconComponent Icon={WarningIcon} warning size={SIZES.SMALL}/>
                                }
                            >
                                <p>
                                    There are assignments not relevant to this category. Can be
                                    either from event or category upgrade.
                                </p>
                            </Popover>
                        )}
                    </>
                );
            },
            total: (
                <span style={{color: getInventoryTotalColor(TicketsTotalAssigned)}}>
                    {TicketsTotalAssigned}
                </span>
            ),
        },
        {
            key: 'ticketsAvailable',
            title: (
                <Box display="flex">
                    Assignable Availability
                    <HelpPopover
                        muted
                        content={{
                            text: 'Tickets available for assignment.',
                        }}
                    />
                </Box>
            ),
            cellRenderer: (eventSeatingplanCategory) => {
                const inventoryCount = new BigNumber(eventSeatingplanCategory.ticketsTotal).minus(
                    eventSeatingplanCategory.ticketsAssigned
                );

                return (
                    <div style={{color: getInventoryTotalColor(inventoryCount.toNumber())}}>
                        {inventoryCount.toString()}
                    </div>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            total: (
                <span style={{color: getInventoryTotalColor(assignableAvailabilityTotal)}}>
                    {assignableAvailabilityTotal}
                </span>
            ),
        },
        {
            key: 'ticketsAvailable',
            title: (
                <Box display="flex">
                    Sellable Availability
                    <HelpPopover
                        muted
                        content={{
                            text: 'Tickets available for sale.',
                        }}
                    />
                </Box>
            ),
            cellRenderer: (eventSeatingplanCategory) => {
                const inventoryCount = new BigNumber(eventSeatingplanCategory.ticketsTotal).minus(
                    eventSeatingplanCategory.ticketsSold
                );

                return (
                    <div style={{color: getInventoryTotalColor(inventoryCount.toNumber())}}>
                        {inventoryCount.toString()}
                    </div>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            total: (
                <span style={{color: getInventoryTotalColor(sellableAvailabilityTotal)}}>
                    {sellableAvailabilityTotal}
                </span>
            ),
        },
        {
            title: 'Average ticket price',
            cellRenderer: ({averageTicketPricesPerCurrency}) => {
                return (
                    <span>
                        {averageTicketPricesPerCurrency?.map((price) => (
                            <div>
                                <PriceComponent price={price}/>
                            </div>
                        ))}
                    </span>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            truncate: true,
        },

        {
            title: 'Average sales price',
            cellRenderer: (eventSeatingplanCategory) => {
                const {averageSalesPricesPerCurrency, hasSalesPrice} = eventSeatingplanCategory;

                return (
                    <div>
                        {averageSalesPricesPerCurrency?.map((price) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <PriceComponent price={price}/>
                                    {price.currency === 'EUR' && !hasSalesPrice && (
                                        <OrderPriceWarningPopover/>
                                    )}
                                </Box>
                            );
                        })}
                    </div>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            truncate: true,
        },
        {
            title: 'Total tickets price',
            cellRenderer: ({totalTicketPricesPerCurrency}) => {
                return (
                    <span>
                        {totalTicketPricesPerCurrency?.map((price) => (
                            <div>
                                <PriceComponent price={price}/>
                            </div>
                        ))}
                    </span>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            colored: {
                mode: 'all',
            },
            total: (
                <span>{ticketsTotalPrice?.map((ticket) => <PriceComponent price={ticket}/>)}</span>
            ),
            truncate: true,
            truncateTotal: true,
        },
        {
            title: 'Total sales price',
            cellRenderer: (eventSeatingplanCategory) => {
                const {totalSalesPricesPerCurrency, hasSalesPrice} = eventSeatingplanCategory;

                return (
                    <div>
                        {totalSalesPricesPerCurrency?.map((price) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <PriceComponent price={price}/>
                                    {price.currency === 'EUR' && !hasSalesPrice && (
                                        <OrderPriceWarningPopover/>
                                    )}
                                </Box>
                            );
                        })}
                    </div>
                );
            },

            width: TABLE_CELL_WIDTH.MEDIUM,
            colored: {
                mode: 'all',
            },
            total: (
                <div>
                    {ticketsTotalSalesPrice.map((price) => (
                        <div>
                            <PriceComponent price={price}/>
                        </div>
                    ))}
                </div>
            ),
            truncate: true,
            truncateTotal: false,
        },
        /** Following is commented out, because historically the users want something we have removed, EVEN IF IT WAS THEIR REQUEST
         * Bring back if such request occurs */
        /** {
         title: (
         <Box display="flex">
         Total sales price (EUR)
         <HelpPopover
         content={{
         title: '',
         text: 'This is the total of all sales together regardless of the currency (in EUR).',
         }}
         />
         </Box>
         ),
         width: TABLE_CELL_WIDTH.MEDIUM,
         cellRenderer: (eventSeatingplanCategory) => {
         const totalSalesPriceEuros = {
         currency: 'EUR',
         value: sumBy(eventSeatingplanCategories, (e) => e.totalSalesPricesAsEuro?.value || 0),
         };
         const { totalSalesPricesAsEuro } = eventSeatingplanCategory;

         return (
         <div className={classes.copyContainer}>
         {totalSalesPricesAsEuro && (
         <PriceComponent price={totalSalesPricesAsEuro} />
         )}
         </div>
         );
         },
         colored: {
         mode: 'all',
         },
         total: (
         <div>
         <PriceComponent price={totalSalesPriceEuros} />
         </div>
         ),
         truncate: true,
         truncateTotal: false,
         },
         */
    ];

    return (
        <div>
            <Table<EventSeatingPlanCategory>
                data={eventSeatingplanCategories}
                rowIdResolver={(rowData) => ({
                    id: rowData.seatingPlanCategoryId,
                })}
                columns={columns}
                loading={loading}
                minWidth={650}
            />
        </div>
    );
}
