import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export interface StatusChipProps {
    label: string;
    variant?: 'error' | 'success' | 'warning' | 'info';
    Icon?: React.ReactElement;
}

const getBackgroundColor = (theme: Theme, variant?: string) => {
    if (variant === 'error') return theme.palette.error.main;
    if (variant === 'success') return theme.palette.success.main;
    if (variant === 'warning') return theme.palette.secondary.main;
    if (variant === 'info') return theme.colors.linkBlue;

    return theme.colors.mediumGrey;
};

const useStyles = makeStyles((theme: Theme) => ({
    statusChipContainer: (props: Partial<StatusChipProps>) => ({
        display: 'flex',
        alignItems: 'center',
        backgroundColor: getBackgroundColor(theme, props.variant),
        borderRadius: theme.layout.borderRadius.large,
        padding: theme.spacing(0, 1),
        width: 'fit-content',
        height: '1.4rem',
        fontSize: '0.7rem',
        color: theme.colors.white,
        '& .MuiSvgIcon-root': {
            fontSize: '0.9rem',
            marginRight: theme.spacing(0.5),
        },
    }),
}));

export default function StatusChip({label, variant, Icon}: StatusChipProps): JSX.Element {
    const classes = useStyles({label, variant});

    return (
        <span className={classes.statusChipContainer}>
            {Icon && Icon}
            {label}
        </span>
    );
}
