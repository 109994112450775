import {useMutation, useQueryClient} from '@tanstack/react-query';
import {deleteTeam} from 'src/data/services/teamsService';
import {TEAMS_QUERY_KEY} from './useFetchTeams';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';

export const useDeleteTeam = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (ids: string[]) => deleteTeam(ids),
        onSuccess: () => {
            queryClient.invalidateQueries([TEAMS_QUERY_KEY]);
            Toaster.toast('Successfully deleted the team.', {
                variant: 'success',
            });
            onSuccess?.();
        },
        onError: (error) => Toaster.toastErrors(parseErrors(error)),
    });
};
