import {useCallback} from 'react';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import {useForm} from 'react-hook-form';
import {config} from 'src/app/constants/config/config';
import EventCategoriesTable from '../../tables/EventCategoriesTable';
import {useEventCategories} from 'src/app/hooks/event-categories/useEventCategories';
import useArchiveEventCategory from 'src/app/hooks/event-categories/useArchiveEventCategory';
import useUnarchiveEventCategory from 'src/app/hooks/event-categories/useUnarchiveEventCategory';
import {useConfirm} from 'material-ui-confirm';

export default function EventCategoriesTableFeature(): JSX.Element {
    const confirm = useConfirm();

    const {
        values: {customFields},
        setCustomFields,
    } = useQueryParams('event-categories');

    const {control} = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const page = Number(customFields['page'] || 0);
    const defaultQuery = customFields.q;

    const {data: eventCategoriesResponse, isLoading: isEventCategoriesLoading} =
        useEventCategories({
            pageSize: config.ITEMS_PER_PAGE_STANDARD,
            q: customFields.q,
            filter: [
                {
                    value: 'true',
                    property: 'WithArchived',
                },
            ],
            page,
        });

    const {mutate: archiveEventCategory, isLoading: isArchiving} = useArchiveEventCategory();
    const {mutate: unarchiveEventCategory, isLoading: isUnarchiving} =
        useUnarchiveEventCategory();

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q) =>
                setCustomFields({
                    ...customFields,
                    q,
                }),
            type: 'search',
            searchDefaultValue: defaultQuery,
            label: 'Search by category name',
        },
    ];

    const toggleArchivedStatus = useCallback(async (id: string, isCurrentlyArchived: boolean) => {
        try {
            await confirm({
                title: `Are you sure you want to ${
                    isCurrentlyArchived ? 'unarchive' : 'archive'
                } the event category?`,
                description: 'You can always revert the changes.',
            });

            isCurrentlyArchived ? unarchiveEventCategory(id) : archiveEventCategory(id);
        } catch (error) {
            /* Empty */
        }
    }, []);

    return (
        <>
            <Filters control={control} filters={arrayOfFilters}/>
            <TableHeaderTotalFeature
                title="Event Categories"
                totalResults={eventCategoriesResponse?.data.meta.total}
            />
            <EventCategoriesTable
                onToggleArchivedStatus={toggleArchivedStatus}
                data={eventCategoriesResponse?.data.data}
                loading={isEventCategoriesLoading || isArchiving || isUnarchiving}
                pagination={{
                    currentPage: eventCategoriesResponse?.data?.meta.currentPage || 1,
                    totalPages: eventCategoriesResponse?.data?.meta.totalPages || 1,
                    onPaginate: (page: number) =>
                        setCustomFields({...customFields, page: page.toString()}),
                }}
            />
        </>
    );
}
