import {useCallback} from 'react';
import CreateOrUpdateSeatingPlanForm, {
    CreateOrUpdateSeatingplanFormValues,
} from 'src/app/components/forms/CreateOrUpdateSeatingPlanForm';
import {useCreateSeatingPlan} from 'src/app/hooks/useCreateSeatingPlan';
import {useGetSeatingPlanById} from 'src/app/hooks/useGetSeatingPlanById';
import {useUpdateSeatingPlan} from 'src/app/hooks/useUpdateSeatingPlan';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

interface Props {
    venueId: string;
    seatingplanId?: string;
    onMutationSuccess?: () => void;
}

const CreateOrUpdateSeatingPlanFeature = ({venueId, seatingplanId, onMutationSuccess}: Props) => {
    const {
        isLoading: isCreateSeatingPlanLoading,
        mutate: mutateCreateSeatingPlan,
        isError: isCreateSeatingPlanError,
        error: createSeatingPlanError,
    } = useCreateSeatingPlan(onMutationSuccess);

    const {
        isLoading: isUpdateSeatingPlanLoading,
        mutate: mutateUpdateSeatingPlan,
        isError: isUpdateSeatingPlanError,
        error: updateSeatingPlanError,
    } = useUpdateSeatingPlan(onMutationSuccess);

    const {
        data: seatingPlanResponse,
        isLoading: isGetSeatingPlanByIdLoading,
        isError: isGetSeatingPlanError,
        error: getSeatingPlanError,
    } = useGetSeatingPlanById(seatingplanId);

    const handleSubmit = useCallback(
        (values: CreateOrUpdateSeatingplanFormValues) => {
            if (seatingplanId) {
                return mutateUpdateSeatingPlan({seatingPlanId: seatingplanId, name: values.name});
            }

            return mutateCreateSeatingPlan({venueId, name: values.name});
        },
        [
            seatingplanId,
            venueId,
            mutateCreateSeatingPlan,
            mutateUpdateSeatingPlan,
            onMutationSuccess,
        ]
    );

    const isLoading =
        (isGetSeatingPlanByIdLoading && !!seatingplanId) ||
        isCreateSeatingPlanLoading ||
        isUpdateSeatingPlanLoading;

    return (
        <>
            {isCreateSeatingPlanError && (
                <ErrorsList errors={parseErrors(createSeatingPlanError)}/>
            )}
            {isUpdateSeatingPlanError && (
                <ErrorsList errors={parseErrors(updateSeatingPlanError)}/>
            )}
            {isGetSeatingPlanError && <ErrorsList errors={parseErrors(getSeatingPlanError)}/>}
            <CreateOrUpdateSeatingPlanForm
                editMode={!!seatingplanId}
                loading={isLoading}
                formDefaultValues={
                    seatingPlanResponse && {
                        name: seatingPlanResponse.data.data.name,
                    }
                }
                onFormSubmit={handleSubmit}
            />
        </>
    );
};

export default CreateOrUpdateSeatingPlanFeature;
