import {zodResolver} from '@hookform/resolvers/zod';
import {Alert, CircularProgress, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {FetchSuppliersDataWrapper} from 'src/app/components/data-wrappers/supplier/FetchSuppliersDataWrapper';
import {FileUploadFeature} from 'src/app/components/features/file-upload/FileUploadFeature';
import {SeatingPlanCategoriesAutocompleteFeature} from 'src/app/components/features/seating-plan-categories/SeatingPlanCategoriesAutocompleteFeature';
import {DuplicateTicketsInTheBatchType} from 'src/app/components/features/tickets/BatchUploadTicketFilesFeature';
import {
    DuplicateTicketFileData,
    DuplicateTicketsUploadAttemptModal,
} from 'src/app/components/modals/DuplicateTicketsUploadAttemptModal';
import TicketAlreadyLinkedError from 'src/app/components/ticket-already-linked/TicketAlreadyLinkedError';
import {FormProps} from 'src/app/interfaces/form/formProps';
import {zodOptionalDdropdownSchema} from 'src/app/utilities/zod/zodOptionalDdropdownSchema';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import {zodRequiredStringSchema} from 'src/app/utilities/zod/zodRequiredStringSchema';
import EventTicketFile from 'src/data/models/tickets/eventTicketFile';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import {Preview} from 'src/view/components/preview/Preview';
import PreviewItem from 'src/view/components/preview/PreviewItem';
import {PreviewItemPlaceholder} from 'src/view/components/preview/PreviewItemPlaceholder';
import {PreviewLabel} from 'src/view/components/preview/PreviewLabel';
import z from 'zod';

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
        marginLeft: theme.spacing(1),
        marginBottom: 0,
    },
    preview: {
        maxHeight: 425,
    },
    previewLoading: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0, 2),
    },
    failedCount: {
        color: theme.palette.error.light,
    },
}));

interface BatchUploadTicketsProps {
    eventId: string;
    onChangeValues?: (values: Partial<BatchUploadTicketsValidationSchemeType>) => void;
    ticketsAffected?: string;
    ticketsCountIsLoading?: boolean;
    alreadyLinkedTickets: EventTicketFile[];
    duplicateTickets?: DuplicateTicketsInTheBatchType;
}

const batchUploadTicketsValidationScheme = z.object({
    category: zodRequiredDropdownSelectSchema,
    supplier: zodOptionalDdropdownSchema,
    block: zodRequiredStringSchema,
    row: zodRequiredStringSchema,
    uploadedFileIds: z
        .array(
            z.object({
                fileId: z.union([z.string().min(1), z.null()]),
                name: z.string().min(1),
            })
        )
        .min(1, 'Please upload a file'),
});

export type BatchUploadTicketsValidationSchemeType = z.infer<
    typeof batchUploadTicketsValidationScheme
>;

export const BatchUploadTicketsForm = ({
                                           eventId,
                                           onSubmit,
                                           loading,
                                           disableSubmit,
                                           onChangeValues,
                                           ticketsAffected,
                                           ticketsCountIsLoading,
                                           alreadyLinkedTickets,
                                           duplicateTickets,
                                       }: FormProps<BatchUploadTicketsValidationSchemeType> & BatchUploadTicketsProps) => {
    const classes = useStyles();
    const spacingStyles = useSpacingStyles();
    const [fileUploadIsLoading, setFileUploadIsLoading] = useState(false);
    const theme = useTheme();

    const [supplierSearchQuery, setSupplierSearchQuery] = useState('');
    const [displayDuplicateTicketsModal, setDisplayDuplicateTicketsModal] = useState(false);

    const {control, formState, handleSubmit, reset, setError, watch, setValue} =
        useForm<BatchUploadTicketsValidationSchemeType>({
            mode: 'onChange',
            resolver: zodResolver(batchUploadTicketsValidationScheme),
            defaultValues: {
                uploadedFileIds: [],
            },
        });

    useEffect(() => {
        if (duplicateTickets?.length) {
            setDisplayDuplicateTicketsModal(true);
        }
    }, [duplicateTickets]);

    useEffect(() => {
        if (alreadyLinkedTickets.length === 0) {
            reset();
        }
    }, [alreadyLinkedTickets.length, reset]);

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'uploadedFileIds',
    });

    const category = watch('category');
    const supplier = watch('supplier');
    const uploadedFileIds = watch('uploadedFileIds');

    useEffect(() => {
        if (!category || !eventId) return;

        onChangeValues?.({category, supplier});
    }, [eventId, category, supplier]);

    const renderEmptyGridItem = () => (
        <Grid
            item
            xs={0}
            md={4}
            sx={{
                display: {
                    xs: 'none',
                    lg: 'flex',
                },
            }}
        />
    );

    const onConfirmDuplicateSelection = useCallback(
        (handPickedCorrectTicketFiles: DuplicateTicketFileData[]) => {
            /* IDs of duplicate tickets from the initial API error */
            const initialDuplicateTicketIDs = new Set(
                duplicateTickets?.flatMap((arr) => arr.map((item) => item.fileId))
            );

            /*
                Clears up the tickets from duplicates, and keeps a clean array only from unique ticket files
                Does NOT contain any duplicate!
            */
            const uniqueTicketFilesWithoutDuplicates = uploadedFileIds.filter(
                (item) => item.fileId && !initialDuplicateTicketIDs.has(item.fileId)
            );

            setValue('uploadedFileIds', [
                // Tickets which did not have any duplicates to begin with
                ...uniqueTicketFilesWithoutDuplicates,
                // Tickets which had duplicates, but were now hand-picked by the user
                ...handPickedCorrectTicketFiles,
            ]);

            setDisplayDuplicateTicketsModal(false);
        },
        [uploadedFileIds, duplicateTickets]
    );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3 className={classnames(spacingStyles.noMarginBottom)}>
                        Ticket batch information
                    </h3>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Alert severity="info">
                        <span>
                            The selected ticket files will be randomly linked to tickets in the same
                            category that are:
                        </span>
                        <ul className={classes.list}>
                            <li>Available</li>
                            <li>Have an empty Block, Row, and Seat</li>
                        </ul>
                    </Alert>
                </Grid>
                {renderEmptyGridItem()}
                <Grid item lg={4} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormLabel>Seatingplan category*</FormLabel>
                            <Controller
                                name="category"
                                control={control}
                                render={({
                                             field: {value, onChange, name},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <SeatingPlanCategoriesAutocompleteFeature
                                            name={name}
                                            eventId={eventId}
                                            onChange={onChange}
                                            value={value}
                                            hideArchivedSpcs
                                            placeholder="Select a seating plan category"
                                        />
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Block*</FormLabel>
                            <Controller
                                name="block"
                                control={control}
                                render={({
                                             field: {value, onChange, name},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <Input
                                            value={value}
                                            onChange={onChange}
                                            name={name}
                                            placeholder="Enter a block"
                                        />
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Row (must be unique for block)*</FormLabel>
                            <Controller
                                name="row"
                                control={control}
                                render={({
                                             field: {value, onChange, name},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <Input
                                            value={value}
                                            onChange={onChange}
                                            name={name}
                                            placeholder="Enter a row"
                                        />
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Seat will be automatically generated</strong>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Supplier</FormLabel>
                            <Controller
                                name="supplier"
                                control={control}
                                render={({
                                             field: {value, onChange, name},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <FetchSuppliersDataWrapper q={supplierSearchQuery}>
                                            {({data}) => (
                                                <AutoComplete
                                                    name={name}
                                                    options={
                                                        data?.data.map((supplier) => ({
                                                            label: supplier.name,
                                                            value: supplier.id,
                                                        })) || []
                                                    }
                                                    onInputChange={(val) => {
                                                        setSupplierSearchQuery(val);
                                                    }}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder="Select a supplier"
                                                />
                                            )}
                                        </FetchSuppliersDataWrapper>
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Files*</FormLabel>

                            <FileUploadFeature
                                onFilesUploaded={append}
                                onLoadingStateChanged={setFileUploadIsLoading}
                            />

                            {formState.errors.uploadedFileIds && (
                                <FormFieldError
                                    message={formState.errors.uploadedFileIds?.message}
                                />
                            )}
                        </Grid>
                        {ticketsAffected !== undefined && category?.value && (
                            <Grid item xs={12}>
                                <Alert severity="info" className={spacingStyles.spacingTop}>
                                    {ticketsCountIsLoading ? (
                                        <CircularProgress size={theme.layout.loader.sizes.small}/>
                                    ) : (
                                        <span>
                                            <strong>{ticketsAffected} tickets</strong> will be
                                            affected
                                        </span>
                                    )}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <FormButtons
                            buttons={[
                                {
                                    children: 'Batch upload tickets',
                                    disabled: loading || disableSubmit,
                                    startIcon: loading && (
                                        <CircularProgress size={theme.layout.loader.sizes.small}/>
                                    ),
                                    onClick: handleSubmit(async (values) => {
                                        await onSubmit(values);
                                        setError('uploadedFileIds', {});
                                    }),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormLabel>
                                Uploaded files{' '}
                                {alreadyLinkedTickets.length > 0 && (
                                    <span className={classes.failedCount}>
                                        ({alreadyLinkedTickets.length} failed)
                                    </span>
                                )}
                            </FormLabel>
                            <Preview className={classes.preview}>
                                <>
                                    {fields.length === 0 && !fileUploadIsLoading && (
                                        <PreviewItemPlaceholder text="No files uploaded"/>
                                    )}

                                    {fields.map((field, index) => {
                                        const linkedTicket = alreadyLinkedTickets.find(
                                            (ticket) => ticket.inputFileUploadId === field.fileId
                                        );

                                        return (
                                            <PreviewItem
                                                key={field.id}
                                                id={field.id}
                                                index={index}
                                                onDelete={() => remove(index)}
                                                type={
                                                    !field.fileId || linkedTicket ? 'error' : null
                                                }
                                            >
                                                <PreviewLabel label={field.name}/>
                                                {linkedTicket && (
                                                    <TicketAlreadyLinkedError
                                                        ticketError={linkedTicket}
                                                    />
                                                )}
                                            </PreviewItem>
                                        );
                                    })}
                                    {fileUploadIsLoading && (
                                        <div className={classes.previewLoading}>
                                            <CircularProgress size={20}/>
                                        </div>
                                    )}
                                </>
                            </Preview>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {displayDuplicateTicketsModal && duplicateTickets?.length && (
                <DuplicateTicketsUploadAttemptModal
                    duplicateTickets={duplicateTickets}
                    onConfirm={onConfirmDuplicateSelection}
                    onClose={() => setDisplayDuplicateTicketsModal(false)}
                />
            )}
        </>
    );
};
