import EventIcon from '@mui/icons-material/Event';
import _ from 'lodash';
import moment, {Moment} from 'moment';
import {type Dispatch, type SetStateAction, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import {CustomFields} from 'src/app/constants/constants/filters/filters';
import {purchaseFinalizedStatusOptions, purchaseStatusOptions,} from 'src/app/utilities/helpers/filter-options/purchase';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import {paymentMethodOptions} from 'src/app/utilities/mappers/mapPaymentMethodsToOptions';
import FilterOption from 'src/data/api/common/FilterOption';
import {dateFormatYearMonthDay} from 'src/shared/date';
import {FilterAutoCompleteOptions, FilterAutoCompleteValueOption,} from 'src/view/components/filters/AutoComplete/AutoComplete';

export interface PurchasesFilterFormValues {
    paymentMethod?: FilterAutoCompleteValueOption;
    status?: FilterAutoCompleteOptions;
    startDate?: Date;
    endDate?: Date;
    searchTerm?: string;
    eventIds?: FilterAutoCompleteOptions;
    creditCardDigits?: string;
    isFinalized?: FilterAutoCompleteValueOption;
}

interface PurchaseFilteRormProps {
    setFilterOptions: Dispatch<SetStateAction<FilterOption[]>>;
    defaultValues: PurchasesFilterFormValues;
    setCustomFields: (customFields: CustomFields) => void;
    eventsFiltersOptions: FilterAutoCompleteOptions;
    customFields: CustomFields;
}

export default function PurchasesFilterForm({
                                                defaultValues,
                                                setFilterOptions,
                                                eventsFiltersOptions,
                                                setCustomFields,
                                                customFields,
                                            }: PurchaseFilteRormProps): JSX.Element {
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const form = useForm<PurchasesFilterFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const {watch, setValue, reset, control} = form;

    const paymentMethod = watch('paymentMethod');
    const status = watch('status');
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const searchTerm = watch('searchTerm');
    const eventIds = watch('eventIds');
    const creditCardDigits = watch('creditCardDigits');
    const isFinalized = watch('isFinalized');

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
        const ticketsFilter = _.compact([
            formatValuesToParams.formatOptionToParam('paymentMethodType', paymentMethod),
            formatValuesToParams.formatOptionToParam('isFinalized', isFinalized),
            formatValuesToParams.formatOptionsToParam('status', status),
            formatValuesToParams.formatOptionsToParam('eventIds', eventIds),
            formatValuesToParams.formatOptionToParam('creditCardDigits', {
                label: creditCardDigits || '',
                value: creditCardDigits || '',
            }),
            startDate
                ? formatValuesToParams.formatDateToParam(
                    'purchasedAt',
                    moment(startDate).format(dateFormatYearMonthDay),
                    'afterDate'
                )
                : undefined,
            endDate
                ? formatValuesToParams.formatDateToParam(
                    'purchasedAt',
                    moment(endDate).format(dateFormatYearMonthDay),
                    'beforeDate'
                )
                : undefined,
        ]).filter((f) => f.value !== undefined && f.value !== '');

        setFilterOptions(ticketsFilter);
    }, [urlInvalidatedAt, setFilterOptions]);

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });

        setCustomFields({
            ...customFields,
            q: value || '',
        });
    };

    const datePickerOnChange = (value: Moment | null, field: 'startDate' | 'endDate'): void => {
        if (value === null) {
            setValue(field, undefined, {
                shouldValidate: true,
            });
            return;
        }

        setValue(field, value.toDate(), {
            shouldValidate: true,
        });
    };

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: paymentMethodOptions,
            name: 'paymentMethod',
            filterPlaceholderProps: {
                selectedText: 'Payment Method',
                placeholder: 'Payment Method',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'autocomplete',
            options: purchaseFinalizedStatusOptions,
            name: 'isFinalized',
            filterPlaceholderProps: {
                selectedText: 'Finalized',
                placeholder: 'Finalized',
            },

            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'autocomplete',
            options: purchaseStatusOptions,
            name: 'status',
            filterPlaceholderProps: {
                selectedText: 'Status',
                placeholder: 'Status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        {
            name: 'startDate',
            onChange: (date) => {
                datePickerOnChange(date, 'startDate');

                if (moment(date).isValid() || date === null) setUrlInvalidatedAt(Date.now());
            },
            type: 'datepicker',
            filterPlaceholderProps: {
                startIcon: <EventIcon/>,
                placeholder: 'From',
                keepPlaceholder: true,
                hideDeleteButton: moment(startDate).isSame(moment(), 'day'),
            },
        },
        {
            name: 'endDate',
            onChange: (date) => {
                datePickerOnChange(date, 'endDate');

                if (moment(date).isValid() || date === null) setUrlInvalidatedAt(Date.now());
            },
            type: 'datepicker',
            filterPlaceholderProps: {
                startIcon: <EventIcon/>,
                placeholder: 'To',
                keepPlaceholder: true,
            },
            minDate: moment(startDate),
        },
        {
            type: 'autocomplete',
            options: eventsFiltersOptions,
            name: 'eventIds',
            filterPlaceholderProps: {
                selectedText: 'Purchase event(s)',
                placeholder: 'Purchase event(s)',
            },
            isMulti: true,
            preserveSearchValue: true,
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            name: 'creditCardDigits',
            onChange: (value) => {
                setValue('creditCardDigits', value, {
                    shouldValidate: true,
                });

                setUrlInvalidatedAt(Date.now());
            },
            type: 'creditcard-search',
            searchPlaceholder: 'Card numbers',
            searchDefaultValue: creditCardDigits?.toString(),
        },
        {
            name: 'searchTerm',
            onChange: onInputChange,
            type: 'search',
            searchPlaceholder: 'Search on ext. reference, notes and supplier',
            searchDefaultValue: searchTerm,
        },
    ];

    return <Filters control={control} filters={arrayOfFilters}/>;
}
