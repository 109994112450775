import {Button, Theme, Tooltip, TooltipProps} from '@mui/material';
import {CSSProperties, makeStyles} from '@mui/styles';
import React from 'react';

export enum HtmlListToolTipVariants {
    SUCCESS = 'success',
    DEFAULT = 'default',
    WHITE = 'white',
}

export interface HtmlListTooltipProps extends Omit<TooltipProps, 'children'> {
    variant?: HtmlListToolTipVariants;
    buttonTitle: string | JSX.Element;
    withoutBorder?: boolean;
    disableRipple?: boolean;
    children?: React.ReactNode;
}

const useRootStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.colors.white,
        boxShadow: theme.layout.boxShadow,
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid transparent',
        margin: 1,
    },
}));

const useStyles = makeStyles((theme: Theme) => ({
    tooltipButton: (props: Partial<HtmlListTooltipProps>) => {
        let htmlTooltipStyle: Record<string, CSSProperties | string | number | undefined> = {
            cursor: 'pointer',
            borderColor: theme.colors.mediumGrey,
            color: 'black',
            '&:hover': {
                borderColor: theme.colors.mediumGrey,
            },
        };

        if (props.variant === HtmlListToolTipVariants.SUCCESS) {
            htmlTooltipStyle = {
                ...htmlTooltipStyle,
                borderColor: theme.palette.success.main,
                color: theme.palette.success.main,
                '&:hover': {
                    borderColor: theme.palette.success.main,
                },
            };
        }

        if (props.variant === HtmlListToolTipVariants.WHITE) {
            htmlTooltipStyle = {
                ...htmlTooltipStyle,
                borderColor: theme.colors.white,
                color: theme.colors.white,
                '&:hover': {
                    borderColor: theme.colors.white,
                },
            };
        }

        return htmlTooltipStyle;
    },
}));

export default function HtmlListTooltip({
                                            variant = HtmlListToolTipVariants.DEFAULT,
                                            withoutBorder = false,
                                            buttonTitle,
                                            disableRipple,
                                            ...props
                                        }: HtmlListTooltipProps): JSX.Element {
    const classes = useStyles({variant});
    const rootClasses = useRootStyles();

    if (withoutBorder) {
        return (
            <Tooltip {...props} classes={rootClasses}>
                <span className={classes.tooltipButton}>{buttonTitle}</span>
            </Tooltip>
        );
    }

    return (
        <Tooltip {...props} classes={rootClasses}>
            <Button
                disableRipple={disableRipple ? true : false}
                disableFocusRipple={disableRipple ? true : false}
                className={classes.tooltipButton}
                variant="outlined"
                size="small"
            >
                {buttonTitle}
            </Button>
        </Tooltip>
    );
}
