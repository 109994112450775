import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const CONTRACTS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/contracts`;

export const CONTRACTS_GET_TEMPLATES_BY_ID_ENDPOINT = (contractId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/contracts/${contractId}/tickets-templates`;

export const CONTRACTS_GET_TEMPLATE_TICKETS_ENDPOINT = (contractId: string, templateId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/contracts/${contractId}/tickets-templates/${templateId}/items`;

export const CONTRACTS_GENERATE_TEMPLATE_TICKETS_ENDPOINT = (
    contractId: string,
    templateId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/contracts/${contractId}/tickets-templates/${templateId}/generate-tickets`;
