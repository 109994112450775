import {useEffect} from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import Series from 'src/data/models/series/series';
import seriesService from 'src/data/services/seriesService';

interface FetchSeriesDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Series>> {
    fetchSeries: (options?: BaseFetchOptions) => Promise<PaginatedApiResponseBody<Series>>;
}

type FetchSeriesDataWrapperProps = DataWrapperProps<
    FetchSeriesDataWrapperChildrenParams,
    PaginatedApiResponseBody<Series>
> &
    BaseFetchOptions;

export const useFetchSeriesDataWrapper = ({
                                              includes = [],
                                              onError,
                                              onSuccess,
                                              invalidatedAt,
                                              page,
                                              pageSize,
                                              filter,
                                              sorting,
                                              q,
                                              skipInitialCall,
                                          }: Omit<FetchSeriesDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleFetchSeries] =
        useApiFetch<PaginatedApiResponseBody<Series>>();

    const fetchSeries = (options?: BaseFetchOptions) => {
        return handleFetchSeries(
            seriesService.fetchSeries({
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
                ...options,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        if (skipInitialCall) return;

        fetchSeries();
    }, [page, pageSize, filter, sorting, q, invalidatedAt]);

    return {
        fetchSeries,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchSeriesDataWrapper = (props: FetchSeriesDataWrapperProps) => {
    const {children} = props;
    const hookData = useFetchSeriesDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
