import {useQuery} from '@tanstack/react-query';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import Toaster from 'src/app/utilities/helpers/Toaster';
import purchaseService from 'src/data/services/purchaseService';

type PurchaseByIdData = Awaited<ReturnType<typeof purchaseService.fetchPurchaseById>>;

type Options = {
    enabled: boolean;
    onSuccess?: (data: PurchaseByIdData) => void;
};

export const GET_PURCHASE_BY_ID_QUERY_KEY = 'GET_PURCHASE_BY_ID';

export const useFetchPurchaseById = (ticketId: string, options?: Options) => {
    return useQuery<PurchaseByIdData>({
        queryKey: [GET_PURCHASE_BY_ID_QUERY_KEY, ticketId],
        queryFn: () => purchaseService.fetchPurchaseById(ticketId),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
        ...options,
    });
};
