import {useEffect, useState} from 'react';
import {ActionMeta} from 'react-select';
import useApiFetch from 'src/app/hooks/useApiFetch';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import SeatingPlan from 'src/data/models/seating-plan/SeatingPlan';
import venuesService, {SeatingPlanDto, SeatingPlanStatus} from 'src/data/services/venuesService';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    venueId: string;
    name: string;
    value?: AutoCompleteOption;
    onChange: (value: AutoCompleteValueOption, action?: ActionMeta<AutoCompleteOption>) => void;
    onSuccessfullyCreate: (option: AutoCompleteOption) => void;
    disabled?: boolean;
}

export default function SeatingPlanSelectDataWrapper({
                                                         venueId,
                                                         name,
                                                         value,
                                                         onChange,
                                                         onSuccessfullyCreate,
                                                         disabled,
                                                     }: Props) {
    const [option, setOption] = useState<AutoCompleteOption | undefined>(value);

    useEffect(() => {
        setOption(value);
    }, [value]);

    const [{data: seatingPlanData}, handleFetchSeatingPlan] =
        useApiFetch<ApiResponseBody<SeatingPlan[]>>();

    useEffect(() => {
        if (!venueId || disabled) return;

        handleFetchSeatingPlan(venuesService.getSeatingPlansByVenueId(venueId), {
            useDefaultErrorHandler: true,
        });
    }, [venueId]);

    const seatingPlanOptions = (): AutoCompleteOptions => {
        if (!venueId) return [];

        if (!seatingPlanData) return [];

        return seatingPlanData.data.map((o) => ({
            label: o.name,
            value: o.id,
        }));
    };

    const onCreateSeatingPlan = async (venueId: string, dto: SeatingPlanDto) => {
        if (!venueId) return;

        try {
            const result = await venuesService.createSeatingPlan(venueId, dto);
            const id = result.data.data.id;
            const createdSeatingPlan = {label: dto.name, value: id};

            onSuccessfullyCreate(createdSeatingPlan);
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AutoComplete
            name={name}
            value={option}
            options={seatingPlanOptions()}
            onChange={onChange}
            placeholder={!venueId ? 'Select a venue first (disabled)' : 'select or create'}
            disabled={disabled || !venueId}
            onCreateOption={(name: string) =>
                onCreateSeatingPlan(venueId, {name, status: SeatingPlanStatus.active})
            }
        />
    );
}
