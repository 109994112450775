import {useEffect, useState} from 'react';
import {Alert, Grid} from '@mui/material';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {TicketsTableDataResolver} from 'src/app/components/tables/TicketsTable';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import Ticket from 'src/data/models/tickets/ticket';
import {CreateNewSplitDataWrapper} from 'src/app/components/data-wrappers/tickets/CreateNewSplitDataWrapper';
import {useCreateNewSplitDataWrapper} from 'src/app/components/data-wrappers/tickets/CreateNewSplitDataWrapper';
import {SplitTicketsTableForm} from 'src/app/components/forms/tickets/splits/SplitTicketsTableForm';
import {makeStyles} from '@material-ui/core';
import {Theme} from '@mui/material';
import {useFetchTicketByIdDataWrapper} from 'src/app/components/data-wrappers/tickets/FetchTicketByIdDataWrapper';

interface CreateSplitFeatureProps {
    eventId: string;
    selectedTickets: RowIdResolver<TicketsTableDataResolver>[];
    onSuccess?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    alert: {
        marginBottom: theme.spacing(1),
    },
}));
export const CreateSplitFeature = ({
                                       eventId,
                                       selectedTickets,
                                       onSuccess,
                                   }: CreateSplitFeatureProps) => {
    const classes = useStyles();
    const [newSplitTickets, setNewSplitTickets] = useState<Ticket[]>([]);

    const {fetchTicketById, errors: FetchTicketByIdErrors} = useFetchTicketByIdDataWrapper({});

    const {createSplit, loading: createSplitLoading} = useCreateNewSplitDataWrapper({
        onSuccess: () => {
            onSuccess?.();
        },
    });

    const fetchSelectedTickets = async () => {
        return await Promise.all(selectedTickets.map((t) => fetchTicketById(t.id)));
    };

    useEffect(() => {
        const handleFetchedSelectedTickets = async () => {
            const resultTickets = await fetchSelectedTickets();
            setNewSplitTickets(resultTickets.map((t) => t.data));
        };
        handleFetchedSelectedTickets();
    }, []);

    const onFormSubmit = (tickets: Ticket[]) => {
        createSplit({
            tickets: tickets.map((ticket) => ({
                ticketId: ticket.id,
                sortOrder: ticket.sortOrder,
            })),
            eventId,
        });
    };

    return (
        <>
            <Alert severity="info" className={classes.alert}>
                You have selected <strong>{selectedTickets.length} ticket(s)</strong> to make into a
                new split. Adjust the position order if needed and then click{' '}
                <strong>Create Split</strong>.
            </Alert>

            <CreateNewSplitDataWrapper>
                {({loading, errors}) => (
                    <>
                        {errors && (
                            <Grid item xs={12}>
                                <ErrorsList errors={errors}/>
                            </Grid>
                        )}

                        {newSplitTickets && newSplitTickets.length > 0 && (
                            <Grid item xs={12}>
                                <SplitTicketsTableForm
                                    tableHeaderText="New Split Tickets"
                                    splitTickets={newSplitTickets}
                                    isTableLoading={loading || createSplitLoading}
                                    isSubmitDisabled={createSplitLoading}
                                    submitButtonText="Create split"
                                    onFormSubmit={onFormSubmit}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </CreateNewSplitDataWrapper>

            {FetchTicketByIdErrors.length > 0 && (
                <Grid item xs={12}>
                    <Alert severity="error">Something went wrong while getting the ticket.</Alert>
                </Grid>
            )}
        </>
    );
};
