import {FilterAutoCompleteOptions} from 'src/view/components/filters/AutoComplete/AutoComplete';

const quantityOptions: FilterAutoCompleteOptions = [
    {
        value: undefined,
        label: 'All',
    },
    {
        value: 'even',
        label: 'Even',
    },
    {
        value: 'odd',
        label: 'Odd',
    },
];

const booleanOptions: FilterAutoCompleteOptions = [
    {
        value: false,
        label: 'No',
    },
    {
        value: true,
        label: 'Yes',
    },
];

export default {
    booleanOptions,
    quantityOptions,
};
