import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {BaseComponent} from 'src/view/interfaces/BaseComponent';

export interface PageProps extends BaseComponent {
    children: JSX.Element | JSX.Element[] | string;
    paddingBottom?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    page: (props: Partial<PageProps>) => {
        let paddingBottom = undefined;

        if (props.paddingBottom) {
            paddingBottom = theme.spacing(15);
        }

        return {
            padding: theme.spacing(2),
            paddingBottom,
        };
    },
}));

const Page = ({children, paddingBottom, className}: PageProps): JSX.Element => {
    const classes = useStyles({paddingBottom});

    return <div className={classNames(classes.page, className)}>{children}</div>;
};

export default Page;
