import {Navigate, Outlet} from 'react-router-dom';
import {BaseComponent} from 'src/view/interfaces/BaseComponent';

interface ProtectedRouteProps extends BaseComponent {
    isRoleAllowed?: boolean;
    redirectUrl?: string;
}

export const ProtectedRoute = ({
                                   isRoleAllowed = false,
                                   redirectUrl = '',
                                   children,
                               }: ProtectedRouteProps) => {
    if (!isRoleAllowed) return <Navigate to={redirectUrl}/>;

    return <div>{children || <Outlet/>}</div>;
};
