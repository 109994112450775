import {useQuery} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {fetchTeams} from 'src/data/services/teamsService';

export const TEAMS_QUERY_KEY = 'TEAMS';

export const useFetchTeams = (fetchOptions?: BaseFetchOptions) => {
    return useQuery({
        queryKey: [TEAMS_QUERY_KEY, fetchOptions],
        queryFn: () => fetchTeams(fetchOptions),
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
};
