import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderService from 'src/data/services/orderService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';

interface UpdateOrderDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    updateOrder: (internalNotes: string) => Promise<void | ErrorReturn>;
}

interface UpdateOrderDataWrapperProps
    extends DataWrapperProps<UpdateOrderDataWrapperChildrenParams, ApiResponseBody<IdResponse>>,
        BaseFetchOptions {
    orderId: string;
}

export const useUpdateOrderDataWrapper = ({
                                              orderId,
                                              onError,
                                              onSuccess,
                                          }: Omit<UpdateOrderDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleUpdateOrder] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const updateOrderCall = (internalNotes: string) => {
        return handleUpdateOrder(orderService.updateOrderInternalNotes(orderId, internalNotes), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'order has been updated',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        updateOrder: updateOrderCall,
        data,
        loading,
        errors,
        resetData,
    };
};
