import {currencies, defaultCurrency} from 'src/shared/currencies';

type CurrencyKey = keyof typeof currencies;

// The currencies supported in the back-end. Occassionaly revisit and check validity
export const currentlySupportedCurrencies: CurrencyKey[] = [
    'EUR',
    'USD',
    'GBP',
    'JPY',
    'HKD',
    'AUD',
    'AED',
    'BHD',
    'CAD',
    'CHF',
    'JPY',
    'QAR',
    'SAR',
    'SGD',
    'CNY',
];

const mappedCurrencyCodes = [];

for (const property in currencies) {
    if (currentlySupportedCurrencies.includes(property as CurrencyKey)) {
        mappedCurrencyCodes.push(property);
    }
}

const supportedCurrencies = mappedCurrencyCodes;
export const ticketSalesCapCurrencies = [defaultCurrency];

export default supportedCurrencies;
