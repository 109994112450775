import {Alert, Grid} from '@mui/material';
import {useParams} from 'react-router-dom';
import {ETBContainer} from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import UnprocessedTicketFilesTableFeature from 'src/app/components/features/event/UnprocessedTicketFilesTableFeature';
import Button from 'src/view/components/button/Button';
import UploadUnprocessedTicketFilesFeatureModal from 'src/app/components/features/tti/UploadUnprocessedTicketFilesFeatureModal';
import {useState} from 'react';


type Params = {
    eventId: string;
};

interface EventTtiOverviewProps {
    eventName: string;
}

const EventTtiOverview = ({eventName}: EventTtiOverviewProps) => {
    const {eventId} = useParams<Params>();

    const [isAddFilesModalOpen, setIsAddFilesModalOpen] = useState(false)

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    return (
        <>
            {isAddFilesModalOpen && <UploadUnprocessedTicketFilesFeatureModal eventId={eventId} onClose={() => setIsAddFilesModalOpen(false)}/>}
            <Header>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <h1>{eventName}</h1>
                    </Grid>
                    <Grid item>
                        <Button color="primary" onClick={() => setIsAddFilesModalOpen(true)}>
                            Add files
                        </Button>
                    </Grid>
                </Grid>
            </Header>
            <ETBContainer>
                <UnprocessedTicketFilesTableFeature eventId={eventId}/>
            </ETBContainer>
        </>
    );
};

export default EventTtiOverview;
