import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderlineService, {AssignTicketsDto} from 'src/data/services/orderlineService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {DataWrapperCallback} from 'src/app/interfaces/dataWrapperCallback';
import {EmptyBody} from 'src/data/models/common/emptyBody';

type ResponseType = ApiResponseBody<EmptyBody>;

interface AcceptAutoAssignResultOrderlineDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ResponseType> {
    acceptAutoAssignResultOrderline: (
        orderId: string,
        orderlineId: string,
        dto: AssignTicketsDto,
        callbacks?: DataWrapperCallback<ResponseType>
    ) => Promise<ResponseType>;
}

type AcceptAutoAssignResultOrderlineDataWrapperProps = DataWrapperProps<
    AcceptAutoAssignResultOrderlineDataWrapperChildrenParams,
    ResponseType
> &
    BaseFetchOptions;

export const useAcceptAutoAssignResultOrderlineDataWrapper = ({
                                                                  onError,
                                                                  onSuccess,
                                                              }: Omit<AcceptAutoAssignResultOrderlineDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleAcceptFetchAutoAssignResultOrderline] =
        useApiFetch<ResponseType>();

    const acceptAutoAssignResultOrderline = (
        orderId: string,
        orderlineId: string,
        dto: AssignTicketsDto,
        callbacks?: DataWrapperCallback<ResponseType>
    ) => {
        return handleAcceptFetchAutoAssignResultOrderline(
            orderlineService.assignTickets(orderId, orderlineId, dto),
            {
                useDefaultErrorHandler: false,
                onFail: () => {
                    onError?.();
                    callbacks?.onFail?.();
                },
                onSuccess: (data) => {
                    onSuccess?.(data);
                    callbacks?.onSuccess?.(data);
                },
            }
        );
    };

    return {
        acceptAutoAssignResultOrderline,
        data,
        loading,
        errors,
        resetData,
    };
};

export const AcceptAutoAssignResultOrderlineDataWrapper = (
    props: AcceptAutoAssignResultOrderlineDataWrapperProps
) => {
    const {children} = props;
    const hookData = useAcceptAutoAssignResultOrderlineDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
