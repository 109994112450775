import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TableColumnSorting,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import Series from 'src/data/models/series/series';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {IconButton} from '@mui/material';
import StatusLabel from 'src/view/components/status-symbol/StatusSymbol';

export interface SeriesTableProps {
    series: Series[];
    loading?: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sorting: TableColumnSorting[]) => void;
    onEditSeries: (seriesId: string) => void;
}

export default function SeriesTable({
                                        loading,
                                        series = [],
                                        pagination,
                                        onSortingChange,
                                        onEditSeries,
                                    }: SeriesTableProps): JSX.Element {
    const [columns, setColumns] = React.useState<NewTableColumn<Series>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<Series>[] = [];

        cols = [
            {
                key: 'name',
                title: 'Name',
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'seriesGroupName',
                title: 'Series Group',
                cellRenderer: (series) => series.seriesGroupName || '',
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'active',
                title: 'Active',
                cellRenderer: (series) =>
                    series.active ? (
                        <StatusLabel variant="success" label="Active"/>
                    ) : (
                        <StatusLabel variant="warning" label="inActive"/>
                    ),
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                key: 'eventsCount',
                title: '# of events',
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                align: 'right',
                cellRenderer: (series) => (
                    <IconButton
                        onClick={() => onEditSeries(series.id)}
                        color="primary"
                        size="small"
                    >
                        <VisibilityOutlinedIcon/>
                    </IconButton>
                ),
                width: TABLE_CELL_WIDTH.TINY,
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<Series>
            data={series}
            rowIdResolver={(rowData: Series) => ({id: rowData.id})}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            onSortingChange={onSortingChange}
        />
    );
}
