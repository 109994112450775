import {useQuery} from '@tanstack/react-query';
import {getSeatingplanCategoriesForSeatingPlan} from 'src/data/services/seatingPlanService';

export const FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY =
    'FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY';

export const useFetchSPCsBySeatingplanId = (id: string) => {
    return useQuery({
        queryKey: [FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY, id],
        queryFn: () => {
            return getSeatingplanCategoriesForSeatingPlan(id);
        },
    });
};
