import {Alert, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Price from 'src/data/models/common/price';
import {defaultCurrency} from 'src/shared/currencies';
import {formatPrice} from 'src/shared/helpers/formatPrice';
import {Divider} from 'src/view/components/divider/Divider';
import Popover from 'src/view/components/popover/Popover';
import {Price as PriceComponent} from 'src/view/components/price/Price';

interface TotalPriceDifferencePopoverProps {
    text: string | number | JSX.Element;
    ticketsTotalOriginal: Price | null;
    purchaseTotalOriginal: Price | null;
    ticketsTotalEuro: Price | null;
    purchaseTotalEuro: Price | null;
    eventTicketsTotalOriginalPrice?: Price | null;
    eventTicketsTotalEuroPrice?: Price | null;
    isEventPurchase?: boolean;
}

export const TotalPriceDifferencePopover = ({
                                                text,
                                                ticketsTotalOriginal,
                                                purchaseTotalOriginal,
                                                ticketsTotalEuro,
                                                purchaseTotalEuro,
                                                eventTicketsTotalOriginalPrice,
                                                eventTicketsTotalEuroPrice,
                                                isEventPurchase,
                                            }: TotalPriceDifferencePopoverProps) => {
    const currency = ticketsTotalOriginal?.currency || purchaseTotalOriginal?.currency;
    const isForeignCurrencyPurchase =
        purchaseTotalOriginal && purchaseTotalOriginal?.currency !== defaultCurrency;
    const ticketsPriceOriginal = ticketsTotalOriginal?.value || 0;
    const purchasePriceOriginal = purchaseTotalOriginal?.value || 0;
    const ticketsPriceEuro = ticketsTotalEuro?.value || ticketsPriceOriginal || 0;
    const purchasePriceEuro = purchaseTotalEuro?.value || purchasePriceOriginal || 0;

    const totalTicketPricesExplanation = isEventPurchase
        ? 'All tickets of the purchase'
        : 'Tickets';

    return (
        <Popover text={text} width={350} boxPadding={0}>
            <Box sx={{padding: (theme) => theme.spacing(1)}}>
                <Box
                    sx={(theme) => ({
                        background: theme.colors.lightGrey,
                        padding: theme.spacing(1),
                        borderRadius: theme.layout.borderRadius.regular,
                    })}
                >
                    <Alert severity="warning">This purchase has additional costs!</Alert>
                    <Divider/>
                    {isEventPurchase && (
                        <>
                            <Section>
                                <Typography variant="body2" marginRight={2}>
                                    Tickets for this event only
                                </Typography>
                                <PriceContainer>
                                    {formatPrice({
                                        currency: eventTicketsTotalOriginalPrice?.currency,
                                        amount: eventTicketsTotalOriginalPrice?.value || 0,
                                    })}
                                    {isForeignCurrencyPurchase && (
                                        <Euro price={eventTicketsTotalEuroPrice?.value || 0}/>
                                    )}
                                </PriceContainer>
                            </Section>
                            <Divider/>
                        </>
                    )}
                    {isEventPurchase && (
                        <Typography variant="body1" fontWeight="bold">
                            Purchase information
                        </Typography>
                    )}

                    <Section>
                        <div
                            style={{
                                marginRight: '2rem',
                            }}
                        >
                            {totalTicketPricesExplanation}
                        </div>

                        <PriceContainer>
                            {formatPrice({
                                currency,
                                amount: ticketsPriceOriginal || 0,
                            })}
                            {isForeignCurrencyPurchase && <Euro price={ticketsPriceEuro}/>}
                        </PriceContainer>
                    </Section>

                    <Section
                        sx={(theme) => ({
                            color: theme.palette.error.main,
                        })}
                    >
                        <Typography variant="body2" marginRight={2}>
                            Additional costs (of this purchase)
                        </Typography>
                        <PriceContainer>
                            {formatPrice({
                                currency,
                                amount: purchasePriceOriginal - ticketsPriceOriginal,
                            })}
                            {isForeignCurrencyPurchase && (
                                <Euro price={purchasePriceEuro - ticketsPriceEuro}/>
                            )}
                        </PriceContainer>
                    </Section>

                    <Section>
                        <Typography variant="body2" fontWeight="bold">
                            Total purchase price
                        </Typography>
                        <PriceContainerBold>
                            {formatPrice({
                                currency,
                                amount: purchasePriceOriginal || 0,
                            })}
                            {isForeignCurrencyPurchase && <Euro price={purchasePriceEuro}/>}
                        </PriceContainerBold>
                    </Section>
                </Box>
            </Box>
        </Popover>
    );
};

const Section = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const PriceContainer = styled(Box)({textAlign: 'right', marginBottom: '0.6rem'});
const PriceContainerBold = styled(Box)({
    textAlign: 'right',
    marginBottom: '0.6rem',
    fontWeight: 'bold',
});

function Euro({price}: { price: number }) {
    return (
        <PriceComponent
            price={{currency: defaultCurrency, value: price}}
            grey
            sx={(theme) => ({
                fontSize: '0.8rem',
                textAlign: 'right',
                marginLeft: theme.spacing(1),
            })}
        />
    );
}
