import {CircularProgress} from '@mui/material';
import {FormActionButtons} from 'src/view/components/form-action-buttons/FormActionButtons';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';
import {useTheme} from '@mui/styles';

interface ActivateEventFormButtonsProps {
    eventIsActive?: boolean;
    onClickActivate?: () => void;
    onClickDeactivate?: () => void;
    onClickDelete?: () => void;
    activateIsLoading?: boolean;
    deactivateIsLoading?: boolean;
    deleteIsLoading?: boolean;
    activateErrors?: ErrorDetail[];
    deactivateErrors?: ErrorDetail[];
    deleteErrors?: ErrorDetail[];
}

export const CreateEventFormActionButtons = ({
                                                 eventIsActive,
                                                 onClickActivate,
                                                 onClickDeactivate,
                                                 onClickDelete,
                                                 activateIsLoading,
                                                 deactivateIsLoading,
                                                 deleteIsLoading,
                                                 activateErrors,
                                                 deactivateErrors,
                                                 deleteErrors,
                                             }: ActivateEventFormButtonsProps) => {
    const theme = useTheme();

    return (
        <FormActionButtons
            buttons={[
                {
                    children: eventIsActive ? 'Deactivate' : 'Activate',
                    onClick: () => (eventIsActive ? onClickDeactivate?.() : onClickActivate?.()),
                    text: eventIsActive
                        ? 'Deactivating the event will remove it outside of ETBAAS'
                        : 'Activating the event will make it available outside of ETBAAS',
                    color: eventIsActive ? 'error' : 'success',
                    disabled: deactivateIsLoading || activateIsLoading,
                    startIcon: (deactivateIsLoading || activateIsLoading) && (
                        <CircularProgress size={theme.layout.loader.sizes.small}/>
                    ),
                    error: eventIsActive
                        ? 'Could not deactivate the vent'
                        : 'Could not activate the event',
                    errors: eventIsActive ? deactivateErrors : activateErrors,
                    hidden: !onClickActivate || !onClickDeactivate,
                },
                {
                    children: 'Delete event',
                    onClick: () => onClickDelete?.(),
                    text: 'The event will be deleted',
                    color: 'error',
                    disabled: deleteIsLoading,
                    startIcon: deleteIsLoading && (
                        <CircularProgress size={theme.layout.loader.sizes.small}/>
                    ),
                    error: 'Could not delete the event',
                    errors: deleteErrors,
                },
            ]}
        />
    );
};
