import {CSSProperties} from 'react';
import {LOADER_SIZES} from 'src/shared/theme/enums';
import {CircularProgress, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    loadingRowContainer: ({height}: Partial<LoadingRowProps>) => ({
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
}));

interface LoadingRowProps {
    height?: CSSProperties['height'];
    size?: LOADER_SIZES;
}

export const LoadingRow = ({size = LOADER_SIZES.LARGE, height = 175}: LoadingRowProps) => {
    const classes = useStyles({height});

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.loadingRowContainer}>
                <CircularProgress size={size}/>
            </Grid>
        </Grid>
    );
};
