import {useEffect} from 'react';
import {config} from 'src/app/constants/config/config';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {SeatingPlanCategoryProperty} from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import seatingPlanCategoryPropertyService from 'src/data/services/seatingPlanCategoryPropertyService';

interface FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<SeatingPlanCategoryProperty>> {
    fetchSeatingplanCategoryProperties: (
        id: string
    ) => Promise<PaginatedApiResponseBody<SeatingPlanCategoryProperty>>;
}

type FetchSeatingPlanCategoryPropertyDataWrapperProps =
    DataWrapperProps<FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams>;

export const useFetchSeatingplanCategoryPropertiesDataWrapper = ({
                                                                     includes = [],
                                                                     onError,
                                                                     onSuccess,
                                                                     invalidatedAt,
                                                                     page,
                                                                     pageSize = config.ITEMS_PER_PAGE_LARGE,
                                                                     filter,
                                                                     sorting,
                                                                     q,
                                                                 }: Omit<FetchSeatingPlanCategoryPropertyDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{data, loading, errors, resetData}, handleFetchSeatingplanCategoryProperties] =
        useApiFetch<PaginatedApiResponseBody<SeatingPlanCategoryProperty>>();

    useEffect(() => {
        fetchSeatingplanCategoryProperties();
    }, [page, pageSize, filter, sorting, q, invalidatedAt]);

    const fetchSeatingplanCategoryProperties = () => {
        return handleFetchSeatingplanCategoryProperties(
            seatingPlanCategoryPropertyService.getProperties({
                page,
                pageSize,
                filter,
                sorting,
                q,
                includes,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    return {
        fetchSeatingplanCategoryProperties,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchSeatingplanCategoryPropertiesDataWrapper = (
    props: FetchSeatingPlanCategoryPropertyDataWrapperProps & BaseFetchOptions
) => {
    const {children} = props;
    const hookData = useFetchSeatingplanCategoryPropertiesDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
