import {useMemo} from 'react';
import useFetchEventBookingOptions from './use-fetch-event-booking-options';

export const useGetBookingOptionsDropdownOptions = (eventId: string) => {
    const {data: bookingOptionsData, isLoading} = useFetchEventBookingOptions(eventId);

    const bookingOptions = useMemo(() => {
        return (
            bookingOptionsData?.data.data.map((option) => ({
                label: option.name,
                value: option.id,
            })) ?? []
        );
    }, [bookingOptionsData]);

    return {
        bookingOptionsDropdownOptions: bookingOptions,
        isFetching: isLoading,
    };
};
