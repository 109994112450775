import {useMutation} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {updateEventCategory} from 'src/data/services/eventService';

type TData = Awaited<ReturnType<typeof updateEventCategory>>;

const useAssignEventCategory = (onSuccess?: (data: TData) => void) => {
    return useMutation({
        mutationFn: updateEventCategory,
        onSuccess,
        onError: (errors) => {
            Toaster.toastErrors(parseErrors(errors));
        },
    });
};

export default useAssignEventCategory;
