import {Alert, Grid} from '@mui/material';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useUpdateSplitDataWrapper} from 'src/app/components/data-wrappers/splits/UpdateSplitDataWrapper';
import {useFetchTicketByIdDataWrapper} from 'src/app/components/data-wrappers/tickets/FetchTicketByIdDataWrapper';
import {useFetchTicketsDataWrapper} from 'src/app/components/data-wrappers/tickets/FetchTicketsDataWrapper';
import {SearchSplitsForm} from 'src/app/components/forms/tickets/splits/SearchSplitsForm';
import {SplitTicketsTableForm} from 'src/app/components/forms/tickets/splits/SplitTicketsTableForm';
import {TicketsTableDataResolver} from 'src/app/components/tables/TicketsTable';
import {config} from 'src/app/constants/config/config';
import {ticketsHaveSameSplit} from 'src/app/utilities/helpers/selectedTicketRows';
import Ticket from 'src/data/models/tickets/ticket';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import {LoadingRow} from 'src/view/components/loading-row/LoadingRow';
import {RowIdResolver} from 'src/view/components/table/table/Table';

interface AddTicketsToSplitFeatureProps {
    eventId: string;
    selectedTickets: RowIdResolver<TicketsTableDataResolver>[];
    onSuccess?: () => void;
}

export const AddTicketsToSplitFeature = ({
                                             eventId,
                                             selectedTickets,
                                             onSuccess,
                                         }: AddTicketsToSplitFeatureProps) => {
    const [ticketsInvalidatedAt, setTicketsInvalidatedAt] = useState<number>();
    const [fetchedSelectedTickets, setFetchedSelectedTicket] = useState<Ticket[]>([]);

    const getSplitNameFromSelectedtickets = () => {
        if (ticketsHaveSameSplit(selectedTickets)) {
            return selectedTickets[0].data?.splitName || '';
        }

        return '';
    };

    const [searchedValue, setSearchedValue] = useState<string>(getSplitNameFromSelectedtickets);

    const {updateSplit, loading: updateSplitIsLoading} = useUpdateSplitDataWrapper({
        onSuccess: () => {
            setTicketsInvalidatedAt(Date.now());
            onSuccess?.();
        },
    });

    const {
        errors: FetchTicketByIdErrors,
        loading: fetchTicketsByIdIsLoading,
        fetchTicketById,
    } = useFetchTicketByIdDataWrapper({});

    const {
        data: fetchedSplitTickets,
        loading,
        errors,
        fetchTickets,
    } = useFetchTicketsDataWrapper({
        eventId,
        includes: ['splitStatistics'],
        pageSize: config.ITEMS_PER_PAGE_LARGE,
        invalidatedAt: ticketsInvalidatedAt,
        skipInitialCall: true,
        filter: [{property: 'splitName', value: searchedValue}],
    });

    const editMode =
        ticketsHaveSameSplit(selectedTickets) && getSplitNameFromSelectedtickets() !== '';

    const fetchSelectedTickets = async () => {
        return await Promise.all(selectedTickets.map((t) => fetchTicketById(t.id)));
    };

    useEffect(() => {
        const handleFetchedSelectedTickets = async () => {
            const resultTickets = await fetchSelectedTickets();
            const mappedTickets = resultTickets.map((t) => t.data);
            setFetchedSelectedTicket(mappedTickets);
        };

        handleFetchedSelectedTickets();
    }, [ticketsInvalidatedAt]);

    useEffect(() => {
        if (!editMode) return;

        setFetchedSelectedTicket([]);

        fetchTickets({
            filter: [{property: 'splitName', value: searchedValue}],
        });
    }, []);

    const getMergedTickets = () => {
        if (!fetchedSplitTickets || !fetchedSplitTickets.data.length) return [];

        const newTicketsToAdd = fetchedSelectedTickets.filter((ticket) => !ticket.splitId);

        const sortedFetchedTickets = _.sortBy(
            fetchedSplitTickets.data,
            (ticket) => ticket.sortOrder
        );

        return _.uniqBy([...sortedFetchedTickets, ...newTicketsToAdd], (ticket) => ticket.id);
    };

    const onFormSubmit = (tickets: Ticket[]) => {
        updateSplit({
            splitId: fetchedSplitTickets?.data[0].splitId || '',
            tickets: tickets.map((ticket) => ({
                ticketId: ticket.id,
                sortOrder: ticket.sortOrder,
            })),
        });
    };

    return (
        <Grid container spacing={2}>
            {editMode && loading && <LoadingRow/>}

            {!editMode && (
                <Grid item xs={12}>
                    <SearchSplitsForm
                        loading={loading}
                        defaultValues={{
                            id: searchedValue.toUpperCase(),
                        }}
                        onSubmit={async (values) => {
                            setSearchedValue(values.id);
                            fetchTickets({
                                filter: [{property: 'splitName', value: values.id.toUpperCase()}],
                            });
                        }}
                    />
                </Grid>
            )}

            {errors && (
                <Grid item xs={12}>
                    <ErrorsList errors={errors}/>
                </Grid>
            )}

            {fetchedSplitTickets &&
                fetchedSplitTickets.data.length !== 0 &&
                fetchedSelectedTickets &&
                fetchedSelectedTickets.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <SplitTicketsTableForm
                                tableHeaderText="Split Tickets"
                                splitTickets={getMergedTickets()}
                                isTableLoading={
                                    loading || updateSplitIsLoading || fetchTicketsByIdIsLoading
                                }
                                isSubmitDisabled={updateSplitIsLoading || loading}
                                submitButtonText="Confirm"
                                onFormSubmit={onFormSubmit}
                                editMode={editMode}
                            />
                        </Grid>
                    </>
                )}

            {fetchedSplitTickets && fetchedSplitTickets.data.length === 0 && (
                <Grid item xs={12}>
                    <Alert severity="error">No split was found</Alert>
                </Grid>
            )}

            {FetchTicketByIdErrors.length > 0 && (
                <Grid item xs={12}>
                    <Alert severity="error">Something went wrong while getting the ticket.</Alert>
                </Grid>
            )}
        </Grid>
    );
};
