import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import splitService, {UpdateSplitDto} from 'src/data/services/splitService';

interface UpdateSplitDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    updateSplit: (dto: UpdateSplitDto) => Promise<void | ErrorReturn>;
}

type UpdateSplitDataWrapperProps = DataWrapperProps<
    UpdateSplitDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
> &
    BaseFetchOptions;

export const useUpdateSplitDataWrapper = ({
                                              onError,
                                              onSuccess,
                                          }: Omit<UpdateSplitDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleUpdateSplit] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const updateSplitCall = (dto: UpdateSplitDto) => {
        return handleUpdateSplit(splitService.updateSplit(dto), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'Split has been updated',
            onFail: onError,
            onSuccess: () => {
                onSuccess?.();
            },
        });
    };

    return {
        updateSplit: updateSplitCall,
        data,
        loading,
        errors,
        resetData,
    };
};
