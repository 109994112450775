import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LOADER_SIZES} from 'src/shared/theme/enums';
import LoadingSpinner from 'src/view/components/loading-overlay/LoadingSpinner';

const useStyles = makeStyles((theme: Theme) => ({
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        textAlign: 'center',
        color: theme.colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1301,
    },
}));

interface LoadingOverlayProps {
    size?: LOADER_SIZES;
}

export default function LoadingOverlay({
                                           size = LOADER_SIZES.LARGE,
                                       }: LoadingOverlayProps): JSX.Element {
    const classes = useStyles({size});

    return (
        <div className={classes.loadingOverlay}>
            <LoadingSpinner size={size}/>
        </div>
    );
}
