import {useEffect} from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import Purchase from 'src/data/models/purchase/Purchase';
import purchaseService from 'src/data/services/purchaseService';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {DataWrapperCallback} from 'src/app/interfaces/dataWrapperCallback';

interface FetchPurchaseByIdDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<Purchase>> {
    fetchPurchaseById: (purchaseId: string) => Promise<ApiResponseBody<Purchase>>;
}

interface FetchPurchaseByIdDataWrapperProps
    extends DataWrapperProps<FetchPurchaseByIdDataWrapperChildrenParams, ApiResponseBody<Purchase>>,
        BaseFetchOptions {
    purchaseId: string;
}

export const useFetchPurchaseByIdDataWrapper = ({
                                                    onSuccess,
                                                    onError,
                                                    purchaseId,
                                                }: Omit<FetchPurchaseByIdDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleFetchPurchases] =
        useApiFetch<ApiResponseBody<Purchase>>();

    const fetchPurchaseById = (id: string, callbacks?: DataWrapperCallback) => {
        return handleFetchPurchases(purchaseService.fetchPurchaseById(id), {
            useDefaultErrorHandler: true,
            onFail: () => {
                onError?.();
                callbacks?.onFail?.();
            },
            onSuccess: () => {
                onSuccess?.();
                callbacks?.onSuccess?.();
            },
        });
    };

    useEffect(() => {
        fetchPurchaseById(purchaseId);
    }, [purchaseId]);

    return {
        fetchPurchaseById,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchPurchaseByIdDataWrapper = (props: FetchPurchaseByIdDataWrapperProps) => {
    const {children} = props;
    const hookData = useFetchPurchaseByIdDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
