import {Theme} from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import {Breadcrumbs as MUIBreadcrumbs} from '@mui/material';

const Breadcrumbs = withStyles((theme: Theme) => ({
    root: {
        padding: '.5rem 0',
        '& .MuiChip-label': {
            color: theme.palette.primary.main,
            '&:first-of-type': {
                padding: 0,
            },
        },
        '& .MuiBreadcrumbs-separator': {
            color: theme.palette.primary.main,
            margin: 0,
            padding: '0 .5rem',
        },
        '& li:last-child': {
            'font-weight': theme.typography.fontWeightBold,
        },
    },
}))(MUIBreadcrumbs) as typeof MUIBreadcrumbs;

export default Breadcrumbs;
