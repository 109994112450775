import {Box} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {CSSProperties, MouseEvent, useState} from 'react';

const useStyles = makeStyles((theme) => ({
    paper: ({width}: Partial<TablePopoverProps>) => ({
        width,
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        marginLeft: -8,
        pointerEvents: 'auto',
        '&::after': {
            background: 'white',
            border: `1px solid ${theme.colors.mediumGrey}`,
            boxShadow: theme.layout.boxShadowSlight,
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 8,
            height: 8,
            top: 6,
            transform: 'rotate(45deg)',
            left: 8,
        },
    }),
    clickable: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    box: ({boxPadding, maxWidth}: Partial<TablePopoverProps>) => ({
        position: 'relative',
        marginTop: '10px',
        background: 'white',
        border: `1px solid ${theme.colors.mediumGrey}`,
        boxShadow: theme.layout.boxShadowSlight,
        zIndex: 99,
        padding: boxPadding || boxPadding === 0 ? boxPadding : theme.spacing(1),
        maxWidth,
        wordBreak: 'break-word',
    }),
    link: {
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
    },
    popper: ({maxHeight}: Partial<TablePopoverProps>) => ({
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight,
        zIndex: 9999,
    }),
    textContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export interface TablePopoverProps {
    width?: number;
    text: string | number | JSX.Element;
    children: JSX.Element;
    trigger?: 'click' | 'hover';
    isLink?: boolean;
    boxPadding?: CSSProperties['padding'];
    maxWidth?: CSSProperties['maxWidth'];
    maxHeight?: CSSProperties['maxHeight'];
}

const Popover = ({
                     text,
                     children,
                     trigger = 'hover',
                     width,
                     isLink = true,
                     boxPadding,
                     maxWidth = '34em', // = 80 characters per line for readibility
                     maxHeight = '30vh',
                 }: TablePopoverProps) => {
    const classes = useStyles({width, boxPadding, maxWidth, maxHeight});
    const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
    const [open, setOpen] = useState(!!anchorEl);
    const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
        setAnchorEl(event?.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };
    const hasClickTrigger = trigger === 'click';
    const hasHoverTrigger = trigger === 'hover';
    const id = open ? 'table-popover-open' : undefined;

    return (
        <div
            onMouseEnter={(e) => hasHoverTrigger && handleClick(e)}
            onMouseLeave={() => hasHoverTrigger && handleClose()}
        >
            <span
                className={classNames(
                    classes.textContainer,
                    classes.clickable,
                    isLink && classes.link
                )}
                onClick={(e) => hasClickTrigger && handleClick(e)}
            >
                {text}
            </span>
            {open && (
                <Popper
                    className={classes.popper}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    placement="auto"
                    disablePortal={false}
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                            options: {
                                altBoundary: true,
                                rootBoundary: 'document',
                                padding: 8,
                            },
                        },
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                altAxis: false,
                                altBoundary: true,
                                tether: false,
                                rootBoundary: 'document',
                                padding: 8,
                            },
                        },
                    ]}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={classes.box}>{children}</Box>
                    </ClickAwayListener>
                </Popper>
            )}
        </div>
    );
};

export default Popover;
