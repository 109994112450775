import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';

interface useArchiveSeatingplanCategoryDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    archiveSpc: () => Promise<ApiResponseBody<IdResponse>> | undefined;
    unArchiveSpc: () => Promise<ApiResponseBody<IdResponse>> | undefined;
}

interface ArchiveSeatingplanCategoryDataWrapperProps
    extends DataWrapperProps<
        useArchiveSeatingplanCategoryDataWrapperChildrenParams,
        ApiResponseBody<IdResponse>
    >,
        BaseFetchOptions {
    seatingplanCategoryId?: string;
}

export const useArchiveSeatingplanCategoryDataWrapper = ({
                                                             onError,
                                                             onSuccess,
                                                             seatingplanCategoryId,
                                                         }: Omit<ArchiveSeatingplanCategoryDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleArchiveSeatingplanCategory] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const archiveSpcCall = () => {
        if (!seatingplanCategoryId) return;

        return handleArchiveSeatingplanCategory(
            seatingPlanCategoryService.archiveSeatingPlanCategory(seatingplanCategoryId),
            {
                useDefaultErrorHandler: true,
                onSuccess,
                onFail: onError,
                defaultSuccessMessage: 'Seatingplan category has been archived',
            }
        );
    };

    const unArchiveSpcCall = () => {
        if (!seatingplanCategoryId) return;

        return handleArchiveSeatingplanCategory(
            seatingPlanCategoryService.unarchiveSeatingPlanCategory(seatingplanCategoryId),
            {
                useDefaultErrorHandler: true,
                onSuccess,
                onFail: onError,
                defaultSuccessMessage: 'Seatingplan category has been unarchived',
            }
        );
    };

    return {
        archiveSpc: archiveSpcCall,
        unArchiveSpc: unArchiveSpcCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const ArchiveSeatingplanCategoryDataWrapper = (
    props: ArchiveSeatingplanCategoryDataWrapperProps
) => {
    const {children} = props;
    const hookData = useArchiveSeatingplanCategoryDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
