import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import FormButtons from 'src/view/components/form/FormButtons';
import useApiFetch from 'src/app/hooks/useApiFetch';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import ticketService from 'src/data/services/ticketService';
import {useSnackbar} from 'notistack';

interface Props {
    ticketId?: string;
    fileId?: string;
    onSucceed?: () => void;
}

export default function DeleteTicketFileFormDataWrapper({
                                                            ticketId,
                                                            fileId,
                                                            onSucceed,
                                                        }: Props): JSX.Element {
    const {enqueueSnackbar} = useSnackbar();

    const [{loading, errors}, handleDeleteFile] = useApiFetch<ApiResponseBody<EmptyBody>>();

    const onDeleteConfirm = () => {
        if (!ticketId || !fileId) return;

        handleDeleteFile(ticketService.deleteTicketFile(ticketId, fileId), {
            onSuccess: () => {
                enqueueSnackbar('File deleted successfully', {variant: 'success'});
                onSucceed?.();
            },
            useDefaultErrorHandler: true,
        });
    };

    return (
        <>
            <FormButtons
                buttons={[
                    {
                        children: 'Delete file',
                        onClick: onDeleteConfirm,
                    },
                ]}
            />
            {loading && <LoadingOverlay/>}
            {errors.length > 0 && <ErrorsList errors={errors}/>}
        </>
    );
}
