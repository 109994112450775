import React from 'react';
import {NavLink} from 'react-router-dom';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {SubMenuItem} from 'src/app/interfaces/menu';

const generalLinkStyles = {
    textDecoration: 'none',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s linear',
};

const baseLinkStyleDesktop = {
    ...generalLinkStyles,
    justifyContent: 'center',
    padding: '1rem',
    '& > p': {
        margin: 0,
        padding: 0,
    },
};

const baseLinkStyleMobile = {
    ...generalLinkStyles,
    padding: '0.2rem 2rem',
    '& > p': {
        fontSize: '13px',
    },
};

const useStyles = makeStyles((theme: Theme) => ({
    link: (props: Partial<SubMenuItem>) => ({
        ...baseLinkStyleDesktop,
        pointerEvents: props.isClickable ? 'auto' : 'none',
        position: 'relative',
        color: theme.colors.darkGrey,
        '&:hover': {
            color: '#000',
            backgroundColor: theme.colors.lightGrey,
        },
    }),
    linkActive: (props: Partial<SubMenuItem>) => ({
        ...baseLinkStyleDesktop,
        pointerEvents: props.isClickable ? 'auto' : 'none',
        position: 'relative',
        color: '#000',
        backgroundColor: theme.colors.lightGrey,
    }),
    mobileLink: (props: Partial<SubMenuItem>) => ({
        ...baseLinkStyleMobile,
        pointerEvents: props.isClickable ? 'auto' : 'none',
        color: theme.colors.white,
        position: 'relative',
        '&:hover, &:active': {
            color: theme.colors.grey,
            backgroundColor: theme.palette.tertiary.dark,
        },
    }),
    mobileLinkActive: (props: Partial<SubMenuItem>) => ({
        ...baseLinkStyleMobile,
        pointerEvents: props.isClickable ? 'auto' : 'none',
        color: theme.colors.white,
        backgroundColor: theme.palette.tertiary.dark,
    }),
}));

export default function NavbarSubNavigationLink({
                                                    url,
                                                    label,
                                                    isClickable = true,
                                                    onLinkClicked,
                                                    isMobile = false,
                                                }: SubMenuItem) {
    const classes = useStyles({isClickable});

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!url) event.preventDefault();

        if (onLinkClicked !== undefined) {
            onLinkClicked();
        }
    };

    const getClasses = (isActive: boolean) => {
        if (isMobile) {
            return isActive ? classes.mobileLinkActive : classes.mobileLink;
        }

        return isActive ? classes.linkActive : classes.link;
    };

    return (
        <NavLink
            to={url}
            onClick={handleLinkClick}
            className={({isActive}) => getClasses(isActive)}
        >
            <p>{label}</p>
        </NavLink>
    );
}
