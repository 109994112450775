import {useEffect, useRef, EffectCallback, DependencyList} from 'react';

export default function useEffectSkipMount(effect: EffectCallback, deps: DependencyList): void {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) effect();
        else didMount.current = true;
    }, deps);
}
