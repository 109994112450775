import {useMutation} from '@tanstack/react-query';
import {useConfirm} from 'material-ui-confirm';
import {useEffect, useState} from 'react';
import {FetchSeriesDataWrapper} from 'src/app/components/data-wrappers/series/FetchSeriesDataWrapper';
import SeriesDetailsUpdateFeature from 'src/app/components/features/series/SeriesDetailsUpdateFeature';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import SeriesTable from 'src/app/components/tables/SeriesTable';
import {config} from 'src/app/constants/config/config';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import Toaster from 'src/app/utilities/helpers/Toaster';
import FilterOption from 'src/data/api/common/FilterOption';
import seriesService from 'src/data/services/seriesService';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';

interface Props {
    filters?: FilterOption[];
    q: string;
    invalidatedAt?: number;
    page: number;
    onChangePage: (page: number) => void;
}

export default function SeriesTableFeature({
                                               q,
                                               invalidatedAt,
                                               filters,
                                               page,
                                               onChangePage,
                                           }: Props): JSX.Element {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [seriesInvalidatedAt, setSeriesInvalidatedAt] = useState<number | undefined>(
        invalidatedAt
    );
    const [seriesId, setSeriesId] = useState<string | undefined>();
    const confirm = useConfirm();
    const {mutate: deleteSeries, isLoading: isDeleteLoading} = useMutation({
        mutationFn: (seriesId: string) => seriesService.deleteSeries(seriesId),
        onError: (error) => {
            Toaster.toastErrors(parseErrors(error));
        },
        onSuccess: () => {
            setSeriesInvalidatedAt(Date.now());
            setDetailsOpen(false);
        },
    });

    useEffect(() => {
        if (!invalidatedAt) return;

        setSeriesInvalidatedAt(invalidatedAt);
    }, [invalidatedAt]);

    const seriesDetailsCallbacks = {
        onUpdateSucceed: () => {
            setDetailsOpen(false);
            setSeriesInvalidatedAt(Date.now());
        },
    };

    return (
        <>
            <FetchSeriesDataWrapper
                filter={filters}
                q={q}
                invalidatedAt={seriesInvalidatedAt}
                page={page}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
            >
                {({loading, data}) => (
                    <>
                        {loading && <LoadingOverlay/>}
                        <TableHeaderTotalFeature title="Series" totalResults={data?.meta.total}/>
                        <SeriesTable
                            series={data?.data || []}
                            loading={loading}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: onChangePage,
                            }}
                            onEditSeries={(seriesId: string) => {
                                setSeriesId(seriesId);
                                setDetailsOpen(true);
                            }}
                        />
                    </>
                )}
            </FetchSeriesDataWrapper>
            <Modal
                width="small"
                title="Series Details"
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
            >
                {seriesId ? (
                    <>
                        {isDeleteLoading && <LoadingOverlay/>}
                        <SeriesDetailsUpdateFeature
                            seriesId={seriesId}
                            onDeleteClick={async () => {
                                try {
                                    await confirm({
                                        title: 'Are you sure you want to delete the series?',
                                        description: "It's not possible to revert this action",
                                    });

                                    deleteSeries(seriesId);
                                } catch {
                                    /* empty */
                                }
                            }}
                            onUpdateSucceed={seriesDetailsCallbacks.onUpdateSucceed}
                        />
                    </>
                ) : (
                    <></>
                )}
            </Modal>
        </>
    );
}
