import {AUTO_ASSIGN_ALGORITHM_TYPES} from "src/data/services/orderlineService"

type AutoAssignAlgorithmType =
    (typeof AUTO_ASSIGN_ALGORITHM_TYPES)[keyof typeof AUTO_ASSIGN_ALGORITHM_TYPES];

export default function getIsGuaranteedSeatsDisabled(algorithm: AutoAssignAlgorithmType) {
    const algorithmsWhichDisableGuaranteedSeats: AutoAssignAlgorithmType[] = [
        AUTO_ASSIGN_ALGORITHM_TYPES.BIN_PACKING_ASSIGNER,
    ];

    return algorithmsWhichDisableGuaranteedSeats.includes(algorithm);
}

