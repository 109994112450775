import {createTheme} from '@mui/material/styles';
import baseTheme from 'src/app/theme/base';

const p1Theme = createTheme({
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        primary: {
            light: '#b2ccdb',
            main: '#212240',
            dark: '#1A1B33',
        },
        secondary: {
            main: '#f47523',
            dark: '#f1660c',
            contrastText: '#fff',
        },
        tertiary: {
            light: '#38b4eb',
            main: '#2E93BF',
            dark: '#237294',
            contrastText: '#fff',
        },
    },
    colors: {
        ...baseTheme.colors,
        buttonBackgroundColor: {normal: '#0672B0', hover: '#035d91'},
        buttonTextColor: {normal: '#fff', hover: '#fff'},
    },
});

export default p1Theme;
