import {useMsal} from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Theme,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import NavbarLink from 'src/app/components/buttons/navbar/NavbarLink';
import NavbarLinkMobile from 'src/app/components/buttons/navbar/NavbarLinkMobile';
import NavbarSubNavigationLink from 'src/app/components/buttons/navbar/NavbarSubNavigationLink';
import {menuTabs} from 'src/app/constants/constants/routing/menuTabs';
import {useUserProfile} from 'src/app/hooks/auth/useUserProfile';
import {MenuItem, SubMenuItem} from 'src/app/interfaces/menu';
import {hasPermission} from 'src/app/utilities/helpers/userRole';
import Button from 'src/view/components/button/Button';
import {ETBContainer} from 'src/view/components/container/container';

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        padding: '0.5rem 0rem',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    menuIcon: {
        marginRight: theme.spacing(3),
        fill: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    drawerPaper: {
        background: theme.palette.primary.main,
        padding: 0,
    },
    drawerTitle: {
        margin: '1.5rem',
    },
    whiteText: {
        color: theme.colors.white,
        '& .MuiTypography-root': {
            fontSize: '.875rem',
        },
    },
    list: {
        paddingTop: 0,
    },
    dropdownPaper: {
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 99,
        width: '100%',
        padding: 0,
        boxShadow: '0px 9px 30px 0px rgb(0 0 0 / 20%)',
        borderRadius: '0 0 4px 4px',
    },
    menuItemWrapper: {
        position: 'relative',
        display: 'flex',
        margin: '0 6px',
    },
    avatar: {
        width: 24,
        height: 24,
        fontSize: '.6rem',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        '& > div': {
            marginTop: 2,
        },
    },
    subMenuItemText: {
        '& .MuiTypography-root': {
            fontSize: '.875rem',
        },
    },
    mobileMenuIcon: {
        fill: 'white',
        '& path': {
            fill: 'white',
        },
    },
    mobileLoggedInAs: {
        '& .MuiTypography-root': {
            fontSize: '.8rem',
        },
    },
}));

interface NavbarProps {
    logo: string | JSX.Element;
}

export const Navbar = ({logo = 'Etbaas'}: NavbarProps) => {
    const classes = useStyles();
    const {accounts} = useMsal();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dropdownOpened, setDropdownOpened] = useState<string | undefined>();
    const profile = useUserProfile();
    const userRoles = profile['msal-roles'];

    const navigate = useNavigate();
    const location = useLocation();

    const {instance} = useMsal();

    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    const logout = () => {
        instance.logoutRedirect({
            account: accounts[0],
        });
    };

    const dropdownIsOpen = (label: string) => label === dropdownOpened;
    const hasSubItems = (menuItem: MenuItem) => menuItem.subItems.length > 0;

    const renderMobileMenuItems = () => {
        return menuTabs.map((menuItem, index) => {
            if (!hasPermission(menuItem.permissions, userRoles)) return;

            return (
                <React.Fragment key={`${menuItem.label}-${index}`}>
                    <NavbarLinkMobile
                        Icon={menuItem.Icon}
                        permissions={menuItem.permissions}
                        url={menuItem.url}
                        label={menuItem.label}
                        subItems={menuItem.subItems}
                        isClickable={menuItem.isClickable}
                        onLinkClicked={() => setDrawerOpen(false)}
                    />
                </React.Fragment>
            );
        });
    };

    const renderProfileMenuItem = () => {
        const profileIdentifier = 'Profile';

        return (
            <div
                className={classes.menuItemWrapper}
                onMouseEnter={() => setDropdownOpened(profileIdentifier)}
                onMouseLeave={() => setDropdownOpened(undefined)}
                data-cy="profile-name"
            >
                <Button variant="text" white>
                    <Avatar className={classes.avatar}>
                        <div>
                            {profile.name
                                .split(' ')
                                .map((word) => word.charAt(0).toUpperCase())
                                .join('')}
                        </div>
                    </Avatar>
                    {profile?.name}
                </Button>
                {dropdownIsOpen(profileIdentifier) && (
                    <Paper className={classes.dropdownPaper}>
                        <List>
                            <ListItemButton disableRipple onClick={logout}>
                                <ListItemText className={classnames(classes.subMenuItemText)}>
                                    Logout
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Paper>
                )}
            </div>
        );
    };

    const renderSubMenuItems = (_menuItem: MenuItem, subMenuItems: (SubMenuItem | null)[]) => {
        return subMenuItems.map(
            (subMenuItem) =>
                subMenuItem && (
                    <NavbarSubNavigationLink
                        key={`${subMenuItem.url}`}
                        url={`${subMenuItem.url}`}
                        label={subMenuItem.label}
                    />
                )
        );
    };

    const renderMenuItems = () => {
        return menuTabs.map((menuItem, index) => {
            if (!hasPermission(menuItem.permissions, userRoles)) return;
            return (
                <div
                    key={`${menuItem.label}-${index}`}
                    className={classes.menuItemWrapper}
                    onMouseEnter={() => setDropdownOpened(menuItem.label)}
                    onMouseLeave={() => setDropdownOpened(undefined)}
                    data-cy={`menu-link-${menuItem.label}`}
                >
                    <NavbarLink
                        url={menuItem.url}
                        label={menuItem.label}
                        isClickable={menuItem.isClickable}
                        permissions={menuItem.permissions}
                        subItems={menuItem.subItems}
                        idDropdownOpened={dropdownIsOpen(menuItem.label) && hasSubItems(menuItem)}
                    />

                    {dropdownIsOpen(menuItem.label) && hasSubItems(menuItem) && (
                        <Paper className={classes.dropdownPaper}>
                            <List>{renderSubMenuItems(menuItem, menuItem.subItems)}</List>
                        </Paper>
                    )}
                </div>
            );
        });
    };

    const goToHomePage = () => {
        if (location.pathname !== '/') {
            navigate('/');
        }
    };

    return (
        <AppBar position="static" color="primary" sx={{padding: 0}}>
            <ETBContainer
                sx={(theme) => ({
                    padding: '0rem 2rem',
                    background: theme.colors.white,
                    borderBottom: `2px solid ${theme.palette.primary.light}`,
                })}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item alignItems="center" display="flex">
                        <Box sx={{display: {xs: 'flex', lg: 'none'}, alignItems: 'center'}}>
                            <MenuIcon onClick={toggleDrawer} className={classes.menuIcon}/>
                        </Box>
                        <div className={classes.logo} onClick={goToHomePage}>
                            {logo}
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{display: {xs: 'none', lg: 'flex'}}} justifyContent="flex-end">
                            {renderMenuItems()}
                            {renderProfileMenuItem()}
                        </Box>
                    </Grid>
                </Grid>
            </ETBContainer>
            <React.Fragment>
                <Drawer
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    PaperProps={{
                        className: classes.drawerPaper,
                    }}
                >
                    <h2 className={classnames(classes.whiteText, classes.drawerTitle)}>{logo}</h2>
                    <List className={classes.list}>{renderMobileMenuItems()}</List>
                    <List className={classes.list}>
                        <ListItemButton onClick={logout}>
                            <ListItemIcon
                                className={classes.mobileMenuIcon}
                                sx={{stroke: 'white'}}
                            >
                                <LogoutIcon/>
                            </ListItemIcon>

                            <ListItemText
                                primary="Logout"
                                className={classnames(classes.whiteText)}
                            />
                        </ListItemButton>
                        <Divider/>
                        <ListItem alignItems="center">
                            <ListItemText
                                primary={`Logged in as ${profile?.name}}`}
                                className={classnames(classes.whiteText, classes.mobileLoggedInAs)}
                            />
                        </ListItem>
                    </List>
                </Drawer>
            </React.Fragment>
        </AppBar>
    );
};
