import {useMutation} from '@tanstack/react-query';
import supplierService from 'src/data/services/supplierService';

type TData = Awaited<ReturnType<typeof supplierService.archiveSupplier>>;

const useArchiveSupplier = (onSuccess?: (data: TData) => void) => {
    return useMutation({
        mutationFn: (id: string) => {
            return supplierService.archiveSupplier(id);
        },
        onSuccess,
    });
};

export default useArchiveSupplier;
