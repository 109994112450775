import {zodResolver} from '@hookform/resolvers/zod';
import {Grid, Typography} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import moment from 'moment';
import {useState, type Dispatch, type SetStateAction} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {FETCH_EVENT_BY_ID_QUERY_KEY} from 'src/app/hooks/events/useFetchEventById';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {type InternalNotesType} from 'src/data/models/events/event';
import {updateEventNotes} from 'src/data/services/eventService';
import {localDateFormat} from 'src/shared/date';
import Button from 'src/view/components/button/Button';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import z from 'zod';

export const EventInternalNotes = ({
                                       eventId,
                                       internalNotes,
                                   }: {
    eventId: string;
    internalNotes: InternalNotesType;
}) => {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            <Typography variant="h6">Internal notes</Typography>
            {isEditing ? (
                <UpdateInternalNoteForm
                    handleEditingState={setIsEditing}
                    existingNote={internalNotes?.notes}
                    eventId={eventId}
                />
            ) : (
                <InternalNote handleEditingState={setIsEditing} internalNotes={internalNotes}/>
            )}
        </>
    );
};

const UpdateInternalNoteForm = ({
                                    handleEditingState,
                                    existingNote,
                                    eventId,
                                }: {
    handleEditingState: Dispatch<SetStateAction<boolean>>;
    existingNote?: string;
    eventId: string;
}) => {
    const form = useForm<UpdateInternalNotesFormSchema>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            internalNotes: existingNote || '',
        },
    });

    const {mutate: updateEventNotes} = useUpdateEventInternalNotes({
        onSuccess: () => {
            handleEditingState(false);
        },
    });

    const {control, handleSubmit} = form;

    return (
        <Grid container rowGap={1}>
            <Controller
                name="internalNotes"
                control={control}
                render={({field: {name, onChange, value}, fieldState: {error}}) => (
                    <>
                        <i>Tip: Include @important to highlight the message. </i>
                        <Input
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder="Internal notes"
                            grey
                        />
                        <FormFieldError message={error?.message}/>
                    </>
                )}
            />
            <Grid container item xs={12} marginLeft="auto" justifyContent={'end'}>
                <Button onClick={() => handleEditingState(false)}>Cancel</Button>

                <Button
                    onClick={handleSubmit((data) => {
                        updateEventNotes({eventId, ...data});
                    })}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
};

const InternalNote = ({
                          internalNotes,
                          handleEditingState,
                      }: {
    internalNotes: InternalNotesType;
    handleEditingState: Dispatch<SetStateAction<boolean>>;
}) => {
    const isImportant = internalNotes?.notes.includes('@important');

    const startEditing = () => handleEditingState(true);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
            rowGap={1}
        >
            <Grid
                container
                item
                xs={12}
                sx={{
                    backgroundColor: (theme) => (isImportant ? '#ffcd5c' : theme.colors.bluishGrey),
                    padding: 2,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography>{internalNotes?.notes || 'No internal notes'}</Typography>
                {internalNotes?.notes && (
                    <Typography marginTop={3}>
                        {moment(internalNotes?.updatedAt)
                            .local()
                            .format(localDateFormat)}
                    </Typography>
                )}
            </Grid>

            <Button
                onClick={startEditing}
                sx={{
                    marginLeft: 'auto',
                }}
            >
                Edit
            </Button>
        </Grid>
    );
};

// Form Zod Schema
const formSchema = z.object({
    internalNotes: z.string().optional(),
});
type UpdateInternalNotesFormSchema = z.infer<typeof formSchema>;

// Hooks
type MutationFnProps = { eventId: string; internalNotes?: string };
type useUpdateEventInternalNotesProps = { onSuccess?: () => void };

export const useUpdateEventInternalNotes = ({onSuccess}: useUpdateEventInternalNotesProps) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({eventId, internalNotes}: MutationFnProps) =>
            updateEventNotes(eventId, internalNotes || ''),
        onSuccess: () => {
            queryClient.invalidateQueries([FETCH_EVENT_BY_ID_QUERY_KEY]);
            Toaster.toast('Internal note updated', {
                variant: 'success',
            });
            onSuccess?.();
        },
        onError: () => {
            Toaster.toast('Failed to update internal note');
        },
    });
};
