import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Organizer} from 'src/data/services/organizerService';
import {IconButton} from '@mui/material';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import EventsOverviewUrlService from 'src/app/pages/events/eventsOverviewUrlService';

export interface Props {
    organizers?: Organizer[];
    loading: boolean;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    pagination?: TablePaginationData;
}

export default function OrganizerTable({
                                           organizers,
                                           loading,
                                           onEditClick,
                                           onDeleteClick,
                                           pagination,
                                       }: Props): JSX.Element {
    const spacingClasses = useSpacingStyles();
    const [columns, setColumns] = React.useState<NewTableColumn<Organizer>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<Organizer>[] = [];

        cols = [
            {
                key: 'name',
                title: 'Name',
                cellRenderer: (rowData) => rowData.name,
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'eventsCount',
                title: '# of events',
                cellRenderer: (rowData) => {
                    return (
                        <a href={EventsOverviewUrlService.root({organizerId: rowData.id})}>
                            {rowData.eventsCount}
                        </a>
                    );
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                align: 'right',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    return (
                        <>
                            <IconButton
                                className={spacingClasses.spacingRight}
                                color="primary"
                                size={'small'}
                                onClick={() => {
                                    onEditClick(rowData.id);
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                            <IconButton
                                color="error"
                                size={'small'}
                                onClick={() => {
                                    onDeleteClick(rowData.id);
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </>
                    );
                },
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<Organizer>
            data={organizers}
            rowIdResolver={(rowData: Organizer) => ({
                id: rowData.id,
            })}
            columns={columns}
            loading={loading}
            minWidth={650}
            pagination={pagination}
        />
    );
}
