import {useEffect, useState} from 'react';
import CreateTicketSalesCapForm, {
    CreateTicketSalesCapFormValues,
} from 'src/app/components/forms/CreateTicketSalesCapForm';
import useApiFetch from 'src/app/hooks/useApiFetch';
import CreateDataResponse from 'src/data/api/common/CreateDataResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import ticketSalesCapService, {
    CreateTicketsSalesCapDto,
    TicketSalesCap,
} from 'src/data/services/ticketSalesCapService';

interface Props {
    eventId: string;
    id?: string;
    onSucceed: () => void;
}

export default function CreateTicketSalesCapFormDataWrapper({
                                                                eventId,
                                                                id,
                                                                onSucceed,
                                                            }: Props): JSX.Element {
    const [defaultFormValues, setDefaultFormValues] = useState<
        CreateTicketSalesCapFormValues | undefined
    >();

    const [{data, loading}, handleFetch] = useApiFetch<ApiResponseBody<TicketSalesCap>>();
    const [{loading: isCreating}, handleCreate] =
        useApiFetch<ApiResponseBody<CreateDataResponse>>();
    const [{loading: isUpdating}, handleUpdate] = useApiFetch<ApiResponseBody<EmptyBody>>();

    useEffect(() => {
        if (!id) return;

        handleFetch(ticketSalesCapService.getTicketSalesCapById(eventId, id), {
            useDefaultErrorHandler: true,
        });
    }, []);

    useEffect(() => {
        if (!id || !data) return;

        const {
            seatingPlanCategoryId,
            seatingPlanCategoryName,
            price,
            salesCap,
            fixedQuantityStep,
            allSalesProfiles,
            salesProfiles,
            bookingOptionId,
            bookingOptionName,
        } = data.data;

        const formValues: CreateTicketSalesCapFormValues = {
            seatingPlanCategory: {
                label: seatingPlanCategoryName,
                value: seatingPlanCategoryId,
            },
            bookingOption: {label: bookingOptionName, value: bookingOptionId},
            price,
            fixedQuantityStep,
            salesCap,
            allSalesProfiles,
            salesProfiles: salesProfiles.map((c) => ({label: c.name, value: c.id})),
        };

        setDefaultFormValues(formValues);
    }, [data]);

    const onFormSubmit = (values: CreateTicketSalesCapFormValues) => {
        const {
            seatingPlanCategory,
            price,
            salesCap,
            fixedQuantityStep,
            allSalesProfiles,
            salesProfiles,
            bookingOption,
        } = values;

        const dto: CreateTicketsSalesCapDto = {
            seatingPlanCategoryId: seatingPlanCategory?.value,
            bookingOptionId: bookingOption.value,
            price,
            salesCap,
            fixedQuantityStep,
            allSalesProfiles,
            salesProfileIds: salesProfiles.map((c) => c.value),
        };

        if (id) {
            handleUpdate(ticketSalesCapService.updateTicketsSalesCap(eventId, id, dto), {
                useDefaultErrorHandler: true,
                onSuccess: () => onSucceed?.(),
            });

            return;
        }

        handleCreate(ticketSalesCapService.createTicketsSalesCap(eventId, dto), {
            useDefaultErrorHandler: true,
            onSuccess: () => onSucceed?.(),
        });
    };

    return (
        <CreateTicketSalesCapForm
            eventId={eventId}
            editMode={!!id}
            loading={loading || isCreating || isUpdating}
            defaultValues={defaultFormValues}
            onFormSubmit={onFormSubmit}
        />
    );
}
