import urlService, {defaultCustomFields} from 'src/app/services/urlService';
import {
    filterOptionsToQueryParams,
    mapKeyStringObjectToFilterOptions,
} from 'src/app/utilities/helpers/filter';
import {PURCHASE_STATUS} from 'src/data/enums/purchase';

interface CreatePurchaseUrlProps {
    eventId?: string;
    eventName?: string;
}

interface PurchasesFilters {
    status?: PURCHASE_STATUS[];
}

const purchasesFiltersDefault: PurchasesFilters = {
    status: [PURCHASE_STATUS.Completed, PURCHASE_STATUS.Confirmed],
};

const purchaseUrlService = {
    overview() {
        return urlService.getPurchases();
    },
    create(props?: CreatePurchaseUrlProps) {
        const {eventId, eventName} = props || {};

        if (eventId && eventName) {
            return urlService.getPurchases(`/create?eventId=${eventId}&eventName=${eventName}`);
        }

        return urlService.getPurchases('/create');
    },
    advancedEdit(purchaseId?: string) {
        return purchaseId
            ? urlService.getPurchases(`/advanced-edit?purchaseId=${purchaseId}`)
            : urlService.getPurchases(`/advanced-edit`);
    },
    root(filters: PurchasesFilters = purchasesFiltersDefault) {
        return urlService.getPurchases(
            `${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(
                    filters || purchasesFiltersDefault
                ),
                customFields: defaultCustomFields,
                suffix: 'purchases',
            })}`
        );
    },
};

export default purchaseUrlService;
