import {default as Delete} from '@mui/icons-material/Delete';

import Box from '@material-ui/core/Box';
import {
    Alert,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Theme,
} from '@mui/material';
import {useTheme} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {debounce} from 'lodash';
import moment from 'moment';
import {useEffect, useMemo, useState} from 'react';
import {Controller, useFieldArray} from 'react-hook-form';
import OrganizersSelectDataWrapper from 'src/app/components/data-wrappers/inputs/OrganizersSelectDataWrapper';
import PerformerSelectDataWrapper from 'src/app/components/data-wrappers/inputs/PerformerSelectDataWrapper';
import SeatingPlanSelectDataWrapper from 'src/app/components/data-wrappers/inputs/SeatingPlanSelectDataWrapper';
import SeriesSelectDataWrapper from 'src/app/components/data-wrappers/inputs/SeriesSelectDataWrapper';
import VenueSelectDataWrapper from 'src/app/components/data-wrappers/inputs/VenueSelectDataWrapper';
import {FetchTagsDataWrapper} from 'src/app/components/data-wrappers/tags/FetchTagsDataWrapper';
import {config} from 'src/app/constants/config/config';
import {
    defaultTicketsSentEarliestDays,
    defaultTicketsSentLatestDays,
} from 'src/app/constants/constants/form/events';
import {useEventCategories} from 'src/app/hooks/event-categories/useEventCategories';
import {useFetchTeams} from 'src/app/hooks/teams/useFetchTeams';
import toaster from 'src/app/utilities/helpers/Toaster';
import mapEventDetailsToFormValues from 'src/app/utilities/mappers/mapEventDetailsToFormValues';
import {TAG_TYPES} from 'src/data/enums/tagType';
import {EVENT_TYPE, EventDetails} from 'src/data/models/events/event';
import tagService from 'src/data/services/tagService';
import {countryList} from 'src/shared/countries';
import {dateTimeFormat} from 'src/shared/date';
import {fontFamilies} from 'src/shared/theme/fonts';
import {generateRandomId} from 'src/shared/utils';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import Chip from 'src/view/components/chip/Chip';
import DateTimePicker from 'src/view/components/date-picker/DateTimePicker';
import {Divider} from 'src/view/components/divider/Divider';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import {EventBookingOptions} from '../../features/event-booking-options/event-booking-options';
import useFetchEventBookingOptions from '../../features/event-booking-options/hooks/use-fetch-event-booking-options';
import {type CreateEventFormValues, type UseCreateEventFormReturn} from './useCreateEventForm';

const useStyles = makeStyles((theme: Theme) => ({
    secondaryTitle: {
        marginBottom: 0,
        fontWeight: 'bold',
        fontSize: '1rem',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    spacingRight: {
        paddingRight: theme.spacing(1),
    },
    spacingBottom: {
        marginBottom: theme.spacing(2),
    },
    largeSpacingBottom: {
        marginBottom: theme.spacing(4),
    },
    versusContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    },
    pointer: {
        cursor: 'pointer',
    },
    chip: {
        marginRight: theme.spacing(0.5),
    },
    activeLabel: {
        '& .MuiFormControlLabel-label': {
            fontFamily: fontFamilies.primary,
            fontSize: theme.typography.body2.fontSize,
        },
    },
    noMarginBottom: {
        marginBottom: theme.spacing(0),
    },
    noMarginTop: {
        marginTop: theme.spacing(0),
    },
    spacingLeft: {
        marginLeft: theme.spacing(1),
    },
    inlineInputButton: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export enum PERFORMERS_TYPE {
    Contestant = 'Contestant',
    Artist = 'Artist',
}

export const eventTypeOptions = [
    {label: 'Team Sport', value: EVENT_TYPE.TeamSports},
    {label: 'Tennis', value: EVENT_TYPE.Tennis},
    {label: 'Concert', value: EVENT_TYPE.Concert},
    {label: 'Sports', value: EVENT_TYPE.Sports},
];

interface Props {
    form: UseCreateEventFormReturn;
    edit?: boolean;
    actionLoading?: boolean;
    eventDetails?: EventDetails;
    seriesOption?: AutoCompleteOption;
    venueOption?: AutoCompleteOption;
    homeTeamOption?: AutoCompleteOption;
    awayTeamOption?: AutoCompleteOption;
    onCreateHomeTeam?: (name: string) => void;
    onCreateAwayTeam?: (name: string) => void;
    onCreateVenue?: (name: string) => void;
    onCreateSeries?: (name: string) => void;
    onFormSubmit: (values: CreateEventFormValues) => void;
    eventId?: string;
}

export default function CreateEventForm({
                                            eventId,
                                            form,
                                            edit,
                                            eventDetails,
                                            actionLoading,
                                            venueOption,
                                            seriesOption,
                                            homeTeamOption,
                                            awayTeamOption,
                                            onCreateHomeTeam,
                                            onCreateAwayTeam,
                                            onCreateVenue,
                                            onCreateSeries,
                                            onFormSubmit,
                                        }: Props) {
    const classes = useStyles();
    const theme = useTheme();

    const {data: teamsData} = useFetchTeams({
        pageSize: config.ITEMS_PER_PAGE_LARGE,
    });

    const [eventTagsSearchQuery, setEventTagsSearchQuery] = useState('');
    const {data: eventCategoriesResponse, isLoading: isEventCategoriesLoading} =
        useEventCategories({pageSize: config.ITEMS_PER_PAGE_LARGE});

    const {
        control,
        watch,
        setValue,
        reset,
        handleSubmit,
        formState: {isDirty},
    } = form;

    const isSubmitButtonDisabled = actionLoading || !isDirty;

    const eventType = watch('eventType');
    const venue = watch('venue');
    const tags = watch('eventTags');
    const startDate = watch('startDate');
    const homeTeam = watch('homeTeam');
    const awayTeam = watch('awayTeam');
    const matches = watch('matches');
    const hasMultipleBookingOptions = watch('hasMultipleBookingOptions');

    const {fields, append, remove} = useFieldArray({
        control: form.control,
        name: 'matches',
    });

    useEffect(() => {
        if (!eventDetails) return;

        const formValues = mapEventDetailsToFormValues(eventDetails);

        reset(formValues);
    }, [eventDetails]);

    useEffect(() => {
        if (!seriesOption) return;

        setValue('series', seriesOption, {shouldValidate: true});
    }, [seriesOption]);

    useEffect(() => {
        if (!venueOption) return;

        setValue('venue', venueOption, {shouldValidate: true});
    }, [venueOption]);

    useEffect(() => {
        setValue('homeTeam', homeTeamOption);
    }, [homeTeamOption]);

    useEffect(() => {
        setValue('awayTeam', awayTeamOption);
    }, [awayTeamOption]);

    useEffect(() => {
        if (edit) return;

        if (!homeTeam || !awayTeam) {
            setValue('eventName', '');

            return;
        }

        setValue('eventName', `${homeTeam.label} vs ${awayTeam.label}`, {shouldValidate: true});
    }, [homeTeam, awayTeam]);

    const resetOtherCategoryFields = (value: EVENT_TYPE) => {
        if (value === EVENT_TYPE.TeamSports) {
            setValue('contestants', [], {shouldValidate: true});
            setValue('artists', [], {shouldValidate: true});
            setValue('matches', [], {shouldValidate: true});
        }

        if (value === EVENT_TYPE.Concert) {
            setValue('contestants', [], {shouldValidate: true});
            setValue('matches', [], {shouldValidate: true});
            setValue('homeTeam', undefined, {shouldValidate: true});
            setValue('awayTeam', undefined, {shouldValidate: true});
        }

        if (value === EVENT_TYPE.Tennis) {
            setValue('artists', [], {shouldValidate: true});
            setValue('contestants', [], {shouldValidate: true});
            setValue('homeTeam', undefined, {shouldValidate: true});
            setValue('awayTeam', undefined, {shouldValidate: true});
        }

        if (value === EVENT_TYPE.Sports) {
            setValue('artists', [], {shouldValidate: true});
            setValue('matches', [], {shouldValidate: true});
            setValue('homeTeam', undefined, {shouldValidate: true});
            setValue('awayTeam', undefined, {shouldValidate: true});
        }
    };

    const onCreateTag = async (type: TAG_TYPES, name: string) => {
        try {
            const result = await tagService.createTag({
                type: type,
                name,
            });

            const createdOption = {label: name, value: result.data.data.id};

            if (tags && tags.length) {
                setValue('eventTags', [...tags, createdOption]);
                return;
            }

            setValue('eventTags', [createdOption]);
        } catch {
            toaster.toast('Something went wrong creating the tags', {
                variant: 'error',
            });
        }
    };

    const updateTagsSearchQueryWithDebounce = debounce(
        (query) => setEventTagsSearchQuery(query),
        500
    );

    const teamsOptions = useMemo(() => {
        return (
            teamsData?.data.data.map((team) => ({
                label: team.name,
                value: team.id,
            })) || []
        );
    }, [teamsData]);

    const eventCategoriesOptions = useMemo(() => {
        return (eventCategoriesResponse?.data.data || []).map((c) => ({
            label: c.name,
            value: c.id,
        }));
    }, [eventCategoriesResponse]);

    const {
        data: bookingOptionsData,
        isLoading: isFetchingBookingOptions,
        isError: isFetchBookingOptionsError,
    } = useFetchEventBookingOptions(eventId || '');
    const hasMoreThanOneBookingOption =
        bookingOptionsData?.data?.data && bookingOptionsData.data.data.length > 1;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2 className={classNames(classes.noMarginBottom, classes.noMarginTop)}>
                        Event Details
                    </h2>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Event Type*</FormLabel>
                    <Controller
                        name="eventType"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    data-cy="event-type"
                                    onChange={(option) => {
                                        onChange(option);

                                        if (!option?.value) return;

                                        resetOtherCategoryFields(option.value);
                                    }}
                                    value={value}
                                    options={eventTypeOptions}
                                    disabled={edit}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>

                {eventType?.value === EVENT_TYPE.Concert && (
                    <Grid item xs={12} data-cy="artists">
                        <FormLabel>Artists</FormLabel>
                        <Controller
                            name="artists"
                            control={control}
                            render={({field: {name, onChange, value}}) => (
                                <PerformerSelectDataWrapper
                                    type={PERFORMERS_TYPE.Artist}
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    onSuccessfullyCreate={(options) =>
                                        setValue('artists', options, {
                                            shouldValidate: true,
                                        })
                                    }
                                    disabled={edit}
                                />
                            )}
                        />
                    </Grid>
                )}

                {eventType?.value === EVENT_TYPE.Sports && (
                    <Grid item xs={12} data-cy="contestants">
                        <FormLabel>Contestants</FormLabel>
                        <Controller
                            name="contestants"
                            control={control}
                            render={({field: {name, onChange, value}}) => (
                                <PerformerSelectDataWrapper
                                    type={PERFORMERS_TYPE.Contestant}
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    onSuccessfullyCreate={(options) =>
                                        setValue('contestants', options, {
                                            shouldValidate: true,
                                        })
                                    }
                                    disabled={edit}
                                />
                            )}
                        />
                    </Grid>
                )}

                {eventType?.value === EVENT_TYPE.Tennis && (
                    <>
                        <Grid
                            item
                            xs={12}
                            className={classNames(classes.spacingBottom)}
                            data-cy="matches"
                        >
                            <FormLabel>Matches</FormLabel>
                            {!edit && (
                                <Chip
                                    color="primary"
                                    label="+ Add match"
                                    rounded
                                    onClick={() =>
                                        append({id: generateRandomId('match'), name: ''})
                                    }
                                />
                            )}
                            {matches?.map((match) => (
                                <Chip
                                    color="primary"
                                    label={match.name}
                                    rounded
                                    className={classes.chip}
                                />
                            ))}
                        </Grid>
                        {fields.map((field, index) => (
                            <Grid
                                item
                                xs={4}
                                key={`matches.${index}.${field.id}`}
                                data-cy={`matches.${index}.${field.id}`}
                                className={classNames(classes.spacingBottom, classes.flex)}
                            >
                                <Grid item xs={10}>
                                    <Controller
                                        name={`matches.${index}.name`}
                                        control={control}
                                        render={({field: {name, onChange, value}}) => (
                                            <Input
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                placeholder="Match name"
                                                grey
                                                disabled={edit}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classNames(classes.flex, !edit && classes.pointer)}
                                >
                                    <Delete
                                        onClick={() => {
                                            if (edit) return;
                                            remove(index);
                                        }}
                                        color={edit ? 'disabled' : 'primary'}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </>
                )}

                {eventType?.value === EVENT_TYPE.TeamSports && (
                    <>
                        <Grid item xs={5}>
                            <FormLabel>Home*</FormLabel>
                            <Controller
                                name="homeTeam"
                                control={control}
                                render={({
                                             field: {name, onChange, value},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <AutoComplete
                                            options={teamsOptions}
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                            onCreateOption={onCreateHomeTeam}
                                            data-cy="home-team"
                                        />
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.versusContainer}>
                            VS
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Away*</FormLabel>
                            <Controller
                                name="awayTeam"
                                control={control}
                                render={({
                                             field: {name, onChange, value},
                                             fieldState: {error},
                                         }) => (
                                    <>
                                        <AutoComplete
                                            options={teamsOptions}
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                            onCreateOption={onCreateAwayTeam}
                                            data-cy="away-team"
                                        />
                                        <FormFieldError message={error?.message}/>
                                    </>
                                )}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={6}>
                    <FormLabel>Start Date*</FormLabel>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <>
                                <DateTimePicker
                                    value={value ? moment.utc(value).format(dateTimeFormat) : ''}
                                    onChange={(e) => onChange(moment.utc(e.target.value))}
                                    minDate={moment.utc().format(dateTimeFormat)}
                                    data-cy="start-date"
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel>End Date</FormLabel>
                    <Controller
                        name="endDate"
                        control={control}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <>
                                <DateTimePicker
                                    disabled={!startDate}
                                    value={value ? moment(value).format(dateTimeFormat) : ''}
                                    onChange={(e) => onChange(moment.utc(e.target.value))}
                                    minDate={moment.utc(startDate).format(dateTimeFormat)}
                                    data-cy="end-date"
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} data-cy="date-confirmed">
                    <Controller
                        name="dateConfirmed"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name={name}
                                            checked={value || false}
                                            onChange={onChange}
                                        />
                                    }
                                    label="This date is confirmed"
                                    className={classes.activeLabel}
                                />
                            </FormGroup>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} data-cy="active">
                    <Controller
                        name="active"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name={name}
                                            checked={value || false}
                                            onChange={onChange}
                                            disabled={edit}
                                        />
                                    }
                                    label="Active"
                                    className={classes.activeLabel}
                                />
                                {edit && (
                                    <FormHelperText>
                                        If you have the correct rights, you can change the event
                                        activation at the bottom of this popover
                                    </FormHelperText>
                                )}
                            </FormGroup>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Event Name*</FormLabel>
                    <Controller
                        name="eventName"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Event name"
                                    disabled={edit}
                                    data-cy="event-name"
                                />
                                {edit && (
                                    <FormHelperText>
                                        If you have the correct rights, you can change the event
                                        name at the bottom of this popover
                                    </FormHelperText>
                                )}
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} data-cy="series">
                    <FormLabel>Series*</FormLabel>
                    <Controller
                        name="series"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <SeriesSelectDataWrapper
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onCreateOption={onCreateSeries}
                                    disabled={edit}
                                />
                                {edit && (
                                    <FormHelperText>
                                        If you have the correct rights, you can change the event
                                        series at the bottom of this popover.
                                    </FormHelperText>
                                )}
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} data-cy="organizers">
                    <FormLabel>Organizer*</FormLabel>
                    <Controller
                        name="organizer"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <OrganizersSelectDataWrapper
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onSuccessfullyCreate={(option) =>
                                        setValue('organizer', option, {shouldValidate: true})
                                    }
                                    disabled={edit}
                                />

                                <FormFieldError message={error?.message}/>

                                {edit && (
                                    <FormHelperText>
                                        If you have the correct rights, you can change the event
                                        organizer at the bottom of this popover.
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} data-cy="venue">
                    <FormLabel>Venue*</FormLabel>
                    <Controller
                        name="venue"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <VenueSelectDataWrapper
                                    name={name}
                                    value={value}
                                    onChange={(option) => {
                                        onChange(option);
                                        setValue('seatingPlan', undefined, {
                                            shouldValidate: true,
                                        });
                                    }}
                                    onCreateOption={onCreateVenue}
                                    disabled={edit}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} data-cy="seating-plan">
                    <FormLabel>Seating Plan*</FormLabel>
                    <Controller
                        name="seatingPlan"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <SeatingPlanSelectDataWrapper
                                    venueId={venue?.value}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onSuccessfullyCreate={(option) =>
                                        setValue('seatingPlan', option, {shouldValidate: true})
                                    }
                                    disabled={edit}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Event Category*</FormLabel>
                    <Controller
                        name="eventCategory"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <AutoComplete
                                    onChange={onChange}
                                    name={name}
                                    value={value}
                                    disabled={edit}
                                    loading={isEventCategoriesLoading}
                                    options={eventCategoriesOptions}
                                    data-cy="event-category"
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Event Tags</FormLabel>
                    <FetchTagsDataWrapper type={TAG_TYPES.EventTag} q={eventTagsSearchQuery}>
                        {({data}) => (
                            <Controller
                                name="eventTags"
                                control={control}
                                render={({field: {name, onChange, value}}) => (
                                    <AutoComplete
                                        options={
                                            data?.data.map((tag) => ({
                                                label: tag.name,
                                                value: tag.id,
                                            })) || []
                                        }
                                        onInputChange={(query) => {
                                            updateTagsSearchQueryWithDebounce(query);
                                        }}
                                        onChange={onChange}
                                        value={value}
                                        name={name}
                                        isMulti
                                        onCreateOption={(value) =>
                                            onCreateTag(TAG_TYPES.EventTag, value)
                                        }
                                        data-cy="event-tags"
                                    />
                                )}
                            />
                        )}
                    </FetchTagsDataWrapper>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Description</FormLabel>
                    <Controller
                        name="description"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <Input
                                multiline
                                minRows={3}
                                maxRows={3}
                                name={name}
                                value={value || ''}
                                onChange={onChange}
                                placeholder="Description"
                                data-cy="description"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel>Tickets sent earliest (days)</FormLabel>
                    <Controller
                        name="ticketsSentEarliestDays"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={`${defaultTicketsSentEarliestDays} (default)`}
                                    type="number"
                                    data-cy="tickets-sent-earliest"
                                />
                                <FormHelperText>
                                    If you leave this empty it will default to 3 days
                                </FormHelperText>
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel>Tickets sent latest (days)</FormLabel>
                    <Controller
                        name="ticketsSentLatestDays"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={`${defaultTicketsSentLatestDays} (default)`}
                                    type="number"
                                    data-cy="tickets-sent-latest"
                                />
                                <FormHelperText>
                                    If you leave this empty it will default to 1 day
                                </FormHelperText>
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
            </Grid>
            <Divider/>
            <Controller
                name="hasMultipleBookingOptions"
                control={control}
                render={({field: {name, onChange, value}}) => (
                    <Box marginBottom={1}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        name={name}
                                        checked={value || false}
                                        onChange={onChange}
                                        disabled={
                                            hasMoreThanOneBookingOption ||
                                            isFetchBookingOptionsError
                                        }
                                    />
                                }
                                label="Has multiple booking options"
                                className={classes.activeLabel}
                            />
                            {hasMoreThanOneBookingOption &&
                                !isFetchingBookingOptions &&
                                !isFetchBookingOptionsError && (
                                    <Alert severity="info">
                                        There are more than 1 booking options present, therefore,
                                        this field cannot be unchecked.
                                    </Alert>
                                )}
                        </FormGroup>
                    </Box>
                )}
            />
            {hasMultipleBookingOptions &&
                eventDetails &&
                eventDetails?.hasMultipleBookingOptions === false && (
                    <Box marginBottom={2}>
                        <Alert severity="info">
                            Save the updates before you can create multiple booking options
                        </Alert>
                    </Box>
                )}
            <EventBookingOptions
                eventId={eventId}
                canCreateBookingOptions={
                    eventDetails?.hasMultipleBookingOptions && hasMultipleBookingOptions
                }
            />
            <Divider/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3 className={classNames(classes.noMarginBottom, classes.noMarginTop)}>
                        Restrictions
                    </h3>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Blacklisted countries</FormLabel>
                    <Controller
                        name="blacklistedCountries"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <AutoComplete
                                options={countryList.map((c) => ({
                                    value: c.value,
                                    label: c.label,
                                }))}
                                onChange={onChange}
                                name={name}
                                value={value}
                                isMulti
                                data-cy="blacklisted-countries"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Blacklisted nationalities</FormLabel>
                    <Controller
                        name="blacklistedNationalities"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <AutoComplete
                                options={countryList.map((c) => ({
                                    value: c.value,
                                    label: c.label,
                                }))}
                                onChange={onChange}
                                name={name}
                                value={value}
                                isMulti
                                data-cy="blacklisted-nationalities"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} data-cy="extra-information">
                    <Controller
                        name="allowAwayFans"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name={name}
                                            checked={value || false}
                                            onChange={onChange}
                                        />
                                    }
                                    label="Away fans allowed"
                                    className={classes.activeLabel}
                                />
                            </FormGroup>
                        )}
                    />
                    <Controller
                        name="requireFullCustomerAddress"
                        control={control}
                        render={({field: {name, onChange, value}}) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name={name}
                                            checked={value || false}
                                            onChange={onChange}
                                        />
                                    }
                                    label="Require full customer address"
                                    className={classes.activeLabel}
                                />
                            </FormGroup>
                        )}
                    />
                </Grid>
            </Grid>

            <FormButtons
                buttons={[
                    {
                        children: edit ? 'Update event' : 'Create event',
                        startIcon: actionLoading && (
                            <CircularProgress size={theme.layout.loader.sizes.small}/>
                        ),
                        disabled: isSubmitButtonDisabled,
                        onClick: () => handleSubmit(onFormSubmit)(),
                    },
                ]}
            />
        </>
    );
}
