import {Button as MuiButton, ButtonProps as MuiButtonProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {BUTTON_SIZES} from 'src/shared/theme/enums';

export interface ButtonProps extends Omit<MuiButtonProps, 'size'> {
    key?: string;
    danger?: boolean;
    white?: boolean;
    size?: BUTTON_SIZES;
    tinyBorderRadius?: boolean;
    target?: '_self' | '_blank' | '_parent' | '_top';
    dataCy?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    button: (props: Partial<ButtonProps>) => {
        let padding = theme.layout.button[BUTTON_SIZES.SMALL].padding;
        let fontSize = theme.layout.button[BUTTON_SIZES.SMALL].fontSize;
        let color;
        let background;
        let backgroundHover;
        let border;

        if (props.size === BUTTON_SIZES.TINY) {
            padding = theme.layout.button[BUTTON_SIZES.TINY].padding;
            fontSize = theme.layout.button[BUTTON_SIZES.TINY].fontSize;
        }

        if (props.size === BUTTON_SIZES.MEDIUM) {
            padding = theme.layout.button[BUTTON_SIZES.MEDIUM].padding;
            fontSize = theme.layout.button[BUTTON_SIZES.MEDIUM].fontSize;
        }

        if (props.size === BUTTON_SIZES.LARGE) {
            padding = theme.layout.button[BUTTON_SIZES.LARGE].padding;
            fontSize = theme.layout.button[BUTTON_SIZES.LARGE].fontSize;
        }

        if (props.white) {
            color = theme.palette.primary.main;
            background = theme.colors.white || 'white';
            backgroundHover = theme.colors.lightGrey;
            border = 'none';
        }

        if (props.danger) {
            if (props.variant === 'text') {
                color = theme.palette.error.main;
                background = 'transparent';
            } else {
                background = theme.palette.error.main;
                color = theme.colors.white || 'white';
                backgroundHover = theme.palette.error.dark;
            }
        }

        return {
            padding,
            fontSize,
            border,
            background,
            color,
            'font-weight': theme.typography.fontWeightBold,
            borderRadius: props.tinyBorderRadius ? '' : theme.layout.borderRadius.large,
            boxShadow: 'none',
            textTransform: 'none',
            '&:hover': {
                boxShadow: 'none',
                background: backgroundHover,
                border,
            },
        };
    },
}));

const Button = ({
                    size = BUTTON_SIZES.SMALL,
                    color = 'primary',
                    disableRipple = true,
                    variant = 'contained',
                    tinyBorderRadius,
                    danger,
                    white,
                    className,
                    dataCy,
                    ...props
                }: ButtonProps): JSX.Element => {
    const classes = useStyles({
        size,
        tinyBorderRadius,
        color,
        variant,
        danger,
        white,
    });
    const {children} = props;

    return (
        <MuiButton
            variant={variant}
            color={color}
            disableRipple={disableRipple}
            {...props}
            data-cy={dataCy}
            className={classNames(className, classes.button)}
        >
            {children}
        </MuiButton>
    );
};

export default Button;
