import {useMutation} from '@tanstack/react-query';
import purchaseService, {UpdatePurchasePriceDto} from 'src/data/services/purchaseService';

type TData = Awaited<ReturnType<typeof purchaseService.updatePurchasePrice>>;

type TVariables = {
    id: string;
    dto: UpdatePurchasePriceDto;
};

export const useUpdatePurchasePrice = (
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown
) => {
    return useMutation({
        mutationFn: ({id, dto}: TVariables) => purchaseService.updatePurchasePrice(id, dto),
        onSuccess,
    });
};
