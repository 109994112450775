import {useEffect, useState} from 'react';

/** Add a delay to propagate the given value.
 * Note: it would be rather expensive to process an object or an array.
 * Use with caution!
 */
export function useDebounce<T>(value: T, delay = 125) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
        // Deep compare for object or array.
    }, [typeof value === 'object' && value !== null ? JSON.stringify(value) : value, delay]);
    return debouncedValue;
}
