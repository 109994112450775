import ArticleIcon from '@mui/icons-material/Article';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {MenuItem} from 'src/app/interfaces/menu';
import BasicAdministrationUrlService from 'src/app/pages/basic-administration/basicAdministrationUrlService';
import eventsOverviewUrlService from 'src/app/pages/events/eventsOverviewUrlService';
import OrdersUrlService from 'src/app/pages/orders/ordersUrlService';
import purchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import ticketManagementUrlService from 'src/app/pages/ticket-management/ticketManagementUrlService';
import UrlService from 'src/app/services/urlService';
import UserPermissions from 'src/app/utilities/helpers/userPermissions';
import {eticketManagementRole} from 'src/app/utilities/helpers/userRole';
import {ETBAAS_ROLES, Roles} from 'src/data/enums/roles';

export const menuTabs: MenuItem[] = [
    {
        label: 'Events',
        url: eventsOverviewUrlService.root(),
        Icon: HomeIcon,
        permissions: [...ETBAAS_ROLES, Roles.EMPLOYEE],
        subItems: [],
    },
    {
        label: 'Purchases',
        url: purchaseUrlService.root(),
        Icon: ShoppingBasketIcon,
        permissions: [...ETBAAS_ROLES, Roles.EMPLOYEE],
        subItems: [],
    },
    {
        label: 'Orders',
        url: OrdersUrlService.root(),
        Icon: ShoppingCartIcon,
        permissions: [...ETBAAS_ROLES, Roles.EMPLOYEE],
        subItems: [],
    },
    {
        label: 'Basic Administration',
        url: UrlService.getBasicAdministration(),
        Icon: ArticleIcon,
        permissions: [...ETBAAS_ROLES, Roles.EMPLOYEE],
        isClickable: false,
        subItems: [
            {
                label: 'Series Groups',
                url: BasicAdministrationUrlService.seriesGroups(),
            },
            {
                label: 'Series',
                url: BasicAdministrationUrlService.series(),
            },
            {
                label: 'Suppliers',
                url: BasicAdministrationUrlService.suppliers(),
            },
            UserPermissions.canUpdateEventCategory()
                ? {
                    label: 'Event Categories',
                    url: BasicAdministrationUrlService.eventCategories(),
                }
                : null,
            {
                label: 'Teams',
                url: BasicAdministrationUrlService.teams(),
            },
            {
                label: 'Venues',
                url: BasicAdministrationUrlService.venues(),
            },
            {
                label: 'Organizers',
                url: BasicAdministrationUrlService.organizers(),
            },
            {
                label: 'Order comparison tool',
                url: BasicAdministrationUrlService.orderComparisonTool(),
            },
            {
                label: 'Seating plan category properties',
                url: BasicAdministrationUrlService.seatingPlanCategoryProperties(),
            },
        ],
    },
    {
        label: 'E-ticket Management',
        url: ticketManagementUrlService.root(),
        Icon: ConfirmationNumberIcon,
        permissions: eticketManagementRole,
        subItems: [],
    },
];
