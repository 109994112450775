import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import {useQueryClient} from '@tanstack/react-query';
import {useConfirm} from 'material-ui-confirm';
import {type Dispatch, type SetStateAction} from 'react';
import {FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY} from 'src/app/hooks/seating-plan-categories/useFetchSPCsBySeatingplanId';
import {Divider} from 'src/view/components/divider/Divider';
import {FormActionButtons} from 'src/view/components/form-action-buttons/FormActionButtons';
import Modal, {ModalBody} from 'src/view/components/modal/Modal';
import {useArchiveSeatingplanCategoryDataWrapper} from '../../data-wrappers/seating-plan-cateogires/ArchiveSeatingplanCategoryDataWrapper';
import CreateSeatingplanCategoryFormFeature from '../seating-plan-categories/CreateSeatingplanCategoryFormFeature';

export function CreateEditSeatingPlanCategoryModal({
                                                       seatingPlanCategory,
                                                       seatingplanId,
                                                       setModalOpenState,
                                                   }: {
    seatingPlanCategory?: {
        id: string;
        name: string;
        isArchived: boolean;
    };
    seatingplanId: string;
    setModalOpenState: Dispatch<SetStateAction<boolean>>;
}) {
    const confirm = useConfirm();

    const queryClient = useQueryClient();

    /** TODO: Create separate hooks for archive & unarchive, handle queryInvalidation there. */
    const {archiveSpc, unArchiveSpc, errors} = useArchiveSeatingplanCategoryDataWrapper({
        seatingplanCategoryId: seatingPlanCategory?.id,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY],
            });
        },
    });

    const isSPCArchived = seatingPlanCategory?.isArchived;

    return (
        <Modal
            title={
                seatingPlanCategory ? `Update Seatingplan category` : 'Create Seatingplan category'
            }
            subTitle={seatingPlanCategory?.name}
            open
            onClose={() => setModalOpenState(false)}
            width="small"
        >
            <ModalBody>
                <CreateSeatingplanCategoryFormFeature
                    seatingplanId={seatingplanId}
                    seatingplanCategoryId={seatingPlanCategory?.id}
                    onCreateOrUpdateSuccess={() => {
                        setModalOpenState(false);
                    }}
                />

                <Divider/>

                <FormActionButtons
                    buttons={[
                        {
                            children: isSPCArchived ? 'Un-archive' : 'Archive',
                            onClick: async () => {
                                try {
                                    await confirm({
                                        description: isSPCArchived
                                            ? 'Are you sure you want to un-archive this seating plan category?'
                                            : 'Are you sure you want to archive this seating plan category?',
                                    });

                                    if (isSPCArchived) {
                                        unArchiveSpc();
                                    } else {
                                        archiveSpc();
                                    }
                                    setModalOpenState(false);
                                } catch (error) {
                                    /* User Cancelled */
                                }
                            },
                            text: isSPCArchived
                                ? 'When you un-archive a seating plan category, it will become available to use for ticket sales caps'
                                : 'Warning: when you archive a seating plan category, all tickets sales caps will be permanently deleted',
                            color: isSPCArchived ? 'primary' : 'error',
                            disabled: false,
                            startIcon: false && <CircularProgress size={1}/>,
                            error: `Could not ${
                                isSPCArchived ? 'un-archive' : 'archive'
                            } this seating plan category`,
                            errors,
                        },
                    ]}
                />
            </ModalBody>
        </Modal>
    );
}
