import EventIcon from '@mui/icons-material/Event';
import {Grid} from '@mui/material';
import _ from 'lodash';
import moment, {Moment} from 'moment/moment';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import {useFetchOrderById} from 'src/app/hooks/orders/useFetchOrderById';
import {orderStatusOptions} from 'src/app/utilities/helpers/filter-options/order';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import {orderSourceOptions} from 'src/app/utilities/mappers/mapSourceToOptions';
import FilterOption from 'src/data/api/common/FilterOption';
import {shippingStatusStrings} from 'src/data/constants/order';
import {ASSIGNMENT_STATUS, PAYMENT_STATUS, READY_STATUS, SHIPPING_STATUS,} from 'src/data/enums/order';
import {dateFormatYearMonthDay} from 'src/shared/date';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import {FilterAutoCompleteOptions} from 'src/view/components/filters/AutoComplete/AutoComplete';
import {FilteredOnNotification} from 'src/view/components/filters/FilteredOnNotification/FilteredOnNotification';

export interface EventOrdersFiltersFormValues {
    orderId?: string;
    shippingStatus?: FilterAutoCompleteOptions;
    source?: FilterAutoCompleteOptions;
    assignmentStatus?: FilterAutoCompleteOptions;
    paymentStatus?: FilterAutoCompleteOptions;
    searchTerm?: string;
    startDate?: Date;
    endDate?: Date;
    status?: FilterAutoCompleteOptions;
    ticketsReady?: FilterAutoCompleteOptions;
    readyStatus?: FilterAutoCompleteOptions;
    category?: FilterAutoCompleteOptions;
    bookingOptionIds?: FilterAutoCompleteOptions;
}

export const shippingStatusOptions: FilterAutoCompleteOptions = [
    {
        label: shippingStatusStrings[SHIPPING_STATUS.NotSent],
        value: SHIPPING_STATUS.NotSent,
    },
    {
        label: shippingStatusStrings[SHIPPING_STATUS.PartiallySent],
        value: SHIPPING_STATUS.PartiallySent,
    },
    {
        label: shippingStatusStrings[SHIPPING_STATUS.Sent],
        value: SHIPPING_STATUS.Sent,
    },
    {
        label: shippingStatusStrings[SHIPPING_STATUS.Unknown],
        value: SHIPPING_STATUS.Unknown,
    },
];

export const assignmentStatusOptions: FilterAutoCompleteOptions = [
    {
        label: 'Assigned',
        value: ASSIGNMENT_STATUS.ASSIGNED,
    },
    {
        label: 'Not assigned',
        value: ASSIGNMENT_STATUS.NOT_ASSIGNED,
    },
    {
        label: 'Partially assigned',
        value: ASSIGNMENT_STATUS.PARTIALLY_ASSIGNED,
    },
];

export const paymentStatusOptions: FilterAutoCompleteOptions = [
    {
        label: 'Approved',
        value: PAYMENT_STATUS.Approved,
    },
    {
        label: 'Partial',
        value: PAYMENT_STATUS.Partial,
    },
    {
        label: 'Missing',
        value: PAYMENT_STATUS.Missing,
    },
];

export const readyStatuses: FilterAutoCompleteOptions = [
    {
        label: 'Ready',
        value: READY_STATUS.READY,
    },
    {
        label: 'Partially Ready',
        value: READY_STATUS.PARTIALLY_READY,
    },
    {
        label: 'Not Ready',
        value: READY_STATUS.NOT_READY,
    },
];

interface Props {
    loading?: boolean;
    defaultValues?: EventOrdersFiltersFormValues;
    initialOptions: FilterOption[];
    initialSearchTerm?: string;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
    isEventOverview?: boolean;
    categoryOptions: FilterAutoCompleteOptions;
    bookingOptions: FilterAutoCompleteOptions;
}

export default function EventOrdersFilterForm({
                                                  loading,
                                                  defaultValues,
                                                  initialOptions,
                                                  initialSearchTerm,
                                                  onChangeFilterOptions,
                                                  onChangeSearchTerm,
                                                  isEventOverview,
                                                  categoryOptions,
                                                  bookingOptions,
                                              }: Props): JSX.Element {
    const spacingClasses = useSpacingStyles();
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const form = useForm<EventOrdersFiltersFormValues>({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const {watch, control, setValue, reset} = form;

    const category = watch('category');
    const shippingStatus = watch('shippingStatus');
    const source = watch('source');
    const assignmentStatus = watch('assignmentStatus');
    const ticketsReady = watch('ticketsReady');
    const paymentStatus = watch('paymentStatus');
    const orderId = watch('orderId');
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const status = watch('status');
    const readyStatus = watch('readyStatus');
    const bookingOptionIds = watch('bookingOptionIds');

    useEffect(() => {
        const findFormOptionsInUrl = (
            name: string,
            options: FilterAutoCompleteOptions
        ): FilterAutoCompleteOptions | undefined => {
            return tableFilterFormHelper.getInitialAutocompleteValues(
                name,
                initialOptions,
                options
            );
        };

        const formDefaultValues: EventOrdersFiltersFormValues = {
            category: findFormOptionsInUrl('seatingPlanCategoryId', categoryOptions),
            bookingOptionIds: findFormOptionsInUrl('bookingOptionIds', bookingOptions),
            shippingStatus: findFormOptionsInUrl('shippingStatus', shippingStatusOptions),
            source: findFormOptionsInUrl('source', orderSourceOptions),
            assignmentStatus: findFormOptionsInUrl('assignmentStatus', assignmentStatusOptions),
            ticketsReady: findFormOptionsInUrl('ticketsReady', readyStatuses),
            paymentStatus: findFormOptionsInUrl('paymentStatus', paymentStatusOptions),
            readyStatus: findFormOptionsInUrl('readyStatus', readyStatuses),
            orderId: initialOptions.find((o) => o.property === 'orderId')?.value,
            searchTerm: initialSearchTerm,
            status: findFormOptionsInUrl('status', orderStatusOptions),
        };

        reset(formDefaultValues);
    }, [initialOptions, initialSearchTerm, categoryOptions]);

    useEffect(() => {
        if (!onChangeFilterOptions || !urlInvalidatedAt) return;

        const ticketsFilter = _.compact([
            formatValuesToParams.formatOptionsToParam('seatingPlanCategoryId', category),
            formatValuesToParams.formatOptionsToParam('bookingOptionIds', bookingOptionIds),
            formatValuesToParams.formatOptionsToParam('shippingStatus', shippingStatus),
            formatValuesToParams.formatOptionsToParam('source', source),
            formatValuesToParams.formatOptionsToParam('assignmentStatus', assignmentStatus),
            formatValuesToParams.formatOptionsToParam('ticketsReady', ticketsReady),
            formatValuesToParams.formatOptionsToParam('paymentStatus', paymentStatus),
            formatValuesToParams.formatOptionsToParam('status', status),
            formatValuesToParams.formatOptionsToParam('readyStatus', readyStatus),
            formatValuesToParams.formatInputToParam('orderId', orderId),
            startDate
                ? formatValuesToParams.formatDateToParam(
                    'orderedAt',
                    moment(startDate).format(dateFormatYearMonthDay),
                    'afterDate'
                )
                : undefined,
            endDate
                ? formatValuesToParams.formatDateToParam(
                    'orderedAt',
                    moment(endDate).format(dateFormatYearMonthDay),
                    'beforeDate'
                )
                : undefined,
        ]).filter((f) => f.value !== undefined);

        onChangeFilterOptions(ticketsFilter);
    }, [urlInvalidatedAt]);

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });
        onChangeSearchTerm?.(value);
    };

    const datePickerOnChange = (value: Moment | null, field: 'startDate' | 'endDate'): void => {
        if (value === null) {
            setValue(field, undefined, {
                shouldValidate: true,
            });
            return;
        }

        setValue(field, value.toDate(), {
            shouldValidate: true,
        });
    };

    const arrayOfFilters = [
        {
            type: 'autocomplete',
            options: shippingStatusOptions,
            name: 'shippingStatus',
            filterPlaceholderProps: {
                selectedText: 'Sending Status',
                placeholder: 'Sending Status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            disabled: loading,
            isMulti: true,
        },
        isEventOverview
            ? {
                type: 'autocomplete',
                options: categoryOptions,
                name: 'category',
                filterPlaceholderProps: {
                    selectedText: 'Category',
                    placeholder: 'Category',
                },
                onChange: () => setUrlInvalidatedAt(Date.now()),
                isMulti: true,
            }
            : null,
        isEventOverview
            ? {
                type: 'autocomplete',
                options: bookingOptions,
                name: 'bookingOptionIds',
                filterPlaceholderProps: {
                    placeholder: 'Booking Option(s)',
                    selectedText: 'Booking Option(s)',
                },
                onChange: () => setUrlInvalidatedAt(Date.now()),
                isMulti: true,
            }
            : null,
        {
            type: 'autocomplete',
            options: orderSourceOptions,
            name: 'source',
            filterPlaceholderProps: {
                placeholder: 'Source',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        {
            name: 'startDate',
            onChange: (date: Moment | null) => {
                datePickerOnChange(date, 'startDate');
                if (moment(date).isValid() || date === null) setUrlInvalidatedAt(Date.now());
            },
            type: 'datepicker',
            filterPlaceholderProps: {
                startIcon: <EventIcon/>,
                placeholder: 'From',
                keepPlaceholder: true,
            },
        },
        {
            name: 'endDate',
            onChange: (date: Moment | null) => {
                datePickerOnChange(date, 'endDate');
                if (moment(date).isValid() || date === null) setUrlInvalidatedAt(Date.now());
            },
            type: 'datepicker',
            filterPlaceholderProps: {
                startIcon: <EventIcon/>,
                placeholder: 'To',
                keepPlaceholder: true,
            },
        },
        {
            type: 'autocomplete',
            options: paymentStatusOptions,
            name: 'paymentStatus',
            filterPlaceholderProps: {
                selectedText: 'Payment Status',
                placeholder: 'Payment Status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        {
            type: 'autocomplete',
            options: orderStatusOptions,
            name: 'status',
            filterPlaceholderProps: {
                selectedText: 'Status',
                placeholder: 'Status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        isEventOverview
            ? {
                type: 'autocomplete',
                options: assignmentStatusOptions,
                name: 'assignmentStatus',
                filterPlaceholderProps: {
                    selectedText: 'Assignment Status',
                    placeholder: 'Assignment Status',
                },
                onChange: () => setUrlInvalidatedAt(Date.now()),
                isMulti: true,
            }
            : null,
        isEventOverview
            ? {
                type: 'autocomplete',
                options: readyStatuses,
                name: 'ticketsReady',
                filterPlaceholderProps: {
                    selectedText: 'Ready Status',
                    placeholder: 'Ready Status',
                },
                onChange: () => setUrlInvalidatedAt(Date.now()),
                control,
                isMulti: true,
            }
            : null,
        {
            name: 'searchTerm',
            onChange: onInputChange,
            type: 'search',
            searchPlaceholder: 'Search by External Order ID, Order Number, or Contact Email',
            searchDefaultValue: initialSearchTerm,
        },
    ];

    const filteredFilters: FiltersArray = arrayOfFilters.filter(
        (value) => value !== null
    ) as FiltersArray;

    const {data: orderData, isLoading: isFetchingOrder} = useFetchOrderById(orderId || '', {
        enabled: !!orderId,
    });

    return (
        <Grid container>
            <Filters control={control} filters={filteredFilters}/>
            {orderData && !isFetchingOrder && (
                <Grid item xs={12} className={spacingClasses.spacingBottom}>
                    <FilteredOnNotification
                        label="order number"
                        value={orderData?.data.data.orderNumber.toUpperCase() || ''}
                        onClickCancel={() => {
                            setValue('orderId', undefined);
                            setUrlInvalidatedAt(Date.now());
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}
