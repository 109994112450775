import {getInternalAxiosInstance} from 'src/data/api/api';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {
    SEATING_PLAN_CREATE_SEATING_PLAN_CATEGORY_ENDPOINT,
    SEATING_PLAN_DELETE_ENDPOINT,
    SEATING_PLAN_GET_BY_ID_ENDPOINT,
    SEATING_PLAN_GET_CATEGORIES_ENDPOINT,
    SEATING_PLAN_UPDATE_ENDPOINT,
    SEATING_PLAN_UPDATE_SEATING_PLAN_CATEGORIES_ORDER_ENDPOINT,
    SEATING_PLAN_UPDATE_SEATING_PLAN_CATEGORY_ENDPOINT,
} from 'src/data/constants/endpoints/seating-plan-endpoints';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import {SeatingPlanCategory} from 'src/data/models/seating-plan-category/SeatingPlanCategory';
import SeatingPlan from 'src/data/models/seating-plan/SeatingPlan';

export interface CreateSeatingplanCategoryDto {
    name: string;
    ticketTypes?: string[];
    tagIds?: string[];
    properties?: {
        propertyId: string;
    }[];
}

async function createSeatingPlanCategory(
    seatingPlanId: string,
    dto: CreateSeatingplanCategoryDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(
            SEATING_PLAN_CREATE_SEATING_PLAN_CATEGORY_ENDPOINT(seatingPlanId),
            dto
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateSeatingplanCategory(
    seatingPlanId: string,
    seatingplanCategoryId: string,
    dto: CreateSeatingplanCategoryDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(
            SEATING_PLAN_UPDATE_SEATING_PLAN_CATEGORY_ENDPOINT(
                seatingPlanId,
                seatingplanCategoryId
            ),
            dto
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function getSeatingplanCategoriesForSeatingPlan(
    seatingPlanId: string
): Promise<ApiResponse<ApiResponseBody<SeatingPlanCategory[]>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<SeatingPlanCategory[]>>(
            SEATING_PLAN_GET_CATEGORIES_ENDPOINT(seatingPlanId)
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getSeatingplanById(
    seatingplanId: string
): Promise<ApiResponse<ApiResponseBody<SeatingPlan>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<SeatingPlan>>(SEATING_PLAN_GET_BY_ID_ENDPOINT(seatingplanId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

interface UpdateSeatingplanDto {
    name: string;
}

async function updateSeatingPlan(
    seatingplanId: string,
    dto: UpdateSeatingplanDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SEATING_PLAN_UPDATE_ENDPOINT(seatingplanId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface UpdateSpcOrderDto {
    seatingPlanCategoriesSortOrders: {
        seatingPlanCategoryId: string;
        sortOrder: number;
    }[];
}

async function updateSpcOrder(
    seatingplanId: string,
    dto: UpdateSpcOrderDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(
            SEATING_PLAN_UPDATE_SEATING_PLAN_CATEGORIES_ORDER_ENDPOINT(seatingplanId),
            dto
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function deleteSeatingplan(seatingplanId: string) {
    return await getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        SEATING_PLAN_DELETE_ENDPOINT(seatingplanId)
    );
}

export default {
    createSeatingPlanCategory,
    getSeatingplanCategoriesForSeatingPlan,
    updateSeatingplanCategory,
    getSeatingplanById,
    updateSeatingPlan,
    updateSpcOrder,
    deleteSeatingplan,
};
