import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, FormLabel, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import TicketAlreadyLinkedError from 'src/app/components/ticket-already-linked/TicketAlreadyLinkedError';
import isTicketAlreadyLinkedError from 'src/data/api/type-guards/isTicketAlreadyLinkedError';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Modal from 'src/view/components/modal/Modal';
import {Preview} from 'src/view/components/preview/Preview';
import PreviewItem from 'src/view/components/preview/PreviewItem';
import {PreviewItemPlaceholder} from 'src/view/components/preview/PreviewItemPlaceholder';
import {PreviewLabel} from 'src/view/components/preview/PreviewLabel';
import z from 'zod';
import {FileData, UnprocessedTicketFileUploadFeature} from './UnprocessedTicketFileUploadFeature';

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
        marginLeft: theme.spacing(1),
        marginBottom: 0,
    },
    preview: {
        maxHeight: 425,
    },
    previewLoading: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0, 2),
    },
    failedCount: {
        color: theme.palette.error.light,
    },
}));

interface UploadUnprocessedTicketFilesFeatureModalProps {
    onClose: () => void;
    eventId: string;
}

interface UploadUnprocessedTicketFilesFeatureModalFormValues {
    uploadedFileIds: FileData[];
}

const UploadUnprocessedTicketFilesFeatureModalFormValidationScheme = z.object({
    uploadedFileIds: z.array(z.any()).min(1, 'Please upload a file'),
});

const UploadUnprocessedTicketFilesFeatureModal = ({
                                                      onClose,
                                                      eventId,
                                                  }: UploadUnprocessedTicketFilesFeatureModalProps) => {
    const classes = useStyles();
    const [fileUploadisLoading, setFileUploadisLoading] = useState(false);

    const {control, formState} = useForm<UploadUnprocessedTicketFilesFeatureModalFormValues>({
        mode: 'onChange',
        resolver: zodResolver(UploadUnprocessedTicketFilesFeatureModalFormValidationScheme),
        defaultValues: {
            uploadedFileIds: [],
        },
    });

    const {fields, append} = useFieldArray({
        control,
        name: 'uploadedFileIds',
    });

    const failedUploadsCount = fields.filter((field) => !field.fileId).length;

    return (
        <Modal open={true} title="Upload ticket files" width="fluid" onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UnprocessedTicketFileUploadFeature
                                eventId={eventId}
                                onFileUploaded={(fileData) => append(fileData)}
                                onLoadingStateChanged={(state) => setFileUploadisLoading(state)}
                            />

                            {formState.errors.uploadedFileIds && (
                                <FormFieldError
                                    message={formState.errors.uploadedFileIds?.message}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormLabel>
                                Uploaded files{' '}
                                {failedUploadsCount > 0 && (
                                    <span className={classes.failedCount}>
                                        ({failedUploadsCount} failed)
                                    </span>
                                )}
                            </FormLabel>
                            <Preview className={classes.preview}>
                                <>
                                    {fields.length === 0 && !fileUploadisLoading && (
                                        <PreviewItemPlaceholder text="No files uploaded"/>
                                    )}

                                    {fields.map((field, index) => {
                                        const uploadError = field.error;
                                        const isAlreadyLinked =
                                            uploadError && isTicketAlreadyLinkedError(uploadError);

                                        const ticketLinkedTo = isAlreadyLinked
                                            ? uploadError.eventTicketFiles[0] // Is always single item.
                                            : null;

                                        /*
                                            If ticket file is already linked to another ticket,
                                            we will display <TicketAlreadyLinkedError /> as a component,
                                            therefore no need for an error string, although the API call failed.

                                            If the call failed, and the error is different than already linked,
                                            we simply display the title of the error
                                         */
                                        const previewItemError = !isAlreadyLinked &&
                                            (!!uploadError?.title || !!uploadError?.detail) && (
                                                <>
                                                    {!!uploadError?.title && (
                                                        <div>{uploadError?.title}</div>
                                                    )}
                                                    {!!uploadError?.detail && (
                                                        <div>{uploadError?.detail}</div>
                                                    )}
                                                </>
                                            );

                                        return (
                                            <PreviewItem
                                                key={field.id}
                                                id={field.id}
                                                index={index}
                                                type={!field.fileId ? 'error' : null}
                                                error={previewItemError}
                                            >
                                                <PreviewLabel label={field.name}/>
                                                {ticketLinkedTo && (
                                                    <TicketAlreadyLinkedError
                                                        ticketError={ticketLinkedTo}
                                                    />
                                                )}
                                            </PreviewItem>
                                        );
                                    })}

                                    {fileUploadisLoading && (
                                        <div className={classes.previewLoading}>
                                            <CircularProgress size={20}/>
                                        </div>
                                    )}
                                </>
                            </Preview>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default UploadUnprocessedTicketFilesFeatureModal;
