import {useMutation} from '@tanstack/react-query';
import eventService from 'src/data/services/eventService';

type TData = Awaited<ReturnType<typeof eventService.unlockEvent>>;
type TVariables = { eventId: string };

export const useUnlockEvent = (options: {
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown;
    onError?: ((error: unknown, variables: TVariables, context: unknown) => unknown);
}) => {
    return useMutation({
        mutationFn: (dto: TVariables) => eventService.unlockEvent(dto.eventId),
        ...options,
    });
};
