import {ActionMeta} from 'react-select';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import organizerService from 'src/data/services/organizerService';
import AsyncAutoComplete from 'src/view/components/auto-complete/AsyncAutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    name: string;
    value?: AutoCompleteOption;
    onChange: (value: AutoCompleteValueOption, action?: ActionMeta<AutoCompleteOption>) => void;
    onSuccessfullyCreate: (option: AutoCompleteOption) => void;
    disabled?: boolean;
}

export default function EventOrganizersSelectDataWrapper({
                                                             name,
                                                             value,
                                                             onChange,
                                                             onSuccessfullyCreate,
                                                             disabled,
                                                         }: Props) {
    const fetchOrganizersOptions = async (query: string): Promise<AutoCompleteOptions> => {
        if (disabled) return [];

        try {
            const res = await organizerService.getOrganizers({q: query});

            if (!res.data.data) return [];

            return res.data.data.map((o) => ({
                label: o.name,
                value: o.id,
            }));
        } catch (e) {
            toastApiErrors(e);

            return [];
        }
    };

    const onCreateOrganizer = async (name: string) => {
        try {
            const result = await organizerService.createOrganizer(name);

            const id = result.data.data.id;
            const createdOrganizer = {label: name, value: id};

            onSuccessfullyCreate(createdOrganizer);
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AsyncAutoComplete
            name={name}
            value={value}
            loadOptions={fetchOrganizersOptions}
            onChange={onChange}
            placeholder="Select or create"
            onCreateOption={onCreateOrganizer}
            disabled={disabled}
        />
    );
}
