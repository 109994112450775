import {useMutation, useQuery} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import SeriesDetailsForm, {
    SeriesDetailsFormValues,
} from 'src/app/components/forms/SerieDetailsForm';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import mapSerieDataToViewModel from 'src/app/utilities/mappers/mapSerieDataToViewModel';
import UpdateSeriesDto from 'src/data/dtos/UpdateSeriesDto';
import seriesService from 'src/data/services/seriesService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

export interface SeriesDetailsUpdateFeatureProps {
    seriesId: string;
    onDeleteClick: () => void;
    onUpdateSucceed: () => void;
}

interface UpdateSeriesById {
    seriesId: string;
    dto: UpdateSeriesDto;
}

export default function SeriesDetailsUpdateFeature({
                                                       seriesId,
                                                       onDeleteClick,
                                                       onUpdateSucceed,
                                                   }: SeriesDetailsUpdateFeatureProps) {
    const [defaultValues, setDefaultValues] = useState<SeriesDetailsFormValues | undefined>();

    const {mutate: updateSeriesById, isLoading: isUpdatingSeries} = useMutation({
        mutationFn: async ({seriesId, dto}: UpdateSeriesById) => {
            return seriesService.updateSeriesById(seriesId, dto);
        },
        onSuccess: () => {
            Toaster.toast(`Successfully updated the series`, {
                variant: 'success',
            });
            onUpdateSucceed();
        },
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err));
        },
    });

    const {
        isLoading: isFetchingSeries,
        isError: isFetchingSeriesError,
        data: seriesData,
        error: fetchSeriesErrors,
    } = useQuery({
        queryKey: ['series', seriesId],
        queryFn: async () => seriesService.fetchSeriesById(seriesId),
        enabled: !!seriesId,
    });

    const onFormSubmit = (values: SeriesDetailsFormValues) => {
        const dto: UpdateSeriesDto = {
            active: values.active.value,
            name: values.name,
            seriesGroupId: values.seriesGroup?.value,
        };

        updateSeriesById({
            seriesId,
            dto,
        });
    };

    useEffect(() => {
        if (!seriesData) return;

        setDefaultValues(mapSerieDataToViewModel(seriesData.data.data));
    }, [seriesData]);

    const isLoading = seriesData === undefined || isFetchingSeries;

    if (isFetchingSeriesError) {
        return <ErrorsList errors={parseErrors(fetchSeriesErrors)}/>;
    }

    return (
        <SeriesDetailsForm
            seriesGroupId={seriesData?.data.data.seriesGroupId}
            disableDelete={
                seriesData?.data.data.eventsCount ? seriesData?.data.data.eventsCount > 0 : false
            }
            dataLoading={isLoading}
            actionLoading={isUpdatingSeries}
            defaultValues={defaultValues}
            onSubmit={onFormSubmit}
            onDeleteSeries={onDeleteClick}
            disableEdit={false}
        />
    );
}
