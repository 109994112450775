import React from 'react';
import Button, {ButtonProps} from 'src/view/components/button/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import {ErrorViewModel} from 'src/view/view-models/ErrorViewModel';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
    },
    text: {
        color: theme.colors.grey,
    },
}));

interface FormActionButtonTextProps {
    text: string;
    error?: string;
    errors?: ErrorViewModel[];
    hidden?: boolean;
}

type ButtonType = ButtonProps & FormActionButtonTextProps;

export interface FormActionButtonsProps {
    buttons: ButtonType[];
}

export const FormActionButtons = ({buttons}: FormActionButtonsProps) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            {buttons.map((b, index) => {
                if (b.hidden) return <></>;

                return (
                    <React.Fragment key={`${b.text}${index}`}>
                        <Grid item xs={4}>
                            <Button {...b} className={classes.button}/>
                        </Grid>
                        <Grid item xs={8} alignItems="center" display="flex">
                            <div className={classes.text}>{b.text}</div>
                        </Grid>
                        {b.errors && b.errors.length > 0 && (
                            <Grid item xs={12}>
                                <ErrorsList errorTitle={b.error} errors={b.errors}/>
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};
