import {BUTTON_SIZES, LOADER_SIZES, SIZES} from 'src/shared/theme/enums';

const baseTheme = {
    colors: {
        lightestGrey: '#fbfbfb',
        lightGrey: '#fbfbfb',
        mediumGrey: '#f5f4f4',
        grey: '#b5b5b5',
        darkGrey: '#828597',
        bluishGrey: '#e3e8ed',
        white: '#fff',
        yellow: '#ebe423',
        lightestRed: '#f7e6e6',
        lightBlue: '#f2f6fa',
        blue: '#3477ba',
        blue_dark_20: '#074f7c',
        darkBlue: '#516A82',
        linkBlue: '#006898',
        inputDisabledBackground: '#f5f4f4',
        inputDisabledColor: '#808080',
        paleOrange: '#ffefe3',
        cyanBlue: '#2FB5D3',
    },
    layout: {
        borderRadius: {
            small: 3,
            regular: 5,
            large: 30,
            rounded: '50%',
        },
        boxShadow: '0px 1px 30px 0px rgb(0 0 0 / 20%)',
        boxShadowBlue: '0px 1px 30px 0px rgb(29 54 112 / 40%)',
        boxShadowSmall: '0px 1px 1px 0px rgba(186,186,186,0.5)',
        boxShadowSlight: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        button: {
            [BUTTON_SIZES.TINY]: {
                padding: '.2rem .9rem',
                fontSize: '.8rem',
            },
            [BUTTON_SIZES.SMALL]: {
                padding: '.45rem .9rem',
                fontSize: '.8rem',
            },
            [BUTTON_SIZES.MEDIUM]: {
                padding: '.6rem 1.2rem',
                fontSize: '.9rem',
            },
            [BUTTON_SIZES.LARGE]: {
                padding: '.75rem 1.5rem',
                fontSize: '1rem',
            },
        },
        iconButton: {
            [BUTTON_SIZES.SMALL]: {
                padding: '.3rem',
            },
            [BUTTON_SIZES.MEDIUM]: {
                padding: '.7rem',
            },
            [BUTTON_SIZES.LARGE]: {
                padding: '1.2rem',
            },
        },
        icon: {
            [SIZES.TINY]: {
                height: '.6rem',
            },
            [SIZES.SMALL]: {
                height: '.9rem',
            },
            [SIZES.MEDIUM]: {
                height: '1.1rem',
            },
            [SIZES.LARGE]: {
                height: '1.7rem',
            },
        },
        loader: {
            sizes: {
                tiny: LOADER_SIZES.TINY,
                small: LOADER_SIZES.SMALL,
                medium: LOADER_SIZES.MEDIUM,
                large: LOADER_SIZES.LARGE,
            },
        },
    },
    palette: {
        info: {
            main: '#114338',
            contrastText: '#fff',
        },
    },
    typography: {
        subtitle2: {
            fontWeight: 'bolder',
            fontSize: '.8rem',
            color: '#707070',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: 'white',
                    '&:hover fieldset': {
                        border: `1px solid #828597`,
                    },
                    '&.Mui-focused fieldset': {
                        border: `1px solid #828597`,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid #0b342b`,
                    },
                    '& > .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid #828597`,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    padding: 16,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    top: '-7px',
                },
            },
        },
    },
};

export default baseTheme;
