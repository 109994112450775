import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import {SeatingPlanCategoryProperty} from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import seatingPlanCategoryPropertyService from 'src/data/services/seatingPlanCategoryPropertyService';

interface FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    createSeatingplanCategoryProperty: (
        categoryProperty: SeatingPlanCategoryProperty
    ) => Promise<ApiResponseBody<IdResponse>>;
}

type CreateSeatingPlanCategoryPropertyDataWrapperProps = DataWrapperProps<
    FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
>;

export const useCreateSeatingPlanCategoryPropertyDataWrapper = ({
                                                                    onError,
                                                                    onSuccess,
                                                                }: Omit<CreateSeatingPlanCategoryPropertyDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{data, loading, errors, resetData}, handleCreateSeatingplanCategoryProperties] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const createSeatingplanCategoryProperty = (categoryProperty: SeatingPlanCategoryProperty) => {
        categoryProperty.key = categoryProperty.key.trim().toUpperCase().replace(/\s+/g, '_');
        return handleCreateSeatingplanCategoryProperties(
            seatingPlanCategoryPropertyService.createProperty(categoryProperty),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    return {
        createSeatingplanCategoryProperty,
        data,
        loading,
        errors,
        resetData,
    };
};
