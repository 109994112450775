import {useQuery} from '@tanstack/react-query';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {getEventCategories} from 'src/data/services/eventCategoriesService';

export const EVENT_CATEGORIES_QUERY_KEY = 'EVENT_CATEGORIES';

export const useEventCategories = (fetchOptions?: BaseFetchOptions) => {
    return useQuery({
        queryKey: [EVENT_CATEGORIES_QUERY_KEY, fetchOptions],
        queryFn: () => getEventCategories(fetchOptions),
    });
};
