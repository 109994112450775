import {useMutation, useQueryClient} from '@tanstack/react-query';
import venuesService, {SeatingPlanStatus} from 'src/data/services/venuesService';
import Toaster from '../utilities/helpers/Toaster';
import {FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY} from './seating-plans/useFetchSeatingPlansByVenueId';

type TData = Awaited<ReturnType<typeof venuesService.createSeatingPlan>>;
type TVariables = {
    venueId: string;
    name: string;
    status?: SeatingPlanStatus | undefined;
};

export const useCreateSeatingPlan = (onSuccess?: (data: TData) => unknown) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({venueId, name, status = SeatingPlanStatus.active}: TVariables) =>
            venuesService.createSeatingPlan(venueId, {name, status}),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY],
            });

            Toaster.toast(`Seatingplan successfully created`, {
                variant: 'success',
            });

            onSuccess?.(data);
        },
    });
};
