import {useMutation, useQueryClient} from '@tanstack/react-query';
import seatingPlanService from 'src/data/services/seatingPlanService';
import Toaster from '../utilities/helpers/Toaster';
import {FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY} from './seating-plans/useFetchSeatingPlansByVenueId';

type TData = Awaited<ReturnType<typeof seatingPlanService.updateSeatingPlan>>;
type TVariables = {
    seatingPlanId: string;
    name: string;
};

export const useUpdateSeatingPlan = (onSuccess?: (data: TData) => unknown) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({seatingPlanId, name}: TVariables) =>
            seatingPlanService.updateSeatingPlan(seatingPlanId, {name}),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY],
            });

            Toaster.toast(`Seatingplan successfully updated`, {
                variant: 'success',
            });

            onSuccess?.(data);
        },
    });
};
