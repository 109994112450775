import {ORDER_SOURCE_SYSTEM} from 'src/data/enums/order';
import {FilterAutoCompleteOptions} from 'src/view/components/filters/AutoComplete/AutoComplete';

export const sourceSystemOptions: FilterAutoCompleteOptions = [
    {label: 'Travelware', value: ORDER_SOURCE_SYSTEM.Travelware},
    {label: 'Moddix', value: ORDER_SOURCE_SYSTEM.Moddix},
    {label: 'Viagogo', value: ORDER_SOURCE_SYSTEM.Viagogo},
    {label: 'Phone', value: ORDER_SOURCE_SYSTEM.Phone},
    {label: 'LiveTickets', value: ORDER_SOURCE_SYSTEM.LiveTickets},
    {label: 'Unspecified', value: ORDER_SOURCE_SYSTEM.Unspecified},
];
