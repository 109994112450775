import {OverridableComponent} from '@mui/material/OverridableComponent';
import {SvgIconTypeMap} from '@mui/material/SvgIcon';
import {Theme} from '@mui/material/styles';
import {CSSProperties} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {SIZES} from 'src/shared/theme/enums';
import {ChildlessBaseComponent} from 'src/view/interfaces/BaseComponent';

export interface IconComponentProps extends ChildlessBaseComponent {
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    Icon:
        | OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    size?: SIZES;
    grey?: boolean;
    contrast?: boolean;
    success?: boolean;
    error?: boolean;
    warning?: boolean;
    color?: CSSProperties['color'];
    isButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: (props: IconComponentProps) => {
        let height = theme.layout.icon[SIZES.MEDIUM].height;
        let fill = theme.colors.grey;
        let stroke = theme.colors.grey;

        switch (props.size) {
            case SIZES.TINY:
                height = theme.layout.icon[SIZES.TINY].height;
                break;
            case SIZES.SMALL:
                height = theme.layout.icon[SIZES.SMALL].height;
                break;
            case SIZES.LARGE:
                height = theme.layout.icon[SIZES.LARGE].height;
                break;
        }

        if (props.color) {
            fill = props.color;
            stroke = props.color;
        }

        if (props.success) {
            fill = theme.palette.success.main;
            stroke = theme.palette.success.main;
        }

        if (props.warning) {
            fill = theme.palette.warning.main;
            stroke = theme.palette.warning.main;
        }

        if (props.error) {
            fill = theme.palette.error.main;
            stroke = theme.palette.error.main;
        }

        if (props.contrast) {
            fill = theme.palette.primary.contrastText;
            stroke = theme.palette.primary.contrastText;
        }

        return {
            height,
            '& path, svg circle': {
                stroke,
                fill,
            },
            '&:hover': {
                opacity: props.isButton ? 0.8 : 1,
                '& path, svg circle': {
                    opacity: props.isButton ? 0.8 : 1,
                },
            },
            cursor: props.isButton ? 'pointer' : 'default',
        };
    },
}));

const IconComponent = ({
                           className,
                           size,
                           Icon,
                           onClick,
                           contrast,
                           success,
                           warning,
                           error,
                           color,
                           isButton,
                       }: IconComponentProps): JSX.Element => {
    const classes = useStyles({size, Icon, success, error, contrast, color, warning, isButton});

    return <Icon onClick={onClick} className={`${classes.icon} ${className || ''}`}/>;
};

export default IconComponent;
