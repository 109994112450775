import {Grid} from '@mui/material';
import {OrderComparisonToolFeature} from 'src/app/components/features/order-comparison-tool/OrderComparisonToolFeature';
import {ETBContainer} from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';

const OrderComparisonTool = () => {
    return (
        <>
            <Header>
                <Grid item>
                    <h1>Order comparison tool</h1>
                </Grid>
            </Header>
            <ETBContainer sx={{marginTop: '1rem', marginBottom: '1rem'}}>
                <OrderComparisonToolFeature/>
            </ETBContainer>
        </>
    );
};

export default OrderComparisonTool;
