import {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {PurchasesFilterFormValues} from 'src/app/components/forms/PurchasesFilterForm';
import {purchaseFinalizedStatusOptions, purchaseStatusOptions,} from 'src/app/utilities/helpers/filter-options/purchase';
import {findDates} from 'src/app/utilities/helpers/find-date-filters';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import {paymentMethodOptions} from 'src/app/utilities/mappers/mapPaymentMethodsToOptions';
import FilterOption from 'src/data/api/common/FilterOption';
import {FilterAutoCompleteOptions} from 'src/view/components/filters/AutoComplete/AutoComplete';
import useQueryParams from '../useQueryParams';
import {useFetchPurchasesEvents} from './useFetchPurchaseFilters';

export function usePurchaseFilters() {
    const {data: purchaseEventsFilterOptions} = useFetchPurchasesEvents({});
    const [searchParams] = useSearchParams();

    const {
        values: {customFields, filterOptions},
        setCustomFields,
        setFilterOptions,
    } = useQueryParams('purchases');

    const eventsFiltersOptions = useMemo(() => {
        return (purchaseEventsFilterOptions?.data.data || []).map((event) => ({
            value: event.eventId,
            label: event.eventName,
        }));
    }, [purchaseEventsFilterOptions]);

    const selectedEventIds = useMemo(
        () =>
            tableFilterFormHelper.getInitialAutocompleteValues(
                'eventIds',
                filterOptions,
                eventsFiltersOptions
            ),
        [eventsFiltersOptions, filterOptions]
    );

    const defaultValues = getDefaultValues(filterOptions, selectedEventIds, searchParams);

    return {
        defaultValues,
        customFields,
        filterOptions,
        setCustomFields,
        setFilterOptions,
        eventsFiltersOptions,
    };
}

function getDefaultValues(
    filterOptions: FilterOption[],
    selectedEventIds: FilterAutoCompleteOptions | undefined,
    searchParams: URLSearchParams
): PurchasesFilterFormValues {
    const [startDate, endDate] = findDates(filterOptions);

    return {
        paymentMethod: tableFilterFormHelper.getInitialAutocompleteValues(
            'paymentMethodType',
            filterOptions,
            paymentMethodOptions
        )?.[0],
        status: tableFilterFormHelper.getInitialAutocompleteValues(
            'status',
            filterOptions,
            purchaseStatusOptions
        ),
        isFinalized: tableFilterFormHelper.getInitialAutocompleteValues(
            'isFinalized',
            filterOptions,
            purchaseFinalizedStatusOptions
        )?.[0],
        startDate,
        endDate,
        eventIds: selectedEventIds,
        creditCardDigits: searchParams.get('filter_purchases[creditCardDigits]') || undefined,
    };
}
