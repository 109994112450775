import {TableCell as MUITableCell, TableCellProps as MUITableCellProps} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

export interface TableCellProps extends MUITableCellProps {
    alignCenter?: boolean;
    alignItemsCenter?: boolean;
    justifyContentCenter?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        border: 'none',
    },
    center: {
        textAlign: 'center',
    },
    alignItemsCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    justifyContentCenter: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const TableCell = ({
                       children,
                       alignCenter,
                       justifyContentCenter,
                       alignItemsCenter,
                       ...props
                   }: TableCellProps): JSX.Element => {
    const classes = useStyles();

    return (
        <MUITableCell
            {...props}
            className={`
                ${classNames({
                [classes.root]: true,
                [classes.center]: alignCenter,
                [classes.alignItemsCenter]: alignItemsCenter,
                [classes.justifyContentCenter]: justifyContentCenter,
            })}
                ${props.className ? props.className : ''}
            `}
        >
            {children}
        </MUITableCell>
    );
};

export default TableCell;
