import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import eventService from 'src/data/services/eventService';
import {EmptyBody} from 'src/data/models/common/emptyBody';

interface UpdateEventNameDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    updateEventName: (name: string) => Promise<void | ErrorReturn>;
}

interface UpdateEventNameDataWrapperProps
    extends DataWrapperProps<UpdateEventNameDataWrapperChildrenParams> {
    eventId: string;
}

export const useUpdateEventNameDataWrapper = ({
                                                  eventId,
                                                  onError,
                                                  onSuccess,
                                              }: Omit<UpdateEventNameDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{loading, errors, resetData, data}, handleUpdateName] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const updateEventNameCall = async (name: string) => {
        await handleUpdateName(eventService.updateEventName(eventId, name), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });

        return;
    };

    return {
        updateEventName: updateEventNameCall,
        data,
        loading,
        errors,
        resetData,
    };
};
