import {useQuery} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {getVenues} from 'src/data/services/venuesService';

export const FETCH_VENUES_QUERY_KEY = 'FETCH_VENUES_QUERY_KEY';

export const useFetchVenues = (options?: BaseFetchOptions) => {
    return useQuery({
        queryKey: [FETCH_VENUES_QUERY_KEY, options],
        queryFn: () => getVenues(options),
        onError: (error) => {
            Toaster.toastErrors(parseErrors(error));
        },
    });
};
