import {useCallback, useState} from 'react';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import SeriesGroup from 'src/data/models/series-group/SeriesGroup';
import seriesGroupService from 'src/data/services/seriesGroupService';
import AsyncAutoComplete, {
    AsyncAutoCompleteProps,
} from 'src/view/components/auto-complete/AsyncAutoComplete';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import FormFieldError from 'src/view/components/form/FormFieldError';

interface Props extends Omit<AsyncAutoCompleteProps, 'loadOptions'> {
    initialValueId?: string;
    onError?: () => void;
    onCreateSucceed?: (option: AutoCompleteOption) => void;
}

const mapSeriesGroupsoptions = (data?: PaginatedApiResponseBody<SeriesGroup>) => {
    if (!data) return [];

    return data.data.map((c) => ({
        label: c.name,
        value: c.id,
    }));
};

export const SeriesGroupsAsyncAutoCompleteDataWrapper = (props: Props) => {
    const [initialized, setInitialized] = useState(false);
    const [error, setError] = useState<string>('');
    const {onCreateSucceed, onError, value} = props;

    const fetchSeriesGroups = useCallback(
        async (inputValue: string) => {
            setError('');

            try {
                const result = await seriesGroupService.fetchSeriesGroups({
                    q: inputValue,
                });

                const {initialValueId} = props;

                if (initialValueId && !initialized) {
                    const initialValue = result.data.data.find((s) => s.id === initialValueId);

                    if (initialValue) {
                        props.onChange({
                            value: initialValueId,
                            label: initialValue?.name,
                        });
                    }
                }

                setInitialized(true);
                return mapSeriesGroupsoptions(result.data);
            } catch {
                setError('something went wrong loading the seatingplan categories');
                onError?.();

                return [];
            }
        },
        [props.onChange, onError]
    );

    const onCreateSeriesGroup = async (name: string) => {
        try {
            const result = await seriesGroupService.createSeriesGroup({
                name,
            });

            const createdOption = {label: name, value: result.data.data.id};

            props.onChange(createdOption);
            onCreateSucceed?.(createdOption);
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <>
            <AsyncAutoComplete
                {...props}
                value={value}
                loadOptions={fetchSeriesGroups}
                onCreateOption={onCreateSeriesGroup}
            />
            {error && <FormFieldError message={error}/>}
        </>
    );
};
