import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import FormButtons from 'src/view/components/form/FormButtons';
import {Controller, useForm} from 'react-hook-form';
import {CircularProgress, Grid, Tooltip, Box} from '@mui/material';
import {FormLabel} from 'src/view/components/form/FormLabel';
import {FormProps} from 'src/app/interfaces/form/formProps';
import {useTheme} from '@mui/styles';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import Button from 'src/view/components/button/Button';
import {useCallback} from 'react';
import {useConfirm} from 'material-ui-confirm';

interface SupplierDetailsFormProps {
    editMode?: boolean;
    canBeArchived: boolean;
    canBeDeleted: boolean;
    archiveLoading: boolean;
    deleteLoading: boolean;
    isArchived: boolean;
    onSupplierActionConfirmation: (action: DisableAction) => void;
}

export interface SupplierDetailsFormValues {
    name: string;
}

export const supplierDetailValidationSchema = Yup.object({
    name: Yup.string().required('Enter a name'),
});

export type DisableAction = 'delete' | 'archive' | 'unarchive';

type ConfirmOptions = {
    title?: string;
    description?: string;
};

export default function SupplierDetailsForm({
                                                editMode,
                                                loading,
                                                archiveLoading,
                                                deleteLoading,
                                                disableSubmit,
                                                onSubmit,
                                                defaultValues,
                                                canBeArchived,
                                                canBeDeleted,
                                                isArchived,
                                                onSupplierActionConfirmation,
                                            }: SupplierDetailsFormProps & FormProps<SupplierDetailsFormValues>) {
    const theme = useTheme();
    const confirm = useConfirm();
    const {
        control,
        handleSubmit,
        formState: {isDirty},
    } = useForm<SupplierDetailsFormValues>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(supplierDetailValidationSchema),
    });

    const actionsLoading = archiveLoading || deleteLoading;
    const isSubmitButtonDisabled = disableSubmit || loading || !isDirty || actionsLoading;

    function getConfirmOptions(action: DisableAction): ConfirmOptions {
        switch (action) {
            case 'archive':
                return {title: 'Are you sure you want to archive the Supplier?'};
            case 'unarchive':
                return {title: 'Are you sure you want to unarchive the Supplier?'};
            case 'delete':
                return {
                    title: 'Are you sure you want to delete the Supplier?',
                    description: 'This action cannot be undone.',
                };
            default:
                return {};
        }
    }

    const triggerSupplierAction = useCallback(
        async (action: DisableAction) => {
            try {
                await confirm(getConfirmOptions(action));

                onSupplierActionConfirmation(action);
            } catch (error) {
                /* Empty */
            }
        },
        [onSupplierActionConfirmation]
    );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <FormLabel>Name*</FormLabel>
                        <Controller
                            name="name"
                            control={control}
                            render={({
                                         field: {name, value, onChange},
                                         fieldState: {error},
                                     }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Name"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormButtons justifyContent={editMode ? 'space-between' : 'flex-end'}>
                        {editMode && (
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <Tooltip
                                    title="Supplier is being actively used"
                                    disableFocusListener={canBeArchived}
                                    disableHoverListener={canBeArchived}
                                    disableTouchListener={canBeArchived}
                                >
                                    <Box>
                                        {isArchived ? (
                                            <Button
                                                disabled={!canBeArchived || actionsLoading}
                                                onClick={() => triggerSupplierAction('unarchive')}
                                                startIcon={
                                                    archiveLoading && (
                                                        <CircularProgress
                                                            size={theme.layout.loader.sizes.small}
                                                        />
                                                    )
                                                }
                                            >
                                                <Box
                                                    component="span"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    Unarchive
                                                </Box>
                                            </Button>
                                        ) : (
                                            <Button
                                                disabled={!canBeArchived || actionsLoading}
                                                onClick={() => triggerSupplierAction('archive')}
                                                startIcon={
                                                    archiveLoading && (
                                                        <CircularProgress
                                                            size={theme.layout.loader.sizes.small}
                                                        />
                                                    )
                                                }
                                            >
                                                <Box
                                                    component="span"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    Archive
                                                    {!canBeArchived && (
                                                        <Box
                                                            component="span"
                                                            display="block"
                                                            ml={0.5}
                                                        >
                                                            (?)
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Button>
                                        )}
                                    </Box>
                                </Tooltip>
                                <Box>
                                    <Tooltip
                                        title="Supplier is being actively used"
                                        disableFocusListener={canBeDeleted}
                                        disableHoverListener={canBeDeleted}
                                        disableTouchListener={canBeDeleted}
                                    >
                                        <Box>
                                            <Button
                                                danger
                                                disabled={!canBeDeleted || actionsLoading}
                                                className="disabled"
                                                onClick={() => triggerSupplierAction('delete')}
                                                startIcon={
                                                    deleteLoading && (
                                                        <CircularProgress
                                                            size={theme.layout.loader.sizes.small}
                                                        />
                                                    )
                                                }
                                            >
                                                <Box
                                                    component="span"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    Delete
                                                    {!canBeDeleted && (
                                                        <Box
                                                            component="span"
                                                            display="block"
                                                            ml={0.5}
                                                        >
                                                            (?)
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        )}

                        <div>
                            <Button
                                disabled={isSubmitButtonDisabled}
                                startIcon={
                                    loading && (
                                        <CircularProgress size={theme.layout.loader.sizes.small}/>
                                    )
                                }
                                onClick={handleSubmit((values) => {
                                    onSubmit(values);
                                })}
                            >
                                {editMode ? 'Update' : 'Create'}
                            </Button>
                        </div>
                    </FormButtons>
                </Grid>
            </Grid>
        </>
    );
}
