import {Grid} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import CreateVenueFormDataWrapper from 'src/app/components/data-wrappers/venues/CreateVenueFormDataWrapper';
import VenuesTableFeature from 'src/app/components/features/venues/VenuesTableFeature';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {FETCH_VENUES_QUERY_KEY} from 'src/app/hooks/venues/useFetchVenues';
import Button from 'src/view/components/button/Button';
import {ETBContainer} from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import Modal from 'src/view/components/modal/Modal';

export default function VenuesOverview() {
    const [createOpen, setCreateOpen] = useState(false);
    const queryClient = useQueryClient();

    const {
        values: {customFields},
        setCustomFields,
    } = useQueryParams('venues');

    const form = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const {control} = form;

    const onSuccessfullyCreateVenue = useCallback(() => {
        setCreateOpen(false);
        queryClient.invalidateQueries({
            queryKey: [FETCH_VENUES_QUERY_KEY],
        });
    }, [queryClient]);

    const onCloseModal = () => {
        setCreateOpen(false);
    };

    const renderVenueCreateModal = () => {
        return (
            <Modal title="Add new venue" open={createOpen} onClose={onCloseModal}>
                <CreateVenueFormDataWrapper onSuccessfullyCreateVenue={onSuccessfullyCreateVenue}/>
            </Modal>
        );
    };

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q) =>
                setCustomFields({
                    ...customFields,
                    q,
                }),
            type: 'search',
            searchPlaceholder: 'Search on Venue name',
            searchDefaultValue: customFields['q'] || '',
            label: 'Search',
        },
    ];

    return (
        <>
            {renderVenueCreateModal()}

            <Header GridProps={{justifyContent: 'flex-end'}}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateOpen(true)}>
                        Add New Venue
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <Filters control={control} filters={arrayOfFilters}/>
                <VenuesTableFeature
                    q={customFields.q || ''}
                    page={Number(customFields['page'] || 1)}
                    onChangePage={(page: number) =>
                        setCustomFields({...customFields, page: page.toString()})
                    }
                />
            </ETBContainer>
        </>
    );
}
