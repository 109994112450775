import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_WIDTH,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import SeriesGroup from 'src/data/models/series-group/SeriesGroup';
import {IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spacingRight: {
        marginRight: theme.spacing(2),
    },
}));

export interface OrdersTableProps {
    eventId?: string;
    seriesGroups: SeriesGroup[];
    loading?: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sortings: TableColumnSorting[]) => void;
    onClickDetails: (id: string) => void;
    onClickDelete: (id: string, name: string) => void;
}

export default function SeriesGroupsOverviewTable({
                                                      seriesGroups,
                                                      loading,
                                                      pagination,
                                                      onSortingChange,
                                                      onClickDetails,
                                                      onClickDelete,
                                                  }: OrdersTableProps): JSX.Element {
    const classes = useStyles();
    const [columns, setColumns] = React.useState<NewTableColumn<SeriesGroup>[]>([]);

    useEffect(() => {
        const cols: NewTableColumn<SeriesGroup>[] = [
            {
                key: 'name',
                title: 'Name',
                width: TABLE_CELL_WIDTH.MEDIUM,
                cellRenderer: (rowData) => rowData.name,
            },
            {
                width: TABLE_CELL_WIDTH.MEDIUM,
                align: 'right',
                cellRenderer: (rowData) => {
                    return (
                        <div className={classes.buttonsContainer}>
                            <IconButton
                                onClick={() => onClickDetails(rowData.id)}
                                color="primary"
                                size="small"
                                className={classes.spacingRight}
                            >
                                <EditIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => onClickDelete(rowData.id, rowData.name)}
                                color="error"
                                size="small"
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                    );
                },
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<SeriesGroup>
            rowIdResolver={(seriesGroup) => ({
                id: seriesGroup.id,
            })}
            data={seriesGroups}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            onSortingChange={onSortingChange}
        />
    );
}
