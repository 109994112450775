import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const EVENT_CATEGORIES_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/event-categories`;

export const EVENT_CATEGORIES_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/event-categories/create`;

export const EVENT_CATEGORIES_ARCHIVE_CATEGORY_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/event-categories/${id}/archive`;

export const EVENT_CATEGORIES_UNARCHIVE_CATEGORY_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/event-categories/${id}/un-archive`;
