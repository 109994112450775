import makeStyles from '@mui/styles/makeStyles';
import {Chip} from '@mui/material';
import {ChevronLeft} from '@mui/icons-material';
import classnames from 'classnames';

const useStyles = makeStyles((t) => ({
    selectorContainer: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    placeholder: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    chevron: {
        transition: 'transform .2s',
    },
    chevronDown: {
        transform: 'rotate(-90deg)',
    },
    chevronUp: {
        transform: 'rotate(90deg)',
    },
    selectedBox: {
        padding: t.spacing(0.2),
        background: 'white',
        fontSize: '.5rem',
        marginLeft: t.spacing(0.5),
        textAlign: 'center',
        minWidth: 12,
        minHeight: 12,
        borderRadius: t.layout.borderRadius.rounded,
    },
    chipContent: {
        display: 'flex',
        alignItems: 'center',
    },
    selectedText: {
        fontSize: '.75rem',
    },
    placeholderMargin: {
        marginRight: t.spacing(1),
    },
    startIcon: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: '.75rem',
            marginTop: 2,
            marginRight: t.spacing(0.5),
        },
    },
    disabled: {
        opacity: 0.5,
        '&:hover': {
            cursor: 'not-allowed',
        },
    },
    chip: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export interface FilterPlaceholderProps {
    value?: string | string[];
    selectedText?: string;
    placeholder?: string;
    onClick: () => void;
    open?: boolean;
    startIcon?: JSX.Element;
    keepPlaceholder?: boolean;
    onDelete?: () => void;
    disabled?: boolean;
    hideDeleteButton?: boolean;
}

export const FilterPlaceholder = ({
                                      value,
                                      selectedText = 'Selected',
                                      placeholder,
                                      onClick,
                                      open,
                                      startIcon,
                                      keepPlaceholder,
                                      onDelete,
                                      hideDeleteButton,
                                      disabled,
                                  }: FilterPlaceholderProps) => {
    const classes = useStyles();
    const isArray = Array.isArray(value);
    const shouldShowPlaceholder = !value || (isArray && value.length === 0) || keepPlaceholder;
    const shouldShowValues = (!isArray && value) || (isArray && value.length > 0);

    return (
        <div
            className={classnames(disabled && classes.disabled, classes.selectorContainer)}
            onClick={onClick}
        >
            {shouldShowPlaceholder && (
                <div
                    className={classnames(
                        classes.placeholder,
                        value && keepPlaceholder && classes.placeholderMargin
                    )}
                >
                    {startIcon && <div className={classes.startIcon}>{startIcon}</div>}
                    {placeholder}
                </div>
            )}

            {shouldShowValues && (
                <>
                    {isArray && value.length > 0 ? (
                        <>
                            <Chip
                                className={classes.chip}
                                size="small"
                                label={
                                    value.length > 0 ? (
                                        <div className={classes.chipContent}>
                                            <span className={classes.selectedText}>
                                                {selectedText}{' '}
                                            </span>
                                            <div className={classes.selectedBox}>
                                                {value.length}
                                            </div>
                                        </div>
                                    ) : (
                                        value[0]
                                    )
                                }
                                onDelete={!hideDeleteButton ? onDelete : undefined}
                            />
                        </>
                    ) : (
                        <Chip
                            className={classes.chip}
                            size="small"
                            label={value}
                            onDelete={!hideDeleteButton ? onDelete : undefined}
                        />
                    )}
                </>
            )}

            <ChevronLeft
                className={classnames(
                    open ? classes.chevronUp : classes.chevronDown,
                    classes.chevron
                )}
            />
        </div>
    );
};
