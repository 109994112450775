import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Button from 'src/view/components/button/Button';
import {BUTTON_SIZES} from 'src/shared/theme/enums';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import classnames from 'classnames';

export interface SplitButtonOption {
    label: string;
    onClick: () => void;
    disabled?: boolean;
}

export interface SplitButtonProps {
    options: SplitButtonOption[];
    disabled?: boolean;
    className?: string;
    size?: BUTTON_SIZES;
}

const useStyles = makeStyles(() => ({
    buttonGroup: {
        boxShadow: 'none',
    },
    splitButton: {
        flexShrink: 0,
    },
}));

export default function SplitButton({options, disabled, className, size}: SplitButtonProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement | null>(null);

    const handleMenuItemClick = (option: SplitButtonOption) => {
        option.onClick();
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const allOptionsDisabled = () => {
        return options.filter((o) => o.disabled).length === options.length;
    };

    return (
        <div className={classNames(classnames(className, classes.splitButton))}>
            <ButtonGroup
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
                className={classes.buttonGroup}
                disableRipple
                disabled={disabled}
            >
                <Button onClick={options[0].onClick} size={size} disabled={options[0].disabled}>
                    {options[0].label}
                </Button>
                <Button
                    color="primary"
                    size={size}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select action"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    disabled={allOptionsDisabled()}
                >
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={false}
                style={{
                    zIndex: 99,
                }}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => {
                                        if (index === 0) return;

                                        return (
                                            <MenuItem
                                                key={option.label}
                                                disabled={option.disabled}
                                                onClick={() => handleMenuItemClick(option)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
