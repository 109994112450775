import {Checkbox as MUICheckbox, CheckboxProps} from '@mui/material';

const Checkbox = (props: CheckboxProps) => {
    return (
        <MUICheckbox
            sx={{
                '&.Mui-disabled [type=checkbox]:checked + svg': {
                    color: (theme) => theme.colors.grey,
                },
                '& [type=checkbox]:checked + svg': {
                    color: (theme) => theme.colors.blue,
                },
                '&:hover ': {
                    backgroundColor: 'transparent',
                    '& [type=checkbox]:checked + svg': {
                        color: (theme) => theme.colors.blue_dark_20,
                    },
                },
                '& .MuiTypography-root': {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                },
            }}
            {...props}
            disableRipple
        />
    );
};

export default Checkbox;
