import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import FilterOption from 'src/data/api/common/FilterOption';
import {FilterAutoCompleteOption, FilterAutoCompleteOptions,} from 'src/view/components/filters/AutoComplete/AutoComplete';
import formatValuesToParams from '../../utilities/helpers/formatValuesToParams';

export interface OrderlineFiltersFormValues {
    category?: FilterAutoCompleteOption;
    searchTerm?: string;
}

interface Props {
    defaultValues: OrderlineFiltersFormValues;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
    initialSearchTerm?: string;
    categoriesOptions: FilterAutoCompleteOptions;
}

export default function SplitFiltersForm({
                                             defaultValues,
                                             onChangeFilterOptions,
                                             onChangeSearchTerm,
                                             initialSearchTerm,
                                             categoriesOptions,
                                         }: Props) {
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();
    const [formIsInitialized, setFormIsInitialized] = useState(false);

    const orderlineFiltersForm = useForm<OrderlineFiltersFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const {control, reset, watch, setValue} = orderlineFiltersForm;
    const category = watch('category');

    useEffect(() => {
        if (!defaultValues || formIsInitialized) return;

        reset(defaultValues);
        setFormIsInitialized(true);
    }, [defaultValues, formIsInitialized, reset]);

    useEffect(() => {
        if (!onChangeFilterOptions || !urlInvalidatedAt) return;

        const ticketsFilter = _.compact([
            formatValuesToParams.formatOptionToParam('seatingPlanCategoryId', category),
        ]).filter((f) => f.value !== undefined);

        onChangeFilterOptions(ticketsFilter);
    }, [category, urlInvalidatedAt]);

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });

        onChangeSearchTerm?.(value);
    };

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: categoriesOptions,
            name: 'category',
            filterPlaceholderProps: {
                placeholder: 'Seating Plan Category',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'search',
            name: 'searchTerm',
            onChange: onInputChange,
            searchPlaceholder: 'Search on external order reference',
            searchDefaultValue: initialSearchTerm,
        },
    ];

    return <Filters control={control} filters={arrayOfFilters}/>;
}
