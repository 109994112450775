import {AutoCompleteOptions} from 'src/view/components/auto-complete/interfaces';
import eventService from 'src/data/services/eventService';
import {Event} from 'src/data/models/events/event';
import {formatEventData} from 'src/app/utilities/helpers/formatEventData';
import FilterOption from 'src/data/api/common/FilterOption';
import {useQuery} from '@tanstack/react-query';
import {useState} from 'react';
import AutoComplete, {AutoCompleteProps} from 'src/view/components/auto-complete/AutoComplete';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

interface EventsAutoCompleteDataWrapperProps {
    customerId?: string;
    filter?: FilterOption[];
}

export const mapEventToAutoCompleteOptions = (events: Event[]): AutoCompleteOptions => {
    return events.map((event) => {
        const {id, name, dateTimeStart, dateTimeEnd} = event;

        return {
            label: formatEventData(name, dateTimeStart, dateTimeEnd),
            value: id,
        };
    });
};

const EventAutoCompleteFeature = (
    props: Omit<AutoCompleteProps, 'options'> & EventsAutoCompleteDataWrapperProps
) => {
    const [searchInputValue, setSearchInputValue] = useState('');

    const {
        isLoading,
        isError,
        data: response,
        error,
    } = useQuery({
        queryKey: ['event_autocomplete', searchInputValue, props.filter],
        queryFn: async () =>
            eventService.fetchEvents({q: searchInputValue, filter: props.filter}),
    });

    return (
        <>
            {isError && <ErrorsList errors={parseErrors(error)}/>}

            <AutoComplete
                {...props}
                onInputChange={(val) => {
                    setSearchInputValue(val);
                }}
                loading={isLoading}
                options={mapEventToAutoCompleteOptions(response?.data.data || [])}
                name={props.name}
            />
        </>
    );
};

export default EventAutoCompleteFeature;
