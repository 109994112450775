import {useQuery} from '@tanstack/react-query';
import seriesGroupService from 'src/data/services/seriesGroupService';
import {config} from '../constants/config/config';
import FilterOption from 'src/data/api/common/FilterOption';

const SERIES_FILTERS_QUERY = 'SERIES_FILTERS'

const useFetchSeriesFilters = (filters: FilterOption[], pageSize = config.ITEMS_PER_PAGE_LARGE) => {
    return useQuery({
        queryKey: [SERIES_FILTERS_QUERY, filters, pageSize],
        queryFn: () =>
            seriesGroupService.fetchSeriesGroups({
                filter: filters,
                pageSize,
            }),
    });
};

export default useFetchSeriesFilters;
