import {useEffect, useState} from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import LegacySeat from 'src/data/models/v1/seat/seat';
import seatService from 'src/data/services/seatService';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    name: string;
    rowId: string;
    eventId: string;
    onChange: (option?: AutoCompleteOption) => void;
    value?: AutoCompleteOption;
    onSuccessfullyCreate?: (option: AutoCompleteOption) => void;
    assignedRowId?: string;
    assignedSeat?: AutoCompleteOption;
    disabled?: boolean;
}

export default function SeatsSelectDataWrapper({
                                                   name,
                                                   eventId,
                                                   rowId,
                                                   onChange,
                                                   value,
                                                   onSuccessfullyCreate,
                                                   assignedRowId,
                                                   assignedSeat,
                                                   disabled,
                                               }: Props) {
    const [option, setOption] = useState<AutoCompleteOption | undefined>(value);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();

    useEffect(() => {
        setOption(value);
    }, [value]);

    const [{data}, handleFetchBlocks] = useApiFetch<ApiResponseBody<LegacySeat[]>>();

    useEffect(() => {
        if (!rowId || disabled) return;

        const filter = [
            {property: 'rowId', value: rowId},
            {property: 'available', value: 'true'},
        ];

        handleFetchBlocks(seatService.getSeatsByEventId(eventId, {filter}), {
            useDefaultErrorHandler: true,
        });
    }, [rowId, invalidatedAt]);

    const seatOptions = (): AutoCompleteOptions => {
        if (!data) return [];

        const seatOptions = data.data.map((seat) => ({
            label: seat.number,
            value: seat.id,
        }));

        const addAssignedSeat = (): AutoCompleteOptions => {
            if (rowId === assignedRowId && assignedSeat) return [assignedSeat];

            return [];
        };

        return [...addAssignedSeat(), ...seatOptions];
    };

    const onCreateSeat = async (value: string) => {
        if (!rowId) return;

        try {
            const response = await seatService.createSeat(value, rowId);
            const createdRow = {label: value, value: response.data.data.id};

            onSuccessfullyCreate?.(createdRow);
            setInvalidatedAt(Date.now());
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AutoComplete
            name={name}
            value={option}
            options={seatOptions()}
            onChange={(value) => {
                if (value === null) value = undefined;
                onChange(value);
            }}
            placeholder={!rowId ? 'Select a row first (disabled)' : 'select or create'}
            disabled={disabled || !rowId}
            onCreateOption={onCreateSeat}
            backgroundColor="white"
        />
    );
}
