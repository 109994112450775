import {useMutation} from '@tanstack/react-query';
import ChangePaymentStatusForm, {
    ChangePaymentStatusFormValues,
} from 'src/app/components/forms/ChangePaymentStatusForm';
import {Price} from 'src/app/components/tables/helpers/OrderlinesTableHelpers';
import {useFetchOrderById} from 'src/app/hooks/orders/useFetchOrderById';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {FINANCE_PAYMENT_TYPES, PAYMENT_STATUS} from 'src/data/enums/order';
import orderService from 'src/data/services/orderService';
import DictionaryGrid, {
    type DictionaryItemProps,
} from 'src/view/components/dictionary-grid/DictionaryGrid';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';

const ChangePaymentStatusFeature = ({
                                        orderId,
                                        onSuccess,
                                    }: {
    orderId: string;
    onSuccess?: () => void;
}) => {
    const {
        data: orderResponse,
        isLoading,
        isError,
        error,
    } = useFetchOrderById(orderId, {
        enabled: !!orderId,
    });

    const {
        mutate,
        isLoading: isUpdateOrderLoading,
        isError: isUpdateOrderError,
        error: updateOrderError,
    } = useMutation({
        mutationFn: (values: ChangePaymentStatusFormValues) =>
            orderService.changePaymentStatus(orderId, {
                paymentStatus: values.status.value as PAYMENT_STATUS,
                reason: values.reason.value as FINANCE_PAYMENT_TYPES,
            }),
        onSuccess,
    });

    const orderData = orderResponse?.data;

    const mapOrderToDictionary = (): DictionaryItemProps[] => {
        if (!orderData?.data) return [];

        const {orderNumber, externalReferenceId, totalPrice} = orderData.data;

        return [
            {label: 'Ordernumber', value: orderNumber},
            {label: 'External order reference', value: externalReferenceId},
            {
                label: 'Selling price',
                renderCustomValue: () =>
                    totalPrice !== null ? <Price price={totalPrice}/> : 'unknown',
            },
        ];
    };

    return (
        <div>
            {(isLoading || isUpdateOrderLoading) && <LoadingOverlay/>}
            {isError && <ErrorsList errors={parseErrors(error)}/>}
            {isUpdateOrderError && <ErrorsList errors={parseErrors(updateOrderError)}/>}
            <ModalSubTitle>Order details</ModalSubTitle>
            <DictionaryGrid data={mapOrderToDictionary()}/>
            {isLoading && <span>loading...</span>}
            <ChangePaymentStatusForm
                onSubmit={(data) => mutate(data)}
                defaultValues={{
                    status: orderData?.data.paymentStatus,
                    reason: orderData?.data.reason,
                }}
            />
        </div>
    );
};

export default ChangePaymentStatusFeature;
