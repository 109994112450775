import {useCallback, useEffect, useMemo, useState} from 'react';
import OrderlinesSuccessfullAssignmentsTable from 'src/app/components/tables/OrderlinesSuccessfullAssignmentsTable';
import {useFetchAutoAssignResultDataWrapper} from 'src/app/components/data-wrappers/orderline/FetchAutoAssignResultDataWrapper';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {useAcceptAutoAssignResultOrderlineDataWrapper} from 'src/app/components/data-wrappers/orderline/AcceptAutoAssignResultOrderlineDataWrapper';
import {useUndoAutoAssignResultOrderlineDataWrapper} from 'src/app/components/data-wrappers/orderline/UndoAutoAssignResultOrderlineDataWrapper';
import {Alert, CircularProgress, Grid} from '@mui/material';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import {useTheme} from '@mui/styles';
import Chip from 'src/view/components/chip/Chip';
import {AssignedOrderline} from 'src/data/models/orderline/assigned-orderline';

interface OrderlinesSuccessfullAssignmentsTableFeatureProps {
    eventId: string;
    invalidatedAt?: number;
}

export const OrderlinesSuccessfullAssignmentsTableFeature = ({
                                                                 eventId,
                                                                 invalidatedAt,
                                                             }: OrderlinesSuccessfullAssignmentsTableFeatureProps) => {
    const theme = useTheme();
    const spacingClasses = useSpacingStyles();
    const [selectedRows, setSelectedRows] = useState<RowIdResolver<AssignedOrderline>[]>([]);

    const [assigningResults, setAssigningResults] = useState<PromiseSettledResult<void>[]>([]);

    const {data, fetchAutoAssignResult} = useFetchAutoAssignResultDataWrapper({});
    const {acceptAutoAssignResultOrderline, loading: acceptIsLoading} =
        useAcceptAutoAssignResultOrderlineDataWrapper({});
    const {undoAutoAssignResultOrderline, loading: undoIsLoading} =
        useUndoAutoAssignResultOrderlineDataWrapper({});

    useEffect(
        () => {
            fetchAutoAssignResult(eventId);
        },
        // TODO: Remove disabling rule below once hook is updated with React-Query
        // // eslint-disable-next-line react-hooks/exhaustive-deps
        [invalidatedAt]
    );

    const getTicketIds = useCallback((orderline: AssignedOrderline) => {
        let ticketIds: string[] = [];

        orderline.splitAssignments.forEach((split) => {
            ticketIds = split.ticketListResult.map((result) => result.ticketId);
        });

        return ticketIds;
    }, []);

    const acceptAssignResultAction = (selectedRows: RowIdResolver<AssignedOrderline>[]) => ({
        label: 'Accept selected',
        disabled: selectedRows.length === 0 || acceptIsLoading,
        startIcon: acceptIsLoading && <CircularProgress size={theme.layout.loader.sizes.small}/>,
        callback: () => {
            setSelectedRows(selectedRows);

            Promise.allSettled(
                selectedRows.map(async (row) => {
                    await acceptAutoAssignResultOrderline(
                        row.data?.orderId || '',
                        row.data?.orderLineId || '',
                        {
                            ticketIds: row.data ? getTicketIds(row.data) : [],
                        }
                    );
                })
            ).then((result) => {
                setAssigningResults(result);
                setSelectedRows([]);
                fetchAutoAssignResult(eventId);
            });
        },
    });

    const undoAssignResultAction = (selectedRows: RowIdResolver<AssignedOrderline>[]) => ({
        label: 'Undo selected',
        disabled: selectedRows.length === 0 || undoIsLoading,
        startIcon: undoIsLoading && <CircularProgress size={theme.layout.loader.sizes.small}/>,
        callback: () => {
            setSelectedRows(selectedRows);
            undoAutoAssignResultOrderline(
                selectedRows.map((row) => (row.data ? row.data.orderLineId : '')),
                {
                    onSuccess: () => {
                        setSelectedRows([]);
                        fetchAutoAssignResult(eventId);
                    },
                }
            );
        },
    });

    const successfulAssigningsCount = useMemo(() => {
        return assigningResults.filter(({status}) => status === 'fulfilled').length;
    }, [assigningResults]);

    const failedAssigningsCount = useMemo(() => {
        return assigningResults.filter(({status}) => status === 'rejected').length;
    }, [assigningResults]);

    return (
        <>
            {!!assigningResults.length && (
                <Grid mb={2}>
                    {successfulAssigningsCount > 0 && (
                        <Alert
                            severity="success"
                            sx={{
                                marginBottom: 1,
                            }}
                        >
                            Successfully assigned <strong>{successfulAssigningsCount}</strong> out
                            of <strong>{assigningResults.length}</strong> from the selected items.
                        </Alert>
                    )}
                    {failedAssigningsCount > 0 && (
                        <Alert severity="error">
                            Failed to assign <strong>{failedAssigningsCount}</strong> out of{' '}
                            <strong>{assigningResults.length}</strong> from the selected items.
                        </Alert>
                    )}
                </Grid>
            )}
            {data?.data.assignedOrderLines && (
                <Grid container spacing={5} className={spacingClasses.spacingBottom}>
                    <Grid item>
                        <div>
                            <strong className={spacingClasses.spacingRight}>
                                Seatingplan category:
                            </strong>
                            <Chip label={data?.data.seatingPlanCategoryName} rounded/>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <strong className={spacingClasses.spacingRight}>Algorithm:</strong>
                            <Chip label={data?.data.algorithmUsed} rounded/>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <strong className={spacingClasses.spacingRight}>Seat guarantee:</strong>
                            <Chip label={data?.data.seatGuarantee} rounded/>
                        </div>
                    </Grid>
                </Grid>
            )}
            {data?.data.categoryContainsLockedTicket && (
                <Alert severity="warning" className={spacingClasses.spacingBottom}>
                    This category contains {data?.data.totalLockedTicketsInCategory} locked
                    ticket(s). They were skipped by the algorithm. Manual action is required to
                    assign them (unlock).
                </Alert>
            )}
            <TableHeader title="Successfully assigned orderlines"/>
            <OrderlinesSuccessfullAssignmentsTable
                successfullAssignedItems={data?.data.assignedOrderLines || []}
                initialSelectedRows={selectedRows}
                tableToolbarActions={(selectedRows) => [
                    acceptAssignResultAction(selectedRows),
                    undoAssignResultAction(selectedRows),
                ]}
                onChangeSelectedRows={() => {
                    if (assigningResults.length) {
                        setAssigningResults([]);
                    }
                }}
            />
        </>
    );
};
