import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, FormHelperText, Grid} from '@mui/material';
import {useTheme} from '@mui/styles';
import classNames from 'classnames';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {config} from 'src/app/constants/config/config';
import {useEventCategories} from 'src/app/hooks/event-categories/useEventCategories';
import {FormProps} from 'src/app/interfaces/form/formProps';
import BasicAdministrationUrlService from 'src/app/pages/basic-administration/basicAdministrationUrlService';
import {eventCategoryUpdateRoles} from 'src/app/utilities/helpers/userPermissions';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import z from 'zod';

interface EditEventCategoryFormProps extends FormProps<EditEventCategoryFormValues> {
    canUpdateEventCategory?: boolean;
}

const validationSchema = z.object({
    category: zodRequiredDropdownSelectSchema.nullable(),
});

export type EditEventCategoryFormValues = z.infer<typeof validationSchema>;

export const EditEventCategoryForm = ({
                                          loading,
                                          onSubmit,
                                          defaultValues,
                                          values,
                                          canUpdateEventCategory,
                                      }: EditEventCategoryFormProps) => {
    const spacingClasses = useSpacingStyles();
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        formState: {isDirty},
    } = useForm<EditEventCategoryFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues,
    });

    const isSubmitButtonDisabled = loading || !isDirty || !canUpdateEventCategory;

    useEffect(() => {
        if (!values) return;

        reset(values);
    }, [values]);

    const {data: eventCategoriesResponse, isLoading: isFetchingEventCategories} =
        useEventCategories({
            pageSize: config.ITEMS_PER_PAGE_LARGE,
        });

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2
                        className={classNames(
                            spacingClasses.noMarginBottom,
                            spacingClasses.noMarginTop
                        )}
                    >
                        Change event category
                    </h2>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel
                        url={BasicAdministrationUrlService.eventCategories()}
                        urlText="Create event category"
                    >
                        Event Category*
                    </FormLabel>

                    <Controller
                        name="category"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => (
                            <>
                                <AutoComplete
                                    options={
                                        eventCategoriesResponse?.data?.data.map((o) => ({
                                            label: o.name,
                                            value: o.id,
                                        })) || []
                                    }
                                    disabled={isFetchingEventCategories || loading}
                                    onChange={onChange}
                                    name={name}
                                    value={value}
                                />

                                <FormHelperText>
                                    Only employees with the following rights can change the category
                                    of an event:{' '}
                                    <strong>{eventCategoryUpdateRoles.join(', ')}</strong>. If you
                                    have questions about your role(s), please contact the system
                                    admin.
                                </FormHelperText>
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: 'Update category',
                        startIcon: loading && (
                            <CircularProgress size={theme.layout.loader.sizes.small}/>
                        ),
                        disabled: isSubmitButtonDisabled,
                        onClick: () => handleSubmit(onSubmit)(),
                    },
                ]}
            />
        </>
    );
};
