import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    VENUES_CREATE_ENDPOINT,
    VENUES_CREATE_SEATING_PLAN_ENDPOINT,
    VENUES_DELETE_ENDPOINT,
    VENUES_GET_BY_ID_ENDPOINT,
    VENUES_GET_ENDPOINT,
    VENUES_GET_SEATING_PLANS_BY_ID_ENDPOINT,
    VENUES_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/venues-endpoints';
import type {AddressOnUpdate} from 'src/data/models/adress/address';
import SeatingPlan from 'src/data/models/seating-plan/SeatingPlan';
import type {IdResponse} from '../models/common/idResponse';
import type {Venue, VenueOnUpdate} from '../models/venue/venue';

export async function getVenues(options?: BaseFetchOptions) {
    const extraQueryParams: QueryParam[] = [];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }
    return await getInternalAxiosInstance().get<PaginatedApiResponseBody<Venue>>(
        VENUES_GET_ENDPOINT,
        {
            params: options && getQueryParams(options, extraQueryParams),
        }
    );
}

async function getVenueById(id: string): Promise<ApiResponse<ApiResponseBody<Venue>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Venue>>(VENUES_GET_BY_ID_ENDPOINT(id))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createVenue(
    name: string,
    address: AddressOnUpdate
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(VENUES_CREATE_ENDPOINT, {name, address})
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateVenue(
    id: string,
    name: string,
    address: AddressOnUpdate
): Promise<ApiResponse<ApiResponseBody<VenueOnUpdate>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<VenueOnUpdate>>(VENUES_UPDATE_ENDPOINT(id), {name, address})
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function deleteVenue(id: string) {
    return await getInternalAxiosInstance().post<ApiResponseBody<Venue>>(
        VENUES_DELETE_ENDPOINT(id)
    );
}

export async function getSeatingPlansByVenueId(
    venueId: string
): Promise<ApiResponse<ApiResponseBody<SeatingPlan[]>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<SeatingPlan[]>>(VENUES_GET_SEATING_PLANS_BY_ID_ENDPOINT(venueId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface SeatingPlanDto {
    name: string;
    status: SeatingPlanStatus;
}

export enum SeatingPlanStatus {
    active = 'active',
    inactive = 'inactive',
}

async function createSeatingPlan(
    venueId: string,
    {name, status}: SeatingPlanDto
): Promise<ApiResponse<ApiResponseBody<SeatingPlan>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<SeatingPlan>>(VENUES_CREATE_SEATING_PLAN_ENDPOINT(venueId), {
            name,
            status,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getVenues,
    getVenueById,
    createVenue,
    updateVenue,
    deleteVenue,
    getSeatingPlansByVenueId,
    createSeatingPlan,
};
