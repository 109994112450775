import makeStyles from '@mui/styles/makeStyles';
import {useQuery} from '@tanstack/react-query';
import {useState} from 'react';
import {AuditLogOverview} from 'src/app/components/overviews/AuditLogOverview';
import auditLogService from 'src/data/services/auditLogService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import TablePagination from 'src/view/components/table-pagination/TablePagination';
import {parseErrors} from 'src/app/utilities/helpers/errors';

const useStyles = makeStyles((theme) => ({
    pagination: {
        marginTop: theme.spacing(2),
    },
}));

const AuditLogFeature = ({
                             groupEntityId,
                         }: {
    groupEntityId: string;
}) => {
    const [page, setPage] = useState(1);
    const classes = useStyles();

    const {
        isLoading,
        isError,
        data: response,
        error,
    } = useQuery({
        queryKey: ['audit_logs', groupEntityId, page],
        queryFn: async () => auditLogService.getAuditLogs(groupEntityId, {pageSize: 8, page}),
    });

    if (isError) {
        const parsedErrors = parseErrors(error);

        return <ErrorsList errors={parsedErrors}/>;
    }

    const data = response?.data;

    return (
        <>
            {isLoading && <LoadingOverlay/>}
            {data && (
                <>
                    <AuditLogOverview logs={data.data || []}/>

                    <TablePagination
                        className={classes.pagination}
                        totalPages={data.meta.totalPages || 1}
                        currentPage={data.meta.currentPage || 1}
                        onPaginate={(pageNumber) => setPage(pageNumber)}
                    />
                </>
            )}
        </>
    );
};

export default AuditLogFeature;
