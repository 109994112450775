import {useMutation} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';

type TData = Awaited<ReturnType<typeof seatingPlanCategoryService.uploadItineraryPdf>>;

export default function useUploadSPCItineraryFile(
    seatingplanCategoryId: string,
    onSuccess?: (data: TData) => unknown
) {
    return useMutation({
        mutationFn: (file: File) =>
            seatingPlanCategoryService.uploadItineraryPdf(seatingplanCategoryId, file),
        onSuccess: (data) => {
            Toaster.toast('Itinerary file uploaded successfully', {
                variant: 'success',
            });

            onSuccess?.(data);
        },
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err));
        },
    });
}
