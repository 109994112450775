import {useMutation} from '@tanstack/react-query';
import {
    type AddContractFormValues,
    type CategoryPrice,
} from 'src/app/components/forms/AddContractForm';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import type TicketTemplateItem from 'src/data/models/contracts/ticketTemplateItem';
import contractService, {
    type TemplateTicketItemPurchasePrice,
} from 'src/data/services/contractService';

type TData = Awaited<ReturnType<typeof contractService.generateTemplateTickets>>;
type TVariables = {
    eventId: string;
    values: AddContractFormValues;
    items: TicketTemplateItem[];
};

const templateTicketItemsToPurchasePrices = (
    items: TicketTemplateItem[],
    categoryPrices: CategoryPrice[]
): TemplateTicketItemPurchasePrice[] =>
    items.map((t) => {
        const category = categoryPrices.find((c) => c.categoryName === t.seatingPlanCategoryName);

        return {
            id: t.id,
            purchasePrice: {
                currency: category?.currency?.value || currencyOptions[0],
                value: Number(category?.price ?? 0),
            },
        };
    });

export const useCreateTemplateTickets = (
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown
) => {
    return useMutation({
        mutationFn: ({
                         eventId,
                         values,
                         items,
                     }: TVariables) =>
            contractService.generateTemplateTickets(
                values.contract?.value,
                values.ticketTemplateSet?.value,
                eventId,
                templateTicketItemsToPurchasePrices(items, values.categoryPrices)
            ),
        onSuccess,
    });
};
