const getEnvironment = (key: string): string | undefined => {
    if (Object.prototype.hasOwnProperty.call(process.env, key)) {
        return process.env[key];
    }

    if (!window.APP_ENV) {
        Object.defineProperty(window, 'APP_ENV', {value: {}});
    }

    return window.APP_ENV[key];
};

// Environment variables need to be added to runtime-config.js as well
export const config = {
    AZUREAD_LOGIN_URL: 'https://login.microsoftonline.com/',
    AZUREAD_CLIENT_ID: getEnvironment('REACT_APP_AZUREAD_CLIENT_ID'),
    AZUREAD_REDIRECT_URL: getEnvironment('REACT_APP_AZUREAD_REDIRECT_URL'),
    AZUREAD_AUTHORITY_ID: getEnvironment('REACT_APP_AZUREAD_AUTHORITY_ID'),
    INTERNAL_API_URL: getEnvironment('REACT_APP_ETBAAS_ENDPOINT'),
    EOB_API_URL: getEnvironment('REACT_APP_EXTERNAL_ORDERS_BOT_ENDPOINT'),
    TICKET_MANAGEMENT_API_URL: getEnvironment('REACT_APP_TICKET_MANAGEMENT_ENDPOINT'),
    TRAVELWARE_BASE_URL: getEnvironment('REACT_APP_TRAVELWARE_BASE_URL'),
    ITEMS_PER_PAGE_STANDARD: 30,
    ITEMS_PER_PAGE_LARGE: 1000,
    SNACKBAR_FADING_TIME: 2000,
    THEME_TYPE: getEnvironment('REACT_APP_THEME'),
    SALES_SHOW_STATUS: false,
    STOPLIGHT_MOCK_API: 'https://stoplight.io/mocks/p1group/etbaas-api-v2/22246437/',
    SHOW_EVENT_OVERVIEW_FILTERS: true,
    REACT_APP_ERROR_SENTRY_DSN: getEnvironment('REACT_APP_ERROR_SENTRY_DSN'),
    NODE_ENV: getEnvironment('REACT_APP_NODE_ENV'),
    GOOGLE: {
        GEOCODING_URL: getEnvironment('REACT_APP_GOOGLE_GEOCODING_URL') || '',
        API_KEY: getEnvironment('REACT_APP_GOOGLE_API_KEY') || '',
    },
} as const;
