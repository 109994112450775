import {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    FilterPlaceholder,
    FilterPlaceholderProps,
} from 'src/view/components/filters/FilterPlaceholder/FilterPlaceholder';
import {Moment} from 'moment';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider, StaticDatePicker} from '@mui/x-date-pickers'
import OutsideClickHandler from 'react-outside-click-handler';

const useStyles = makeStyles((t) => ({
    container: {
        position: 'relative',
    },
    calendar: {
        position: 'absolute',
        top: 40,
        zIndex: 1300,
        boxShadow: t.layout.boxShadow,
    },
}));

interface FilterDatePickerProps {
    value?: Moment | null;
    onChange: (date: Moment | null, keyboardInputValue?: string | undefined) => void;
    filterPlaceholderProps: Omit<FilterPlaceholderProps, 'value ' | 'onClick' | 'open'>;
    disabled?: boolean;
    minDate?: Moment;
}

export const FilterDatePicker = ({
                                     value,
                                     onChange,
                                     filterPlaceholderProps,
                                     disabled,
                                     minDate,
                                 }: FilterDatePickerProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <div className={classes.container}>
                <FilterPlaceholder
                    {...filterPlaceholderProps}
                    onClick={() => {
                        if (disabled) return;

                        setOpen(!open);
                    }}
                    open={open}
                    value={value?.toDate().toDateString()}
                    disabled={disabled}
                    onDelete={() => onChange(null)}
                />

                {open && (
                    <div className={classes.calendar}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StaticDatePicker
                                minDate={minDate}
                                value={value}
                                onChange={(date) => {
                                    !disabled && onChange(date);
                                    setOpen(false);
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                )}
            </div>
        </OutsideClickHandler>
    );
};
