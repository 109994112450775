import {useMutation} from '@tanstack/react-query';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import orderlineService from 'src/data/services/orderlineService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import FormButtons from 'src/view/components/form/FormButtons';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

const UnassignAllTicketsFeature = ({
                                       orderLineIds,
                                       onSuccess,
                                   }: {
    orderLineIds: string[];
    onSuccess?: () => void;
}) => {
    const {mutate, error, isError, isLoading} = useMutation({
        mutationFn: async (ids: string[]) =>
            orderlineService.unassignTickets({orderLineIds: ids}),
    });

    return (
        <>
            {isLoading && <LoadingOverlay/>}
            {isError && <ErrorsList errors={parseErrors(error)}/>}

            <FormButtons
                buttons={[
                    {
                        children: 'Unassign ticket(s)',
                        disabled: isError,
                        onClick: () => mutate(orderLineIds, {onSuccess}),
                    },
                ]}
            />
        </>
    );
};

export default UnassignAllTicketsFeature;
