import {Alert} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {EditEventFeature} from 'src/app/components/features/event/EditEventFeature';
import EventDetailsFeature from 'src/app/components/features/EventDetailsFeature';
import {FETCH_EVENT_BY_ID_QUERY_KEY} from 'src/app/hooks/events/useFetchEventById';

type Params = {
    eventId: string;
};

export default function EventDetails() {
    const {eventId} = useParams<Params>();

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();
    const queryClient = useQueryClient();


    const onSuccess = (hideModal = true) => {
        setInvalidatedAt(Date.now());

        queryClient.invalidateQueries({
            queryKey: [FETCH_EVENT_BY_ID_QUERY_KEY],
            exact: false,
        });

        if (hideModal) setShowDetailsModal(false);
    };

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    return (
        <>
            <EventDetailsFeature
                eventId={eventId}
                invalidatedAt={invalidatedAt}
                onDetailsClick={() => setShowDetailsModal(true)}
            />

            {eventId && showDetailsModal && (
                <EditEventFeature
                    eventId={eventId}
                    open={showDetailsModal}
                    onClose={() => setShowDetailsModal(false)}
                    onUpdatedEvent={() => onSuccess(false)}
                    onDeleteEvent={() => onSuccess(true)}
                    onUpdatedName={() => onSuccess(false)}
                    onUpdatedOrganizer={() => onSuccess(false)}
                    onUpdatedSerie={() => onSuccess(false)}
                    invalidatedAt={invalidatedAt}
                />
            )}
        </>
    );
}
