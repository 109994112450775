import {Chip} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
    FilterPlaceholder,
    FilterPlaceholderProps,
} from 'src/view/components/filters/FilterPlaceholder/FilterPlaceholder';
import Input from 'src/view/components/input/Input';
import CheckIcon from 'src/view/icons/CheckIcon';

const useStyles = makeStyles((t) => ({
    container: {
        position: 'relative',
    },
    dropdownContainer: {
        position: 'absolute',
        borderRadius: t.layout.borderRadius.regular,
        boxShadow: t.layout.boxShadow,
        top: 40,
        width: 250,
        background: 'white',
        padding: t.spacing(0.5),
        zIndex: 99,
    },
    list: {
        maxHeight: 250,
        overflowY: 'auto',
        scrollbarColor: 'dark',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        padding: t.spacing(1),
    },
    listItemActive: {
        cursor: 'pointer',
        background: t.palette.primary.light,
    },
    valueList: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: t.spacing(1, 0),
        maxHeight: t.spacing(9),
        overflowY: 'auto',
    },
    valueListItem: {
        '&:not(:last-of-type)': {
            marginRight: t.spacing(0.5),
            marginBottom: t.spacing(1),
        },
    },
    selectedBox: {
        padding: t.spacing(0.2),
        background: 'white',
        fontSize: '.5rem',
        marginLeft: t.spacing(0.5),
        textAlign: 'center',
        minWidth: 12,
        minHeight: 12,
        borderRadius: t.layout.borderRadius.rounded,
    },
    chipContent: {
        display: 'flex',
        alignItems: 'center',
    },
    selectedText: {
        fontSize: '.75rem',
    },
    noOptions: {
        fontSize: '.75rem',
        color: t.colors.darkGrey,
    },
}));

export interface FilterAutoCompleteOption {
    label: string;
    value: string | boolean | number | undefined;
    isArchived?: boolean;
}

export type FilterAutoCompleteOptions = FilterAutoCompleteOption[];
export type FilterAutoCompleteValueOption = FilterAutoCompleteOption | FilterAutoCompleteOptions;

interface AutoCompleteProps {
    value?: FilterAutoCompleteValueOption;
    options: FilterAutoCompleteOptions;
    onChange: (value?: FilterAutoCompleteValueOption) => void;
    onCreate?: () => void;
    onSearch?: (inputValue: string) => Promise<void> | void;
    filterPlaceholderProps?: Omit<FilterPlaceholderProps, 'value ' | 'onClick' | 'open'>;
    isMulti?: boolean;
    disabled?: boolean;
    preserveSearchValue?: boolean;
}

export const FilterAutoComplete = ({
                                       filterPlaceholderProps,
                                       onSearch,
                                       options,
                                       onChange,
                                       value,
                                       isMulti,
                                       disabled,
                                       preserveSearchValue = false,
                                   }: AutoCompleteProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [internalOptions, setInternalOptions] = useState<FilterAutoCompleteOptions>(options);
    const [hoveredOption, setHoveredOption] = useState<FilterAutoCompleteOption | undefined>();
    const inputRef = React.useRef<HTMLInputElement | undefined>();
    const listItemRefs = React.useRef<Array<HTMLDivElement | null>>([]);
    const preserveSearchValueRef = useRef<boolean>(preserveSearchValue);

    useEffect(() => {
        listItemRefs.current = listItemRefs.current.slice(0, options.length);
    }, [options]);

    useEffect(() => {
        setInternalOptions(options);
    }, [options]);

    const resetAutoComplete = useCallback(
        (preserveHoveredOption = false) => {
            setSearchValue('');
            setInternalOptions(options);

            if (!preserveHoveredOption) setHoveredOption(undefined);
        },
        [options]
    );

    const isArray = Array.isArray(value);
    const valuePlaceholder = isArray ? value?.map((v) => v.label) : value?.label;

    const onDelete = (autoCompleteOption: FilterAutoCompleteOption) => {
        if (!autoCompleteOption || !isArray) return;

        onChange(value.filter((option) => option.value !== autoCompleteOption.value));
    };

    const handleArrowPress = (direction: 'up' | 'down') => {
        if (!hoveredOption) {
            setHoveredOption(internalOptions[0]);

            return;
        }

        const hoveredOptionIndex = internalOptions.findIndex(
            (o) => o.value === hoveredOption.value
        );

        if (direction === 'down') {
            if (hoveredOptionIndex === internalOptions.length - 1) return;

            if (hoveredOptionIndex !== -1) {
                setHoveredOption(internalOptions[hoveredOptionIndex + 1]);
                listItemRefs.current[hoveredOptionIndex + 1]?.scrollIntoView({
                    block: 'nearest',
                    inline: 'start',
                });
            }
        }

        if (direction === 'up') {
            if (hoveredOptionIndex === 0) return;

            if (hoveredOptionIndex !== -1) {
                setHoveredOption(internalOptions[hoveredOptionIndex - 1]);
                listItemRefs.current[hoveredOptionIndex - 1]?.scrollIntoView({
                    block: 'nearest',
                    inline: 'start',
                });

                return;
            }
        }
    };

    const onKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (internalOptions.length === 1) {
                    onClickItem(internalOptions[0]);
                    if (!preserveSearchValueRef.current) {
                        resetAutoComplete(true);
                    }
                } else {
                    if (!hoveredOption) return;

                    onClickItem(hoveredOption);
                    if (!preserveSearchValueRef.current) {
                        resetAutoComplete(true);
                    }
                }
            }

            if (event.key === 'ArrowDown') {
                event.preventDefault();
                handleArrowPress('down');
            }

            if (event.key === 'ArrowUp') {
                event.preventDefault();
                handleArrowPress('up');
            }

            if (event.key === 'Escape') {
                setOpen(false);
            }

            if (event.key === 'Backspace' && hoveredOption && Array.isArray(value)) {
                if (valueIsSelected(hoveredOption)) {
                    onDelete(hoveredOption);
                }
            }
        },
        [internalOptions, hoveredOption]
    );

    useEffect(() => {
        if (open) {
            addEventListener('keydown', onKeyPress);
        }

        return () => {
            removeEventListener('keydown', onKeyPress);
        };
    }, [open, internalOptions, hoveredOption]);

    const valueIsSelected = (o: FilterAutoCompleteOption) => {
        if (isArray && isMulti) return !!value.find((val) => val.value === o.value);

        return value === o;
    };

    const onChangeSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setHoveredOption(undefined);

        if (onSearch) {
            onSearch(e.target.value);

            return;
        }

        setInternalOptions(
            options.filter((o) => o.label.toLowerCase().includes(e.target.value.toLowerCase()))
        );
    };

    const onClickItem = (o: FilterAutoCompleteOption) => {
        if (isMulti) {
            if (!value) {
                onChange([o]);

                return;
            }

            if (isArray && !valueIsSelected(o)) onChange([...value, o]);

            return;
        }

        onChange(o);
        setOpen(false);
    };

    useEffect(() => {
        if (open) return;

        if (!preserveSearchValueRef.current) {
            resetAutoComplete(true);
        }
    }, [open]);

    useEffect(() => {
        if (!open || !inputRef.current) return;

        inputRef.current?.focus();
    }, [open, inputRef]);

    const handleDeleteSelection = useCallback(() => {
        isArray ? onChange([]) : onChange(undefined);

        resetAutoComplete();
    }, [resetAutoComplete, onChange]);

    return (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <div className={classes.container}>
                <FilterPlaceholder
                    {...filterPlaceholderProps}
                    onClick={() => !disabled && setOpen(!open)}
                    open={open}
                    value={valuePlaceholder}
                    onDelete={handleDeleteSelection}
                    disabled={disabled}
                />

                {open && (
                    <div className={classes.dropdownContainer}>
                        <Input
                            placeholder="search"
                            padding={2}
                            grey
                            noBorder
                            value={searchValue}
                            onChange={onChangeSearch}
                            inputProps={{ref: inputRef}}
                        />

                        <div className={classes.valueList}>
                            {isArray && (
                                <>
                                    {value.map((v) => (
                                        <Chip
                                            key={v.value?.toString()}
                                            size="small"
                                            label={
                                                <span className={classes.selectedText}>
                                                    {v.label}
                                                </span>
                                            }
                                            onDelete={() => onDelete(v)}
                                            className={classes.valueListItem}
                                        />
                                    ))}
                                </>
                            )}
                        </div>

                        <div className={classes.list}>
                            {internalOptions.length === 0 && (
                                <div className={classnames(classes.listItem, classes.noOptions)}>
                                    No options found
                                </div>
                            )}

                            {internalOptions.map((o, i) => (
                                <div
                                    key={o.value?.toString()}
                                    ref={(el) => (listItemRefs.current[i] = el)}
                                    className={classnames(classes.listItem, {
                                        [`${classes.listItemActive}`]:
                                        (hoveredOption && hoveredOption?.value === o.value) ||
                                        (!isArray && !isMulti && value?.label === o.label),
                                    })}
                                    onClick={() => {
                                        onClickItem(o);

                                        if (!valueIsSelected(o)) {
                                            if (!preserveSearchValueRef.current) {
                                                resetAutoComplete(true);
                                            }
                                        } else {
                                            onDelete(o);
                                        }
                                    }}
                                    onMouseEnter={() => setHoveredOption(o)}
                                    onMouseLeave={() => setHoveredOption(undefined)}
                                >
                                    {o.label}{' '}
                                    {valueIsSelected(o) && <CheckIcon success height={10}/>}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </OutsideClickHandler>
    );
};
