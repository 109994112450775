import {useMutation, useQueryClient} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import purchaseService from 'src/data/services/purchaseService';
import {GET_PURCHASE_BY_ID_QUERY_KEY} from './useFetchPurchaseById';
import {PURCHASES_QUERY} from './useFetchPurchases';

type TData = Awaited<ReturnType<typeof purchaseService.cancelPurchase>>;

export const useCancelPurchase = (onSuccess?: (data: TData) => unknown) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (purchaseId: string) => purchaseService.cancelPurchase(purchaseId),
        onSuccess: (data) => {
            onSuccess?.(data);
            Toaster.toast('Successfully cancelled the purchase!', {
                variant: 'success',
            });
            queryClient.invalidateQueries({queryKey: [GET_PURCHASE_BY_ID_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: [PURCHASES_QUERY]});
        },
    });
};
