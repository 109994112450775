import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import FormButtons from 'src/view/components/form/FormButtons';
import {Controller, useForm} from 'react-hook-form';
import {CircularProgress, Grid} from '@mui/material';
import {FormLabel} from 'src/view/components/form/FormLabel';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import Button from 'src/view/components/button/Button';
import useCreateEventCategory from 'src/app/hooks/event-categories/useCreateEventCategory';

export interface EventCategoriesDetailsFormValues {
    name: string;
    key: string;
}

export const eventCategoryValidationSchema = Yup.object({
    name: Yup.string().required('Enter a name'),
    key: Yup.string()
        .required('Enter a key')
        .transform((value, originalValue) => (originalValue ? originalValue.toUpperCase() : value)),
});

type EventCategoriesDetailsForm = {
    onSuccess: () => void;
};

export default function EventCategoriesDetailsForm({onSuccess}: EventCategoriesDetailsForm) {
    const {
        control,
        handleSubmit,
        formState: {isDirty},
    } = useForm<EventCategoriesDetailsFormValues>({
        mode: 'onChange',
        resolver: yupResolver(eventCategoryValidationSchema),
    });

    const {mutate: createEventCategory, isLoading} = useCreateEventCategory(onSuccess);

    const isSubmitButtonDisabled = isLoading || !isDirty;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel>Name*</FormLabel>
                    <Controller
                        name="name"
                        control={control}
                        render={({field: {name, value, onChange}, fieldState: {error}}) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Name"
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel>Key*</FormLabel>
                    <Controller
                        name="key"
                        control={control}
                        render={({
                                     field: {name, value = '', onChange},
                                     fieldState: {error},
                                 }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value.toUpperCase()}
                                    onChange={onChange}
                                    placeholder="Key"
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormButtons justifyContent={'flex-end'}>
                        <div>
                            <Button
                                disabled={isSubmitButtonDisabled}
                                startIcon={isLoading && <CircularProgress size={11}/>}
                                onClick={handleSubmit((values) => createEventCategory(values))}
                            >
                                Create
                            </Button>
                        </div>
                    </FormButtons>
                </Grid>
            </Grid>
        </>
    );
}
