import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const TTI_LINK_TICKET_FILES_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/unprocessed-ticket-files/link-ticket`;

export const TTI_FIND_MATCHING_TICKETS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/unprocessed-ticket-files/find-matching-ticket`;

export const TTI_DELETE_TICKETS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/unprocessed-ticket-files/delete`;

export const TTI_MASS_LINK_TICKETS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/unprocessed-ticket-files/mass-link-tickets`;

export const TTI_GET_TICKET_FILE = (ticketFileId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/unprocessed-ticket-files/create-unprocessed-ticket-file-link?unprocessedTicketFileId=${ticketFileId}`;
