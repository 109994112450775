import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {Box} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {makeStyles} from '@mui/styles';
import {CSSProperties, useState} from 'react';

interface HelpPopoverProps {
    content: {
        title?: string;
        text?: string | string[];
    };
    muted?: boolean;
    iconZIndex?: CSSProperties['zIndex'];
}

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(1, 0),
        fontSize: '0.9rem',
    },
    tooltip: {
        backgroundColor: theme.colors.white,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: '34em',
        border: `1px solid ${theme.colors.bluishGrey}`,
        boxShadow: theme.layout.boxShadowSlight,
    },
    text: {
        fontSize: '0.8rem',
        marginBottom: theme.spacing(1),
    },
    list: {
        color: 'gray',
        margin: '0px',
        paddingLeft: theme.spacing(2),
    },
    icon: (props: Partial<HelpPopoverProps>) => ({
        zIndex: props.iconZIndex,
        '& svg': {
            cursor: 'pointer',
            display: 'block',
            marginLeft: theme.spacing(0.4),
            width: 20,
        },
    }),
}));

export default function HelpPopover({content, iconZIndex = 1, muted = false}: HelpPopoverProps) {
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const classes = useStyles({iconZIndex});

    const renderText = () => {
        if (Array.isArray(content.text)) {
            return (
                <ol className={classes.list}>
                    {content.text.map((text) => {
                        return (
                            <li key={text} className={classes.text}>
                                {text}
                            </li>
                        );
                    })}
                </ol>
            );
        }

        return <p className={classes.text}>{content.text}</p>;
    };

    return (
        <Tooltip
            classes={{tooltip: classes.tooltip}}
            title={
                <>
                    <h4 className={classes.title}>{content.title}</h4>
                    {content.text && renderText()}
                </>
            }
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            open={tooltipIsOpen}
            sx={(theme) => ({color: muted ? theme.colors.grey : 'inherit'})}
        >
            <Box className={classes.icon} onClick={() => setTooltipIsOpen(true)}>
                <HelpOutlineOutlinedIcon/>
            </Box>
        </Tooltip>
    );
}
