import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import CreateDataResponse from 'src/data/api/common/CreateDataResponse';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    SEATING_PLAN_CATEGORIES_ARCHIVE_ENDPOINT,
    SEATING_PLAN_CATEGORIES_CREATE_BLOCK_ENDPOINT,
    SEATING_PLAN_CATEGORIES_CREATE_ROW_ENDPOINT,
    SEATING_PLAN_CATEGORIES_DELETE_ENDPOINT,
    SEATING_PLAN_CATEGORIES_DELETE_ITINERARY_ENDPOINT,
    SEATING_PLAN_CATEGORIES_EDIT_ITINERARY_FILE_ENDPOINT,
    SEATING_PLAN_CATEGORIES_FETCH_BLOCKS_ENDPOINT,
    SEATING_PLAN_CATEGORIES_FETCH_BY_ID_ENDPOINT,
    SEATING_PLAN_CATEGORIES_FETCH_ROWS_ENDPOINT,
    SEATING_PLAN_CATEGORIES_UN_ARCHIVE_ENDPOINT,
    SEATING_PLAN_CATEGORIES_UPLOAD_IMAGE_ENDPOINT,
    SEATING_PLAN_CATEGORIES_UPLOAD_ITINERARY_ENDPOINT,
} from 'src/data/constants/endpoints/seating-plan-categories-endpoints';
import Block from 'src/data/models/block/block';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import Row from 'src/data/models/row/row';
import {SeatingPlanCategory} from 'src/data/models/seating-plan-category/SeatingPlanCategory';

async function getSeatingplanCategoryById(
    seatingplanCategoryId: string
): Promise<ApiResponse<ApiResponseBody<SeatingPlanCategory>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<SeatingPlanCategory>>(
            SEATING_PLAN_CATEGORIES_FETCH_BY_ID_ENDPOINT(seatingplanCategoryId)
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchBlocksBySeatingPlanCategory(
    seatingPlanCategoryId: string,
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Block>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options?.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Block>>(
            SEATING_PLAN_CATEGORIES_FETCH_BLOCKS_ENDPOINT(seatingPlanCategoryId),
            {params: options && getQueryParams(options, extraQueryParams)}
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchRowsBySeatingPlanCategoryId(
    seatingPlanCategoryId: string,
    blockId: string,
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Row>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options?.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Row>>(
            SEATING_PLAN_CATEGORIES_FETCH_ROWS_ENDPOINT(seatingPlanCategoryId, blockId),
            {params: options && getQueryParams(options, extraQueryParams)}
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createBlock(
    number: string,
    seatingPlanCategoryId: string
): Promise<ApiResponse<ApiResponseBody<CreateDataResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<CreateDataResponse>>(
            SEATING_PLAN_CATEGORIES_CREATE_BLOCK_ENDPOINT(seatingPlanCategoryId),
            {number}
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createRow(
    number: string,
    seatingPlanCategoryId: string,
    blockId: string
): Promise<ApiResponse<ApiResponseBody<CreateDataResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<CreateDataResponse>>(
            SEATING_PLAN_CATEGORIES_CREATE_ROW_ENDPOINT(seatingPlanCategoryId, blockId),
            {number}
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function uploadImage(
    id: string,
    image: File
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    const formData = new FormData();
    formData.append('file', image);

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            SEATING_PLAN_CATEGORIES_UPLOAD_IMAGE_ENDPOINT(id),
            formData
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function uploadItineraryPdf(
    id: string,
    pdf: File
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    const formData = new FormData();
    formData.append('file', pdf);

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            SEATING_PLAN_CATEGORIES_UPLOAD_ITINERARY_ENDPOINT(id),
            formData
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteItineraryPdf(
    id: string,
    pdfId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(SEATING_PLAN_CATEGORIES_DELETE_ITINERARY_ENDPOINT(id), {
            fileIds: [pdfId],
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function editItineraryPdf(
    planId: string,
    fileId: string,
    pdf: File
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    const formData = new FormData();
    formData.append('file', pdf);

    return getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            SEATING_PLAN_CATEGORIES_EDIT_ITINERARY_FILE_ENDPOINT(planId, fileId),
            formData
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function deleteSeatingPlanCategory(id: string) {
    return await getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        SEATING_PLAN_CATEGORIES_DELETE_ENDPOINT(id)
    );
}

async function archiveSeatingPlanCategory(
    id: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SEATING_PLAN_CATEGORIES_ARCHIVE_ENDPOINT(id))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function unarchiveSeatingPlanCategory(
    id: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SEATING_PLAN_CATEGORIES_UN_ARCHIVE_ENDPOINT(id))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getSeatingplanCategoryById,
    fetchBlocksBySeatingPlanCategory,
    fetchRowsBySeatingPlanCategoryId,
    createBlock,
    createRow,
    uploadImage,
    uploadItineraryPdf,
    deleteItineraryPdf,
    editItineraryPdf,
    deleteSeatingPlanCategory,
    archiveSeatingPlanCategory,
    unarchiveSeatingPlanCategory,
};
