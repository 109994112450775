import {CircularProgress} from '@mui/material';
import {useTheme} from '@mui/styles';
import {useQueryClient} from '@tanstack/react-query';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useCreateDownloadLinkDataWrapper} from 'src/app/components/data-wrappers/tickets/CreateDownloadLinkDataWrapper';
import {useDeleteTicketFileByIdDataWrapper} from 'src/app/components/data-wrappers/tickets/DeleteTicketFileByIdDataWrapper';
import {useFetchTicketByIdDataWrapper} from 'src/app/components/data-wrappers/tickets/FetchTicketByIdDataWrapper';
import {useUploadTicketFileDataWrapper} from 'src/app/components/data-wrappers/tickets/UploadTicketFileDataWrapper';
import {TicketUploadFilesForm} from 'src/app/components/forms/tickets/upload-files-form/TicketUploadFilesForm';
import {FETCH_EVENT_TICKETS_QUERY_KEY} from 'src/app/hooks/tickets/useFetchEventTickets';
import {ErrorReturn} from 'src/app/hooks/useApiFetch';
import useTicketFileAlreadyExistsToast from 'src/app/hooks/useTicketFileAlreadyExistsToast';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors, showMappedBackendErrors} from 'src/app/utilities/helpers/errors';
import isTicketAlreadyLinkedError from 'src/data/api/type-guards/isTicketAlreadyLinkedError';
import TicketFile from 'src/data/models/common/ticketFile';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import FormButtons from 'src/view/components/form/FormButtons';
import Modal, {ModalBody, ModalFooter} from 'src/view/components/modal/Modal';

interface TicketUploadFilesFormFeatureProps {
    ticketId: string;
    defaultFiles?: TicketFile[];
    eventId: string;
}

export const TicketUploadFilesFormFeature = ({
                                                 ticketId,
                                                 defaultFiles,
                                             }: TicketUploadFilesFormFeatureProps) => {
    const theme = useTheme();

    const queryClient = useQueryClient();

    const showFileAlreadyExistsToast = useTicketFileAlreadyExistsToast();

    const {enqueueSnackbar} = useSnackbar();

    const {fetchTicketById, data: ticketData} = useFetchTicketByIdDataWrapper({});
    const {deleteTicketFile} = useDeleteTicketFileByIdDataWrapper({
        disableDefaultSuccessMessage: true,
    });
    const {uploadTicketFile} = useUploadTicketFileDataWrapper({
        disableDefaultSuccessMessage: true,
        useDefaultErrorHandler: false,
    });

    const {createDownloadLink} = useCreateDownloadLinkDataWrapper({
        onSuccess: (data) => window.open(data?.data.url, '_blank'),
    });

    const [showDeleteAllmodal, setShowDeleteAllModal] = useState(false);
    const [deleteAllFilesIsLoading, setDeleteAllFilesIsLoading] = useState(false);

    const files = ticketData?.data.files || defaultFiles;

    const handleUploadFiles = async (files: File[]) => {
        if (!files.length) {
            Toaster.toast('Something went wrong while handling the chosen file(s).', {
                variant: 'error',
            });

            return;
        }

        Toaster.toast('Uploading files...', {variant: 'info'});

        for (const file of files) {
            try {
                await uploadTicketFile(ticketId, file);

                Toaster.toast(`Successfully attached ${file.name}`, {variant: 'success'});

                fetchTicketById(ticketId);

                // TODO: When uploadTicketFile is moved to React-Query, invalidate below query there
                queryClient.invalidateQueries({
                    queryKey: [FETCH_EVENT_TICKETS_QUERY_KEY],
                    exact: false,
                });
            } catch (e) {
                const error = e as ErrorReturn;

                if (isTicketAlreadyLinkedError(error.parsedErrors[0])) {
                    const {title, eventTicketFiles} = error.parsedErrors[0];

                    showFileAlreadyExistsToast({
                        title,
                        eventId: eventTicketFiles[0].eventId,
                        ticketId: eventTicketFiles[0].ticketId,
                    });
                } else {
                    const parsedErrors = parseErrors(e);

                    enqueueSnackbar(<ErrorsList errors={parsedErrors} plainStyle/>, {
                        variant: 'error',
                    });
                }
            }
        }
    };

    const deleteAllTickets = async () => {
        if (!files) return;

        setDeleteAllFilesIsLoading(true);

        try {
            const result = await Promise.all(
                files.map((file) => deleteTicketFile(ticketId, file.id))
            );

            const resultLength = result.filter((res) => res !== undefined).length;

            if (resultLength === files.length) {
                Toaster.toast('All files are deleted', {variant: 'success'});
            }

            if (resultLength > 0 && resultLength !== files.length) {
                Toaster.toast('Some of the files are deleted', {variant: 'success'});
            }

            setDeleteAllFilesIsLoading(false);
            fetchTicketById(ticketId);
            setShowDeleteAllModal(false);
        } catch (e) {
            showMappedBackendErrors(e);
        }
    };

    const renderDeleteAllTicketsModal = () => (
        <Modal
            open={showDeleteAllmodal}
            title="Confirm your action"
            onClose={() => setShowDeleteAllModal(false)}
        >
            <>
                <ModalBody>
                    You are about to delete <strong>{ticketData?.data.files.length}</strong> files.
                    Are you sure you want to continue?
                </ModalBody>
                <ModalFooter>
                    <FormButtons
                        buttons={[
                            {
                                children: 'Delete selected files',
                                disabled: deleteAllFilesIsLoading,
                                startIcon: deleteAllFilesIsLoading && (
                                    <CircularProgress size={theme.layout.loader.sizes.small}/>
                                ),
                                onClick: deleteAllTickets,
                            },
                        ]}
                    />
                </ModalFooter>
            </>
        </Modal>
    );

    return (
        <>
            <TicketUploadFilesForm
                dropzoneProps={{
                    onDrop: (acceptedFiles) => handleUploadFiles(acceptedFiles),
                }}
                onUpload={(files: File[]) => handleUploadFiles(files)}
                ticketFiles={ticketData?.data.files || defaultFiles}
                ticketUploadedFilesTableProps={{
                    onFileDeleted: () => fetchTicketById(ticketId),
                    onClickFile: (fileId) => createDownloadLink(ticketId, fileId),
                    loading: false,
                    ticketId,
                }}
                onDeleteAll={() => setShowDeleteAllModal(true)}
            />
            {renderDeleteAllTicketsModal()}
        </>
    );
};
