import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    TEAMS_CREATE_ENDPOINT,
    TEAMS_DELETE_ENDPOINT,
    TEAMS_GET_ENDPOINT,
    TEAMS_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/teams-endpoints';
import CreateTeamDto from 'src/data/dtos/CreateTeamDto';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import {Team} from 'src/data/models/team/team';

export async function fetchTeams(options?: BaseFetchOptions) {
    const extraQueryParams: QueryParam[] = [
        {
            key: 'includeNumEvents',
            value: 'true',
        },
    ];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance().get<PaginatedApiResponseBody<Team>>(
        TEAMS_GET_ENDPOINT,
        {
            params: options && getQueryParams(options, extraQueryParams),
        }
    );
}

export async function createTeam(dto: CreateTeamDto) {
    return await getInternalAxiosInstance().post<ApiResponseBody<Team>>(TEAMS_CREATE_ENDPOINT, dto);
}

export async function updateTeam(id: string, dto: CreateTeamDto) {
    return await getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        TEAMS_UPDATE_ENDPOINT(id),
        dto
    );
}

export async function deleteTeam(teamIds: string[]) {
    return await getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        TEAMS_DELETE_ENDPOINT,
        {
            teamIds,
        }
    );
}
