import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const EVENTS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/events`;

export const EVENTS_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/events/create`;

export const EVENTS_GET_BY_ID_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2_2}/events/${eventId}`;

export const EVENTS_UPDATE_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/update`;

export const EVENTS_UPDATE_NAME_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/update-event-name`;

export const EVENTS_UPDATE_ORGANIZER_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/assign-organizer`;

export const EVENTS_UPDATE_SERIES_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/assign-series`;

export const EVENTS_UPDATE_EVENT_CATEGORY_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/assign-event-category`;

export const EVENTS_DELETE_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/delete`;

export const EVENTS_DEACTIVATE_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/deactivate`;

export const EVENTS_ACTIVATE_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/activate`;

export const EVENTS_GET_ORDERS_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/events/${eventId}/orders`;

export const EVENTS_CREATE_ORDER_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/events/${eventId}/orders/create`;

export const EVENTS_GET_ORDERLINES_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/events/${eventId}/order-lines`;

export const EVENTS_GET_FILTER_OPTIONS_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/events/filter-options`;

export const EVENTS_GET_TICKETS_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/tickets`;

export const EVENTS_GET_SUPPLIERS_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/suppliers`;

export const EVENTS_GET_SEATS_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/seats`;

export const EVENTS_GET_SPLITS_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/splits`;

export const EVENTS_SEND_TICKETS_FOR_ORDER_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/orders/send-tickets`;

export const EVENTS_REVOKE_TICKETS_FOR_ORDER_ENDPOINT = (eventId: string, orderId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/orders/${orderId}/revoke-tickets`;

export const EVENTS_MARK_TICKETS_AS_SENT_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/orders/mark-as-sent`;

export const EVENTS_EXPORT_TICKETS_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/exports/events/${eventId}/tickets`;

export const EVENTS_GET_SEATING_PLAN_CATEGORIES_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/events/${eventId}/seating-plan-categories`;

export const EVENTS_GET_AVAILABLE_SEATS_ENDPOINT = (eventId: string, rowId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/seats?filter[rowId]=${rowId}&filter[available]=true`;

export const EVENTS_GET_UNPROCESSED_TICKET_FILES_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/unprocessed-ticket-files`;

export const EVENTS_UPLOAD_UNPROCESSED_TICKET_FILE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/unprocessed-ticket-files/upload`;

export const EVENTS_LOCK_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/lock`;

export const EVENTS_UNLOCK_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/unlock`;

export const EVENTS_CLOSE_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/close`;

export const EVENTS_UPDATE_EVENT_INTERNAL_NOTES_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/update-internal-notes`;

export const EVENTS_TICKET_SALES_CAP_ENDPOINT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/ticket-sales-caps`;
