import EditIcon from '@mui/icons-material/Edit';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import {useMemo} from 'react';
import {PurchasesStatisticsTableFeature} from 'src/app/components/features/purchase/PurchaseStatisticsTablesFeature';
import {TotalPriceDifferencePopover} from 'src/app/components/pop-overs/purchases/TotalPriceDifferencePopover';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import {PURCHASE_STATUS} from 'src/data/enums/purchase';
import Price from 'src/data/models/common/price';
import Purchase from 'src/data/models/purchase/Purchase';
import {dateOnlyFormat} from 'src/shared/date';
import Popover from 'src/view/components/popover/Popover';
import {Price as PriceComponent} from 'src/view/components/price/Price';
import {StatusLabel} from 'src/view/components/status-label/StatusLabel';
import {TableAction} from 'src/view/components/table-toolbar/TableToolbar';
import Table, {RowIdResolver} from 'src/view/components/table/table/Table';
import {NewTableColumn, TABLE_CELL_WIDTH, TABLE_ROW_TYPE, TableColumnSorting, TablePaginationData,} from 'src/view/components/table/table/Types';

export type RowIdResolvedPurchase = {
    id: string;
    data: Purchase;
};

export interface PurchaseTableProps {
    eventId?: string;
    purchases?: Purchase[];
    loading?: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sortings: TableColumnSorting[]) => void;
    onPurchaseEdit: (purchase: Purchase) => void;
    tableToolbarActions?: (selectedRows: RowIdResolver<Purchase>[]) => TableAction[];
    selectedRows?: RowIdResolver<Purchase>[];
    onChangeSelectedRows: (data: RowIdResolver<Purchase>[]) => void;
}

export type PurchasesOverviewRowIdResolver = {
    id: string;
    data: Purchase;
};

export default function PurchasesTable({
                                           eventId,
                                           loading,
                                           purchases = [],
                                           pagination,
                                           onSortingChange,
                                           onPurchaseEdit,
                                           tableToolbarActions,
                                           selectedRows,
                                           onChangeSelectedRows,
                                       }: PurchaseTableProps): JSX.Element {
    const columns = useMemo<NewTableColumn<Purchase>[]>(
        () => [
            {
                key: 'purchasedAt',
                title: 'Date of purchase',
                isSortable: true,
                cellRenderer: (purchase) => (
                    <span>{moment(purchase.purchasedAt).format(dateOnlyFormat)}</span>
                ),
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                key: 'supplierName',
                title: 'Supplier',
                cellRenderer: (purchase) => <span>{purchase.supplierName}</span>,
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            },
            {
                key: 'purchaseNumber',
                title: 'Purchase Number',
                cellRenderer: (purchase) => <span>{purchase.purchaseNumber}</span>,
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            },
            {
                key: 'numTickets',
                title: '# of tickets',
                cellRenderer: (purchase: Purchase) => {
                    if (eventId) {
                        const ticketsUrl =
                            purchase.eventNumTickets && purchase.eventNumTickets > 0
                                ? getEventTicketsUrl(eventId, purchase.id)
                                : undefined;

                        return (
                            <>
                                {ticketsUrl ? (
                                    <a href={ticketsUrl}>{purchase.eventNumTickets}</a>
                                ) : (
                                    <span>{purchase.eventNumTickets}</span>
                                )}
                                {purchase.eventNumTickets !== purchase.numTickets && (
                                    <Box
                                        component="span"
                                        sx={(theme) => ({
                                            color: theme.colors.grey,
                                            marginLeft: theme.spacing(1),
                                        })}
                                    >
                                        ({purchase.numTickets} total)
                                    </Box>
                                )}
                            </>
                        );
                    }

                    return <span>{purchase.numTickets}</span>;
                },
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                hidden: !!eventId,
                key: 'numEvents',
                title: '# of events',
                cellRenderer: (purchase) => {
                    if (!purchase.numEvents) return purchase.numEvents;

                    return (
                        <Popover
                            text={purchase.numEvents}
                            trigger="click"
                            boxPadding={0}
                            maxWidth="unset"
                            maxHeight="50vh"
                        >
                            <PurchasesStatisticsTableFeature
                                purchaseId={purchase.id}
                                linkText="Go to tickets"
                            />
                        </Popover>
                    );
                },
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'totalPrice',
                title: 'Total Price',
                isSortable: true,
                cellRenderer: (purchase) => {
                    const {
                        ticketsTotalOriginalPrice,
                        ticketsTotalEuroPrice,
                        originalPrice,
                        euroPrice,
                        eventTicketsTotalOriginalPrice,
                        eventTicketsTotalEuroPrice,
                    } = purchase;

                    const hasAdditionalCosts = (
                        ticketsOriginalPriceValue?: number,
                        originalPriceValue?: number
                    ) => ticketsOriginalPriceValue !== originalPriceValue;

                    const renderPrice = (
                        originalPriceToDisplay: Price | null,
                        euroPriceToDisplay: Price | null
                    ) => {
                        const hasDifferentCurrency =
                            originalPriceToDisplay?.currency !== euroPriceToDisplay?.currency;

                        if (!originalPriceToDisplay) return <></>;

                        return (
                            <>
                                <span>
                                    <PriceComponent
                                        price={euroPriceToDisplay}
                                        sx={(theme) => ({
                                            marginLeft: theme.spacing(1),
                                        })}
                                    />
                                </span>

                                {hasDifferentCurrency && (
                                    <PriceComponent
                                        sx={(theme) => ({
                                            marginLeft: theme.spacing(1),
                                        })}
                                        price={originalPriceToDisplay}
                                        inParenthesis
                                        grey
                                    />
                                )}

                                {hasAdditionalCosts(
                                    ticketsTotalOriginalPrice?.value,
                                    originalPrice?.value
                                ) && (
                                    <TotalPriceDifferencePopover
                                        text={
                                            <WarningAmberIcon
                                                color="warning"
                                                sx={{
                                                    fontSize: '1rem',
                                                    marginLeft: (theme) => theme.spacing(1),
                                                }}
                                            />
                                        }
                                        isEventPurchase={!!eventId}
                                        ticketsTotalOriginal={ticketsTotalOriginalPrice}
                                        purchaseTotalOriginal={originalPrice}
                                        ticketsTotalEuro={ticketsTotalEuroPrice}
                                        purchaseTotalEuro={euroPrice}
                                        eventTicketsTotalOriginalPrice={
                                            eventTicketsTotalOriginalPrice
                                        }
                                        eventTicketsTotalEuroPrice={eventTicketsTotalEuroPrice}
                                    />
                                )}
                            </>
                        );
                    };

                    return eventId
                        ? renderPrice(
                            eventTicketsTotalOriginalPrice ?? null,
                            eventTicketsTotalEuroPrice ?? null
                        )
                        : renderPrice(originalPrice, euroPrice);
                },
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            },
            {
                key: 'status',
                title: 'Status',
                cellRenderer: (purchase) => {
                    return (
                        <StatusLabel
                            text={purchase.status}
                            variant={getStatusChipColor(purchase.status)}
                        />
                    );
                },
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'externalReferenceId',
                title: 'Ext. Reference',
                cellRenderer: (purchase) => <span>{purchase.externalReferenceId}</span>,
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'paymentMethod',
                title: 'Payment Method',
                cellRenderer: (purchase) => {
                    if (!purchase.paymentMethod) return <></>;

                    return (
                        <span>
                            {purchase.paymentMethod?.type} ({purchase.paymentMethod?.creditCardInfo}
                            )
                        </span>
                    );
                },
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'numSplits',
                title: '# of splits',
                cellRenderer: (purchase) => <span>{purchase.numSplits}</span>,
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'notes',
                title: 'Notes',
                width: TABLE_CELL_WIDTH.LARGE,
                cellRenderer: (purchase) => {
                    const text = purchase.notes || '';

                    return (
                        <Popover text={text} isLink={false}>
                            <span>{text}</span>
                        </Popover>
                    );
                },
                truncate: true,
                truncateTotal: true,
            },
            {
                align: 'right',
                cellRenderer: (purchase) => (
                    <IconButton
                        onClick={() => onPurchaseEdit(purchase)}
                        color="primary"
                        size={'small'}
                    >
                        <EditIcon/>
                    </IconButton>
                ),
                width: TABLE_CELL_WIDTH.SMALL,
            },
        ],
        []
    );

    return (
        <Table<Purchase, Purchase>
            data={purchases}
            rowIdResolver={(rowData: Purchase) => ({
                id: rowData.id,
                data: {...rowData},
            })}
            rowProps={(rowData) => ({
                type:
                    rowData.status === PURCHASE_STATUS.Cancelled
                        ? TABLE_ROW_TYPE.danger
                        : TABLE_ROW_TYPE.default,
            })}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            onSortingChange={onSortingChange}
            highlightRow={({finalized}) => finalized}
            tableToolbarActions={tableToolbarActions}
            enableCheckboxes={true}
            initialSelectedRows={selectedRows}
            onChangeSelectedRows={onChangeSelectedRows}
        />
    );
}

function getEventTicketsUrl(eventId: string | undefined, purchaseId: string) {
    if (!eventId) return;
    return EventUrlService.tickets(eventId, {purchaseId});
}

export function getStatusChipColor(status: string) {
    if (status === PURCHASE_STATUS.Cancelled) return 'red';
    if (status === PURCHASE_STATUS.Completed) return 'green';
    if (status === PURCHASE_STATUS.Confirmed) return 'blue';

    return 'grey';
}
