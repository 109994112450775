import {useMutation, useQueryClient} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import purchaseService, {UpdatePurchaseDto} from 'src/data/services/purchaseService';
import {GET_PURCHASE_BY_ID_QUERY_KEY} from './useFetchPurchaseById';
import {PURCHASES_QUERY} from './useFetchPurchases';

type TData = Awaited<ReturnType<typeof purchaseService.updatePurchase>>;

export const useUpdatePurchase = (onSuccess?: (data: TData) => unknown) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({
                         purchaseId,
                         updatePurchaseDto,
                     }: {
            purchaseId: string;
            updatePurchaseDto: UpdatePurchaseDto;
        }) => purchaseService.updatePurchase(purchaseId, updatePurchaseDto),
        onSuccess: (data) => {
            onSuccess?.(data);
            Toaster.toast('Successfully updated the purchase!', {
                variant: 'success',
            });
            queryClient.invalidateQueries({queryKey: [GET_PURCHASE_BY_ID_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: [PURCHASES_QUERY]});
        },
    });
};
