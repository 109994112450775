import {useEffect, useMemo} from 'react';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import AutoComplete, {
    type AutoCompleteProps,
} from 'src/view/components/auto-complete/AutoComplete';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import useFetchEventBookingOptions from '../../features/event-booking-options/hooks/use-fetch-event-booking-options';

interface Props extends Omit<AutoCompleteProps, 'onCreateOption' | 'options' | 'loading'> {
    eventId: string;
    selectedBookingOptionCombinations?: {
        eventId: string;
        seatingPlanCategoryId: string;
        bookingOption: string[];
    }[];
    seatingPlanCategoryId?: string;
    autoSelectSingleOption?: boolean;
}

export const BookingOptionsAutoComplete = ({
                                               eventId,
                                               value,
                                               selectedBookingOptionCombinations,
                                               seatingPlanCategoryId,
                                               autoSelectSingleOption = true,
                                               ...autocompleteProps
                                           }: Props) => {
    const {data, isLoading, isError, error} = useFetchEventBookingOptions(eventId);
    const currentCombo = selectedBookingOptionCombinations?.find(
        (item) => item.eventId === eventId && item.seatingPlanCategoryId === seatingPlanCategoryId
    );

    const filteredOptions = useMemo(() => {
        return (data?.data.data ?? [])
            .map((item) => ({
                label: item.name,
                value: item.id,
            }))
            .filter((item) => !currentCombo?.bookingOption.includes(item.value));
    }, [data, currentCombo]);

    useEffect(() => {
        if (!autoSelectSingleOption) return;

        // If there is only one option, select it
        // Can also be passed from the parent component as defaultValue, but this approach works, and is simpler
        if (!isError && filteredOptions.length === 1 && !isLoading) {
            autocompleteProps.onChange(filteredOptions[0]);
        }
    }, [filteredOptions, isError, isLoading]);

    if (isError) {
        return <ErrorsList errors={parseErrors(error)}/>;
    }

    return (
        <AutoComplete
            loading={isLoading}
            disabled={isLoading}
            value={value}
            options={filteredOptions}
            isClearable={filteredOptions.length > 1} // Only show the clear button if there are multiple options
            {...autocompleteProps}
        />
    );
};
