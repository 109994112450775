import {useMutation, useQueryClient} from '@tanstack/react-query';
import CreateTeamDto from 'src/data/dtos/CreateTeamDto';
import {updateTeam} from 'src/data/services/teamsService';
import {TEAMS_QUERY_KEY} from './useFetchTeams';
import Toaster from 'src/app/utilities/helpers/Toaster';

type TVariables = {
    id: string;
    dto: CreateTeamDto;
};

export const useUpdateTeam = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({id, dto}: TVariables) => updateTeam(id, dto),
        onSuccess: () => {
            queryClient.invalidateQueries([TEAMS_QUERY_KEY]);
            Toaster.toast('Successfully updated the team.', {
                variant: 'success',
            });
            onSuccess?.();
        },
    });
};
