import {useState, useLayoutEffect} from 'react';
import {History} from 'history';
import {Router} from 'react-router-dom';

export interface RouteComponentProps {
    history: History;
    children: React.ReactNode;
}

export default function CustomRouter({history, ...props}: RouteComponentProps) {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
}
