import {useCallback, useMemo, useRef, useState} from 'react';
import {Grid} from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {ETBContainer} from 'src/view/components/container/container';
import CreateTeamFormFeature from 'src/app/components/features/teams/CreateTeamFormFeature';
import {Team} from 'src/data/models/team/team';
import {useForm} from 'react-hook-form';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import Button from 'src/view/components/button/Button';
import {useFetchTeams} from 'src/app/hooks/teams/useFetchTeams';
import {config} from 'src/app/constants/config/config';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import TeamsTable from 'src/app/components/tables/TeamsTable';
import {useDeleteTeam} from 'src/app/hooks/teams/useDeleteTeam';
import {useConfirm} from 'material-ui-confirm';

export default function TeamsOverview() {
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const activeTeam = useRef<Team | undefined>();
    const confirm = useConfirm();

    const {
        values: {customFields},
        setCustomFields,
    } = useQueryParams('teams');

    const arrayOfFilters: FiltersArray = useMemo(
        () => [
            {
                name: 'searchTerm',
                onChange: (q: string) =>
                    setCustomFields({
                        ...customFields,
                        q,
                    }),
                type: 'search',
                searchPlaceholder: 'Search on team name',
                searchDefaultValue: customFields['q'] || '',
                label: 'Search',
            },
        ],
        [customFields['q']]
    );

    const {control} = useForm({mode: 'onChange', defaultValues: customFields});

    const {data, isLoading} = useFetchTeams({
        q: customFields.q || '',
        page: Number(customFields['page'] || 1),
        pageSize: config.ITEMS_PER_PAGE_STANDARD,
    });

    const {mutate: deleteTeam} = useDeleteTeam();

    const teamsTablePaginationData = useMemo(() => {
        return {
            currentPage: data?.data?.meta.currentPage || 1,
            totalPages: data?.data.meta.totalPages || 1,
            onPaginate: (page: number) =>
                setCustomFields({...customFields, page: page.toString()}),
        };
    }, [data]);

    const onCloseModal = useCallback(() => {
        setCreateModalOpen(false);
        activeTeam.current = undefined;
    }, []);

    const onClickEdit = useCallback((t: Team) => {
        activeTeam.current = t;
        setCreateModalOpen(true);
    }, []);

    const onClickDelete = useCallback(async (teamId: Team['id']) => {
        try {
            await confirm({
                title: 'Delete team',
                description:
                    'Are you sure you want to delete this team? This action cannot be undone.',
            });
            deleteTeam([teamId]);
        } catch {
            /*
                This error state is being handled inside useDeleteTeam hook.
                This block prevents from unhandled exception
             */
        }
    }, []);

    return (
        <>
            <Header GridProps={{justifyContent: 'flex-end'}}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateModalOpen(true)}>
                        Add New Team
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <Filters control={control} filters={arrayOfFilters}/>
                <>
                    {isLoading && <LoadingOverlay/>}
                    <TableHeaderTotalFeature title="Teams" totalResults={data?.data.meta.total}/>

                    <TeamsTable
                        teams={data?.data.data || []}
                        loading={isLoading}
                        pagination={teamsTablePaginationData}
                        onDeleteTeam={onClickDelete}
                        onEditTeam={onClickEdit}
                    />
                </>
            </ETBContainer>

            {isCreateModalOpen && (
                <Modal
                    title={activeTeam.current ? `Edit ${activeTeam.current.name}` : 'Add new team'}
                    open={isCreateModalOpen}
                    onClose={onCloseModal}
                >
                    <CreateTeamFormFeature
                        onCreateOrUpdateTeamSuccess={onCloseModal}
                        team={activeTeam.current}
                    />
                </Modal>
            )}
        </>
    );
}
