import useQueryParams from 'src/app/hooks/useQueryParams';
import {SplitFiltersFormFeature} from 'src/app/components/features/splits/SplitFiltersFormFeature';
import {FetchSplitsDataWrapper} from 'src/app/components/data-wrappers/splits/FetchSplitsDataWrapper';
import SplitOverviewTable from 'src/app/components/tables/SplitOverviewTable';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import {splitsDefaultFilters} from 'src/app/pages/events/eventUrlService';
import {config} from 'src/app/constants/config/config';

export interface OrderLinesFeatureProps {
    eventId: string;
    invalidatedAt?: number;
}

export default function SplitTableFeature({
                                              eventId,
                                              invalidatedAt,
                                          }: OrderLinesFeatureProps): JSX.Element {
    const {
        values: {filterOptions, customFields, sortingOptions},
        setFilterOptions,
        setCustomFields,
        setSortingOptions,
    } = useQueryParams('splits', {
        defaultFilters: splitsDefaultFilters,
    });

    return (
        <>
            <SplitFiltersFormFeature
                eventId={eventId}
                initialOptions={filterOptions}
                onChangeFilterOptions={setFilterOptions}
                initialSearchTerm={customFields.q}
                onChangeSearchTerm={(q: string) => {
                    setCustomFields({
                        ...customFields,
                        q: q?.length > 0 ? q : '',
                    });
                }}
            />
            <FetchSplitsDataWrapper
                eventId={eventId}
                filter={filterOptions}
                sorting={sortingOptions.map(tableSortingToSortingOptionMapper)}
                q={customFields.q}
                page={Number(customFields['page'] || 1)}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
                invalidatedAt={invalidatedAt}
            >
                {({loading, data}) => (
                    <>
                        {loading && <LoadingOverlay/>}
                        <TableHeaderTotalFeature title="Splits" totalResults={data?.meta.total}/>
                        <SplitOverviewTable
                            eventId={eventId}
                            splits={data?.data || []}
                            loading={loading}
                            onChangeSorting={(sortings) => setSortingOptions(sortings)}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({...customFields, page: page.toString()}),
                            }}
                        />
                    </>
                )}
            </FetchSplitsDataWrapper>
        </>
    );
}
