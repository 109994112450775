import {type Moment} from 'moment';
import {getInternalAxiosInstance} from '../api/api';
import ApiResponseBody from '../api/responses/ApiResponseBody';
import {
    BOOKING_OPTIONS_CREATE_BOOKING_OPTION,
    BOOKING_OPTIONS_DELETE_BOOKING_OPTION,
    BOOKING_OPTIONS_GET_BOOKING_OPTION,
    BOOKING_OPTIONS_GET_EVENT_BOOKING_OPTIONS,
    BOOKING_OPTIONS_UPDATE_BOOKING_OPTION,
} from '../constants/endpoints/booking-options-endpoints';

export interface BookingOptionFormFields {
    name: string;
    startDate: Date | Moment;
    endDate: Date | Moment | null;
    isDefault: boolean;
}

export interface BookingOptionType extends BookingOptionFormFields {
    id: string;
    eventId: string;
}

export async function fetchEventBookingOptions(eventId: string) {
    return await getInternalAxiosInstance().get<ApiResponseBody<BookingOptionType[]>>(
        BOOKING_OPTIONS_GET_EVENT_BOOKING_OPTIONS(eventId)
    );
}

export async function createBookingOption(eventId: string, payload: BookingOptionFormFields[]) {
    return await getInternalAxiosInstance().post<ApiResponseBody<BookingOptionType>>(
        BOOKING_OPTIONS_CREATE_BOOKING_OPTION(eventId),
        {
            bookingOptions: payload,
        }
    );
}

export async function fetchBookingOption(id: string) {
    return await getInternalAxiosInstance().post<ApiResponseBody<BookingOptionType>>(
        BOOKING_OPTIONS_GET_BOOKING_OPTION(id)
    );
}

export async function updateBookingOption(id: string, payload: BookingOptionFormFields) {
    return await getInternalAxiosInstance().post<ApiResponseBody<BookingOptionType>>(
        BOOKING_OPTIONS_UPDATE_BOOKING_OPTION(id),
        payload
    );
}

export async function deleteBookingOption(id: string) {
    return await getInternalAxiosInstance().post<ApiResponseBody<BookingOptionType>>(
        BOOKING_OPTIONS_DELETE_BOOKING_OPTION(id)
    );
}
