import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Alert, Box, CircularProgress} from '@mui/material';
import Tab from '@mui/material/Tab';
import {useCallback, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {OptionTypeBase} from 'react-select';
import {useFetchSeatsDataWrapper} from 'src/app/components/data-wrappers/event/FetchEventSeatsDataWrapper';
import {useBatchEditTicketsDataWrapper} from 'src/app/components/data-wrappers/tickets/BatchEditTicketsDataWrapper';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import {BatchEditTicketFormFeature} from 'src/app/components/features/tickets/BatchEditTicketFormFeature';
import {BatchEditTicketsFormValues as BatchEditFormVales} from 'src/app/components/forms/tickets/batch-edit-tickets/BatchEditTicketsForm';
import BatchEditTicketsTable from 'src/app/components/tables/BatchEditTicketsTable';
import {TicketsTableDataResolver} from 'src/app/components/tables/TicketsTable';
import Price from 'src/data/models/common/price';
import {BatchEditTicketDto} from 'src/data/services/ticketService';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import {RowIdResolver} from 'src/view/components/table/table/Table';

interface BatchEditTicketFeatureProps {
    eventId: string;
    selectedTickets: RowIdResolver<TicketsTableDataResolver>[];
    onSuccess?: () => void;
}

export interface TicketFormModel {
    id: string;
    blockNumber?: AutoCompleteOption | null;
    rowNumber?: AutoCompleteOption | null;
    seatNumber?: string | null;
    purchasePrice?: Price | null;
    seatingplanCategoryId: string;
    seatingplanCategoryName?: string;
}

export interface BatchEditTicketsFormValues {
    tickets: TicketFormModel[];
}

enum BulkActionTabs {
    SEATING = 'SEATING',
}

export const BatchEditTicketFeature = ({
                                           selectedTickets,
                                           eventId,
                                           onSuccess,
                                       }: BatchEditTicketFeatureProps) => {
    const [seatsInvalidatedAt, setSeatsInvalidatedAt] = useState<number | undefined>();

    const {data} = useFetchSeatsDataWrapper({eventId, invalidatedAt: seatsInvalidatedAt});

    const [selectedTicketsToEdit, setSelectedTicketsToEdit] = useState<RowIdResolver[]>(
        selectedTickets.map((t) => ({
            id: t.id,
        }))
    );

    const {loading: batchEditIsLoading, batchEditTickets} = useBatchEditTicketsDataWrapper({
        onSuccess: () => {
            setSelectedTicketsToEdit([]);
            onSuccess?.();
        },
    });

    const [bulkActionTab, setBulkActionTab] = useState<string>(BulkActionTabs.SEATING);

    const defaultValues = useMemo(() => {
        return {
            tickets: selectedTickets.map((t) => {
                return {
                    id: t.id,
                    blockNumber: {value: t.data?.blockId, label: t.data?.blockNumber},
                    rowNumber: {value: t.data?.rowId, label: t.data?.rowNumber},
                    seatNumber: t.data?.seatNumber ?? undefined,
                    purchasePrice: t.data?.purchasePrice,
                    seatingplanCategoryName: t.data?.seatingplanCategoryName,
                    seatingplanCategoryId: t.data?.seatingplanCategoryId || '',
                };
            }),
        };
    }, [selectedTickets]);

    const form = useForm<BatchEditTicketsFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const {
        watch,
        setValue,
        reset,
        handleSubmit,
        formState: {isDirty},
    } = form;

    const formTickets = watch('tickets');

    const onFormSubmit = async (values: BatchEditTicketsFormValues) => {
        const dto: BatchEditTicketDto[] = values.tickets.map((t) => ({
            ticketId: t.id,
            block: t.blockNumber?.label,
            row: t.rowNumber?.label,
            seat: t.seatNumber,
        }));

        batchEditTickets(dto);
    };

    const handleTabChange = useCallback((newValue: string) => {
        setBulkActionTab(newValue);
    }, []);

    const handleChangeTicketSeating = useCallback(
        (values: BatchEditFormVales) => {
            const selectedTicketsToEditMap = new Map(
                selectedTicketsToEdit.map((item, index) => [item.id, index])
            );
            const newFormValues: BatchEditTicketsFormValues = {
                tickets: selectedTickets.map((_, i) => {
                    const currentIndex = selectedTicketsToEditMap.get(formTickets[i].id);

                    const {id, purchasePrice, seatingplanCategoryId, seatingplanCategoryName} =
                        formTickets[i];

                    /** If the ticket is not selected, do not edit any values and simply return it
                     * Checking for 'undefined' because we are using .get() from Map,
                     * which returns undefined and not '-1'
                     */
                    if (currentIndex === undefined) {
                        return formTickets[i];
                    }

                    /**
                     * If step = 5, currentIndex = 3, and startSeat = 10
                     * 5 * 3 + 10 = 15 + 10 = 25
                     */
                    const seatNumber = values.startSeat
                        ? (values.step || 1) * currentIndex + values.startSeat
                        : null;

                    return {
                        id,
                        purchasePrice,
                        seatingplanCategoryName,
                        seatingplanCategoryId,
                        blockNumber: values.blockNumber,
                        rowNumber: values.rowNumber,
                        seatNumber: seatNumber?.toString(),
                    };
                }),
            };

            setValue('tickets', newFormValues.tickets, {shouldDirty: true});
        },
        [formTickets, selectedTickets, selectedTicketsToEdit, setValue]
    );

    const hasDifferentSeatingplanCategories = useMemo(() => {
        return formTickets.some(
            (t) => t.seatingplanCategoryId !== formTickets[0].seatingplanCategoryId
        );
    }, [formTickets]);

    const onChangeBlock = useCallback(
        (ticketIndex: number, value: OptionTypeBase | null) => {
            setValue(`tickets.${ticketIndex}.blockNumber`, value, {shouldDirty: true});

            // Clean up rowNumber and seatNumber if the blockNumber is empty
            if (!value) {
                setValue(`tickets.${ticketIndex}.rowNumber`, null, {shouldDirty: true});
                setValue(`tickets.${ticketIndex}.seatNumber`, null, {shouldDirty: true});
            }
        },
        [setValue]
    );

    const onChangeRow = useCallback(
        (ticketIndex: number, value: OptionTypeBase | null) => {
            setValue(`tickets.${ticketIndex}.rowNumber`, value, {shouldDirty: true});

            // Clean up seatNumber if the rowNumber is empty
            if (!value) {
                setValue(`tickets.${ticketIndex}.seatNumber`, null, {shouldDirty: true});
            }
        },
        [setValue]
    );

    const onChangeSeat = useCallback(
        (ticketIndex: number, value: string) => {
            setValue(`tickets.${ticketIndex}.seatNumber`, value, {shouldDirty: true});
        },
        [setValue]
    );

    const onActionSuccess = useCallback(() => {
        setSeatsInvalidatedAt(Date.now());
    }, []);

    const seats = useMemo(() => data?.data || [], [data]);

    return (
        <>
            <TableHeaderTotalFeature title="Tickets" totalResults={formTickets.length}/>
            <BatchEditTicketsTable
                seats={seats}
                tickets={formTickets}
                onChangeBlock={onChangeBlock}
                onChangeRow={onChangeRow}
                onChangeSeat={onChangeSeat}
                onChangeSelectedRows={(selectedRows) => setSelectedTicketsToEdit(selectedRows)}
                initialSelectedRows={selectedTicketsToEdit}
                onActionSuccess={onActionSuccess}
                enableCheckboxes={!hasDifferentSeatingplanCategories}
            />

            {hasDifferentSeatingplanCategories ? (
                <Alert severity="info">
                    The selected tickets have multiple seatingplan categories, so bulk actions
                    cannot be done
                </Alert>
            ) : (
                <Box
                    sx={{
                        marginTop: (theme) => theme.spacing(4),
                    }}
                >
                    <TableHeader title="Bulk action"/>
                    <TabContext value={bulkActionTab}>
                        <TabList
                            onChange={(_, value) => handleTabChange(value)}
                            aria-label="basic tabs example"
                        >
                            <Tab label="SEATING" value={BulkActionTabs.SEATING}/>
                        </TabList>
                        <TabPanel
                            value={BulkActionTabs.SEATING}
                            sx={{
                                marginTop: (theme) => theme.spacing(2),
                            }}
                        >
                            <BatchEditTicketFormFeature
                                seats={data?.data || []}
                                onSubmit={handleChangeTicketSeating}
                                seatingplanCategoryId={formTickets[0].seatingplanCategoryId}
                                onBlockCreated={() => setSeatsInvalidatedAt(Date.now())}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>
            )}

            <FormButtons
                buttons={[
                    {
                        children: 'Reset',
                        onClick: () => reset(defaultValues),
                        variant: 'text',
                        disabled: batchEditIsLoading || !isDirty,
                    },
                    {
                        children: 'Confirm changes',
                        onClick: handleSubmit((values) => {
                            setValue('tickets', values.tickets);
                            onFormSubmit(values);
                        }),
                        disabled: batchEditIsLoading || !isDirty,
                        startIcon: batchEditIsLoading && <CircularProgress size="1rem"/>,
                    },
                ]}
            />
        </>
    );
};
