import BigNumber from 'bignumber.js';
import {formatDecimals} from './formatDecimals';

const calculatedExchangeRate = (price: string | number = 0, exchangeRate: string | number = 1) => {
    const rate = new BigNumber(exchangeRate);
    const priceToConvert = new BigNumber(price);

    if (rate.isNaN() || priceToConvert.isNaN()) return 'Invalid price';

    const calculatedPrice = priceToConvert.toNumber() * rate.toNumber();

    return formatDecimals(calculatedPrice);
};

export default calculatedExchangeRate;
