import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const ORGANIZERS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/organizers`;

export const ORGANIZER_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/organizers/create`;

export const ORGANIZERS_GET_BY_ID_ENDPOINT = (organizerId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/organizers/${organizerId}`;

export const ORGANIZERS_UPDATE_ENDPOINT = (organizerId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/organizers/${organizerId}/update`;

export const ORGANIZERS_DELETE_ENDPOINT = (organizerId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/organizers/${organizerId}/delete`;
