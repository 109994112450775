import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import {type TicketFileStatus} from 'src/data/models/tickets/unprocessedTicketFile';
import CheckIcon from 'src/view/icons/CheckIcon';

export const getStatusIndicators = (status: TicketFileStatus) => {
    switch (status) {
        case 'Linked':
            return {
                color: 'green',
                icon: CheckIcon,
            };
        case 'NotLinked':
            return {
                color: 'orange',
                icon: WarningIcon,
            };
        default:
            return {
                color: 'red',
                icon: CancelIcon,
            };
    }
};
