import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import _ from 'lodash';
import {useConfirm} from 'material-ui-confirm';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {
    mapAdvancedEditPurchaseDetailsFormToDto,
    mapEditPurchaseFormValuesToDto,
    mapPurchasePricesDataToEditPurchasePricesForm,
} from 'src/app/utilities/mappers/edit-purchase/editPurchaseMappers';
import mapPurchaseDataToViewModel from 'src/app/utilities/mappers/mapPurchaseDataToFormValues';
import purchaseService, {UpdatePurchaseDto, UpdatePurchasePriceDto,} from 'src/data/services/purchaseService';
import EditPurchasePricesForm, {EditPurchasePriceFormValuesProps, EditPurchasePricesFormSubmitValues,} from '../../forms/purchase/EditPurchasePricesForm';
import {AdvancedEditPurchaseDetailsForm, AdvancedEditPurchaseFormValues,} from './AdvancedEditPurchaseDetailsForm';
import PostEditPurchaseFollowUpModal from './PostEditPurchaseFollowUpModal';
import PurchaseTicketsTableFeature, {PURCHASE_TICKETS_QUERY} from './PurchaseTicketsTableFeature';

export const PURCHASE_PRICES_QUERY = 'purchase_prices';

const AdvancedEditPurchaseFormFeature = () => {
    const [searchParams] = useSearchParams();
    const confirm = useConfirm();
    const purchaseId = searchParams.get('purchaseId') || '';
    const queryClient = useQueryClient();

    const [followUpModalData, setFollowUpModalData] = useState<EditPurchasePriceFormValuesProps[]>(
        []
    );
    const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);

    const {data: purchaseResponseData} = useQuery({
        queryKey: ['purchase', purchaseId],
        queryFn: async () => purchaseService.fetchPurchaseById(purchaseId),
        enabled: !!purchaseId,
    });

    const {data: purchasePricesData, isLoading: isPurchasePricesLoading} = useQuery({
        queryKey: [PURCHASE_PRICES_QUERY, purchaseId],
        queryFn: async () => purchaseService.fetchPurchasePrices(purchaseId),
        enabled: !!purchaseId,
    });

    const {mutate: updatePurchasePrices, isLoading: isUpdatingPurchasePrices} = useMutation({
        mutationKey: ['update_purchase_prices'],
        mutationFn: (dto: UpdatePurchasePriceDto) =>
            purchaseService.updatePurchasePrice(purchaseId, dto),
        onSuccess: () => {
            Toaster.toast('Successfully updated the purchase prices!', {
                variant: 'success',
            });
            queryClient.invalidateQueries({queryKey: [PURCHASE_PRICES_QUERY]});
            queryClient.invalidateQueries({queryKey: [PURCHASE_TICKETS_QUERY]});
            setIsFollowUpModalOpen(true);
        },
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err));
        },
    });

    const {mutate: updatePurchaseDetails, isLoading: isUpdatingPurchaseDetails} = useMutation({
        mutationKey: ['update_purchase_details'],
        mutationFn: (dto: UpdatePurchaseDto) => purchaseService.updatePurchase(purchaseId, dto),
        onSuccess: () => {
            Toaster.toast('Successfully updated the purchase details!', {
                variant: 'success',
            });
        },
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err));
        },
    });

    function handleSubmitEditPurchaseDetails(values: AdvancedEditPurchaseFormValues) {
        const dto = mapAdvancedEditPurchaseDetailsFormToDto(values);

        updatePurchaseDetails(dto);
    }

    async function handleEditPurchaseSubmission(values: EditPurchasePricesFormSubmitValues) {
        try {
            await confirm({
                title: 'Are you sure you want update the purchase prices?',
                description: 'This will save the changes, but you can update them later again.',
            });

            const dto = mapEditPurchaseFormValuesToDto(values);
            setFollowUpModalData(_.uniqBy(values.SeatingPlanCategoryBookingOptionPrices, 'eventId'));

            updatePurchasePrices(dto);
        } catch (err) {
            /* Empty */
        }
    }

    return (
        <>
            {purchaseResponseData && (
                <AdvancedEditPurchaseDetailsForm
                    defaultValues={mapPurchaseDataToViewModel(purchaseResponseData?.data.data)}
                    onSubmit={handleSubmitEditPurchaseDetails}
                    loading={isUpdatingPurchaseDetails}
                />
            )}
            {purchasePricesData && !isPurchasePricesLoading && purchaseResponseData && (
                <EditPurchasePricesForm
                    purchasePrices={mapPurchasePricesDataToEditPurchasePricesForm(
                        purchasePricesData.data.data
                    )}
                    onSubmit={handleEditPurchaseSubmission}
                    isSubmitDisabled={isUpdatingPurchasePrices}
                    isLoading={isUpdatingPurchasePrices}
                />
            )}
            {purchasePricesData && (
                <PurchaseTicketsTableFeature
                    purchaseId={purchaseId}
                    purchasePrices={mapPurchasePricesDataToEditPurchasePricesForm(
                        purchasePricesData.data.data
                    )}
                />
            )}
            {isFollowUpModalOpen && (
                <PostEditPurchaseFollowUpModal
                    seatingPlanCategories={followUpModalData}
                    purchaseId={purchaseId}
                    isOpen={isFollowUpModalOpen}
                    onCloseModal={() => setIsFollowUpModalOpen(false)}
                />
            )}
        </>
    );
};

export default AdvancedEditPurchaseFormFeature;
