import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderlineService, {AutoAssignOptionsResponse} from 'src/data/services/orderlineService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';

interface FetchAutoAssignResultDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<AutoAssignOptionsResponse>> {
    fetchAutoAssignFormOptions: (
        eventId: string
    ) => Promise<ApiResponseBody<AutoAssignOptionsResponse>>;
}

type FetchAutoAssignFormOptionsDataWrapperProps = DataWrapperProps<
    FetchAutoAssignResultDataWrapperChildrenParams,
    ApiResponseBody<AutoAssignOptionsResponse>
> &
    BaseFetchOptions;

export const useFetchAutoAssignFormOptionsDataWrapper = ({
                                                             onError,
                                                             onSuccess,
                                                         }: Omit<FetchAutoAssignFormOptionsDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleFetchAutoAssignResult] =
        useApiFetch<ApiResponseBody<AutoAssignOptionsResponse>>();

    const fetchAutoAssignFormOptions = (eventId: string) => {
        return handleFetchAutoAssignResult(orderlineService.getAutoAssignOptions({eventId}), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        fetchAutoAssignFormOptions,
        data,
        loading,
        errors,
        resetData,
    };
};
