import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import VenuesTable from 'src/app/components/tables/VenuesTable';
import {config} from 'src/app/constants/config/config';
import {useFetchVenues} from 'src/app/hooks/venues/useFetchVenues';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

interface Props {
    q: string;
    page: number;
    onChangePage: (page: number) => void;
}

export default function VenuesTableFeature({q, page, onChangePage}: Props): JSX.Element {
    const {data, isLoading} = useFetchVenues({
        q,
        pageSize: config.ITEMS_PER_PAGE_STANDARD,
        page,
    });

    return (
        <>
            {isLoading && <LoadingOverlay/>}

            <TableHeaderTotalFeature title="Venues" totalResults={data?.data.meta.total}/>
            <VenuesTable
                venues={data?.data.data}
                loading={isLoading}
                pagination={{
                    currentPage: data?.data.meta.currentPage || 1,
                    totalPages: data?.data.meta.totalPages || 1,
                    onPaginate: onChangePage,
                }}
            />
        </>
    );
}
