import {useMutation, useQueryClient} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {deleteBookingOption} from 'src/data/services/booking-options-service';
import {EVENT_BOOKING_OPTIONS_QUERY_KEY} from './use-fetch-event-booking-options';

const useDeleteBookingOption = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => deleteBookingOption(id),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [EVENT_BOOKING_OPTIONS_QUERY_KEY],
            });
            onSuccess?.();
        },
        onError: (errors) => {
            Toaster.toastErrors(parseErrors(errors));
        },
    });
};

export default useDeleteBookingOption;
