import {makeStyles} from '@mui/styles';
import {BaseComponent} from 'src/view/interfaces/BaseComponent';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.colors.linkBlue,
        fontWeight: 'bold',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

interface LinkActionButtonProps extends BaseComponent {
    onClick: () => void;
}

export const LinkActionButton = ({onClick, children, className}: LinkActionButtonProps) => {
    const classes = useStyles();

    return (
        <div onClick={onClick} className={classnames(className, classes.text)}>
            {children}
        </div>
    );
};
