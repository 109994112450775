import {useEffect, useState} from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import Block from 'src/data/models/block/block';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    name: string;
    seatingPlanCategoryId: string;
    onChange: (option?: AutoCompleteOption) => void;
    value?: AutoCompleteOption;
    onSuccessfullyCreate?: (option: AutoCompleteOption) => void;
    disabled?: boolean;
}

export default function BlocksSelectDataWrapper({
                                                    name,
                                                    seatingPlanCategoryId,
                                                    onChange,
                                                    value,
                                                    onSuccessfullyCreate,
                                                    disabled,
                                                }: Props) {
    const [option, setOption] = useState<AutoCompleteOption | undefined>(value);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();

    useEffect(() => {
        setOption(value);
    }, [value]);

    const [{data}, handleFetchBlocks] = useApiFetch<PaginatedApiResponseBody<Block>>();

    useEffect(() => {
        if (!seatingPlanCategoryId || disabled) return;

        handleFetchBlocks(
            seatingPlanCategoryService.fetchBlocksBySeatingPlanCategory(seatingPlanCategoryId),
            {
                useDefaultErrorHandler: true,
            }
        );
    }, [seatingPlanCategoryId, invalidatedAt]);

    const blocksOptions = (): AutoCompleteOptions => {
        if (!data) return [];

        return data.data.map((block) => ({
            label: block.name,
            value: block.id,
        }));
    };

    const onCreateBlock = async (name: string) => {
        if (!seatingPlanCategoryId) return;

        try {
            const result = await seatingPlanCategoryService.createBlock(
                name,
                seatingPlanCategoryId
            );
            const createdSeatingPlan = {label: name, value: result.data.data.id};

            onSuccessfullyCreate?.(createdSeatingPlan);
            setInvalidatedAt(Date.now());
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AutoComplete
            name={name}
            value={option}
            options={blocksOptions()}
            onChange={(value) => {
                if (value === null) value = undefined;
                onChange(value);
            }}
            placeholder={
                !seatingPlanCategoryId
                    ? 'Select a seating plan category first (disabled)'
                    : 'select or create'
            }
            disabled={disabled || !seatingPlanCategoryId}
            onCreateOption={onCreateBlock}
            backgroundColor="white"
        />
    );
}
