import {useCallback, useState} from 'react';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {ETBContainer} from 'src/view/components/container/container';
import {FetchETicketsDataWrapper} from 'src/app/components/data-wrappers/ticket-management/FetchETicketsDataWrapper';
import TicketManagementTable from 'src/app/components/tables/TicketManagementTable';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import {useForm} from 'react-hook-form';
import {useFetchETicketDownloadLinkDataWrapper} from 'src/app/components/data-wrappers/ticket-management/FetchETicketDownloadLinkDataWrapper';
import {useFetchETicketFilesDownloadLinksDataWrapper} from 'src/app/components/data-wrappers/ticket-management/FetchETicketFilesDownloadLinksDataWrapper';
import {useTheme} from '@mui/styles';
import {CircularProgress} from '@mui/material';

export default function TicketManagementTableFeature(): JSX.Element {
    const theme = useTheme();
    const [selectedRows, setSelectedRows] = useState<RowIdResolver[]>([]);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();

    const {loading: downloadLinkLoading, fetchDownloadLink} =
        useFetchETicketDownloadLinkDataWrapper({});
    const {loading: filesDownloadLinksLoading, fetchETicketFilesDownloadLinks} =
        useFetchETicketFilesDownloadLinksDataWrapper({});

    const {
        values: {customFields},
        setCustomFields,
    } = useQueryParams('t-management');

    const form = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const {control} = form;

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q) =>
                setCustomFields({
                    ...customFields,
                    q: q.length > 0 ? q : '',
                }),
            type: "search",
            searchPlaceholder: 'Search (at least 3 characters)',
            searchDefaultValue: customFields['q'] || '',
            limit: 3,
            label: 'Search e-tickets',
        },
    ];

    const tableActionsCallback = useCallback(setSelectedRows, [selectedRows]);

    const downloadTicketFilesAction = (selectedRows: RowIdResolver[]) => ({
        label: 'Download files',
        disabled: selectedRows.length === 0 || filesDownloadLinksLoading,
        startIcon: filesDownloadLinksLoading && (
            <CircularProgress size={theme.layout.loader.sizes.small}/>
        ),
        callback: () => {
            tableActionsCallback(selectedRows);
            fetchETicketFilesDownloadLinks(
                selectedRows.map((row) => row.id),
                {
                    onSuccess: (data) => {
                        setSelectedRows([]);
                        setInvalidatedAt(Date.now());

                        if (data) {
                            window.open(data, '_blank');
                        }
                    },
                }
            );
        },
    });

    return (
        <ETBContainer>
            <>
                <Filters control={control} filters={arrayOfFilters}/>
                <FetchETicketsDataWrapper
                    invalidatedAt={invalidatedAt}
                    q={customFields.q}
                    page={Number(customFields['page'] || 1)}
                >
                    {({loading, data}) => (
                        <>
                            <TableHeaderTotalFeature
                                title="E-ticket Management"
                                totalResults={data?.meta.count}
                            />
                            <TicketManagementTable
                                parsedTickets={data?.data}
                                loading={loading || downloadLinkLoading}
                                initialSelectedRows={selectedRows}
                                onDownloadClick={(id: string) => {
                                    fetchDownloadLink(id, {
                                        onSuccess: (url) => window.open(url, '_blank'),
                                    });
                                }}
                                pagination={{
                                    currentPage: data?.meta.currentPage || 1,
                                    totalPages: data?.meta.totalPages || 1,
                                    onPaginate: (page: number) =>
                                        setCustomFields({
                                            ...customFields,
                                            page: page.toString(),
                                        }),
                                }}
                                tableToolbarActions={(selectedRows) => [
                                    downloadTicketFilesAction(selectedRows),
                                ]}
                            />
                        </>
                    )}
                </FetchETicketsDataWrapper>
            </>
        </ETBContainer>
    );
}
