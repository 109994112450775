import {useMutation} from '@tanstack/react-query';
import tagService from 'src/data/services/tagService';
import {type CreateTagDto} from 'src/data/dtos/createTagDto';

type TData = Awaited<ReturnType<typeof tagService.createTag>>;
type TVariables = CreateTagDto;

export const useCreateTag = (options: {
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown;
}) => {
    return useMutation({
        mutationFn: (dto: TVariables) => tagService.createTag(dto),
        ...options,
    });
};
