import {zodResolver} from '@hookform/resolvers/zod';
import {Grid} from '@mui/material';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {SeriesGroupsAsyncAutoCompleteDataWrapper} from 'src/app/components/data-wrappers/series-groups/SeriesGroupsAsyncAutoCompleteDataWrapper';
import {seriesStatusOptions} from 'src/app/components/forms/SerieDetailsForm';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import {zodRequiredStringSchema} from 'src/app/utilities/zod/zodRequiredStringSchema';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import z from 'zod';

export const CreateSeriesFormValidation = z.object({
    name: zodRequiredStringSchema,
    status: z
        .boolean()
        .optional()
        .refine((val) => val !== undefined, {
            message: 'Please select status',
        }),
    seriesGroup: zodRequiredDropdownSelectSchema,
});

export type CreateSeriesFormValues = z.infer<typeof CreateSeriesFormValidation>;

interface Props {
    loading?: boolean;
    defaultValues?: CreateSeriesFormValues;
    onSubmit: (values: CreateSeriesFormValues) => void;
}

export default function SerieCreateForm({loading, defaultValues, onSubmit}: Props) {
    const form = useForm<CreateSeriesFormValues>({
        mode: 'onChange',
        resolver: zodResolver(CreateSeriesFormValidation),
    });

    const {control, reset, handleSubmit} = form;

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    if (loading) return <LoadingOverlay/>;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel>Name*</FormLabel>
                    <Controller
                        name="name"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <Input
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Series name"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel>Status*</FormLabel>
                    <Controller
                        name="status"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <AutoComplete
                                        name={name}
                                        onChange={(option) => onChange(option?.value)}
                                        value={seriesStatusOptions.find((s) => s.value === value)}
                                        options={seriesStatusOptions}
                                        placeholder="Status"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel>Series Group*</FormLabel>

                    <Controller
                        name="seriesGroup"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <SeriesGroupsAsyncAutoCompleteDataWrapper
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: 'Create',
                        onClick: () => {
                            handleSubmit(onSubmit)();
                        },
                    },
                ]}
            />
        </>
    );
}
