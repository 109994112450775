import {useEffect, useState} from 'react';
import {ActionMeta} from 'react-select';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import supplierService from 'src/data/services/supplierService';
import AsyncAutoComplete from 'src/view/components/auto-complete/AsyncAutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    name: string;
    value?: AutoCompleteOption;
    onChange: (value: AutoCompleteValueOption, action?: ActionMeta<AutoCompleteOption>) => void;
    onSuccessfullyCreate?: (option: AutoCompleteOption) => void;
    disabled?: boolean;
}

export default function SupplierSelectDataWrapper({
                                                      name,
                                                      value,
                                                      onChange,
                                                      onSuccessfullyCreate,
                                                      disabled,
                                                  }: Props) {
    const [option, setOption] = useState<AutoCompleteOption | undefined>(value);

    useEffect(() => {
        setOption(value);
    }, [value]);

    const fetchSuppliersOptions = async (query: string): Promise<AutoCompleteOptions> => {
        if (disabled) return [];

        try {
            const result = await supplierService.getSuppliers({q: query});

            if (!result.data) return [];

            return result.data?.data.map((supplier) => ({
                label: supplier.name,
                value: supplier.id,
            }));
        } catch (e) {
            toastApiErrors(e);

            return [];
        }
    };

    const onCreateSupplier = async (name: string) => {
        try {
            const result = await supplierService.createSupplier({name});

            const id = result.data.data.id;
            const createdSupplier = {label: name, value: id};

            setOption(createdSupplier);
            onSuccessfullyCreate?.(createdSupplier);
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AsyncAutoComplete
            name={name}
            value={option}
            loadOptions={fetchSuppliersOptions}
            onChange={onChange}
            placeholder="Select a supplier"
            onCreateOption={onCreateSupplier}
            disabled={disabled}
        />
    );
}
