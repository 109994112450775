import {zodResolver} from '@hookform/resolvers/zod';
import {Grid} from '@mui/material';
import moment from 'moment';
import {Controller, useForm} from 'react-hook-form';
import EventAutoCompleteFeature from 'src/app/components/features/event/EventAutoCompleteFeature';
import {
    CreatePurchaseFormValues,
    type PurchaseEvent,
} from 'src/app/components/forms/purchase/CreatePurchaseForm';
import {FormProps} from 'src/app/interfaces/form/formProps';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import {dateFormatYearMonthDay} from 'src/shared/date';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import z from 'zod';

export interface SelectPurchaseEventFormValues {
    event: AutoCompleteOption;
}

const selectEventValidationSchema = (events: PurchaseEvent[]) =>
    z.object({
        event: zodRequiredDropdownSelectSchema.refine(
            (value) => {
                return !events.find((event) => event.event.value === value?.value);
            },
            {
                message: 'This event has already been added, please select another',
            }
        ),
    });

interface SelectPurchaseEventFormProps extends FormProps<SelectPurchaseEventFormValues> {
    events: CreatePurchaseFormValues['events'];
}

export const SelectPurchaseEventForm = ({
                                            defaultValues,
                                            onSubmit,
                                            events,
                                        }: SelectPurchaseEventFormProps) => {
    const {control, handleSubmit} = useForm<SelectPurchaseEventFormValues>({
        defaultValues,
        resolver: zodResolver(selectEventValidationSchema(events)),
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormLabel>Select an event</FormLabel>
                <Controller
                    name="event"
                    control={control}
                    render={({field: {name, value, onChange}, fieldState: {error}}) => (
                        <>
                            <EventAutoCompleteFeature
                                name={name}
                                placeholder="Select an event"
                                onChange={onChange}
                                value={value}
                                filter={[
                                    {
                                        property: 'dateTimeStart',
                                        operation: 'afterDate',
                                        value: moment().format(dateFormatYearMonthDay),
                                    },
                                ]}
                            />

                            <FormFieldError message={error?.message}/>
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <FormButtons
                    buttons={[
                        {
                            children: 'Select event',
                            onClick: handleSubmit(onSubmit),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};
