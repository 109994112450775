import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import SupplierSelectDataWrapper from 'src/app/components/data-wrappers/inputs/SupplierSelectDataWrapper';
import {FormProps} from 'src/app/interfaces/form/formProps';
import {isCreditCardMethod} from 'src/app/utilities/helpers/paymentMethod';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import {paymentMethodOptions} from 'src/app/utilities/mappers/mapPaymentMethodsToOptions';
import {zodCreateOrEditPurchaseValidationSchema} from 'src/app/utilities/zod/purchase/zodCreateOrEditPurchaseValidationSchema';
import {PURCHASE_TYPES, PURCHASE_TYPE_ITEMS} from 'src/data/enums/purchase';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import DatePicker from 'src/view/components/date-picker/DatePicker';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import {FormLabel} from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(2),
    },
    todaysDateWrapper: {
        marginTop: theme.spacing(1),
    },
    todaysDate: {
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

interface PurchaseEventTicketInfo {
    quantity: number;
    seatingplanCategory: AutoCompleteOption;
    price: string;
    ticketsPerSplit: number;
}

export interface PurchaseEvent {
    event: AutoCompleteOption;
    ticketInfo?: PurchaseEventTicketInfo[];
}

export interface AdvancedEditPurchaseFormValues {
    supplier: AutoCompleteOption;
    supplierAccountUsername?: string;
    purchaseDate: Date;
    currency: AutoCompleteOption;
    externalReference?: string;
    paymentMethod?: AutoCompleteOption | null;
    creditcardNumber?: string;
    internalNotes?: string;
    foreignPriceExchangeRate: string;
    finalized?: boolean;
    purchaseType?: AutoCompleteOption;
    paymentMethodPresent?: boolean;
}

export const AdvancedEditPurchaseDetailsForm = ({
                                                    defaultValues,
                                                    onSubmit,
                                                    loading,
                                                }: FormProps<AdvancedEditPurchaseFormValues>) => {
    const classes = useStyles();
    const theme = useTheme();
    const spacingStyles = useSpacingStyles();

    const {
        control,
        watch,
        handleSubmit,
        formState: {isDirty},
    } = useForm<AdvancedEditPurchaseFormValues>({
        mode: 'onChange',
        defaultValues: {
            currency: currencyOptions[0],
            ...defaultValues,
        },
        resolver: zodResolver(zodCreateOrEditPurchaseValidationSchema),
    });
    const paymentMethod = watch('paymentMethod');

    return (
        <Grid container className={classes.container} alignItems="start">
            <Grid
                item
                container
                lg={3}
                spacing={1}
                justifyContent="stretch"
                direction="column"
                rowSpacing={3}
            >
                <Grid item>
                    <h3 className={classnames(spacingStyles.noMarginBottom)}>Details</h3>
                </Grid>
                <Grid item>
                    <FormLabel>Supplier</FormLabel>
                    <Controller
                        name="supplier"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <SupplierSelectDataWrapper
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        disabled
                                        onSuccessfullyCreate={(option) => onChange(option)}
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormLabel>Supplier account username</FormLabel>
                    <Controller
                        name="supplierAccountUsername"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <Input
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        disabled
                                        placeholder="Enter a supplier account username"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormLabel>Purchase date</FormLabel>
                    <Controller
                        name="purchaseDate"
                        control={control}
                        render={({field: {value}, fieldState: {error}}) => (
                            <>
                                <DatePicker
                                    placeholder="Purchase date"
                                    value={value || null}
                                    disabled
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item>
                    <FormLabel>Currency</FormLabel>
                    <Controller
                        name="currency"
                        control={control}
                        render={({field: {name, value, onChange}, fieldState: {error}}) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    placeholder="Select a currency"
                                    value={value || null}
                                    onChange={onChange}
                                    options={currencyOptions}
                                    isClearable={false}
                                    disabled
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                <Grid item>
                    <FormLabel>External Reference</FormLabel>
                    <Controller
                        name="externalReference"
                        control={control}
                        render={({field: {name, onChange, value}, fieldState: {error}}) => {
                            return (
                                <>
                                    <Input
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Enter a external reference"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            );
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormLabel>Payment Method</FormLabel>
                    <Controller
                        name="paymentMethod"
                        control={control}
                        render={({field: {name, value, onChange}, fieldState: {error}}) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    placeholder="Select a payment method"
                                    value={value || null}
                                    onChange={onChange}
                                    options={paymentMethodOptions}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>
                {paymentMethod && isCreditCardMethod(paymentMethod.value) && (
                    <Grid item>
                        <FormLabel>Creditcard Number (last 4 digits)*</FormLabel>
                        <Controller
                            name="creditcardNumber"
                            control={control}
                            render={({
                                         field: {name, value, onChange},
                                         fieldState: {error},
                                     }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Enter last 4 digits of creditcard"
                                    />
                                    <FormFieldError message={error?.message}/>
                                </>
                            )}
                        />
                    </Grid>
                )}
                <Grid item>
                    <FormLabel>Internal notes</FormLabel>
                    <Controller
                        name="internalNotes"
                        control={control}
                        render={({field: {name, value, onChange}, fieldState: {error}}) => (
                            <>
                                <Input
                                    multiline
                                    maxRows={5}
                                    minRows={5}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Enter internal notes"
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>

                <Grid item>
                    <FormLabel>Purchase Type</FormLabel>
                    <Controller
                        name="purchaseType"
                        control={control}
                        render={({field: {name, value, onChange}, fieldState: {error}}) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    placeholder="Select a purchase type"
                                    value={value || null}
                                    onChange={onChange}
                                    options={PURCHASE_TYPES.map((pt) => ({
                                        label: PURCHASE_TYPE_ITEMS[pt],
                                        value: pt,
                                    }))}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>

                <Grid item>
                    <Controller
                        name="finalized"
                        control={control}
                        render={({field: {name, value, onChange}, fieldState: {error}}) => (
                            <>
                                <FormLabel>
                                    Mark as finalized
                                    <Checkbox
                                        color="primary"
                                        name={name}
                                        checked={value || false}
                                        onChange={onChange}
                                    />
                                </FormLabel>

                                <FormFieldError message={error?.message}/>
                            </>
                        )}
                    />
                </Grid>

                <Grid item alignSelf="start">
                    <FormButtons
                        buttons={[
                            {
                                children: loading ? 'Loading...' : 'Save Changes',
                                onClick: handleSubmit((values) => onSubmit(values)),
                                color: 'info',
                                disabled: !isDirty || loading,
                                startIcon: loading && (
                                    <CircularProgress size={theme.layout.loader.sizes.small}/>
                                ),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
