import {makeStyles} from '@mui/styles';
import Price from 'src/data/models/common/price';
import {formatPrice} from 'src/shared/helpers/formatPrice';
import OrderPriceWarningPopover from '../pop-overs/orders/OrderPriceWarningPopover';

const useStyles = makeStyles(() => ({
    price: {
        display: 'flex',
    },
}));

interface OrderPriceProps {
    newSystemPrice: Price | null;
    oldSystemPrice: Price;
}

const OrderPrice = ({newSystemPrice, oldSystemPrice}: OrderPriceProps) => {
    const classes = useStyles();
    const price = newSystemPrice ?? oldSystemPrice;

    return (
        <p className={classes.price}>
            {formatPrice({
                currency: price.currency,
                amount: price.value || 0,
            })}
            {!newSystemPrice && <OrderPriceWarningPopover/>}
        </p>
    );
};

export default OrderPrice;
