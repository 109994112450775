import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const SUPPLIERS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/suppliers`;

export const SUPPLIERS_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/suppliers/create`;

export const SUPPLIERS_UPDATE_ENDPOINT = (supplierId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/suppliers/${supplierId}/update`;

export const SUPPLIERS_GET_BY_ID_ENDPOINT = (supplierId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/suppliers/${supplierId}`;

export const SUPPLIERS_ARCHIVE_ENDPOINT = (supplierId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/suppliers/${supplierId}/archive`;

export const SUPPLIERS_UNARCHIVE_ENDPOINT = (supplierId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/suppliers/${supplierId}/un-archive`;

export const SUPPLIERS_DELETE_ENDPOINT = (supplierId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/suppliers/${supplierId}/delete`;
