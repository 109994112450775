import {AxiosError} from 'axios';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';

export const mapApiErrors = (e: AxiosError<ErrorDetail>): ErrorDetail[] => {
    if (!e.response) {
        return [
            {
                code: 'FE_API_NO_RESPONSE',
                title: 'The API returned no response.',
                detail: `Message: ${e.message}`,
            },
        ];
    }

    if (!e.response.data?.errors && e.response.status) {
        return [
            {
                code: 'FE_API_ERROR_STATUS',
                title: `The API returned a ${e.response.status} status code.`,
                detail: `${e.response.statusText}`,
            },
        ];
    }

    if (Array.isArray(e.response.data?.errors)) {
        const errors = e.response.data.errors as ErrorDetail[];
        return errors
            .filter((errorDetail) => errorDetail.code && errorDetail.title)
            .map((errorDetail) => ({
                code: errorDetail.code,
                title: errorDetail.title,
                detail: errorDetail.detail,
                source: errorDetail.source,
            }));
    }

    return [
        {
            code: 'FE_API_UNKNOWN_ERROR',
            title: `An unknown error occurred in the API communication layer.`,
            detail: `Message: ${e.message}`,
        },
    ];
};
