import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import Ticket from 'src/data/models/tickets/ticket';
import ticketsService from 'src/data/services/ticketsService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';

interface FetchTicketByIdDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<Ticket>> {
    fetchTicketById: (ticketId: string) => Promise<ApiResponseBody<Ticket>>;
}

type FetchTicketByIdDataWrapperProps = DataWrapperProps<
    FetchTicketByIdDataWrapperChildrenParams,
    ApiResponseBody<Ticket>
> &
    BaseFetchOptions;

export const useFetchTicketByIdDataWrapper = ({
                                                  onError,
                                                  onSuccess,
                                              }: Omit<FetchTicketByIdDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleFetchTickets] =
        useApiFetch<ApiResponseBody<Ticket>>();

    const fetchTicketByIdCall = (id: string) => {
        return handleFetchTickets(ticketsService.fetchTicketById(id), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        fetchTicketById: fetchTicketByIdCall,
        data,
        loading,
        errors,
        resetData,
    };
};
