import {NestedKeyOf} from 'src/shared/utils';

export enum TABLE_CELL_WIDTH {
    TINY = 50,
    SMALL = 100,
    MEDIUM = 150,
    MEDIUM_LARGE = 175,
    LARGE = 200,
    EXTRA_LARGE = 250,
    MEGA = 275,
}

export enum TABLE_CELL_HEIGHT {
    NONE,
    SMALL,
    DEFAULT,
    LARGE,
    EXTRA_LARGE,
}

export enum TABLE_ROW_TYPE {
    'default',
    'danger',
}

export interface TablePaginationData {
    currentPage: number;
    totalPages: number;
    onPaginate: (page: number) => void;
}

export interface NewTableColumn<TData> {
    key?: NestedKeyOf<TData>;
    sortingKey?: string;
    title?: string | JSX.Element;
    columnHeader?: string | JSX.Element;
    isSortable?: boolean;
    cellRenderer?: (
        rowData: TData,
        previousRow?: TData,
        rowIndex?: number
    ) => number | string | JSX.Element;
    width?: React.CSSProperties['width'];
    colored?: {
        mode: 'normal' | 'all';
    };
    titleAlign?: 'left' | 'right' | 'center';
    align?: 'left' | 'right' | 'center';
    total?: string | number | JSX.Element;
    truncate?: boolean;
    truncateTotal?: boolean;
    hidden?: boolean;
}

export type TableColumnSortingDirection = 'asc' | 'desc' | null;
export type TableColumnSorting = {
    column: string;
    direction: TableColumnSortingDirection;
};
