import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CircularProgress} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import organizerService from 'src/data/services/organizerService';
import FormButtons from 'src/view/components/form/FormButtons';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {QUERY_KEY as ORGANIZERS_QUERY_KEY} from 'src/app/hooks/useOrganizers';

const DeleteOrganizerFeature = ({
                                    organizerId,
                                    onSuccess,
                                }: {
    organizerId: string;
    onSuccess?: () => void;
}) => {
    const theme = useTheme();
    const queryClient = useQueryClient();
    const {mutate, isLoading, isError, error} = useMutation({
        mutationFn: (organizerId: string) => organizerService.deleteOrganizer(organizerId),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [ORGANIZERS_QUERY_KEY]});
            onSuccess?.();
        },
    });

    const onFormSubmit = () => mutate(organizerId);

    return (
        <>
            <p>Are you sure you want to delete the organizer?</p>
            {isError && <ErrorsList errors={parseErrors(error)}/>}
            <FormButtons
                buttons={[
                    {
                        children: 'Delete',
                        onClick: () => onFormSubmit(),
                        disabled: isLoading,
                        startIcon: isLoading && (
                            <CircularProgress size={theme.layout.loader.sizes.small}/>
                        ),
                    },
                ]}
            />
        </>
    );
};

export default DeleteOrganizerFeature;
