import {FetchPurchaseStatisticsDataWrapper} from 'src/app/components/data-wrappers/purchase/FetchPurchaseStatisticsDataWrapper';
import PurchaseStatisticsTable from 'src/app/components/tables/PurchaseStatisticsTable';
import {useState} from 'react';
import TablePagination from 'src/view/components/table-pagination/TablePagination';
import classnames from 'classnames';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import {useNavigate} from 'react-router-dom';
import {formatEventDates} from 'src/app/utilities/helpers/formatEventData';
import makeStyles from '@mui/styles/makeStyles';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import eventUrlService from 'src/app/pages/events/eventUrlService';

const useStyles = makeStyles((theme) => ({
    tableSpacingBottom: {
        marginBottom: theme.spacing(3),
    },
}));

interface PurchasesStatisticsTableFeatureProps {
    purchaseId: string;
    linkText: string;
}

export const PurchasesStatisticsTableFeature = ({
                                                    purchaseId,
                                                    linkText,
                                                }: PurchasesStatisticsTableFeatureProps) => {
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();

    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    return (
        <FetchPurchaseStatisticsDataWrapper purchaseId={purchaseId} page={page}>
            {({loading, data}) => (
                <>
                    {data?.data.map((statistic, index) => (
                        <div
                            className={classnames(
                                index !== data.data.length - 1 && classes.tableSpacingBottom
                            )}
                            key={statistic.eventId}
                        >
                            <TableHeader
                                title={statistic.name}
                                additionalTitle={
                                    statistic.seriesName ? `Series: ${statistic.seriesName}` : ''
                                }
                                subTitle={formatEventDates(
                                    statistic.eventStartDate,
                                    statistic.eventEndDate
                                )}
                                variant="small"
                                onClickShow={() =>
                                    navigate(
                                        eventUrlService.tickets(statistic.eventId, {purchaseId})
                                    )
                                }
                                showMoreText={linkText}
                                enableShowMoreIcon={false}
                                disableBorderRadius
                            />
                            <PurchaseStatisticsTable
                                loading={loading}
                                seatingplanCategories={statistic.seatingPlanCategories}
                            />
                        </div>
                    ))}

                    {data?.meta.totalPages && data.meta.totalPages > 1 && (
                        <div
                            className={classnames(
                                spacingClasses.spacingBottom,
                                spacingClasses.spacingTop
                            )}
                        >
                            <TablePagination
                                totalPages={data?.meta.totalPages || 1}
                                currentPage={data?.meta.currentPage || 1}
                                onPaginate={(pageNumber) => setPage(pageNumber)}
                            />
                        </div>
                    )}
                </>
            )}
        </FetchPurchaseStatisticsDataWrapper>
    );
};
