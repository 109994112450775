import {CircularProgress, Grid} from '@mui/material';
import {useTheme} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {useConfirm} from 'material-ui-confirm';
import {useEffect} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import EventOrder from 'src/data/models/order/EventOrder';
import FormButtons from 'src/view/components/form/FormButtons';
import {FormLabel} from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import z from 'zod';

const useStyles = makeStyles(() => ({
    secondaryTitle: {
        marginBottom: 0,
    },
}));

export const validationSchema = z.object({
    internalNotes: z.string(),
});

export type CreateOrderFormValues = z.infer<typeof validationSchema>;

interface Props {
    order?: EventOrder;
    defaultValues?: CreateOrderFormValues;
    onFormSubmit: (values: CreateOrderFormValues) => void;
    dataLoading?: boolean;
    actionLoading?: boolean;
    onRevokeManuallySentTickets: () => void;
    form: UseFormReturn<CreateOrderFormValues, unknown, undefined>;
}

export default function EditOrderForm({
                                          defaultValues,
                                          onFormSubmit,
                                          onRevokeManuallySentTickets,
                                          order,
                                          actionLoading,
                                          dataLoading,
                                          form,
                                      }: Props) {
    const classes = useStyles();
    const theme = useTheme();
    const confirm = useConfirm();

    const {
        control,
        reset,
        handleSubmit,
        formState: {isDirty},
    } = form;

    const isSubmitButtonDisabled = actionLoading || !isDirty;

    useEffect(() => {
        reset({internalNotes: defaultValues?.internalNotes});
    }, [defaultValues?.internalNotes]);

    if (dataLoading) return <CircularProgress/>;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3 className={classes.secondaryTitle}>Event Details</h3>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Client Email</FormLabel>
                    <Input name="clientEmail" value={order?.deliveryContactData?.email} disabled/>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Order Number</FormLabel>
                    <Input name="orderNumber" value={order?.orderNumber} disabled/>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>External reference</FormLabel>
                    <Input name="externalRef" value={order?.externalOrderReference} disabled/>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Internal Notes</FormLabel>
                    <Controller
                        name="internalNotes"
                        control={control}
                        render={({field: {name, onChange, value}}) => {
                            return (
                                <Input
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Source</FormLabel>
                    <Input name="source" value={order?.source} disabled/>
                </Grid>
            </Grid>

            <FormButtons
                buttons={[
                    {
                        children: 'Revoke order',
                        startIcon: actionLoading && (
                            <CircularProgress size={theme.layout.loader.sizes.small}/>
                        ),
                        disabled: actionLoading || !order?.isManuallyRevocable,
                        onClick: async () => {
                            await confirm({
                                title: 'Are you sure you want to revoke the manually sent tickets?',
                                description: 'This action cannot be undone.',
                            });
                            onRevokeManuallySentTickets();
                        },
                        color: 'error',
                    },
                    {
                        children: 'Update order',
                        startIcon: actionLoading && (
                            <CircularProgress size={theme.layout.loader.sizes.small}/>
                        ),
                        disabled: isSubmitButtonDisabled,
                        onClick: () => handleSubmit(onFormSubmit)(),
                    },
                ]}
            />
        </>
    );
}
