import {zodResolver} from '@hookform/resolvers/zod';
import {Grid} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import PurchaseDetailsFeature from 'src/app/components/features/purchase/PurchaseDetailsFeature';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import {PurchaseDetailsFormValues} from 'src/app/components/forms/CreatePurchaseDetailsForm';
import PurchasesFilterForm from 'src/app/components/forms/PurchasesFilterForm';
import {PopUpForm} from 'src/app/components/pop-up-form/pop-up-form';
import PurchasesTable from 'src/app/components/tables/PurchasesTable';
import {config} from 'src/app/constants/config/config';
import {useCompletePurchases} from 'src/app/hooks/purchases/useCompletePurchases';
import {useFetchPurchaseById} from 'src/app/hooks/purchases/useFetchPurchaseById';
import {useFetchPurchases} from 'src/app/hooks/purchases/useFetchPurchases';
import {usePurchaseFilters} from 'src/app/hooks/purchases/usePurchaseFilters';
import PurchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import mapPurchaseDataToViewModel from 'src/app/utilities/mappers/mapPurchaseDataToFormValues';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import {zodCreateOrEditPurchaseValidationSchema} from 'src/app/utilities/zod/purchase/zodCreateOrEditPurchaseValidationSchema';
import SortingOption from 'src/data/api/common/SortingOption';
import Purchase from 'src/data/models/purchase/Purchase';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import Button from 'src/view/components/button/Button';
import {ETBContainer} from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {TableColumnSorting} from 'src/view/components/table/table/Types';

interface PurchasePageProps {
    eventId?: string;
    eventName?: string;
}

const initialValues: PurchaseDetailsFormValues = {
    purchaseDate: new Date(Date.now()),
    currency: currencyOptions[0],
};

export default function PurchasesOverview({eventId, eventName}: PurchasePageProps) {
    const spacingClasses = useSpacingStyles();
    const navigate = useNavigate();

    const [selectedRows, setSelectedRows] = useState<RowIdResolver<Purchase>[]>([]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [notesModalContent, setNotesModalContent] = useState<string | null>(null);
    const [purchase, setPurchase] = useState<Purchase | undefined>();
    const [sorting, setSorting] = useState<SortingOption[]>([]);

    const purchaseData = useFetchPurchaseById(purchase?.id ?? '', {
        enabled: !!purchase?.id,
        onSuccess: (response) => {
            form.reset(
                {...initialValues, ...mapPurchaseDataToViewModel(response.data?.data)},
                {
                    keepTouched: false,
                    keepDefaultValues: false,
                    keepDirty: false,
                    keepDirtyValues: false,
                    keepErrors: false,
                    keepIsSubmitSuccessful: false,
                    keepIsSubmitted: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                    keepValues: false,
                }
            );
        },
    });

    const form = useForm<PurchaseDetailsFormValues>({
        mode: 'all',
        resolver: zodResolver(zodCreateOrEditPurchaseValidationSchema),
    });

    const onPurchaseEdit = (purchase: Purchase) => {
        setPurchase(purchase);
        setShowEditModal(true);
    };

    const renderPurchaseNotesModal = (): JSX.Element => {
        return (
            <Modal
                title="Purchase notes"
                open={!!notesModalContent}
                onClose={() => setNotesModalContent(null)}
                width="small"
            >
                <>{notesModalContent}</>
            </Modal>
        );
    };

    const handleSortingChange = (tableSorting: TableColumnSorting[]) => {
        if (sorting.length === 0 && tableSorting.length === 0) return;

        setSorting(tableSorting.map(tableSortingToSortingOptionMapper));
    };
    const {
        defaultValues,
        filterOptions,
        customFields,
        eventsFiltersOptions,
        setFilterOptions,
        setCustomFields,
    } = usePurchaseFilters();

    const {data, isLoading} = useFetchPurchases(
        {
            filter: filterOptions,
            q: customFields.q || '',
            sorting: sorting,
            page: Number(customFields['page'] || 1),
            pageSize: config.ITEMS_PER_PAGE_STANDARD,
        },
        eventId
    );

    const resetSelection = () => {
        setSelectedRows([]);
    };
    const {mutate: completePurchases, isLoading: isCompletingPurchases} = useCompletePurchases(
        resetSelection,
        resetSelection
    );

    const completeInBulkActionDisabledReasons = useMemo(() => {
        const reasons: string[] = [];
        const containsNonFinalized = selectedRows.some((row) => !row.data?.finalized);
        if (containsNonFinalized) {
            reasons.push('Selection contains non-finalized purchases');
        }
        const containsOtherThanConfirmed = selectedRows.some(
            (row) => row.data?.status !== 'Confirmed'
        );

        if (containsOtherThanConfirmed) {
            reasons.push('Selection contains purchases with status other than Confirmed');
        }

        for (const row of selectedRows) {
            if (row.data && !canPurchaseBeCompleted(row.data)) {
                reasons.push(
                    `Purchase number [${
                        row.data.purchaseNumber
                    }] cannot be completed. ${getPurchaseStatusMessage(row.data)}`
                );
            }
        }

        return reasons;
    }, [selectedRows]);

    const completeSelectedPurchases = useCallback(
        (selectedRows: RowIdResolver<Purchase>[]) => ({
            label: 'Complete selected purchases',
            disabled:
                !!completeInBulkActionDisabledReasons.length ||
                selectedRows.length === 0 ||
                isCompletingPurchases,
            disabledButtonReasons: completeInBulkActionDisabledReasons,
            callback: () => {
                setSelectedRows(selectedRows);
                completePurchases(selectedRows.map((row) => row.id));
            },
        }),
        [selectedRows]
    );

    return (
        <>
            <Header GridProps={{justifyContent: 'space-between'}}>
                <Grid item>
                    <h1>{eventName}</h1>
                </Grid>
                <Grid item>
                    <Button
                        className={spacingClasses.spacingRight}
                        color="primary"
                        onClick={() =>
                            navigate(
                                eventId
                                    ? PurchaseUrlService.create({eventId, eventName})
                                    : PurchaseUrlService.create()
                            )
                        }
                    >
                        Create Purchase
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <>
                    <PurchasesFilterForm
                        defaultValues={defaultValues}
                        setFilterOptions={setFilterOptions}
                        setCustomFields={setCustomFields}
                        eventsFiltersOptions={eventsFiltersOptions}
                        customFields={customFields}
                    />
                    {isLoading && <LoadingOverlay/>}
                    <TableHeaderTotalFeature
                        title="Purchases"
                        totalResults={data?.data.meta.total}
                        priceTotals={data?.data.meta.totalPriceSumPerCurrency}
                    />
                    <PurchasesTable
                        eventId={eventId}
                        purchases={data?.data.data}
                        loading={isLoading}
                        pagination={{
                            currentPage: data?.data.meta.currentPage || 1,
                            totalPages: data?.data.meta.totalPages || 1,
                            onPaginate: (page: number) =>
                                setCustomFields({
                                    ...customFields,
                                    page: page.toString(),
                                }),
                        }}
                        onSortingChange={handleSortingChange}
                        onPurchaseEdit={onPurchaseEdit}
                        tableToolbarActions={(selectedRows) => [
                            completeSelectedPurchases(selectedRows),
                        ]}
                        selectedRows={selectedRows}
                        onChangeSelectedRows={setSelectedRows}
                    />
                    {!!purchase?.id && (
                        <PopUpForm
                            formState={form.formState}
                            open={showEditModal}
                            onClose={() => {
                                setShowEditModal(false);
                            }}
                            confirmOptions={{
                                title: 'Are you sure you want to exit editing purchase details?',
                                description: 'Any unsaved changes will be lost',
                            }}
                        >
                            <div>
                                <PurchaseDetailsFeature
                                    purchaseData={purchaseData}
                                    form={form}
                                    purchaseId={purchase.id}
                                    onActionSucceeded={() => setSelectedRows([])}
                                />
                            </div>
                        </PopUpForm>
                    )}
                    {renderPurchaseNotesModal()}
                </>
            </ETBContainer>
        </>
    );
}

const canPurchaseBeCompleted = (purchase: Purchase) => {
    return (
        purchase.status === 'Confirmed' &&
        purchase.finalized &&
        purchase.paymentMethod &&
        purchase.externalReferenceId
    );
};

const getPurchaseStatusMessage = (purchase: Purchase) => {
    const missingFields = [];
    const notStatuses = [];

    if (!purchase.paymentMethod) missingFields.push('Payment method');
    if (!purchase.externalReferenceId) missingFields.push('External Reference Id');

    if (purchase.status !== 'Confirmed') notStatuses.push('Confirmed');
    if (!purchase.finalized) notStatuses.push('Finalized');

    const missingPart = missingFields.length ? `It is missing [${missingFields.join(', ')}]` : '';

    const notPart = notStatuses.length
        ? `It is not ${notStatuses.length > 1 ? `[${notStatuses.join(' & ')}]` : notStatuses[0]}`
        : '';

    if (missingPart && notPart) return `${missingPart} and ${notPart}.`;
    return missingPart || notPart || 'Everything is set.';
};
