import {useQuery} from '@tanstack/react-query';
import {getSeatingPlansByVenueId} from 'src/data/services/venuesService';

export const FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY = 'FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY';

export const useFetchSeatingPlansByVenueId = (id: string) => {
    return useQuery({
        queryKey: [FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY, id],
        queryFn: () => {
            return getSeatingPlansByVenueId(id);
        },
    });
};
