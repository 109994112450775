import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ticketManagementService from 'src/data/services/ticketManagementService';
import {DataWrapperCallback} from 'src/app/interfaces/dataWrapperCallback';

interface FetchETicketFilesDownloadLinksDataWrapperChildrenParams
    extends DataWrapperChildrenParams<string> {
    fetchETicketFilesDownloadLinks: (
        ticketIds: string[],
        callbacks?: DataWrapperCallback<string>
    ) => Promise<string>;
}

type FetchETicketFilesDownloadLinksDataWrapperProps = DataWrapperProps<
    FetchETicketFilesDownloadLinksDataWrapperChildrenParams,
    string
> &
    BaseFetchOptions;

export const useFetchETicketFilesDownloadLinksDataWrapper = ({
                                                                 onError,
                                                                 onSuccess,
                                                             }: Omit<FetchETicketFilesDownloadLinksDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleFetchFilesDownloadLinks] =
        useApiFetch<string>();

    const fetchETicketFilesDownloadLinks = (
        ticketIds: string[],
        callbacks?: DataWrapperCallback<string>
    ) => {
        return handleFetchFilesDownloadLinks(ticketManagementService.getTicketsFile(ticketIds), {
            useDefaultErrorHandler: true,
            onFail: () => {
                onError?.();
                callbacks?.onFail?.();
            },
            onSuccess: (data) => {
                onSuccess?.(data);
                callbacks?.onSuccess?.(data);
            },
        });
    };

    return {
        fetchETicketFilesDownloadLinks,
        data,
        loading,
        errors,
        resetData,
    };
};
