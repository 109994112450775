import {Alert} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {VenueSeatingplanCategoriesPreviewItem} from 'src/app/components/previews/venues/VenueSeatingplanCategoriesPreviewItem';
import {FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY} from 'src/app/hooks/seating-plan-categories/useFetchSPCsBySeatingplanId';
import TicketFile from 'src/data/models/common/ticketFile';
import type {Tag} from 'src/data/models/seating-plan-category/SeatingPlanCategory';
import {type UpdateSpcOrderDto} from 'src/data/services/seatingPlanService';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import {Preview} from 'src/view/components/preview/Preview';
import {useUpdateSeatingplanSpcOrderDataWrapper} from '../../data-wrappers/seating-plan/UpdateSeatingplanSpcOrderDataWrapper';

export interface SeatingplanCategoryItem {
    id: string;
    name: string;
    isArchived: boolean;
    image?: TicketFile;
    itineraryFiles: TicketFile[];
    ticketTypes: string[];
    tags: Tag[];
}

interface SeatingplansPreviewProps {
    seatingPlanCategories: SeatingplanCategoryItem[];
    seatingPlanId: string;
    loading: boolean;
}

export const SeatingPlanCategoriesPreview = ({
                                                 seatingPlanCategories,
                                                 seatingPlanId,
                                                 loading,
                                             }: SeatingplansPreviewProps) => {
    const queryClient = useQueryClient();
    /** TODO: Replace it with React Query */
    const {updateSpcOrder, loading: isUpdatingOrder} = useUpdateSeatingplanSpcOrderDataWrapper({
        seatingplanId: seatingPlanId,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY],
                exact: false,
            });
        },
    });
    const onDragEnd = (result: DropResult) => {
        if (!result.destination || isNaN(result?.destination.index)) return;

        const draggedSpcOrder = reorder(
            seatingPlanCategories,
            result.source.index,
            result.destination.index
        );

        const dto: UpdateSpcOrderDto = {
            seatingPlanCategoriesSortOrders: draggedSpcOrder.map((s, i) => ({
                seatingPlanCategoryId: s.id,
                sortOrder: i,
            })),
        };

        updateSpcOrder(dto);
    };

    if (seatingPlanCategories.length === 0 && !loading) {
        return (
            <Alert severity="info">
                This seating plan does not have any seating plan categories yet
            </Alert>
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {(isUpdatingOrder || loading) && <LoadingOverlay/>}

            <Droppable droppableId="spc-droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Preview>
                            {seatingPlanCategories.map((s, index) => (
                                <Draggable key={s.id} draggableId={s.id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <VenueSeatingplanCategoriesPreviewItem
                                                seatingPlanId={seatingPlanId}
                                                spcItem={s}
                                                index={index}
                                                key={`${s.id}-${index}`}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Preview>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

const reorder = (list: SeatingplanCategoryItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
