import {CircularProgress, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LOADER_SIZES} from 'src/shared/theme/enums';
import {BaseComponent} from 'src/view/interfaces/BaseComponent';

export interface ModalSubTitleProps extends BaseComponent {
    children: React.ReactNode;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '.9rem',
        color: theme.palette.primary.main,
        'font-weight': theme.typography.fontWeightBold,
        marginBottom: '.4rem',
        marginTop: '.4rem',
    },
    loadingSpinner: {
        marginLeft: theme.spacing(1),
    },
}));

export default function ModalSubTitle({
                                          children,
                                          className,
                                          loading,
                                      }: ModalSubTitleProps): JSX.Element {
    const classes = useStyles();

    return (
        <div className={`${classes.title} ${className ? className : ''}`}>
            {children}
            {loading && (
                <CircularProgress size={LOADER_SIZES.TINY} className={classes.loadingSpinner}/>
            )}
        </div>
    );
}
