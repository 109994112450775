import CreateTeamForm, {CreateTeamFormValues} from 'src/app/components/forms/CreateTeamForm';
import {Team} from 'src/data/models/team/team';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {useUpdateTeam} from 'src/app/hooks/teams/useUpdateTeam';
import {useCreateTeam} from 'src/app/hooks/teams/useCreateTeam';

interface Props {
    teamName?: string;
    onCreateOrUpdateTeamSuccess: (id?: string, name?: string) => void;
    team?: Team;
}

export default function CreateTeamFormFeature({
                                                  teamName,
                                                  onCreateOrUpdateTeamSuccess,
                                                  team,
                                              }: Props): JSX.Element {
    const {
        mutate: updateTeam,
        error: updateTeamErrors,
        isLoading: isUpdatingTeam,
    } = useUpdateTeam(onCreateOrUpdateTeamSuccess);

    const {
        mutate: createTeam,
        error: createTeamErrors,
        isLoading: isCreatingTeam,
    } = useCreateTeam((id, name) => onCreateOrUpdateTeamSuccess(id, name));

    const onFormSubmit = (values: CreateTeamFormValues) => {
        const createTeamDto = {
            countryCode: values.country.value,
            ...values,
        };

        if (team)
            return updateTeam({
                id: team.id,
                dto: createTeamDto,
            });

        createTeam(createTeamDto);
    };

    const responseErrors = createTeamErrors || updateTeamErrors;

    return (
        <>
            {responseErrors && <ErrorsList errors={parseErrors(responseErrors)}/>}
            <CreateTeamForm
                loading={isCreatingTeam || isUpdatingTeam}
                teamName={teamName}
                onFormSubmit={onFormSubmit}
                team={team}
            />
        </>
    );
}
