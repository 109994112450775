import React, {useEffect} from 'react';
import {OrdersWithReasons} from 'src/data/services/orderService';
import Table from 'src/view/components/table/table/Table';
import {NewTableColumn, TABLE_CELL_WIDTH} from 'src/view/components/table/table/Types';

export interface OrderComparisonResultTableProps {
    reasons: OrdersWithReasons[];
    loading?: boolean;
}

export const OrderComparisonResultTable = ({
                                               loading,
                                               reasons,
                                           }: OrderComparisonResultTableProps) => {
    const [columns, setColumns] = React.useState<NewTableColumn<OrdersWithReasons>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<OrdersWithReasons>[] = [];

        cols = [
            {
                key: 'orderNumber',
                title: 'order number',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => rowData.orderNumber,
            },
            {
                key: 'reason',
                title: 'Reason',
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
                cellRenderer: (rowData) => rowData.reason,
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<OrdersWithReasons>
            data={reasons}
            rowIdResolver={(rowData) => ({id: rowData.orderNumber})}
            columns={columns}
            loading={loading}
            minWidth={300}
        />
    );
};
