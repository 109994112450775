import {useEffect} from 'react';
import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import SeriesGroup from 'src/data/models/series-group/SeriesGroup';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import seriesGroupService from 'src/data/services/seriesGroupService';

interface FetchSeriesGroupsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<SeriesGroup>> {
    fetchSeriesGroups: (orderId: string) => Promise<void | ErrorReturn>;
}

type FetchSeriesGroupDataWrapperProps = DataWrapperProps<
    FetchSeriesGroupsDataWrapperChildrenParams,
    PaginatedApiResponseBody<SeriesGroup>
> &
    BaseFetchOptions;

export const useFetchSeriesGroupsDataWrapper = ({
                                                    onError,
                                                    onSuccess,
                                                    invalidatedAt,
                                                    filter,
                                                    page,
                                                    pageSize,
                                                    q,
                                                }: Omit<FetchSeriesGroupDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleFetchOrderById] =
        useApiFetch<PaginatedApiResponseBody<SeriesGroup>>();

    const fetchSeriesGroupsCall = () => {
        return handleFetchOrderById(
            seriesGroupService.fetchSeriesGroups({filter, page, pageSize, q}),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchSeriesGroupsCall();
    }, [invalidatedAt, q, page, pageSize]);

    return {
        fetchSeriesGroups: () => fetchSeriesGroupsCall(),
        data,
        loading,
        errors,
        resetData,
    };
};
