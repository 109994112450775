import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import seriesGroupService from 'src/data/services/seriesGroupService';
import {DataWrapperCallback} from 'src/app/interfaces/dataWrapperCallback';

interface DeleteSeriesGroupDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    deleteSeriesGroup: (
        id: string,
        callbacks?: DataWrapperCallback
    ) => Promise<ApiResponseBody<IdResponse>>;
}

type DeleteSeriesGroupDataWrapperProps = DataWrapperProps<
    DeleteSeriesGroupDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
> &
    BaseFetchOptions;

export const useDeleteSeriesGroupDataWrapper = ({
                                                    onSuccess,
                                                    onError,
                                                }: Omit<DeleteSeriesGroupDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleDeleteSeriesGroup] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const deleteSeriesGroupCall = (id: string, callbacks?: DataWrapperCallback) => {
        return handleDeleteSeriesGroup(seriesGroupService.deleteSeriesGroup(id), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'Series Group has been deleted',
            onFail: () => {
                onError?.();
                callbacks?.onFail?.();
            },
            onSuccess: () => {
                onSuccess?.();
                callbacks?.onSuccess?.();
            },
        });
    };

    return {
        deleteSeriesGroup: deleteSeriesGroupCall,
        data,
        loading,
        errors,
        resetData,
    };
};
