import {useMutation} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';

type TData = Awaited<ReturnType<typeof seatingPlanCategoryService.editItineraryPdf>>;
type TVars = { fileId: string; file: File };

export default function useEditSPCItineraryFile(
    seatingplanCategoryId: string,
    onSuccess?: (data: TData, vars: TVars) => unknown
) {
    return useMutation({
        mutationFn: ({file, fileId}: TVars) =>
            seatingPlanCategoryService.editItineraryPdf(seatingplanCategoryId, fileId, file),
        onSuccess: (data, vars) => {
            Toaster.toast('Itinerary file updated successfully', {
                variant: 'success',
            });

            onSuccess?.(data, vars);
        },
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err));
        },
    });
}
