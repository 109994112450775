import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderlineService, {AutoAssignDto} from 'src/data/services/orderlineService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {DataWrapperCallback} from 'src/app/interfaces/dataWrapperCallback';
import {EmptyBody} from 'src/data/models/common/emptyBody';

interface RunAutoAssignDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    runAutoAssign: (
        eventId: string,
        dto: AutoAssignDto,
        callbacks?: DataWrapperCallback<EmptyBody>
    ) => Promise<ApiResponseBody<EmptyBody>>;
}

type RunAutoAssignDataWrapperProps = DataWrapperProps<
    RunAutoAssignDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useRunAutoAssignDataWrapper = ({
                                                onError,
                                                onSuccess,
                                            }: Omit<RunAutoAssignDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleRunAutoAssign] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const runAutoAssign = (
        eventId: string,
        dto: AutoAssignDto,
        callbacks?: DataWrapperCallback<EmptyBody>
    ) => {
        return handleRunAutoAssign(orderlineService.autoAssign({eventId}, dto), {
            useDefaultErrorHandler: true,
            onFail: () => {
                callbacks?.onFail?.();
                onError?.();
            },
            onSuccess: () => {
                callbacks?.onSuccess?.();
                onSuccess?.();
            },
        });
    };

    return {
        runAutoAssign,
        data,
        loading,
        errors,
        resetData,
    };
};
