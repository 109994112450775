import {AxiosError, AxiosResponseHeaders, RawAxiosRequestHeaders} from 'axios';
import {useCallback, useState} from 'react';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import Toaster from 'src/app/utilities/helpers/Toaster';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';

interface CustomError extends Error {
    parsedErrors: ErrorDetail[];
    originalError: Error;
}

interface ResultInterface<T> {
    data?: T | null;
    headers?: AxiosResponseHeaders | Partial<RawAxiosRequestHeaders> | null;
    statusCode?: number | null;
    errors: ErrorDetail[];
    loading: boolean;
    resetData: () => void;
}

export type FetchOptions<T> = {
    onSuccess?: (data?: T) => void;
    onFail?: () => void;
    useDefaultErrorHandler?: boolean;
    defaultSuccessMessage?: string | null;
};

export type ErrorReturn = { parsedErrors: ErrorDetail[]; originalError: AxiosError };

export default function useApiFetch<T>(): readonly [
    ResultInterface<T>,
    (promise: Promise<ApiResponse<T>>, options?: FetchOptions<T>) => Promise<T>
] {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<T | null>();
    const [statusCode, setStatusCode] = useState<number | null>();
    const [headers, setHeaders] = useState<
        AxiosResponseHeaders | Partial<RawAxiosRequestHeaders> | null
    >();
    const [errors, setErrors] = useState<ErrorDetail[]>([]);

    const fetch = useCallback(
        async (promise: Promise<ApiResponse<T>>, options?: FetchOptions<T>) => {
            const mergedFetchOptions: FetchOptions<T> = {
                useDefaultErrorHandler: true,
                ...options,
            };

            setIsLoading(true);

            return promise
                .then((response) => {
                    setHeaders(response.headers);
                    setData(response.data);
                    setStatusCode(response.statusCode);
                    setErrors([]);
                    setIsLoading(false);

                    // Invoke onSuccess callback
                    options?.onSuccess?.(response.data);

                    // Show success message if specified
                    if (mergedFetchOptions.defaultSuccessMessage) {
                        Toaster.toast(mergedFetchOptions.defaultSuccessMessage, {
                            variant: 'success',
                        });
                    }

                    return response.data;
                })
                .catch((error: AxiosError) => {
                    // Handle errors
                    const {response} = error;
                    const parsedErrors = parseErrors(error);

                    setData(null);
                    setHeaders(null);
                    setStatusCode(response?.status);
                    setErrors(parsedErrors);
                    setIsLoading(false);

                    /*
                    The following CustomError code is needed to prevent the Catch from reaching Sentry
                    promise.reject() expects an instance of an Error, therefore this custom logic was needed.

                    The type safety needs to be improved. Perhaps custom Reject has to be implemented.

                    DO NOT CHANGE parsedErrors OR originalError. Other components depend on this.
                */
                    const constructedError: CustomError = new Error(error.message) as CustomError;
                    constructedError.parsedErrors = parsedErrors;
                    constructedError.originalError = error;

                    // Invoke onFail callback
                    mergedFetchOptions.onFail?.();

                    if (mergedFetchOptions?.useDefaultErrorHandler) {
                        Toaster.toastErrors(parsedErrors);

                        // This line returns undefined, making TS complain in line 132 - return [results, fetch] as const;
                        // [fetch] can't be undefined
                        return;
                    }

                    return Promise.reject(constructedError);
                });
        },
        []
    );

    const resetData = () => {
        setIsLoading(false);
        setData(null);
        setStatusCode(null);
        setHeaders(null);
        setErrors([]);
    };

    const results: ResultInterface<T> = {
        data,
        headers,
        statusCode,
        errors,
        loading: isLoading,
        resetData,
    };

    /*
        This file will be removed once we replace all the fetching with React-Query
        Hence we are just ignoring the warning.
        Otherwise it creates errors is many other places.
    */

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return [results, fetch] as const;
}
