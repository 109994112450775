import {SupportedTtiLanguagesType} from 'src/app/components/features/tti/UnprocessedTicketFileUploadFeature';
import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    TTI_DELETE_TICKETS_ENDPOINT,
    TTI_FIND_MATCHING_TICKETS_ENDPOINT,
    TTI_GET_TICKET_FILE,
    TTI_LINK_TICKET_FILES_ENDPOINT,
    TTI_MASS_LINK_TICKETS_ENDPOINT,
} from 'src/data/constants/endpoints/tti-endpoints';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import UnprocessedTicketFile, {
    MatchingTickets,
} from 'src/data/models/tickets/unprocessedTicketFile';
import ApiResponseBody from '../api/responses/ApiResponseBody';
import {
    EVENTS_GET_UNPROCESSED_TICKET_FILES_ENDPOINT,
    EVENTS_UPLOAD_UNPROCESSED_TICKET_FILE_ENDPOINT,
} from '../constants/endpoints/events-endpoints';

export interface UnprocessedTicketFilesFetchOptions extends BaseFetchOptions {
    q?: string;
    sortBy?: string[];
}

async function fetchUnprocessedTicketFilesOverView(
    eventId: string,
    fetchOptions: UnprocessedTicketFilesFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<UnprocessedTicketFile>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<UnprocessedTicketFile>>(
            EVENTS_GET_UNPROCESSED_TICKET_FILES_ENDPOINT(eventId),
            {
                params: getQueryParams(fetchOptions),
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

function linkUnprocessedTicketFile(ticketId: string, unprocessedTicketFileId: string) {
    return getInternalAxiosInstance().post<EmptyBody>(TTI_LINK_TICKET_FILES_ENDPOINT, {
        ticketId,
        unprocessedTicketFileId,
    });
}

async function uploadUnprocessedTicketFile({
                                               eventId,
                                               file,
                                               language,
                                           }: {
    eventId: string;
    file: File;
    language: SupportedTtiLanguagesType;
}): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    const formData = new FormData();
    formData.append('file', file);

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(
            EVENTS_UPLOAD_UNPROCESSED_TICKET_FILE_ENDPOINT,
            formData,
            {
                params: {
                    eventId,
                    language,
                },
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

function findMatchingTickets(
    eventId: string,
    block: string,
    row: string,
    seat: string,
    signal?: AbortSignal
) {
    return getInternalAxiosInstance().get<PaginatedApiResponseBody<MatchingTickets>>(
        TTI_FIND_MATCHING_TICKETS_ENDPOINT,
        {
            params: getQueryParams({
                filter: [
                    {property: 'eventId', value: eventId},
                    {property: 'block', value: block},
                    {property: 'row', value: row},
                    {property: 'seat', value: seat},
                ],
            }),
            signal,
        }
    );
}

function deleteTickets(ticketIds: string[]) {
    return getInternalAxiosInstance().post<EmptyBody>(TTI_DELETE_TICKETS_ENDPOINT, {
        unprocessedTicketIds: ticketIds,
    });
}

function getTicketFile(ticketFileId: string) {
    return getInternalAxiosInstance().get<{ data: string }>(TTI_GET_TICKET_FILE(ticketFileId));
}

function linkTickets(eventId: string, ticketIds: string[]) {
    return getInternalAxiosInstance().post<EmptyBody>(TTI_MASS_LINK_TICKETS_ENDPOINT, {
        eventId,
        unprocessedTicketFileIds: ticketIds,
    });
}

export default {
    fetchUnprocessedTicketFilesOverView,
    uploadUnprocessedTicketFile,
    linkUnprocessedTicketFile,
    findMatchingTickets,
    deleteTickets,
    getTicketFile,
    linkTickets,
};
