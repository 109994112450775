import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    TICKETS_CHANGE_SEAT_ENDPOINT,
    TICKETS_DELETE_ENDPOINT,
    TICKETS_GET_BY_ID_ENDPOINT,
    TICKETS_LOCK_MULTIPLE_TICKETS_ENDPOINT,
    TICKETS_LOCK_TICKET_BY_ID_ENDPOINT,
    TICKETS_UNLOCK_MULTIPLE_TICKETS_ENDPOINT,
    TICKETS_UNLOCK_TICKET_BY_ID_ENDPOINT,
} from 'src/data/constants/endpoints/tickets-endpoints';
import ChangeSeatDto from 'src/data/dtos/ChangeSeatDto';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import Ticket from 'src/data/models/tickets/ticket';
import {EVENTS_GET_TICKETS_ENDPOINT} from '../constants/endpoints/events-endpoints';
import {
    ORDERS_ORDERLINE_TICKETS,
    ORDERS_TICKETS_BY_ORDER_ID,
} from '../constants/endpoints/orders-endpoints';

export interface EventTicketsFetchOptions extends BaseFetchOptions {
    q?: string;
    sortBy?: string[];
}

export async function fetchEventTickets(
    eventId: string,
    options: EventTicketsFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Ticket>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    if (!options.sorting && options.sortBy) {
        extraQueryParams.push({
            key: 'sortBy',
            value: options.sortBy.join(','),
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Ticket>>(EVENTS_GET_TICKETS_ENDPOINT(eventId), {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function fetchAssignedOrderlineTickets(
    orderId: string,
    orderLineId: string,
    // fetchAssignedOrderlineTickets has the same options as EventTicketsFetchOptions
    options: EventTicketsFetchOptions
) {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    if (!options.sorting && options.sortBy) {
        extraQueryParams.push({
            key: 'sortBy',
            value: options.sortBy.join(','),
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Ticket>>(ORDERS_ORDERLINE_TICKETS(orderId, orderLineId), {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            // Tickets table relies on statusCode...
            statusCode: response.status,
        }));
}

export async function fetchOrderTickets(
    orderId: string,
    options: EventTicketsFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Ticket>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    if (!options.sorting && options.sortBy) {
        extraQueryParams.push({
            key: 'sortBy',
            value: options.sortBy.join(','),
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Ticket>>(ORDERS_TICKETS_BY_ORDER_ID(orderId), {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteTickets(
    ticketIds: string[]
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_DELETE_ENDPOINT, {
            ticketIds,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function lockTicket(ticketId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_LOCK_TICKET_BY_ID_ENDPOINT(ticketId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function lockTickets(ticketIds: string[]): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_LOCK_MULTIPLE_TICKETS_ENDPOINT, {
            ticketIds,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function unlockTickets(
    ticketIds: string[]
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_UNLOCK_MULTIPLE_TICKETS_ENDPOINT, {
            ticketIds,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function unlockTicket(ticketId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_UNLOCK_TICKET_BY_ID_ENDPOINT(ticketId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateTicketBlockRowSeat(
    ticketId: string,
    dto: ChangeSeatDto
): Promise<ApiResponse<EmptyBody>> {
    return await getInternalAxiosInstance()
        .post<EmptyBody>(TICKETS_CHANGE_SEAT_ENDPOINT(ticketId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchTicketById(ticketId: string): Promise<ApiResponse<ApiResponseBody<Ticket>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Ticket>>(TICKETS_GET_BY_ID_ENDPOINT(ticketId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    fetchEventTickets,
    updateTicketBlockRowSeat,
    deleteTickets,
    fetchTicketById,
    lockTicket,
    unlockTicket,
    lockTickets,
    unlockTickets,
};
