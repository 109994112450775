import {zodResolver} from '@hookform/resolvers/zod';
import makeStyles from '@mui/styles/makeStyles';
import {useMutation} from '@tanstack/react-query';
import _ from 'lodash';
import {useConfirm} from 'material-ui-confirm';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import DeleteTicketFileFormDataWrapper from 'src/app/components/data-wrappers/tickets/DeleteTicketFileFormDataWrapper';
import TicketsTableDataWrapper from 'src/app/components/data-wrappers/tickets/TicketsTableDataWrapper';
import TicketsTableDetailsFeature from 'src/app/components/features/tickets/TicketDetailsFeature';
import {TicketsTableDataResolver} from 'src/app/components/tables/TicketsTable';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import {zodCreateOrEditPurchaseValidationSchema} from 'src/app/utilities/zod/purchase/zodCreateOrEditPurchaseValidationSchema';
import orderlineService from 'src/data/services/orderlineService';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import {TableAction} from 'src/view/components/table-toolbar/TableToolbar';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import {type RowIdResolver} from 'src/view/components/table/table/Table';
import {TableColumnSorting} from 'src/view/components/table/table/Types';
import {PurchaseDetailsFormValues} from '../../forms/CreatePurchaseDetailsForm';
import {PopUpForm} from '../../pop-up-form/pop-up-form';

const useStyles = makeStyles((theme) => ({
    spacingBottomSmall: {
        marginBottom: theme.spacing(2),
    },
    spacingBottomLarge: {
        marginBottom: theme.spacing(8),
    },
    clickable: {
        cursor: 'pointer',
    },
    assignedTicketsTableHeader: {
        marginTop: theme.spacing(2),
    },
}));

interface AssignedTicketsTableToolbarProps {
    orderId?: string;
    orderLineId?: string;
    toolbarAdditionalItems?: string[];
    onSuccess?: () => void;
}

interface Props extends AssignedTicketsTableToolbarProps {
    eventId: string;
    invalidatedAt?: number;
    seatingPlanCategory?: AutoCompleteOption;
}

const MemoizedTicketsTableDataWrapper = React.memo(TicketsTableDataWrapper);

export default function AssignTicketsTableFeature({
                                                      eventId,
                                                      invalidatedAt,
                                                      seatingPlanCategory,
                                                      orderId,
                                                      orderLineId,
                                                      toolbarAdditionalItems,
                                                      onSuccess,
                                                  }: Props): JSX.Element {
    const classes = useStyles();
    const confirm = useConfirm();
    const [showErrorsModal, setShowErrorsModal] = useState(false);

    const {
        mutate: assignTickets,
        error,
        isLoading,
    } = useMutation({
        mutationFn: async ({
                               orderId,
                               orderLineId,
                               ticketIds,
                           }: {
            orderId: string;
            orderLineId: string;
            ticketIds: string[];
        }) => orderlineService.assignTickets(orderId, orderLineId, {ticketIds}),
        onSuccess,
        onError: () => {
            setShowErrorsModal(true);
        },
    });

    const defaultSorting: TableColumnSorting[] = [{column: 'blockRowSeatName', direction: 'asc'}];

    const defaultFilters = _.compact([
        seatingPlanCategory
            ? formatValuesToParams.formatOptionToParam('seatingPlanCategoryId', seatingPlanCategory)
            : undefined,
        orderLineId ? {property: 'orderLineId', value: orderLineId} : undefined,
    ]).filter((f) => f.value !== undefined);

    const {
        values: {sortingOptions, customFields},
        setSortingOptions,
        setCustomFields,
    } = useQueryParams('assigned_tickets', {
        defaultFilters,
        defaultSorting,
        defaultCustomFields: {
            page: '1',
        },
        disableUrlWriting: true,
    });

    const [activeTicket, setActiveTicket] = useState<string | undefined>();
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [ticketsInvalidatedAt, setTicketsInvalidatedAt] = useState<number | undefined>();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [fileId, setFileId] = useState<undefined | string>();

    useEffect(() => {
        setTicketsInvalidatedAt(invalidatedAt);
    }, [invalidatedAt]);

    const onSuccessfullyDeleteFile = () => {
        setShowConfirmationModal(false);
        setTicketsInvalidatedAt(Date.now());
    };

    const ticketPurchaseDetailsForm = useForm<PurchaseDetailsFormValues>({
        mode: 'all',
        resolver: zodResolver(zodCreateOrEditPurchaseValidationSchema),
    });

    const unasssignTickets = (
        selectedRows: RowIdResolver<TicketsTableDataResolver>[]
    ): TableAction => {
        return {
            label: 'Unassign tickets',
            disabled: selectedRows.length === 0,
            hidden: !orderId || !orderLineId,
            callback: async () => {
                try {
                    if (!orderId || !orderLineId) return;
                    await confirm({
                        title: 'Are you sure you want to unassign all the tickets?',
                        description: '',
                    });

                    const ticketIds: string[] = selectedRows.map((t) => t.id);
                    const ticketsToUnassign = _.remove(
                        toolbarAdditionalItems ?? [],
                        (additionalTicketId) => !ticketIds.includes(additionalTicketId)
                    );
                    assignTickets({orderId, orderLineId, ticketIds: ticketsToUnassign});
                } catch {
                    /* empty */
                }
            },
        };
    };

    return (
        <>
            {isLoading && <LoadingOverlay/>}

            <Modal open={showErrorsModal} onClose={() => setShowErrorsModal(false)}>
                <ErrorsList errors={parseErrors(error)}/>
            </Modal>

            <div className={classes.assignedTicketsTableHeader}>
                <TableHeader title="Assigned Tickets"/>
                <MemoizedTicketsTableDataWrapper
                    tableToolbarActions={(selectedRows) => [unasssignTickets(selectedRows)]}
                    q={customFields.q}
                    includePurchaseData={true}
                    orderId={orderId}
                    orderLineId={orderLineId}
                    onClickDetails={(ticketId) => {
                        setActiveTicket(ticketId);
                        setDetailsModalOpen(true);
                    }}
                    onDeleteTicketFile={(ticketId: string, fileId: string) => {
                        setActiveTicket(ticketId);
                        setFileId(fileId);
                        setShowConfirmationModal(true);
                    }}
                    onUploadTicketSuccess={() => setTicketsInvalidatedAt(Date.now())}
                    invalidatedAt={ticketsInvalidatedAt}
                    defaultSorting={sortingOptions}
                    onChangeSorting={(sortingOption: TableColumnSorting[]) =>
                        setSortingOptions(sortingOption)
                    }
                    page={Number(customFields['page'] || 1)}
                    onChangePage={(page: number) =>
                        setCustomFields({...customFields, page: page.toString()})
                    }
                />
            </div>

            <PopUpForm
                title="Ticket Details"
                open={detailsModalOpen}
                formState={ticketPurchaseDetailsForm.formState}
                onClose={() => setDetailsModalOpen(false)}
                confirmOptions={{
                    title: 'Are you sure you want to exit editing details?',
                    description: 'Any unsaved changes will be lost',
                }}
            >
                <>
                    {activeTicket && (
                        <TicketsTableDetailsFeature
                            eventId={eventId}
                            form={ticketPurchaseDetailsForm}
                            ticketId={activeTicket}
                            showRelatedPurchase
                            showRelatedOrder
                            onDetailsUpdated={() => setTicketsInvalidatedAt(Date.now())}
                        />
                    )}
                </>
            </PopUpForm>
            <Modal
                title="Confirm your action"
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >
                <>
                    <p className={classes.spacingBottomLarge}>
                        Are you sure you want to delete this file?
                    </p>
                    <DeleteTicketFileFormDataWrapper
                        ticketId={activeTicket}
                        fileId={fileId}
                        onSucceed={onSuccessfullyDeleteFile}
                    />
                </>
            </Modal>
        </>
    );
}
