import ErrorDetail from 'src/data/api/responses/ErrorDetail';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

export interface ToasterOptions {
    variant?: 'success' | 'error' | 'warning' | 'info';
    component?: JSX.Element;
}

type ToasterHandleCallback =
    | ((message: string | JSX.Element, options: ToasterOptions | undefined) => void)
    | undefined;

let handleToastCallback: ToasterHandleCallback = undefined;

const init = (toasterHandleCallback: ToasterHandleCallback) => {
    handleToastCallback = toasterHandleCallback;
};

const toast = (message: string | JSX.Element, options?: ToasterOptions) => {
    if (!handleToastCallback) return;

    handleToastCallback(message, options);
};

const toastErrors = (errors: ErrorDetail[]) => {
    toast(<ErrorsList errors={errors} plainStyle/>, {variant: 'error'});
};

const Toaster = {init, toast, toastErrors};

export default Toaster;
