import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {IdResponse} from 'src/data/models/common/idResponse';
import UpdateEventDto from 'src/data/dtos/UpdateEventDto';
import eventService from 'src/data/services/eventService';

interface UpdateEventDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    updateEvent: (dto: UpdateEventDto) => Promise<void | ErrorReturn>;
}

interface UpdateEventDataWrapperProps
    extends DataWrapperProps<UpdateEventDataWrapperChildrenParams, ApiResponseBody<IdResponse>>,
        BaseFetchOptions {
    eventId: string;
}

export const useUpdateEventDataWrapper = ({
                                              eventId,
                                              onError,
                                              onSuccess,
                                          }: Omit<UpdateEventDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleUpdateEvent] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const updateEventCall = async (dto: UpdateEventDto) => {
        return handleUpdateEvent(eventService.updateEvent(eventId, dto), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'event has been updated',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        updateEvent: updateEventCall,
        data,
        loading,
        errors,
        resetData,
    };
};
