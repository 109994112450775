import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button, {ButtonProps} from 'src/view/components/button/Button';
import {CSSProperties} from 'react';
import {OptionalChildrenBaseComponent} from 'src/view/interfaces/BaseComponent';
import classnames from 'classnames';

export interface FormButtonProps extends OptionalChildrenBaseComponent {
    buttons?: ButtonProps[];
    justifyContent?: CSSProperties['justifyContent'];
}

const useStyles = makeStyles((theme: Theme) => ({
    buttonContainer: ({justifyContent}: Partial<FormButtonProps>) => ({
        display: 'flex',
        justifyContent: justifyContent || 'flex-end',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        width: '100%',
    }),
    spacingRight: {
        marginRight: theme.spacing(2),
    },
}));

export default function FormButtons({buttons, justifyContent, children}: FormButtonProps) {
    const classes = useStyles({justifyContent});

    if (!children && !buttons) {
        console.warn('FormButtons: You did not provide any children and buttons');

        return <></>;
    }

    const renderButtons = (): JSX.Element[] | JSX.Element => {
        if (!buttons) return <></>;

        return buttons.map((props, i) => {
            if (props.hidden) return <></>;

            return (
                <Button
                    key={`formButtons-${i}`}
                    className={classnames(i !== buttons.length - 1 && classes.spacingRight)}
                    dataCy={props.dataCy}
                    {...props}
                />
            );
        });
    };

    return <div className={classes.buttonContainer}>{children || renderButtons()}</div>;
}
