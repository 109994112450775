import {useMutation, useQueryClient} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {createEventCategory} from 'src/data/services/eventCategoriesService';
import {EVENT_CATEGORIES_QUERY_KEY} from 'src/app/hooks/event-categories/useEventCategories';

const useCreateEventCategory = (onSuccess: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createEventCategory,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [EVENT_CATEGORIES_QUERY_KEY],
            });
            onSuccess();
        },
        onError: (errors) => {
            Toaster.toastErrors(parseErrors(errors));
        },
    });
};

export default useCreateEventCategory;
