import Header from 'src/view/components/header/Header';
import {Grid} from '@mui/material';
import {ETBContainer} from 'src/view/components/container/container';
import {CreatePurchaseFormFeature} from 'src/app/components/features/purchase/CreatePurchaseFormFeature';

const AddPurchase = () => {
    return (
        <>
            <Header>
                <Grid container spacing={0.5}>
                    <Grid item>
                        <h1>Add New Purchase</h1>
                    </Grid>
                </Grid>
            </Header>
            <ETBContainer>
                <CreatePurchaseFormFeature/>
            </ETBContainer>
        </>
    );
};

export default AddPurchase;
