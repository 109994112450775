import {useQuery} from '@tanstack/react-query';
import {fetchEventBookingOptions} from 'src/data/services/booking-options-service';

export const EVENT_BOOKING_OPTIONS_QUERY_KEY = 'EVENT_BOOKING_OPTIONS';
const useFetchEventBookingOptions = (eventId: string | undefined) => {
    return useQuery({
        queryKey: [EVENT_BOOKING_OPTIONS_QUERY_KEY, eventId],
        queryFn: () => fetchEventBookingOptions(eventId || ''),
        enabled: !!eventId,
    });
};

export default useFetchEventBookingOptions;
