import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import {Box, IconButton} from '@mui/material';
import {useConfirm} from 'material-ui-confirm';
import {useState} from 'react';
import {useDeleteVenue} from 'src/app/hooks/venues/useDeleteVenue';
import type {Venue} from 'src/data/models/venue/venue';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_WIDTH,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import {CreateEditVenueModal} from '../features/venues/CreateEditVenueModal';
import {SeatingPlansOverview} from '../features/venues/seating-plans-overview';

export interface Props {
    venues?: Venue[];
    loading: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sortings: TableColumnSorting[]) => void;
}

export default function VenuesTable({
                                        venues,
                                        loading,
                                        pagination,
                                        onSortingChange,
                                    }: Props): JSX.Element {
    const confirm = useConfirm();

    const [isShowDetailsModalOpen, setIsShowDetailsModalOpen] = useState(false);
    const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);

    const [selectedVenue, setSelectedVenue] = useState<{
        name: string;
        id: string;
    } | null>(null);

    const {mutate: deleteVenue} = useDeleteVenue();

    const columns: NewTableColumn<Venue>[] = [
        {
            key: 'name',
            title: 'Name',
            cellRenderer: (rowData) => rowData.name,
        },
        {
            key: 'address.addressLine1',
            title: 'Address',
            cellRenderer: (rowData) => rowData.address.addressLine1,
        },
        {
            key: 'address.addressLine2',
            title: 'Address Line 2',
            cellRenderer: (rowData) => rowData.address?.addressLine2 || '',
        },
        {
            key: 'address.postalCode',
            title: 'Postal Code',
            cellRenderer: (rowData) => rowData.address.postalCode,
        },
        {
            key: 'address.city',
            title: 'City',
            cellRenderer: (rowData) => rowData.address.city,
        },
        {
            key: 'address.countryCode',
            title: 'Country Code',
            cellRenderer: (rowData) => rowData.address.countryCode || '',
        },
        {
            align: 'right',
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 0.75,
                        }}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setSelectedVenue({id: rowData.id, name: rowData.name});
                                setIsEditingModalOpen(true);
                            }}
                        >
                            <EditIcon/>
                        </IconButton>

                        <IconButton
                            color="primary"
                            onClick={() => {
                                setSelectedVenue({id: rowData.id, name: rowData.name});
                                setIsShowDetailsModalOpen(true);
                            }}
                        >
                            <VisibilityOutlined/>
                        </IconButton>

                        <IconButton
                            color="error"
                            onClick={async () => {
                                try {
                                    await confirm({
                                        title: `Are you sure you want to delete ${rowData.name}?`,
                                        description: 'This action is irreversible.',
                                    });
                                    deleteVenue(rowData.id);
                                } catch {
                                    /* User canceled */
                                }
                            }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <>
            <Table<Venue>
                data={venues}
                rowIdResolver={(rowData: Venue) => ({
                    id: rowData.id,
                })}
                columns={columns}
                loading={loading}
                minWidth={650}
                pagination={pagination}
                onSortingChange={onSortingChange}
            />

            {isShowDetailsModalOpen && selectedVenue && (
                <SeatingPlansOverview
                    venue={selectedVenue}
                    setIsShowingDetails={setIsShowDetailsModalOpen}
                />
            )}

            {isEditingModalOpen && selectedVenue && (
                <CreateEditVenueModal
                    venueId={selectedVenue?.id}
                    setModalOpenState={setIsEditingModalOpen}
                />
            )}
        </>
    );
}
