import {useEffect} from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import eventService, {EventOverviewFetchOptions} from 'src/data/services/eventService';
import {Event} from 'src/data/models/events/event';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {EventOverviewMeta} from 'src/data/models/events/eventOverviewMeta';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';

interface FetchEventsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Event, EventOverviewMeta>> {
    fetchEvents: (
        options?: BaseFetchOptions
    ) => Promise<PaginatedApiResponseBody<Event, EventOverviewMeta>>;
}

interface FetchEventsDataWrapperProps
    extends DataWrapperProps<
        FetchEventsDataWrapperChildrenParams,
        PaginatedApiResponseBody<Event, EventOverviewMeta>
    >,
        EventOverviewFetchOptions {
    purchaseId?: string;
    orderId?: string;
    seatingPlan?: string;
}

export const useFetchEventsDataWrapper = ({
                                              includes = [],
                                              onError,
                                              onSuccess,
                                              invalidatedAt,
                                              purchaseId,
                                              orderId,
                                              seatingPlan,
                                              page,
                                              pageSize,
                                              filter,
                                              sorting,
                                              q,
                                              skipInitialCall,
                                          }: Omit<FetchEventsDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleFetchEventById] =
        useApiFetch<PaginatedApiResponseBody<Event, EventOverviewMeta>>();

    const fetchEvents = (options?: BaseFetchOptions) => {
        return handleFetchEventById(
            eventService.fetchEvents({
                includes,
                purchaseId,
                orderId,
                seatingPlan,
                page,
                pageSize,
                filter,
                sorting,
                q,
                ...options,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        if (skipInitialCall) return;

        fetchEvents();
    }, [page, pageSize, filter, sorting, q, invalidatedAt, purchaseId, orderId]);

    return {
        fetchEvents,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchEventsDataWrapper = (props: FetchEventsDataWrapperProps) => {
    const {children} = props;
    const hookData = useFetchEventsDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
