import {createTheme} from '@mui/material/styles';
import baseTheme from 'src/app/theme/base';

const etbaasTheme = createTheme({
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        primary: {
            light: '#E6F7F2',
            main: '#0b342b',
            dark: '#092b24',
        },
        secondary: {
            main: '#f0a647',
            dark: '#db941f',
            contrastText: '#fff',
        },
        tertiary: {
            light: '#0ac297',
            main: '#05765c',
            dark: '#045240',
            contrastText: '#fff',
        },
    },
    colors: {
        ...baseTheme.colors,
        buttonBackgroundColor: {normal: '#0b342b', hover: '#092b24'},
        buttonTextColor: {normal: '#fff', hover: '#fff'},
    },
});

export default etbaasTheme;
