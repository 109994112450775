import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

export interface NoteProps {
    title: string;
    message: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(2),
        background: theme.colors.lightestGrey,
        borderRadius: theme.layout.borderRadius.regular,
    },
    title: {
        'font-weight': theme.typography.fontWeightBold,
        marginTop: 0,
        marginBottom: theme.spacing(0.25),
    },
}));

export default function Note({title, message, className}: NoteProps) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className)}>
            <h4 className={classes.title}>{title}</h4>
            <div>{message}</div>
        </div>
    );
}
