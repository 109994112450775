import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    SEATING_PLAN_CATEGORY_PROPERTIES_CREATE_PROPERTY_ENDPOINT,
    SEATING_PLAN_CATEGORY_PROPERTIES_DELETE_PROPERTY_ENDPOINT,
    SEATING_PLAN_CATEGORY_PROPERTIES_GET_PROPERTIES_ENDPOINT,
    SEATING_PLAN_CATEGORY_PROPERTIES_GET_PROPERTY_ENDPOINT,
    SEATING_PLAN_CATEGORY_PROPERTIES_UPDATE_PROPERTY_ENDPOINT,
} from 'src/data/constants/endpoints/seating-plan-category-properties-endpoints';
import {IdResponse} from 'src/data/models/common/idResponse';
import {SeatingPlanCategoryProperty} from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import {EmptyBody} from '../models/common/emptyBody';

async function getProperties(
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<SeatingPlanCategoryProperty>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options?.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<SeatingPlanCategoryProperty>>(
            SEATING_PLAN_CATEGORY_PROPERTIES_GET_PROPERTIES_ENDPOINT,
            {
                params: options && getQueryParams(options, extraQueryParams),
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getPropertyById(
    propertyId: string
): Promise<ApiResponse<ApiResponseBody<SeatingPlanCategoryProperty>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<SeatingPlanCategoryProperty>>(
            SEATING_PLAN_CATEGORY_PROPERTIES_GET_PROPERTY_ENDPOINT(propertyId)
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteProperty(
    propertyId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            SEATING_PLAN_CATEGORY_PROPERTIES_DELETE_PROPERTY_ENDPOINT(propertyId)
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createProperty({
                                  key,
                                  internalDescription,
                                  supportsValue,
                              }: Partial<SeatingPlanCategoryProperty>): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(
            SEATING_PLAN_CATEGORY_PROPERTIES_CREATE_PROPERTY_ENDPOINT,
            {
                key,
                internalDescription,
                supportsValue,
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateProperty(
    propertyId: string,
    {key, internalDescription, supportsValue}: Omit<SeatingPlanCategoryProperty, 'id'>
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(
            SEATING_PLAN_CATEGORY_PROPERTIES_UPDATE_PROPERTY_ENDPOINT(propertyId),
            {key, internalDescription, supportsValue}
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {getProperties, getPropertyById, createProperty, updateProperty, deleteProperty};
