import {useMutation} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';

type TData = Awaited<ReturnType<typeof seatingPlanCategoryService.deleteItineraryPdf>>;

type TVariables = {
    seatingplanCategoryId: string;
    fileId: string;
};

export default function useDeleteItieneraryFile(
    onSuccess?: (data: TData, vars: TVariables) => unknown
) {
    return useMutation({
        mutationFn: ({seatingplanCategoryId, fileId}: TVariables) =>
            seatingPlanCategoryService.deleteItineraryPdf(seatingplanCategoryId, fileId),
        onSuccess: (data, vars) => {
            Toaster.toast('Itinerary file deleted successfully', {variant: 'success'});
            onSuccess?.(data, vars);
        },
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
}
