import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from '@mui/styles';
import {Chip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme) => ({
    listItem: {
        margin: 0,
        padding: 0,
        marginBottom: '5px',
    },
    listItemContent: {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(16),
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        maxWidth: 'calc(100% - 45px)',
        flexWrap: 'wrap',
    },
    text: {
        marginRight: '0.5rem',
        wordBreak: 'break-word',
    },
    buttons: {
        width: '45px',
        display: 'flex',
        marginLeft: 'auto',
        alignSelf: 'flex-start',
    },
    closeIcon: {
        marginTop: theme.spacing(0.5),
    },
}));

export interface ListItemProps {
    text: string;
    value: string | number | null;
    removable: boolean;
    editable?: boolean;
    onClickEdit?: () => void;
    onRemove?: () => void;
    chipInfo?: string;
}

const ListItem = ({
                      text,
                      removable = false,
                      onRemove,
                      chipInfo,
                      onClickEdit,
                      editable,
                  }: ListItemProps) => {
    const classes = useStyles();

    return (
        <li className={classes.listItem}>
            <div className={classes.listItemContent}>
                <div className={classes.content}>
                    <span className={classes.text}>{text}</span>
                    {chipInfo && (
                        <Chip size="small" title={chipInfo} label={chipInfo} variant="filled"/>
                    )}
                </div>

                <div className={classes.buttons}>
                    {editable && (
                        <EditIcon color="primary" onClick={onClickEdit} cursor="pointer"/>
                    )}
                    {removable && (
                        <CloseIcon
                            className={classes.closeIcon}
                            color="error"
                            onClick={onRemove}
                            cursor="pointer"
                        />
                    )}
                </div>
            </div>
        </li>
    );
};

export default ListItem;
