import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    TAGS_CREATE_ENDPOINT,
    TAGS_GET_ENDPOINT,
} from 'src/data/constants/endpoints/tags-endpoints';
import {CreateTagDto} from 'src/data/dtos/createTagDto';
import {TAG_TYPES} from 'src/data/enums/tagType';
import {IdResponse} from 'src/data/models/common/idResponse';
import {Tag} from 'src/data/models/tag/tag';

async function getTags(
    type: TAG_TYPES,
    options: BaseFetchOptions = {},
    signal?: AbortSignal
): Promise<ApiResponse<PaginatedApiResponseBody<Tag>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    extraQueryParams.push({
        key: 'tagType',
        value: type,
    });

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Tag>>(TAGS_GET_ENDPOINT, {
            params: getQueryParams(options, extraQueryParams),
            signal,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createTag(dto: CreateTagDto): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(TAGS_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {getTags, createTag};
