import React, {CSSProperties} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from '@mui/styles';
import {Theme} from '@mui/material';

type VariantType = 'blue' | 'green' | 'red' | 'grey';
type StatusColorMapObjectType = { [key in VariantType]: CSSProperties['color'] };

const statusColorMap = (theme: Theme): StatusColorMapObjectType => ({
    blue: theme.colors.blue,
    green: theme.palette.success.main,
    red: theme.palette.error.main,
    grey: theme.colors.grey,
});

const useStyles = makeStyles((theme) => ({
    container: (props: Partial<StatusLabelProps>) => ({
        background: props.variant
            ? statusColorMap(theme)[props.variant]
            : statusColorMap(theme)['grey'],
        color: props.textColor ? props.textColor : 'white',
        borderRadius: theme.layout.borderRadius.large,
        padding: theme.spacing(0.3, 0.875),
        fontSize: 11,
    }),
}));

interface StatusLabelProps {
    text: string;
    variant: VariantType;
    textColor?: React.CSSProperties['color'];
}

export const StatusLabel = ({text, variant}: StatusLabelProps) => {
    const theme = useTheme();
    const classes = useStyles({variant});

    return (
        <div
            className={classes.container}
            style={{
                background: statusColorMap(theme)[variant],
            }}
        >
            {text}
        </div>
    );
};
