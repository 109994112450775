import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import EditIcon from '@mui/icons-material/Edit';
import {makeStyles} from '@mui/styles';
import {IconButton} from '@mui/material';
import {SeatingPlanCategoryProperty} from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(() => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

export interface Props {
    properties?: SeatingPlanCategoryProperty[];
    loading: boolean;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    pagination?: TablePaginationData;
}

export default function SeatingPlanCategoryPropertiesTable({
                                                               properties,
                                                               loading,
                                                               onEditClick,
                                                               onDeleteClick,
                                                               pagination,
                                                           }: Props): JSX.Element {
    const classes = useStyles();
    const [columns, setColumns] = React.useState<NewTableColumn<SeatingPlanCategoryProperty>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<SeatingPlanCategoryProperty>[] = [];

        cols = [
            {
                key: 'key',
                title: 'Property',
                cellRenderer: (rowData) => rowData.key,
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                key: 'internalDescription',
                title: 'Internal notes',
                cellRenderer: (rowData) => rowData.internalDescription,
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                align: 'right',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    return (
                        <div className={classes.buttonsContainer}>
                            <IconButton
                                onClick={() => onEditClick(rowData.id || '')}
                                color="primary"
                                size={'small'}
                            >
                                <EditIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => onDeleteClick(rowData.id || '')}
                                color="error"
                                size={'small'}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                    );
                },
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<SeatingPlanCategoryProperty>
            data={properties}
            rowIdResolver={(rowData: SeatingPlanCategoryProperty) => ({
                id: `property-${rowData.key}`,
            })}
            columns={columns}
            loading={loading}
            minWidth={650}
            pagination={pagination}
        />
    );
}
