import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid} from '@mui/material';
import {useTheme} from '@mui/styles';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {FormProps} from 'src/app/interfaces/form/formProps';
import getIsGuaranteedSeatsDisabled from 'src/app/utilities/helpers/getIsGuaranteedSeatsDisabled';
import {zodOptionalNumberInputSchema} from 'src/app/utilities/zod/zodOptionalNumberInputSchema';
import {zodRequiredDropdownSelectSchema} from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {AutoCompleteOptions} from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import z from 'zod';

export const ticketValidationSchema = z.object({
    seatingplanCategory: zodRequiredDropdownSelectSchema,
    algorithm: zodRequiredDropdownSelectSchema,
    seatGuarantee: zodOptionalNumberInputSchema.optional(),
});

export type OrderlinesAutoAssignFormValues = z.infer<typeof ticketValidationSchema>;

interface OrderlinesAutoAssignFormProps {
    seatingplanCategoryOptions: AutoCompleteOptions;
    algorithmOptions: AutoCompleteOptions;
}

type OrderlinesAutoAssignFormPropsType = FormProps<OrderlinesAutoAssignFormValues> &
    OrderlinesAutoAssignFormProps;

export const OrderlinesAutoAssignForm = ({
                                             seatingplanCategoryOptions,
                                             algorithmOptions,
                                             onSubmit,
                                             loading,
                                         }: OrderlinesAutoAssignFormPropsType) => {
    const theme = useTheme();
    const {control, handleSubmit, watch, setValue} = useForm<OrderlinesAutoAssignFormValues>({
        mode: 'onChange',
        resolver: zodResolver(ticketValidationSchema),
    });

    const algorithm = watch('algorithm');

    const isSeatGuaranteeDisabled = getIsGuaranteedSeatsDisabled(algorithm?.value);

    useEffect(() => {
        if (getIsGuaranteedSeatsDisabled(algorithm?.value)) {
            setValue('seatGuarantee', undefined);
        }
    }, [algorithm]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ModalSubTitle>Seatingplan category*</ModalSubTitle>
                <Controller
                    name="seatingplanCategory"
                    control={control}
                    render={({field: {onChange, value, name}, fieldState: {error}}) => {
                        return (
                            <>
                                <AutoComplete
                                    name={name}
                                    onChange={(option) => {
                                        onChange(option);
                                    }}
                                    value={value}
                                    isClearable
                                    options={seatingplanCategoryOptions}
                                    placeholder="Select a seatingplan category"
                                    loading={loading}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <ModalSubTitle>Select algorithm*</ModalSubTitle>
                <Controller
                    name="algorithm"
                    control={control}
                    render={({field: {onChange, value, name}, fieldState: {error}}) => {
                        return (
                            <>
                                <AutoComplete
                                    name={name}
                                    onChange={(option) => {
                                        onChange(option);
                                    }}
                                    value={value}
                                    isClearable
                                    options={algorithmOptions}
                                    placeholder="Select an algorithm"
                                    loading={loading}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <ModalSubTitle>Seat guarantee</ModalSubTitle>
                <Controller
                    name="seatGuarantee"
                    control={control}
                    render={({field: {onChange, value, name}, fieldState: {error}}) => {
                        return (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isSeatGuaranteeDisabled}
                                />
                                <FormFieldError message={error?.message}/>
                            </>
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormButtons
                    buttons={[
                        {
                            disabled: loading,
                            startIcon: loading && (
                                <CircularProgress size={theme.layout.loader.sizes.small}/>
                            ),
                            children: 'Run',
                            onClick: () => handleSubmit((values) => onSubmit(values))(),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};
