import type {Theme} from '@material-ui/core';
import type {SxProps} from '@mui/material';
import Box from '@mui/material/Box';
import {formatPrice} from 'src/shared/helpers/formatPrice';

interface PriceProps {
    inParenthesis?: boolean;
    grey?: boolean;
    price:
        | {
        currency: string;
        value?: number;
    }
        | null
        | undefined;
    sx?: SxProps<Theme>;
}

export function Price({price, grey, inParenthesis, sx = []}: PriceProps) {
    const priceText = formatPrice({
        currency: price?.currency,
        amount: price?.value || 0,
        showCurrency: true,
    });

    return (
        <Box
            component="span"
            sx={[
                {color: (theme) => (grey ? theme.colors.grey : undefined)},
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {inParenthesis ? `(${priceText})` : priceText}
        </Box>
    );
}
