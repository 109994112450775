import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import {customStyles} from 'src/view/components/auto-complete/constants';
import {Button, ButtonProps, useTheme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {ActionMeta, Theme} from 'react-select';
import {Theme as MuiTheme} from '@mui/material';
import {useScrollbarStyles} from 'src/shared/styles/scrollbarStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme: MuiTheme) => ({
    selectContainer: {
        display: 'flex',
        flex: 1,
    },
    autoComplete: {
        flex: 1,
    },
    button: {
        background: theme.colors.white,
        border: `1px solid #0000003b`,
        borderLeft: 0,
        boxShadow: 'none',
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        fontSize: theme.spacing(1.5),
        borderRadius: `0 ${theme.layout.borderRadius.regular}px ${theme.layout.borderRadius.regular}px 0`,
        '& svg': {
            fill: theme.colors.grey,
        },
    },
}));

export interface AsyncAutoCompleteProps {
    buttonProps?: ButtonProps;
    value?: AutoCompleteValueOption;
    placeholder?: string;
    onInputChange?: (value: string) => void;
    onChange: (value: AutoCompleteValueOption, action?: ActionMeta<AutoCompleteOption>) => void;
    loadOptions: (inputValue: string) => Promise<AutoCompleteOptions>;
    onCreateOption?: ((value: string) => void) | ((value: string) => Promise<void>);
    disabled?: boolean;
    name: string;
    backgroundColor?: React.CSSProperties['backgroundColor'];
    isMulti?: boolean;
    isClearable?: boolean;
}

export default function AsyncAutoComplete({
                                              buttonProps,
                                              value,
                                              onChange,
                                              loadOptions,
                                              onCreateOption,
                                              placeholder,
                                              disabled,
                                              name,
                                              backgroundColor,
                                              isMulti,
                                              isClearable = true,
                                          }: AsyncAutoCompleteProps): JSX.Element {
    const classes = useStyles();
    const scrollbarClasses = useScrollbarStyles();
    const theme = useTheme<MuiTheme>();

    const themeConfig = (themeConfig: Theme) => ({
        ...themeConfig,
        colors: {
            ...themeConfig.colors,
            primary: theme.palette.primary.main,
            primary25: theme.palette.primary.light,
        },
    });

    const onInputChange = () => {
        _.debounce(() => undefined, 800);
    };

    const onChangeFn = (value: AutoCompleteValueOption) => {
        if (value === null) value = undefined;

        onChange(value);
    };

    return (
        <div className={classnames(classes.selectContainer, scrollbarClasses.hideScrollbar)}>
            {!onCreateOption && (
                <AsyncSelect
                    key={`key-${name}-${value}`}
                    isDisabled={disabled}
                    value={value}
                    className={classes.autoComplete}
                    menuPosition="fixed"
                    theme={themeConfig}
                    loadOptions={loadOptions}
                    isClearable={isClearable}
                    defaultOptions
                    styles={customStyles(
                        theme,
                        !!buttonProps,
                        !disabled ? backgroundColor : undefined
                    )}
                    placeholder={placeholder}
                    onInputChange={onInputChange}
                    onChange={onChangeFn}
                    isMulti={isMulti}
                />
            )}

            {onCreateOption && (
                <AsyncCreatableSelect
                    key={`key-${name}-${value}`}
                    menuPosition="fixed"
                    isDisabled={disabled}
                    value={value}
                    className={classes.autoComplete}
                    theme={themeConfig}
                    loadOptions={loadOptions}
                    isClearable={isClearable}
                    defaultOptions
                    styles={customStyles(
                        theme,
                        !!buttonProps,
                        !disabled ? backgroundColor : undefined
                    )}
                    placeholder={placeholder}
                    onInputChange={onInputChange}
                    onChange={onChangeFn}
                    onCreateOption={onCreateOption}
                    isMulti={isMulti}
                />
            )}

            {buttonProps && (
                <Button
                    {...buttonProps}
                    className={classNames(classes.button, buttonProps.className)}
                    disableRipple
                >
                    {buttonProps.children}
                </Button>
            )}
        </div>
    );
}
