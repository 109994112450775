import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TableColumnSorting,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import {Team} from 'src/data/models/team/team';
import makeStyles from '@mui/styles/makeStyles';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import EventsOverviewUrlService from 'src/app/pages/events/eventsOverviewUrlService';

export interface SeriesTableProps {
    teams: Team[];
    loading?: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sorting: TableColumnSorting[]) => void;
    onEditTeam: (t: Team) => void;
    onDeleteTeam: (teamId: Team['id']) => void;
    onChangeSelectedRows?: (selectedRows: RowIdResolver[]) => void;
    initialSelectedRows?: RowIdResolver[];
}

const useStyles = makeStyles(() => ({
    colorBlock: {
        height: 15,
        width: 15,
        borderRadius: '50%',
    },
    eventsLink: {
        textDecoration: 'underline',
    },
}));

export default function TeamsTable({
                                       loading,
                                       teams = [],
                                       pagination,
                                       onSortingChange,
                                       onEditTeam,
                                       onDeleteTeam,
                                       onChangeSelectedRows,
                                       initialSelectedRows,
                                   }: SeriesTableProps): JSX.Element {
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();

    const [columns, setColumns] = React.useState<NewTableColumn<Team>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<Team>[] = [];

        cols = [
            {
                title: '',
                cellRenderer: (t) => {
                    const {r, g, b} = t.color;

                    return (
                        <div
                            className={classes.colorBlock}
                            style={{
                                background: `rgb(${r}, ${g}, ${b})`,
                            }}
                        />
                    );
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                key: 'name',
                title: 'Name',
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                key: 'shortName',
                title: 'Short Name',
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'abbreviation',
                title: 'Abbreviation',
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'countryCode',
                title: 'Country',
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'city',
                title: 'City',
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'numEvents',
                title: '# of events',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (team) => (
                    <a
                        className={classes.eventsLink}
                        href={EventsOverviewUrlService.root({teamId: team.id})}
                    >
                        {team.numEvents}
                    </a>
                ),
            },
            {
                align: 'right',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (team) => (
                    <>
                        <IconButton
                            className={spacingClasses.spacingRight}
                            onClick={() => onEditTeam(team)}
                            color="primary"
                            size="small"
                        >
                            <EditIcon/>
                        </IconButton>
                        <IconButton
                            color="error"
                            size={'small'}
                            onClick={() => onDeleteTeam(team.id)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </>
                ),
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<Team>
            data={teams}
            rowIdResolver={(rowData: Team) => ({id: rowData.id})}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            onSortingChange={onSortingChange}
            onChangeSelectedRows={onChangeSelectedRows}
            initialSelectedRows={initialSelectedRows}
        />
    );
}
