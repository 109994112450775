import urlService from 'src/app/services/urlService';

const travelwareUrlService = {
    events(eventId: string) {
        return urlService.getTravelWareSystemAdmin(`events?filters[main_event_uuid]=${eventId}`);
    },
    orderNumberRedirect(orderNumber: string) {
        return urlService.getTravelwareOrders(`${orderNumber}/redirect`);
    },
};

export default travelwareUrlService;
