import LaunchIcon from '@mui/icons-material/Launch';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useSetFilesToTicketsDataWrapper} from 'src/app/components/data-wrappers/files/SetFilesToTicketsDataWrapper';
import {
    BatchUploadTicketsForm,
    type BatchUploadTicketsValidationSchemeType,
} from 'src/app/components/forms/tickets/batch-upload-tickets/BatchUploadTicketsForm';
import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import isTicketAlreadyLinkedError from 'src/data/api/type-guards/isTicketAlreadyLinkedError';
import EventTicketFile from 'src/data/models/tickets/eventTicketFile';
import filesService, {GetAvailableTicketsCountResponse} from 'src/data/services/filesService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import Modal, {ModalBody} from 'src/view/components/modal/Modal';
import {OptionsList} from 'src/view/components/options-list/OptionsList';

interface BatchUploadTicketsFeatureProps {
    eventId: string;
    eventName?: string;
}

// Is an array containing arrays of duplicates, hence the [][]
export type DuplicateTicketsInTheBatchType = {
    fileId: string;
    fileName: string;
}[][];

export const BatchUploadTicketFilesFeature = ({
                                                  eventId,
                                                  eventName,
                                              }: BatchUploadTicketsFeatureProps) => {
    const {setFilesToTickets, loading: setFilesToTicketsIsLoading} =
        useSetFilesToTicketsDataWrapper({});
    const [
        {data: availableTicketsCountData, loading: availableTicketsCountIsLoading},
        handleGetAvailableTicketsCount,
    ] = useApiFetch<ApiResponseBody<GetAvailableTicketsCountResponse>>();
    const [showRedirectModal, setShowRedirectModal] = useState(false);

    const [alreadyLinkedTickets, setAlreadyLinkedTickets] = useState<EventTicketFile[]>([]);

    const {enqueueSnackbar} = useSnackbar();

    const [duplicateTickets, setDuplicateTickets] = useState<DuplicateTicketsInTheBatchType>([]);

    const onSubmit = async (values: BatchUploadTicketsValidationSchemeType) => {
        const fileIds: string[] = [];

        values.uploadedFileIds.forEach((fileData) => {
            if (fileData.fileId === null) return;

            fileIds.push(fileData.fileId);
        });

        try {
            await setFilesToTickets(
                {
                    seatingplanCategoryId: values.category.value || '',
                    eventId,
                    blockNumber: values.block,
                    rowNumber: values.row,
                    fileUploadIds: fileIds,
                    supplierId: values.supplier?.value || undefined,
                },
                {
                    onSuccess: () => {
                        setShowRedirectModal(true);
                        setAlreadyLinkedTickets([]);
                    },
                }
            );
        } catch (e) {
            const error = e as ErrorReturn;
            const parsedErrors = error.parsedErrors;

            if (isTicketAlreadyLinkedError(parsedErrors[0])) {
                setAlreadyLinkedTickets(parsedErrors[0].eventTicketFiles);

                return;
            }

            if (error.parsedErrors[0].code === 'CanNotUploadDuplicateFilesException') {
                setDuplicateTickets(JSON.parse(error.parsedErrors[0]?.detail || ''));

                return;
            }

            enqueueSnackbar(<ErrorsList errors={parsedErrors} plainStyle/>, {
                variant: 'error',
            });
        }
    };

    const fetchAvailableTicketsCount = (
        values: Partial<BatchUploadTicketsValidationSchemeType>
    ) => {
        handleGetAvailableTicketsCount(
            filesService.getAvailableTicketsCount({
                filter: [
                    {
                        property: 'eventId',
                        value: eventId,
                    },
                    {
                        property: 'seatingPlanCategoryId',
                        value: values.category?.value || '',
                    },
                    {
                        property: 'supplierId',
                        value: values.supplier?.value || '',
                    },
                ],
            })
        );
    };

    const renderRedirectOptionsModal = () => {
        return (
            <Modal
                open={showRedirectModal}
                title="Tickets were attached successfully"
                subTitle="Where do you want to redirect to?"
                onClose={() => setShowRedirectModal(false)}
            >
                <ModalBody>
                    <OptionsList
                        items={[
                            {
                                label: `${eventName} - Tickets overview`,
                                actions: [
                                    {
                                        icon: <LaunchIcon fontSize="small"/>,
                                        url: EventUrlService.tickets(eventId),
                                    },
                                ],
                            },
                        ]}
                    />
                </ModalBody>
            </Modal>
        );
    };

    const hasZeroTicketsAvailable = () => {
        const availableTicketCount = availableTicketsCountData?.data.availableTicketCount || '0';

        return availableTicketCount === '0';
    };

    return (
        <>
            <BatchUploadTicketsForm
                onSubmit={onSubmit}
                eventId={eventId}
                loading={setFilesToTicketsIsLoading || availableTicketsCountIsLoading}
                disableSubmit={hasZeroTicketsAvailable()}
                onChangeValues={fetchAvailableTicketsCount}
                ticketsAffected={availableTicketsCountData?.data.availableTicketCount}
                ticketsCountIsLoading={availableTicketsCountIsLoading}
                alreadyLinkedTickets={alreadyLinkedTickets}
                duplicateTickets={duplicateTickets}
            />
            {renderRedirectOptionsModal()}
        </>
    );
};
