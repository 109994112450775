import {Alert, Grid} from '@mui/material';
import OrdersTableFeature from 'src/app/components/features/orders/OrdersTableFeature';
import {useParams} from 'react-router-dom';
import {ETBContainer} from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import {ORDER_TABLE_VARIANTS} from 'src/app/components/tables/OrdersOverviewTable';

type Params = {
    eventId: string;
};

interface EventOrdersOverviewProps {
    eventName: string;
}

const EventOrdersOverview = ({eventName}: EventOrdersOverviewProps) => {
    const {eventId} = useParams<Params>();

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    return (
        <>
            <Header>
                <Grid item>
                    <h1>{eventName}</h1>
                </Grid>
            </Header>
            <ETBContainer>
                <OrdersTableFeature variant={ORDER_TABLE_VARIANTS.EventOrders} eventId={eventId}/>
            </ETBContainer>
        </>
    );
};

export default EventOrdersOverview;
