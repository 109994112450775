import uniqBy from 'lodash/uniqBy';
import {useCallback, useMemo, useState} from 'react';
import {
    OnCreateBlockSuccessType,
    useCreateBlockDataWrapper,
} from 'src/app/components/data-wrappers/block/CreateBlockDataWrapper';
import {
    OnCreateRowSuccessType,
    useCreateRowDataWrapper,
} from 'src/app/components/data-wrappers/row/CreateRowDataWrapper';
import {
    BatchEditTicketsForm,
    BatchEditTicketsFormProps,
    type BatchEditTicketsFormValues,
} from 'src/app/components/forms/tickets/batch-edit-tickets/BatchEditTicketsForm';
import Seat from 'src/data/models/seat/seat';
import {AutoCompleteValueOption} from 'src/view/components/auto-complete/interfaces';

interface BatchEditTicketFormFeatureProps
    extends Omit<BatchEditTicketsFormProps, 'blockOptions' | 'rowOptions'> {
    seats: Seat[];
    onSubmit?: (values: BatchEditTicketsFormValues) => void;
    seatingplanCategoryId: string;
    onBlockCreated?: (data?: OnCreateBlockSuccessType) => void;
    onRowCreated?: (data?: OnCreateRowSuccessType) => void;
}

export const BatchEditTicketFormFeature = ({
                                               seats,
                                               ticket,
                                               onChangeBlockOption,
                                               onChangeRowOption,
                                               onChangeSeatOption,
                                               onSubmit,
                                               seatingplanCategoryId,
                                               variant,
                                               onBlockCreated,
                                               onRowCreated,
                                           }: BatchEditTicketFormFeatureProps) => {
    const [selectedBlock, setSelectedBlock] = useState<AutoCompleteValueOption>();
    const [selectedRow, setSelectedRow] = useState<AutoCompleteValueOption>();

    const seatNumber = useMemo(() => {
        return ticket?.seatNumber || '';
    }, [ticket?.seatNumber]);

    const {createBlock} = useCreateBlockDataWrapper({
        onSuccess: (data) => {
            onBlockCreated?.(data);
            setSelectedBlock({
                value: data?.id,
                label: data?.name,
            });
        },
    });

    const {createRow} = useCreateRowDataWrapper({
        onSuccess: (data) => {
            onRowCreated?.(data);
            setSelectedRow({
                value: data?.id,
                label: data?.name,
            });
        },
    });

    const blocksBelongingToCategory = useMemo(
        () => seats.filter((seat) => seat.seatingPlanCategoryId === seatingplanCategoryId),
        [seatingplanCategoryId, seats]
    );

    const blockOptions = useMemo(
        () =>
            blocksBelongingToCategory.map((s: Seat) => ({
                value: s.blockId,
                label: s.blockNumber,
            })),
        [blocksBelongingToCategory]
    );
    const uniqueBlockOptions = useMemo(() => uniqBy(blockOptions, (b) => b.label), [blockOptions]);

    const rowOptionMapper = useMemo(
        () => (s: Seat) => ({
            value: s.rowId,
            label: s.rowNumber,
        }),
        []
    );

    const rowOptions = selectedBlock
        ? seats.filter((s) => s.blockId === selectedBlock.value).map(rowOptionMapper)
        : seats.map(rowOptionMapper);

    const uniqueRowOptions = useMemo(() => uniqBy(rowOptions, (r) => r.label), [rowOptions]);

    const onCreateRow = useCallback(
        (name: string) => {
            createRow(
                name,
                seatingplanCategoryId,
                ticket?.blockNumber?.value || selectedBlock?.value
            );
        },
        [createRow, seatingplanCategoryId, selectedBlock?.value, ticket?.blockNumber?.value]
    );

    const onCreateBlock = useCallback(
        (name: string) => {
            createBlock(name, seatingplanCategoryId);
        },
        [createBlock, seatingplanCategoryId]
    );

    const onChangeBlockOptionHandler = useCallback(
        (value: AutoCompleteValueOption) => {
            setSelectedBlock(value);
            onChangeBlockOption?.(value);
        },
        [onChangeBlockOption]
    );

    const onChangeRowOptionHandler = useCallback(
        (value: AutoCompleteValueOption) => {
            setSelectedRow(value);
            onChangeRowOption?.(value);
        },
        [onChangeRowOption]
    );

    return (
        <BatchEditTicketsForm
            ticket={ticket}
            blockOptions={uniqueBlockOptions}
            rowOptions={uniqueRowOptions}
            onChangeBlockOption={onChangeBlockOptionHandler}
            onChangeRowOption={onChangeRowOptionHandler}
            onFormSubmit={onSubmit}
            onChangeSeatOption={(value) => onChangeSeatOption?.(value)}
            onCreateBlock={onCreateBlock}
            onCreateRow={onCreateRow}
            variant={variant}
            block={selectedBlock}
            row={selectedRow}
            seat={seatNumber}
        />
    );
};
