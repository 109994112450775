import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import {Supplier} from 'src/data/models/supplier/supplier';
import EditIcon from '@mui/icons-material/Edit';
import {makeStyles} from '@mui/styles';
import {IconButton, Typography} from '@mui/material';

const useStyles = makeStyles(() => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

export interface Props {
    suppliers?: Supplier[];
    loading: boolean;
    onEditClick: (id: string) => void;
    pagination?: TablePaginationData;
}

export default function SupplierTable({
                                          suppliers,
                                          loading,
                                          onEditClick,
                                          pagination,
                                      }: Props): JSX.Element {
    const classes = useStyles();
    const [columns, setColumns] = React.useState<NewTableColumn<Supplier>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<Supplier>[] = [];

        cols = [
            {
                key: 'name',
                title: 'Name',
                cellRenderer: (rowData) => {
                    if (rowData.isArchived) {
                        return (
                            <p>
                                {rowData.name}
                                <Typography
                                    fontSize={12}
                                    sx={{
                                        display: 'inline-flex',
                                        marginLeft: 0.5,
                                        color: (theme) => theme.palette.warning.main,
                                    }}
                                >
                                    (Archived)
                                </Typography>
                            </p>
                        );
                    }

                    return rowData.name;
                },
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                align: 'right',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    return (
                        <div className={classes.buttonsContainer}>
                            <IconButton
                                onClick={() => onEditClick(rowData.id)}
                                color="primary"
                                size={'small'}
                            >
                                <EditIcon/>
                            </IconButton>
                        </div>
                    );
                },
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<Supplier>
            data={suppliers}
            rowIdResolver={(rowData: Supplier) => ({
                id: `supplier-${rowData.name}`,
            })}
            columns={columns}
            loading={loading}
            minWidth={650}
            pagination={pagination}
        />
    );
}
