import {useState} from 'react';
import {Grid} from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import SeriesCreateFormFeature from 'src/app/components/features/series/SeriesCreateFormFeature';
import SeriesTableFeature from 'src/app/components/features/series/SeriesTableFeature';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {ETBContainer} from 'src/view/components/container/container';
import FilterOption from 'src/data/api/common/FilterOption';
import SeriesFilterFormDataWrapper from 'src/app/components/data-wrappers/series/SeriesFiltersFormDataWrapper';
import Button from 'src/view/components/button/Button';

export default function SeriesOverview() {
    const [createOpen, setCreateOpen] = useState(false);
    const [seriesInvalidatedAt, setSeriesInvalidatedAt] = useState<number | undefined>();

    const {
        values: {customFields, filterOptions},
        setCustomFields,
        setFilterOptions,
    } = useQueryParams('series');

    const onSuccessfullyCreateSeries = () => {
        setCreateOpen(false);
        setSeriesInvalidatedAt(Date.now());
    };

    const renderSeriesCreateModal = () => {
        return (
            <Modal title="Add New Series" open={createOpen} onClose={() => setCreateOpen(false)}>
                <SeriesCreateFormFeature onSucceed={onSuccessfullyCreateSeries}/>
            </Modal>
        );
    };

    return (
        <>
            {renderSeriesCreateModal()}

            <Header GridProps={{justifyContent: 'flex-end'}}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateOpen(true)}>
                        Add New Series
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <SeriesFilterFormDataWrapper
                    filters={filterOptions}
                    initialSearch={customFields['q'] || ''}
                    onChangeFilterOptions={(options: FilterOption[]) => {
                        setFilterOptions(options);
                        setSeriesInvalidatedAt(Date.now());
                    }}
                    onChangeSearchTerm={(q: string) => {
                        setCustomFields({
                            ...customFields,
                            q: q?.length > 0 ? q : '',
                        });
                    }}
                />

                <SeriesTableFeature
                    filters={filterOptions}
                    q={customFields.q || ''}
                    invalidatedAt={seriesInvalidatedAt}
                    page={Number(customFields['page'] || 1)}
                    onChangePage={(page: number) =>
                        setCustomFields({...customFields, page: page.toString()})
                    }
                />
            </ETBContainer>
        </>
    );
}
