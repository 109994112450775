import {DropzoneOptions, useDropzone} from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import {ChildlessBaseComponent} from 'src/view/interfaces/BaseComponent';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    dropzoneContainer: {
        width: '100%',
        padding: theme.spacing(2, 3),
        border: `1px dashed ${theme.colors.grey}`,
        backgroundColor: theme.colors.lightGrey,
        borderRadius: theme.layout.borderRadius.regular,
        '&:hover': {
            cursor: 'pointer',
        },
        '& p': {
            textAlign: 'center',
            marginBottom: 0,
            marginTop: 0,
        },
    },
    dragActiveContainer: {
        backgroundColor: theme.colors.bluishGrey,
    },
}));

export interface DropzoneComponentProps extends ChildlessBaseComponent {
    loading?: boolean;
    isLoadingText?: string;
    dragActiveText?: string | JSX.Element;
    uploadText?: string | JSX.Element;
}

export const Dropzone = ({
                             loading,
                             isLoadingText = 'Uploading files...',
                             className,
                             dragActiveText = 'Drop the file(s) here',
                             uploadText = "Drag 'n' drop some files here, or click to select files",
                             ...options
                         }: DropzoneOptions & DropzoneComponentProps) => {
    const classes = useStyles();

    const {getRootProps, getInputProps, isDragActive} = useDropzone(options);

    return (
        <div
            {...getRootProps()}
            className={classnames(
                isDragActive && classes.dragActiveContainer,
                classes.dropzoneContainer,
                className
            )}
        >
            <input {...getInputProps()} />
            <p>{loading ? isLoadingText : <>{isDragActive ? dragActiveText : uploadText}</>}</p>
            {options.maxFiles && <p><em>(Maximum of {options.maxFiles} files at once)</em></p>}
        </div>
    );
};
