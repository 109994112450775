import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    CUSTOM_FIELD_PARAM_KEY,
    CustomFields,
    FILTER_OPTION_PARAM_KEY,
    SORTING_OPTION_PARAM_KEY,
} from 'src/app/constants/constants/filters/filters';
import history from 'src/app/routing/history';
import {createFilterOptionsQuery} from 'src/app/utilities/helpers/filter';
import FilterOption from 'src/data/api/common/FilterOption';
import {TableColumnSorting} from 'src/view/components/table/table/Types';

const parseSortingOptionQueryParam = (
    key: string,
    value: string
): TableColumnSorting | undefined => {
    let property;

    const keys = key.split('[');

    keys.forEach((keySegment) => {
        if (!keySegment.endsWith(']') || keySegment.length <= 1) return;

        property = keySegment.substring(0, keySegment.length - 1);
    });

    if (!property || value?.length === 0) return;

    return {
        column: property,
        direction: value === 'asc' ? 'asc' : 'desc',
    };
};

const parseFilterOptionQueryParam = (key: string, value: string): FilterOption | undefined => {
    let property;
    let operation;

    const keys = key.split('[');

    keys.forEach((keySegment, index) => {
        if (!keySegment.endsWith(']') || keySegment.length <= 1) return;

        const cleanSegment = keySegment.substring(0, keySegment.length - 1);

        switch (index) {
            case 1:
                property = cleanSegment;
                break;
            case 2:
                operation = cleanSegment;
                break;
        }
    });

    if (!property || value?.length === 0) return;

    return {
        property,
        value,
        operation,
    };
};

const parseCustomField = (
    key: string,
    value: string
): { key: string; value: string } | undefined => {
    let fieldKey;

    const keys = key.split('[');

    keys.forEach((keySegment, index) => {
        if (!keySegment.endsWith(']') || keySegment.length <= 1) return;

        const cleanSegment = keySegment.substring(0, keySegment.length - 1);

        switch (index) {
            case 1:
                fieldKey = cleanSegment;
                break;
        }
    });

    if (!fieldKey || value?.length === 0) return;

    return {
        key: fieldKey,
        value,
    };
};

export function findOtherQueryParams(currentSuffix: string, keys: string[]) {
    let value = '';

    const filtersInUrl: string[] = window.location.search.replace('?', '').split('&');

    const suffixKeys: string[] = keys.map((key) => `${key}_${currentSuffix}`);

    const filterChecker = (filter: string) => !suffixKeys.some((key) => filter.includes(key));

    const otherSuffixFilters = filtersInUrl.filter(filterChecker).join('&');

    if (otherSuffixFilters.length > 0) {
        value += '&';
    }

    value += otherSuffixFilters;

    return value;
}

interface GetUrlOptionsProps {
    filterKey?: string;
    sortingKey?: string;
    customFieldKey?: string;
    suffix?: string;
    defaultOptions?: useQueryParamsValues;
}

function getUrlOptions({
                           filterKey,
                           customFieldKey,
                           sortingKey,
                           suffix,
                           defaultOptions,
                       }: GetUrlOptionsProps): {
    filterOptions: FilterOption[];
    customFields: CustomFields;
    sortingOptions: TableColumnSorting[];
} {
    let filterOptions: FilterOption[] = [];
    let sortingOptions: TableColumnSorting[] = [];
    let customFields: CustomFields = {};
    const params = new URLSearchParams(window.location.search);

    params.forEach((value, key) => {
        const sSuffix = suffix && suffix.length > 0 ? `_${suffix}` : '';

        if (filterKey && key.startsWith(filterKey + sSuffix)) {
            const filterOption = parseFilterOptionQueryParam(key, value);

            if (filterOption) {
                filterOptions.push(filterOption);
            }
        }

        if (sortingKey && key.startsWith(sortingKey + sSuffix)) {
            const sortingOption = parseSortingOptionQueryParam(key, value);

            if (sortingOption) {
                sortingOptions.push(sortingOption);
            }
        }

        if (key.startsWith(customFieldKey + sSuffix)) {
            const customField = parseCustomField(key, value);

            if (customField) {
                customFields[customField.key] = customField.value;
            }
        }
    });

    const allFilterOptions = [...(defaultOptions?.defaultFilters || []), ...filterOptions];

    filterOptions = _.map(allFilterOptions, (filterOption) => {
        Object.keys(filterOption).forEach((key) =>
            filterOption[key as keyof typeof filterOption] === undefined
                ? delete filterOption[key as keyof typeof filterOption]
                : {}
        );

        return filterOption;
    });
    filterOptions = _.reverse(filterOptions);
    filterOptions = filterOptions.filter(
        (value, index, self) =>
            index ===
            self.findIndex((t) => t.property === value.property && t.operation === value.operation)
    );

    const allSortingOptions = [...(defaultOptions?.defaultSorting || []), ...sortingOptions];
    sortingOptions = [...new Map(allSortingOptions.map((item) => [item['column'], item])).values()];

    if (Object.keys(customFields).length === 0) {
        customFields = defaultOptions?.defaultCustomFields || {};
    }

    return {
        filterOptions,
        customFields,
        sortingOptions,
    };
}

interface useQueryParamsValues {
    defaultFilters?: FilterOption[];
    defaultSorting?: TableColumnSorting[];
    defaultCustomFields?: CustomFields;
    transformDefaultFilterOptions?: (filterOptions: FilterOption[]) => FilterOption[];
    disableUrlWriting?: boolean;
}

/**
 * @param filterSuffix Use this if you're using multiple times on a single URL, then you need to
 * @param defaultOptions Can be used to provide default filter options
 */
export default function useQueryParams(filterSuffix = '', defaultOptions?: useQueryParamsValues) {
    const navigate = useNavigate();
    const initialOptions = getUrlOptions({
        filterKey: FILTER_OPTION_PARAM_KEY,
        sortingKey: SORTING_OPTION_PARAM_KEY,
        customFieldKey: CUSTOM_FIELD_PARAM_KEY,
        suffix: filterSuffix,
        defaultOptions,
    });

    const transformedFilterOptions = defaultOptions?.transformDefaultFilterOptions
        ? defaultOptions?.transformDefaultFilterOptions(initialOptions.filterOptions)
        : initialOptions.filterOptions;

    const [filterOptions, setFilterOptions] = useState<FilterOption[]>(
        transformedFilterOptions || []
    );
    const [sortingOptions, setSortingOptions] = useState<TableColumnSorting[]>(
        initialOptions.sortingOptions || []
    );
    const [customFields, setCustomFields] = useState<CustomFields>(initialOptions.customFields);

    const [skipUrlChange, setSkipUrlChange] = useState(false);

    const createUrlParts = (
        filterOptions: FilterOption[],
        sortingOptions: TableColumnSorting[],
        customFields: CustomFields
    ) => {
        const currentSuffixQuery = createFilterOptionsQuery({
            filterOptions,
            filterKey: FILTER_OPTION_PARAM_KEY,
            customFields,
            customFieldsKey: CUSTOM_FIELD_PARAM_KEY,
            sortingOptions,
            sortingKey: SORTING_OPTION_PARAM_KEY,
            suffix: filterSuffix,
        });

        const otherQueryParams = findOtherQueryParams(filterSuffix, [
            FILTER_OPTION_PARAM_KEY,
            SORTING_OPTION_PARAM_KEY,
            CUSTOM_FIELD_PARAM_KEY,
        ]);

        const searchPrefix =
            currentSuffixQuery.length > 0 || otherQueryParams.length > 0 ? '?' : '';

        return {
            currentSuffixQuery,
            otherQueryParams,
            searchPrefix,
        };
    };

    useEffect(() => {
        if (customFields['page'] !== '1') {
            setCustomFields({...customFields, page: '1'});
        }
    }, [filterOptions]);

    useEffect(() => {
        const {searchPrefix, currentSuffixQuery, otherQueryParams} = createUrlParts(
            filterOptions,
            sortingOptions,
            customFields
        );

        if (defaultOptions?.disableUrlWriting) return;

        const {search} = window.location;

        const isNotSameUrl = search !== searchPrefix + currentSuffixQuery + otherQueryParams;

        if (isNotSameUrl && !skipUrlChange) {
            navigate(searchPrefix + currentSuffixQuery + otherQueryParams, {
                replace: true,
            });

            setSkipUrlChange(false);
        }
    }, [filterOptions, sortingOptions, customFields]);

    useEffect(() => {
        // Handle user browser forward / backward
        const unListen = history.listen((Listener) => {
            // Only respond to user triggered browser url change
            if (Listener.action !== 'POP') return;

            // Temporarily disable writing url, because user already changed it.
            setSkipUrlChange(true);
            // Set new options from url
            const queryParams = getUrlOptions({
                filterKey: SORTING_OPTION_PARAM_KEY,
                sortingKey: SORTING_OPTION_PARAM_KEY,
                customFieldKey: CUSTOM_FIELD_PARAM_KEY,
                suffix: filterSuffix,
            });

            setFilterOptions(queryParams.filterOptions);
            setSortingOptions(queryParams.sortingOptions);
            setCustomFields(queryParams.customFields);

            // Enable writing url again
            setSkipUrlChange(false);
        });

        // Unsubscribe to event when component unloads.
        return () => {
            unListen();
        };
    }, []);

    const handleSetCustomFields = (customFields: CustomFields) => {
        setCustomFields(
            Object.keys(customFields).reduce((obj, key) => {
                const value = customFields[key];
                if (value !== undefined) obj[key] = value.trim();
                return obj;
            }, {} as CustomFields)
        );
    };

    return {
        values: {
            filterOptions,
            sortingOptions,
            customFields,
        },
        setFilterOptions,
        setSortingOptions,
        setCustomFields: handleSetCustomFields,
    } as const;
}
