import {Grid} from '@mui/material';
import classNames from 'classnames';
import moment, {Moment} from 'moment/moment';
import {CSSProperties} from 'react';
import {Control, Controller} from 'react-hook-form';
import {useFilterFormStyles} from 'src/shared/styling/filterFormStyles';
import {
    FilterAutoComplete,
    FilterAutoCompleteOptions,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import {FilterDatePicker} from 'src/view/components/filters/DatePicker/DatePicker';
import {FilterPlaceholderProps} from 'src/view/components/filters/FilterPlaceholder/FilterPlaceholder';
import FormFieldError from 'src/view/components/form/FormFieldError';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import SearchInput from 'src/view/components/search-input/SearchInput';

interface FilterProps {
    name: string;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    label?: string;
}

export interface SearchInput extends FilterProps {
    type: 'search' | 'creditcard-search';
    onChange: (value: string) => void;
    searchDefaultValue?: string;
    searchPlaceholder?: string;
    limit?: number;
}

export interface AutoCompleteSelect extends FilterProps {
    type: 'autocomplete';
    options: FilterAutoCompleteOptions;
    filterPlaceholderProps: Omit<FilterPlaceholderProps, 'value ' | 'onClick' | 'open'>;
    onChange: () => void;
    isMulti?: boolean;
    preserveSearchValue?: boolean;
}

export interface DatePicker extends FilterProps {
    type: 'datepicker';
    onChange: (date: Moment | null) => void;
    filterPlaceholderProps: Omit<FilterPlaceholderProps, 'value ' | 'onClick' | 'open'>;
    minDate?: Moment;
}

export type FiltersArray = Array<AutoCompleteSelect | SearchInput | DatePicker>;

const isSearchFilter = (filter: FiltersArray[number]): filter is SearchInput =>
    filter.type === 'search' || filter.type === 'creditcard-search';
const isAutoComplete = (filter: FiltersArray[number]): filter is AutoCompleteSelect =>
    filter.type === 'autocomplete';
const isDatePicker = (filter: FiltersArray[number]): filter is DatePicker =>
    filter.type === 'datepicker';

export default function Filters({
                                    control,
                                    filters,
                                    style,
                                }: {
    control: Control;
    filters: FiltersArray;
    style?: CSSProperties;
}): JSX.Element {
    const filterFormClasses = useFilterFormStyles();

    return (
        <Grid
            container
            className={filterFormClasses.filterSpacing}
            alignItems="center"
            style={style}
        >
            {filters.map((filter) => (
                <Grid
                    item
                    className={classNames({
                        [`${filter.className}`]: filter.className,
                        [`${filterFormClasses.filterSearchInputPosition}`]:
                        filter.type === 'search',
                        [`${filterFormClasses.filterPlaceholderSpacing}`]: filter.type !== 'search',
                    })}
                    key={filter.name}
                    style={filter.style}
                >
                    <Controller
                        name={filter.name}
                        control={control}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <div>
                                {filter.label && <ModalSubTitle>{filter.label}</ModalSubTitle>}
                                {isSearchFilter(filter) && (
                                    <SearchInput
                                        onChange={(value: string) => filter.onChange(value)}
                                        defaultValue={filter.searchDefaultValue}
                                        placeholder={filter.searchPlaceholder}
                                        containerClassName={
                                            filter.type === 'search'
                                                ? filterFormClasses.filterSearchInput
                                                : filterFormClasses.filterFromCreditCardSearchInput
                                        }
                                        limit={filter.limit}
                                    />
                                )}
                                {isAutoComplete(filter) && (
                                    <FilterAutoComplete
                                        filterPlaceholderProps={filter.filterPlaceholderProps}
                                        value={value}
                                        options={filter.options}
                                        onChange={(value) => {
                                            onChange(value);
                                            filter.onChange();
                                        }}
                                        disabled={filter.disabled}
                                        isMulti={filter.isMulti}
                                        preserveSearchValue={filter.preserveSearchValue}
                                    />
                                )}
                                {isDatePicker(filter) && (
                                    <FilterDatePicker
                                        onChange={(date: Moment | null) => filter.onChange(date)}
                                        filterPlaceholderProps={filter.filterPlaceholderProps}
                                        value={value ? moment(value) : null}
                                        minDate={filter.minDate}
                                        disabled={filter.disabled}
                                    />
                                )}
                                <FormFieldError message={error?.message}/>
                            </div>
                        )}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
