import {useMutation} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import CreateSeriesForm, {CreateSeriesFormValues} from 'src/app/components/forms/SerieCreateForm';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import seriesService from 'src/data/services/seriesService';
import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';

export interface CreateSeriesDataWrapperProps {
    seriesName?: string;
    onCreateSeries?: (option: AutoCompleteOption) => void;
    onSucceed?: () => void;
}

interface CreateSeriesDto {
    name: string;
    status: boolean;
    seriesGroupId?: string;
}

export default function SeriesCreateFormFeature({
                                                    onSucceed,
                                                    onCreateSeries,
                                                    seriesName,
                                                }: CreateSeriesDataWrapperProps) {
    const [defaultValues, setDefaultValues] = useState<CreateSeriesFormValues>();
    const [createdSeriesName, setCreatedSeriesName] = useState<string>();

    const createSeriesFormOnSubmit = async (values: CreateSeriesFormValues) => {
        if (!values.name) return;

        setCreatedSeriesName(values.name);

        createSeries({
            name: values.name,
            status: !!values.status,
            seriesGroupId: values.seriesGroup?.value,
        });
    };

    const {mutate: createSeries, isLoading: isCreatingSeries} = useMutation({
        mutationFn: async ({name, status, seriesGroupId}: CreateSeriesDto) => {
            return seriesService.createSeries(name, !!status, seriesGroupId);
        },
        onSuccess: ({data}) => {
            Toaster.toast(`Successfully created the series: ${createdSeriesName}`, {
                variant: 'success',
            });

            onCreateSeries?.({label: createdSeriesName, value: data.data.id});

            onSucceed?.();
        },
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err));
        },
    });

    useEffect(() => {
        if (!seriesName) return;

        setDefaultValues({
            name: seriesName,
            status: undefined,
            seriesGroup: {value: undefined},
        });
    }, [seriesName]);

    return (
        <CreateSeriesForm
            loading={isCreatingSeries}
            defaultValues={defaultValues}
            onSubmit={createSeriesFormOnSubmit}
        />
    );
}
