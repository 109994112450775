import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Grid} from '@mui/material';
import {useState, type Dispatch, type SetStateAction} from 'react';
import {SeatingPlansList} from 'src/app/components/data-wrappers/venues/seating-plans-list';
import Button from 'src/view/components/button/Button';
import Modal from 'src/view/components/modal/Modal';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import {CreateEditSeatingPlanModal} from './CreateEditSeatingPlanModal';

export function SeatingPlansOverview({
                                         venue,
                                         setIsShowingDetails,
                                     }: {
    venue: {
        name: string;
        id: string;
    };
    setIsShowingDetails: Dispatch<SetStateAction<boolean>>;
}) {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    return (
        <>
            <Modal
                title={venue.name}
                open={true}
                width="large"
                onClose={() => setIsShowingDetails(false)}
            >
                <>
                    <Grid
                        container
                        justifyContent="space-between"
                        sx={(theme) => ({
                            marginBottom: theme.spacing(2),
                        })}
                    >
                        <Grid item display="flex" alignItems="center">
                            <ModalSubTitle>Seating Plans</ModalSubTitle>
                        </Grid>
                        <Grid item display="flex" alignItems="center" justifyContent="flex-end">
                            <Button
                                variant="text"
                                startIcon={<AddCircleOutlineIcon/>}
                                sx={(theme) => ({
                                    color: theme.palette.tertiary.main,
                                })}
                                onClick={() => setIsCreateModalOpen(true)}
                            >
                                Add seating plan
                            </Button>
                        </Grid>
                    </Grid>

                    <SeatingPlansList venueId={venue.id}/>
                </>
            </Modal>

            {isCreateModalOpen && (
                <CreateEditSeatingPlanModal
                    venueId={venue.id}
                    setIsModalOpenState={setIsCreateModalOpen}
                />
            )}
        </>
    );
}
