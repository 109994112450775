import {ETBContainer} from 'src/view/components/container/container';
import {OrderlinesAutoAssignFeature} from 'src/app/components/features/orderline/OrderlinesAutoAssignFeature';
import Header from 'src/view/components/header/Header';
import {Grid} from '@mui/material';
import Button from 'src/view/components/button/Button';
import {useNavigate} from 'react-router-dom';
import eventUrlService from 'src/app/pages/events/eventUrlService';

interface AutoAssignPageProps {
    eventId?: string;
}

export const AutoAssignPage = ({eventId}: AutoAssignPageProps) => {
    const navigate = useNavigate();

    if (!eventId) return <></>;

    return (
        <>
            <Header>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <h1>Auto assign tickets</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => navigate(eventUrlService.orderlines(eventId))}
                        >
                            Go to orderlines
                        </Button>
                    </Grid>
                </Grid>
            </Header>
            <ETBContainer>
                <OrderlinesAutoAssignFeature eventId={eventId}/>
            </ETBContainer>
        </>
    );
};
