import {defaultCurrency} from 'src/shared/currencies';

export const formatPrice = ({
                                currency = defaultCurrency,
                                amount = 0,
                                showCurrency = true,
                            }): string => {
    const locale = 'nl-NL';
    // In case they want to see [EUR 49] instead of [EUR 49,00]
    // const minimumFractionDigits = hasDecimals ? 2 : 0;
    const minimumFractionDigits = 2;
    const maximumFractionDigits = 2;

    const price = amount.toLocaleString(locale, {
        minimumFractionDigits,
        maximumFractionDigits,
    });

    return showCurrency ? `${currency} ${price}` : price;
};
