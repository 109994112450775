import {useEffect, useState} from 'react';
import {ActionMeta} from 'react-select';
import {toastApiErrors} from 'src/app/utilities/helpers/toast-api-errors';
import seriesService from 'src/data/services/seriesService';
import AsyncAutoComplete from 'src/view/components/auto-complete/AsyncAutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    name: string;
    value?: AutoCompleteOption;
    onChange: (value: AutoCompleteValueOption, action?: ActionMeta<AutoCompleteOption>) => void;
    onCreateOption?: (name: string) => void;
    disabled?: boolean;
}

export default function EventSeriesSelectDataWrapper({
                                                         name,
                                                         value,
                                                         onChange,
                                                         onCreateOption,
                                                         disabled,
                                                     }: Props) {
    const [option, setOption] = useState<AutoCompleteOption | undefined>(value);

    useEffect(() => {
        setOption(value);
    }, [value]);

    const fetchSeriesOptions = async (query: string): Promise<AutoCompleteOptions> => {
        if (disabled) return [];

        try {
            const res = await seriesService.fetchSeries({q: query});

            if (!res.data.data) return [];

            return res.data.data.map((o) => ({
                label: o.name,
                value: o.id,
            }));
        } catch (e) {
            toastApiErrors(e);

            return [];
        }
    };

    return (
        <AsyncAutoComplete
            name={name}
            value={option}
            loadOptions={fetchSeriesOptions}
            onChange={onChange}
            placeholder="Select or create"
            onCreateOption={onCreateOption}
            disabled={disabled}
        />
    );
}
