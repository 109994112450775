import {CircularProgress} from '@mui/material';
import Alert from '@mui/material/Alert';
import {useTheme} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
    useDeleteSeatingplanCategoryProperty
} from 'src/app/components/data-wrappers/seating-plan-category-properties/DeleteSeatingPlangCategoryPropertyDataWrapper';
import {
    FetchSeatingplanCategoryPropertyByIdDataWrapper
} from 'src/app/components/data-wrappers/seating-plan-category-properties/FetchSeatingPlanPropertyByIdDataWrapper';
import {
    FetchSeatingplanCategoryPropertiesDataWrapper
} from 'src/app/components/data-wrappers/seating-plan-category-properties/FetchSeatingplanCategoryPropertiesDataWrapper';
import {
    useUpdateSeatingPlanCategoryPropertyDataWrapper
} from 'src/app/components/data-wrappers/seating-plan-category-properties/UpdateSeatingPlanCategoryPropertyDataWrapper';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import Filters, {FiltersArray} from 'src/app/components/forms/filters/Filters';
import SeatingPlanCategoryPropertyForm from 'src/app/components/forms/seating-plan-category-property/SeatingPlanCategoryPropertyForm';
import SeatingPlanCategoryPropertiesTable from 'src/app/components/tables/SeatingPlanCategoryPropertiesTable';
import {config} from 'src/app/constants/config/config';
import useQueryParams from 'src/app/hooks/useQueryParams';
import {SeatingPlanCategoryProperty} from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import FormButtons from 'src/view/components/form/FormButtons';
import Modal from 'src/view/components/modal/Modal';

interface Props {
    tableInvalidatedAt?: number;
}

export default function SeatingPlanCategoryPropertiesFeature({
                                                                 tableInvalidatedAt,
                                                             }: Props): JSX.Element {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [propertyId, setPropertyId] = useState<string | null>(null);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();
    const theme = useTheme();

    const {
        deleteSeatingPlanCategoryProperty,
        loading,
        errors: deletionErrors,
    } = useDeleteSeatingplanCategoryProperty({
        onSuccess: () => {
            setDeleteModalOpen(false);
            setInvalidatedAt(Date.now());
        },
    });

    const {
        updateSeatingplanCategoryProperty,
        loading: updatingSeatingPlanCategoryProperty,
        errors: updatingErrors,
    } = useUpdateSeatingPlanCategoryPropertyDataWrapper({
        onSuccess: () => {
            setEditModalOpen(false);
            setInvalidatedAt(Date.now());
        },
    });

    const errors = [...deletionErrors, ...updatingErrors];

    useEffect(() => {
        if (!tableInvalidatedAt) return;

        setInvalidatedAt(tableInvalidatedAt);
    }, [tableInvalidatedAt]);

    const {
        values: {customFields},
        setCustomFields,
    } = useQueryParams('seating-plan-category-properties', {
        defaultCustomFields: {
            page: '1',
        },
    });

    const form = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const {control} = form;

    const onEditClick = (propertyId: string) => {
        setEditModalOpen(true);
        setPropertyId(propertyId);
    };

    const onDeleteClick = (propertyId: string) => {
        setDeleteModalOpen(true);
        setPropertyId(propertyId);
    };

    const page = Number(customFields['page'] || 0);

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q) =>
                setCustomFields({
                    ...customFields,
                    q: q.trim().toUpperCase().replace(/\s+/g, '_'),
                }),
            type: 'search',
            searchPlaceholder: 'Search on property name',
            searchDefaultValue: customFields['q'] || '',
            label: 'Search',
        },
    ];

    const onFormSubmit = (values: SeatingPlanCategoryProperty) => {
        updateSeatingplanCategoryProperty(propertyId || '', values);
    };

    return (
        <>
            <Filters control={control} filters={arrayOfFilters}/>
            <FetchSeatingplanCategoryPropertiesDataWrapper
                page={page}
                q={customFields.q || ''}
                invalidatedAt={invalidatedAt}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
            >
                {({loading, data}) => (
                    <>
                        <TableHeaderTotalFeature
                            title="Properties"
                            totalResults={data?.meta.total}
                        />
                        <SeatingPlanCategoryPropertiesTable
                            properties={data?.data}
                            loading={loading}
                            onEditClick={onEditClick}
                            onDeleteClick={onDeleteClick}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({...customFields, page: page.toString()}),
                            }}
                        />
                    </>
                )}
            </FetchSeatingplanCategoryPropertiesDataWrapper>
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title="Seating Plan Category Property Details"
            >
                <>
                    <FetchSeatingplanCategoryPropertyByIdDataWrapper propertyId={propertyId || ''}>
                        {({loading, data}) => (
                            <>
                                {!loading && data && (
                                    <SeatingPlanCategoryPropertyForm
                                        propertyId={propertyId || ''}
                                        loading={loading || updatingSeatingPlanCategoryProperty}
                                        defaultValues={{
                                            key: data?.data.key,
                                            internalDescription: data?.data.internalDescription,
                                            supportsValue: data?.data.supportsValue,
                                        }}
                                        onFormSubmit={onFormSubmit}
                                    />
                                )}
                            </>
                        )}
                    </FetchSeatingplanCategoryPropertyByIdDataWrapper>
                </>
            </Modal>

            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title="Delete Seating Plan Category Property"
            >
                <>
                    <Alert severity="warning">
                        <strong>
                            Attention! This action will unlink the property from all the connected
                            Seating Plan Categories. This action cannot be undone!{' '}
                        </strong>
                        <p>Are you sure you want to delete?</p>
                    </Alert>
                    {errors && errors.length > 0 && <ErrorsList errors={errors}/>}
                    <FormButtons
                        buttons={[
                            {
                                children: 'Delete',
                                onClick: () => deleteSeatingPlanCategoryProperty(propertyId || ''),
                                disabled: loading,
                                startIcon: loading && (
                                    <CircularProgress size={theme.layout.loader.sizes.small}/>
                                ),
                            },
                        ]}
                    />
                </>
            </Modal>
        </>
    );
}
