import {getInternalAxiosInstance} from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {getQueryParams} from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    CONTRACTS_GENERATE_TEMPLATE_TICKETS_ENDPOINT,
    CONTRACTS_GET_ENDPOINT,
    CONTRACTS_GET_TEMPLATES_BY_ID_ENDPOINT,
    CONTRACTS_GET_TEMPLATE_TICKETS_ENDPOINT,
} from 'src/data/constants/endpoints/contracts-endpoints';
import Price from 'src/data/models/common/price';
import Contract from 'src/data/models/contracts/contract';
import TicketTemplate from 'src/data/models/contracts/ticketTemplate';
import TicketTemplateItem from 'src/data/models/contracts/ticketTemplateItem';

async function getContracts(
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<Contract>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Contract>>(CONTRACTS_GET_ENDPOINT, {
            params: options && getQueryParams(options),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getTemplatesByContractId(
    contractId: string,
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<TicketTemplate>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<TicketTemplate>>(
            CONTRACTS_GET_TEMPLATES_BY_ID_ENDPOINT(contractId),
            {
                params: options && getQueryParams(options),
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getTicketTemplateItems(
    contractId: string,
    ticketTemplateId: string
): Promise<ApiResponse<PaginatedApiResponseBody<TicketTemplateItem>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<TicketTemplateItem>>(
            CONTRACTS_GET_TEMPLATE_TICKETS_ENDPOINT(contractId, ticketTemplateId)
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface TemplateTicketItemPurchasePrice {
    id: string;
    purchasePrice: Price;
}

async function generateTemplateTickets(
    contractId: string,
    ticketTemplateId: string,
    eventId: string,
    TicketTemplateItemPurchasePrices: TemplateTicketItemPurchasePrice[]
): Promise<ApiResponse<PaginatedApiResponseBody<TicketTemplateItem>>> {
    return await getInternalAxiosInstance()
        .post<PaginatedApiResponseBody<TicketTemplateItem>>(
            CONTRACTS_GENERATE_TEMPLATE_TICKETS_ENDPOINT(contractId, ticketTemplateId),
            {
                eventId,
                TicketTemplateItemPurchasePrices,
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getContracts,
    getTemplatesByContractId,
    getTicketTemplateItems,
    generateTemplateTickets,
};
