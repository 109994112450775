import {useState, useEffect} from 'react';
import {Grid, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DictionaryGrid from 'src/view/components/dictionary-grid/DictionaryGrid';
import {useForm} from 'react-hook-form';
import FormEditText from 'src/view/components/form/FormEditText';
import Price from 'src/data/models/common/price';
import currency from 'src/app/utilities/helpers/currency';

const useStyles = makeStyles((theme: Theme) => ({
    editText: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    spacingLeft: {
        marginLeft: theme.spacing(1),
    },
}));

export interface OrderDetailsFormValues {
    orderNumber: string;
    externalReferenceId: string;
    orderNotes: string;
    totalPrice: Price | null;
    numberOfTickets: number;
    source: string;
}

interface Props {
    defaultValues?: OrderDetailsFormValues;
    disableEdit?: boolean;
}

export default function OrderDetailsForm({defaultValues, disableEdit = true}: Props) {
    const classes = useStyles();

    const form = useForm<OrderDetailsFormValues>({
        mode: 'onChange',
    });

    const {getValues, reset} = form;

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    const [editMode, setEditMode] = useState(false);

    const orderNumber = getValues().orderNumber,
        externalReferenceId = getValues().externalReferenceId,
        orderNotes = getValues().orderNotes,
        numberOfTickets = getValues().numberOfTickets,
        source = getValues().source,
        totalPrice = getValues().totalPrice;

    return (
        <>
            <DictionaryGrid>
                <Grid item xs={4}>
                    Total Price
                </Grid>
                <Grid item xs={8}>
                    {totalPrice && (
                        <>
                            <span>
                                {currency.formatPrice(
                                    totalPrice?.currency,
                                    +(totalPrice?.value || 0),
                                    false
                                )}
                            </span>
                            <span className={classes.spacingLeft}>{totalPrice.currency}</span>
                        </>
                    )}
                </Grid>
                <Grid item xs={4}>
                    Order Number
                </Grid>
                <Grid item xs={8}>
                    <span>{orderNumber}</span>
                </Grid>
                <Grid item xs={4}>
                    External Reference ID
                </Grid>
                <Grid item xs={8}>
                    <span>{externalReferenceId}</span>
                </Grid>
                <Grid item xs={4}>
                    Number of Tickets
                </Grid>
                <Grid item xs={8}>
                    <span>{numberOfTickets}</span>
                </Grid>
                <Grid item xs={4}>
                    Source
                </Grid>
                <Grid item xs={8}>
                    <span>{source}</span>
                </Grid>
                <Grid item xs={4}>
                    Order Notes
                </Grid>
                <Grid item xs={8}>
                    <span>{orderNotes}</span>
                </Grid>
            </DictionaryGrid>
            {!disableEdit && (
                <div className={classes.editText}>
                    <FormEditText
                        text={editMode ? 'Save order' : 'Edit order'}
                        onClick={() => setEditMode(!editMode)}
                    />
                </div>
            )}
        </>
    );
}
