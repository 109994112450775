import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import ticketService, {BatchEditTicketDto} from 'src/data/services/ticketService';

interface BatchEditTicketsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    batchEditTickets: (dto: BatchEditTicketDto[]) => Promise<void | ErrorReturn>;
}

type BatchEditTicketsDataWrapperProps = DataWrapperProps<
    BatchEditTicketsDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useBatchEditTicketsDataWrapper = ({
                                                   onError,
                                                   onSuccess,
                                               }: Omit<BatchEditTicketsDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleCreateDownloadLink] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const batchEditTicketsCall = (dto: BatchEditTicketDto[]) => {
        return handleCreateDownloadLink(ticketService.batchEditTickets(dto), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        batchEditTickets: batchEditTicketsCall,
        data,
        loading,
        errors,
        resetData,
    };
};
