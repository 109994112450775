import {useConfirm, type ConfirmOptions} from 'material-ui-confirm';
import {useCallback} from 'react';

export function useCustomConfirm() {
    const originalConfirm = useConfirm();

    return useCallback(
        async (options: ConfirmOptions): Promise<boolean> => {
            try {
                await originalConfirm(options);
                return true;
            } catch (e) {
                return false;
            }
        },
        [originalConfirm]
    );
}
