import React from 'react';
import {ConfirmProvider} from 'material-ui-confirm';
import {makeStyles} from '@mui/styles';
import {fontFamilies} from 'src/shared/theme/fonts';

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-container': {
            alignItems: 'flex-start',
        },
        '& .MuiPaper-root ': {
            marginTop: theme.spacing(3),
        },
        '& .MuiDialogContentText-root': {
            fontFamily: fontFamilies.primary,
            color: theme.typography.body1.color,
        },
    },
    buttonProps: {
        textTransform: 'capitalize',
        borderRadius: 30,
        fontWeight: 700,
    },
    title: {
        fontFamily: fontFamilies.primary,
        fontWeight: theme.typography.fontWeightBold,
    },
}));

interface ETBAASConfirmProviderProps {
    children: React.ReactNode;
}

export const ETBAASConfirmProvider = ({children}: ETBAASConfirmProviderProps) => {
    const classes = useStyles();

    return (
        <ConfirmProvider
            defaultOptions={{
                titleProps: {
                    className: classes.title,
                },
                description: 'Any unsaved changes will be lost',
                confirmationText: 'Confirm',
                cancellationText: 'Cancel',
                confirmationButtonProps: {
                    className: classes.buttonProps,
                    color: 'primary',
                    variant: 'contained',
                    children: 'Confirm',
                    disableElevation: true,
                    disableRipple: true,
                },
                cancellationButtonProps: {
                    className: classes.buttonProps,
                    disableElevation: true,
                    disableRipple: true,
                },
                dialogProps: {
                    className: classes.dialog,
                },
            }}
        >
            {children}
        </ConfirmProvider>
    );
};
