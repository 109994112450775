import {styled} from '@mui/system';

interface DismissableToastProps {
    title: string;
    dismissText: string;
    onDismiss: () => void;
    children?: JSX.Element | string;
}

const ToastHeader = styled('h2')(() => ({
    fontSize: '1rem',
    margin: 0,
}));

const DismissableToastBody = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
}));

const DismissableToastContent = styled('div')(({theme}) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: 'white',
    a: {
        color: 'inherit !important',
        fontWeight: 'bold',
        textDecorationColor: 'unset',
        marginLeft: '0.3rem',
    },
}));

const DismissButton = styled('button')(() => ({
    display: 'flex',
    textDecoration: 'underline',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    padding: 0,
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
        opacity: '0.6',
    },
}));

const DismissableToast = ({title, dismissText, onDismiss, children}: DismissableToastProps) => {
    return (
        <DismissableToastBody>
            <ToastHeader>{title}</ToastHeader>
            <DismissableToastContent>{children}</DismissableToastContent>
            <DismissButton onClick={onDismiss} color="warning">
                {dismissText}
            </DismissButton>
        </DismissableToastBody>
    );
};

export default DismissableToast;
