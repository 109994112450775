import OrdersTableFeature from 'src/app/components/features/orders/OrdersTableFeature';
import {ETBContainer} from 'src/view/components/container/container';

export default function OrdersOverview() {
    return (
        <>
            <ETBContainer>
                <OrdersTableFeature/>
            </ETBContainer>
        </>
    );
}
