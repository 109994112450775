import {Alert, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useCallback, useMemo, useState} from 'react';
import Button from 'src/view/components/button/Button';
import Modal, {ModalBody} from 'src/view/components/modal/Modal';
import {DuplicateTicketsInTheBatchType} from '../features/tickets/BatchUploadTicketFilesFeature';

export interface DuplicateTicketFileData {
    fileId: string;
    name: string;
}

interface DuplicateTicketsUploadAttemptModalProps {
    duplicateTickets: DuplicateTicketsInTheBatchType;
    onConfirm: (selection: DuplicateTicketFileData[]) => void;
    onClose: () => void;
}

export const DuplicateTicketsUploadAttemptModal = ({
                                                       duplicateTickets,
                                                       onConfirm,
                                                       onClose,
                                                   }: DuplicateTicketsUploadAttemptModalProps) => {
    const [selectedTicketIDs, setSelectedTicketIDs] = useState<string[]>(
        Array(duplicateTickets.length).fill('')
    );

    const isEveryCorrectDuplicateSelected = useMemo(
        () => selectedTicketIDs.every((val) => !!val),
        [selectedTicketIDs]
    );

    const handleRadioButtonChange = useCallback((groupIndex: number, fileId: string) => {
        setSelectedTicketIDs((prevSelection) => {
            prevSelection[groupIndex] = fileId;
            return [...prevSelection];
        });
    }, []);

    const onConfirmSelection = useCallback(() => {
        const selectedTicketIDsSet = new Set(selectedTicketIDs);

        const selection = duplicateTickets
            .flatMap((ticket) => ticket)
            .filter((ticket) => selectedTicketIDsSet.has(ticket.fileId))
            .map((ticket) => ({
                name: ticket.fileName,
                fileId: ticket.fileId,
            }));

        onConfirm(selection);
    }, [duplicateTickets, selectedTicketIDs, onConfirm]);

    return (
        <Modal open title="Batch upload contains duplicate tickets" onClose={onClose}>
            <ModalBody>
                <Typography marginBottom={2}>
                    Please select which file from the duplicates you would like to proceed with.
                </Typography>
                <Alert
                    severity="info"
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    Please select a file from each duplicate group to enable submit button.
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {duplicateTickets.map((duplicateGroup, groupIndex) => (
                        <Box key={groupIndex} marginBottom={2} marginLeft={2}>
                            <FormLabel id="duplicatesGroup-label">
                                Duplicate group {groupIndex + 1}
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="duplicatesGroup-label"
                                name={`duplicateGroup-${groupIndex + 1}`}
                                defaultChecked={false}
                                defaultValue={null}
                                onChange={(event) => {
                                    handleRadioButtonChange(groupIndex, event.target.value);
                                }}
                            >
                                {duplicateGroup.map((ticket) => {
                                    return (
                                        <FormControlLabel
                                            key={ticket.fileId}
                                            value={ticket.fileId}
                                            control={<Radio/>}
                                            label={ticket.fileName}
                                        />
                                    );
                                })}
                            </RadioGroup>
                            <Typography fontWeight="bold" fontSize={18}></Typography>
                        </Box>
                    ))}
                    <Button
                        disabled={!isEveryCorrectDuplicateSelected}
                        onClick={onConfirmSelection}
                        sx={{alignSelf: 'flex-end', marginTop: 1}}
                    >
                        Confirm
                    </Button>
                </Box>
            </ModalBody>
        </Modal>
    );
};
