import urlService from 'src/app/services/urlService';
import {
    filterOptionsToQueryParams,
    mapKeyStringObjectToFilterOptions,
} from 'src/app/utilities/helpers/filter';
import FilterOption from 'src/data/api/common/FilterOption';
import moment from 'moment';
import {dateFormatYearMonthDay} from 'src/shared/date';

interface EventOverviewFilters {
    teamId?: string;
    organizerId?: string;
    dateTimeStart?: FilterOption;
}

const eventOverviewFiltersDefault: EventOverviewFilters = {
    dateTimeStart: {
        property: 'dateTimeStart',
        operation: 'afterDate',
        value: moment().startOf('day').format(dateFormatYearMonthDay),
    },
};

export const eventOverviewDefaultFilters = mapKeyStringObjectToFilterOptions(
    eventOverviewFiltersDefault
);

const EventsOverviewUrlService = {
    root(filters?: EventOverviewFilters) {
        return urlService.getEventsOverview(
            `${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(
                    filters || eventOverviewFiltersDefault
                ),
                suffix: 'events',
            })}`
        );
    },
};

export default EventsOverviewUrlService;
