import React, {useEffect} from 'react';
import Table from 'src/view/components/table/table/Table';
import {TableProps} from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import ParsedTicket from 'src/data/models/tickets/parsedTicket';
import DownloadIcon from '@mui/icons-material/Download';
import {dateFormat} from 'src/shared/date';
import moment from 'moment';
import {IconButton} from '@mui/material';

export interface ParsedTicketTableProps {
    parsedTickets?: ParsedTicket[];
    loading: boolean;
    onDownloadClick: (id: string) => void;
    onChangeSelectedRows?: (selectedRows: RowIdResolver[]) => void;
    initialSelectedRows?: RowIdResolver[];
    pagination?: TablePaginationData;
    tableToolbarActions?: TableProps<ParsedTicket>['tableToolbarActions'];
}

export default function TicketManagementTable({
                                                  parsedTickets,
                                                  loading,
                                                  onDownloadClick,
                                                  onChangeSelectedRows,
                                                  initialSelectedRows,
                                                  pagination,
                                                  tableToolbarActions,
                                              }: ParsedTicketTableProps): JSX.Element {
    const [columns, setColumns] = React.useState<NewTableColumn<ParsedTicket>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<ParsedTicket>[] = [];

        cols = [
            {
                key: 'orderId',
                title: 'order Id',
                cellRenderer: (rowData) => rowData.orderId,
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                key: 'eventName',
                title: 'event Name',
                cellRenderer: (rowData) => rowData.eventName,
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                key: 'eventDate',
                title: 'event Date',
                cellRenderer: (rowData) => moment(rowData.eventDate).format(dateFormat),
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                key: 'block',
                title: 'Block',
                cellRenderer: (rowData) => rowData.block,
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                key: 'row',
                title: 'Row',
                cellRenderer: (rowData) => rowData.row,
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                key: 'seat',
                title: 'Seat',
                cellRenderer: (rowData) => rowData.seat,
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                key: 'downloadAmount',
                title: 'Download Amount',
                cellRenderer: (rowData) => rowData.downloadAmount,
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'comments',
                title: 'Comments',
                cellRenderer: (rowData) => rowData.comments,
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'recipient',
                title: 'Account',
                cellRenderer: (rowData) => rowData.recipient,
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'createdAt',
                title: 'Created At',
                cellRenderer: (rowData) => moment(rowData.createdAt).local().format(dateFormat),
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                title: 'Download',
                align: 'center',
                width: TABLE_CELL_WIDTH.SMALL,
                titleAlign: 'center',
                cellRenderer: (rowData) => {
                    if (onDownloadClick) {
                        return (
                            <IconButton
                                color="primary"
                                onClick={() => onDownloadClick(rowData.id)}
                                size={'small'}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        );
                    }

                    return '';
                },
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<ParsedTicket>
            data={parsedTickets}
            rowIdResolver={(rowData: ParsedTicket) => ({
                id: rowData.id,
            })}
            columns={columns}
            loading={loading}
            minWidth={650}
            enableCheckboxes={true}
            onChangeSelectedRows={onChangeSelectedRows}
            initialSelectedRows={initialSelectedRows}
            pagination={pagination}
            tableToolbarActions={tableToolbarActions}
        />
    );
}
