import axios from 'axios';
import {publicClientApplication} from 'src/app/constants/config/authConfig';
import {config} from 'src/app/constants/config/config';

interface ApiConfiguration {
    baseUrl: string;
    tokenResolver?: () => string;
    key?: string;
}

class Api {
    public config: ApiConfiguration = {
        baseUrl: '',
    };

    public init(config: ApiConfiguration) {
        this.config = config;
    }

    public getBaseUrl() {
        return this.config.baseUrl;
    }

    public getToken() {
        if (!this.config.tokenResolver) return '';

        return this.config.tokenResolver();
    }
}

const apiInternal = new Api();
const apiStoplight = new Api();
const apiTicketManagement = new Api();

/** Initialize all API instances; should be executed as early as possible in the App. */
export function initApis() {
    apiInternal.init({
        baseUrl: config.INTERNAL_API_URL || '',
    });

    apiTicketManagement.init({
        baseUrl: config.TICKET_MANAGEMENT_API_URL || '',
    });

    apiStoplight.init({
        baseUrl: config.STOPLIGHT_MOCK_API,
    });
}

function getAxios(api: Api) {
    const token = publicClientApplication.getAllAccounts()[0].idToken;
    return axios.create({
        baseURL: api.getBaseUrl(),
        headers: {Authorization: `Bearer ${token}`},
    });
}

export const getInternalAxiosInstance = () => getAxios(apiInternal);
export const getTicketManagementAxiosInstance = () => getAxios(apiTicketManagement);
