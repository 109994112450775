import {useMsal} from '@azure/msal-react';
import {useEffect, useState} from 'react';
import {initApis} from 'src/data/api/api';

export const useInitApis = () => {
    const [isApiConfigured, setIsApiConfigured] = useState(false);
    const {accounts, instance} = useMsal();

    useEffect(() => {
        if (accounts.length === 0) return;

        instance.setActiveAccount(accounts[0]);
        const token = accounts[0].idToken;

        if (!token) return;

        initApis();
        setIsApiConfigured(true);
    }, [instance, accounts]);

    return isApiConfigured;
};
