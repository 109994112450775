import {useQuery} from '@tanstack/react-query';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import organizerService from 'src/data/services/organizerService';

export const QUERY_KEY = 'organizers';

export const useOrganizers = (options?: BaseFetchOptions) => {
    const hookData = useQuery({
        queryKey: [QUERY_KEY, options],
        queryFn: () => organizerService.getOrganizers(options),
    });

    return hookData;
};
