import React from 'react';
import {makeStyles} from '@mui/styles';
import {Grid, Theme} from '@mui/material';
import {ETBContainer} from 'src/view/components/container/container';
import {GridProps} from '@mui/material/Grid/Grid';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        backgroundColor: theme.colors.mediumGrey,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: `${theme.spacing(2)} 0`,
        minHeight: 70,
        '& h1': {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    headerLogo: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '40px',
        color: theme.colors.white,
        fontWeight: 900,
        fontSize: '1.5rem',
        '& span': {
            cursor: 'pointer',
        },
    },
}));

export interface HeaderProps {
    children?: React.ReactElement | React.ReactElement[];
    GridProps?: GridProps;
}

const defaultGridProps: GridProps = {
    container: true,
    alignItems: 'center',
    justifyContent: 'space-between',
};

export default function Header({children, GridProps}: HeaderProps): JSX.Element {
    const classes = useStyles();

    const GridElementProps = {
        ...defaultGridProps,
        ...GridProps,
    };

    return (
        <header className={classes.header}>
            <ETBContainer>
                <Grid {...GridElementProps}>{children}</Grid>
            </ETBContainer>
        </header>
    );
}
