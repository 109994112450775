/* eslint-disable no-console */
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import moment from 'moment';
import {useEffect, useRef} from 'react';

const useTokenRefresher = () => {
    const {instance, accounts} = useMsal();
    const refreshTimeoutRef = useRef<NodeJS.Timeout | null | number>(null);

    const request = {
        scopes: [],
        account: instance.getAllAccounts()[0],
    };

    /** Tries to refresh the token silently, without user action */
    const refreshAccessToken = () => {
        instance
            .acquireTokenSilent(request)
            .then((response) => {
                // Set a timer to refresh the token
                scheduleTokenRefresh(response.expiresOn);
            })
            .catch((error) => {
                console.error('Token acquisition failed', error);
                /** If the silent token was  */
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request).catch(() => {
                        instance.logout({
                            account: accounts[0],
                        });
                    });
                }
            });
    };

    const scheduleTokenRefresh = (expiresOn: Date | null) => {
        if (!expiresOn) {
            console.error('Failed to schedule token refresh: No token expiration time provided.');
            return;
        }

        // Calculate the time to refresh the token (5 minutes before expiration)
        const refreshTime = moment(expiresOn).subtract(5, 'minutes');
        const currentTime = moment();
        const delay = refreshTime.diff(currentTime);

        if (delay <= 0) {
            console.error('Token expiration time is already past or too close.');
            return;
        }

        // Clear any existing timeouts
        if (refreshTimeoutRef.current) {
            clearTimeout(refreshTimeoutRef.current);
        }
        refreshTimeoutRef.current = setTimeout(() => {
            console.log('Refreshing token before expiration');
            refreshAccessToken();
        }, delay);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            // Re-initialize token refresh when the user comes back
            refreshAccessToken();
        } else if (document.visibilityState === 'hidden' && refreshTimeoutRef.current) {
            // Clear the token refresh timer when the user leaves the page
            clearTimeout(refreshTimeoutRef.current);
        }
    };

    useEffect(() => {
        // Initial token refresh when the component mounts
        refreshAccessToken();

        // Add event listener for page visibility changes
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup event listener and timeout on unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
        };
    }, []);

    return null;
};

export default useTokenRefresher;
