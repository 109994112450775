import {zodResolver} from '@hookform/resolvers/zod';
import {CircularProgress, Grid} from '@mui/material';
import {useTheme} from '@mui/styles';
import {useForm} from 'react-hook-form';
import TicketsTable from 'src/app/components/tables/TicketsTable';
import {zodRequiredStringSchema} from 'src/app/utilities/zod/zodRequiredStringSchema';
import Ticket from 'src/data/models/tickets/ticket';
import FormButtons from 'src/view/components/form/FormButtons';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import {TABLE_CELL_HEIGHT} from 'src/view/components/table/table/Types';

import z from 'zod';

interface AddToSplitFormValues {
    tickets: Ticket[];
}

interface SplitTicketsTableFormProps {
    isTableLoading: boolean;
    tableHeaderText: string;
    isSubmitDisabled: boolean;
    submitButtonText: string;
    splitTickets: Ticket[];
    editMode?: boolean;
    onFormSubmit: (tickets: Ticket[]) => void;
    preserveSortOrder?: boolean;
}

export const SplitTicketsFormValidation = z.object({
    tickets: z.array(
        z.object({
            id: zodRequiredStringSchema,
            sortOrder: z
                .number()
                .min(1, {message: 'You are missing a sort order for a ticket'})
                .nullable(),
        })
    ),
});

export const SplitTicketsTableForm = ({
                                          isTableLoading,
                                          isSubmitDisabled,
                                          submitButtonText,
                                          splitTickets,
                                          tableHeaderText,
                                          onFormSubmit,
                                          editMode = false,
                                          preserveSortOrder = false,
                                      }: SplitTicketsTableFormProps) => {
    const theme = useTheme();

    const setFormTicketsValues = () => {
        return splitTickets.map((ticket, index) => {
            const getSortOrder = () => {
                if (preserveSortOrder) return ticket.sortOrder;

                if (ticket.sortOrder !== index + 1) return index + 1;

                return ticket.sortOrder || index + 1;
            };

            return {
                ...ticket,
                sortOrder: getSortOrder(),
            };
        });
    };

    const {handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            tickets: setFormTicketsValues(),
        },
        resolver: zodResolver(SplitTicketsFormValidation),
    });

    const tickets = watch('tickets');

    const onSubmit = (values: AddToSplitFormValues) => {
        onFormSubmit(values.tickets);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableHeader title={tableHeaderText}/>
                <TicketsTable
                    loading={isTableLoading}
                    includeSortOrderForm
                    includePurchaseStatistics
                    rowHeight={TABLE_CELL_HEIGHT.LARGE}
                    tickets={tickets}
                    enableCheckboxes={false}
                    highlightRow={(ticket) => {
                        return !editMode && !ticket.splitName;
                    }}
                    onChangeRowOrder={(tickets) => {
                        setValue(
                            `tickets`,
                            tickets.map((ticket, index) => ({
                                ...ticket,
                                sortOrder: index + 1,
                            }))
                        );
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <FormButtons
                    buttons={[
                        {
                            children: submitButtonText,
                            onClick: handleSubmit(onSubmit),
                            disabled: isSubmitDisabled,
                            startIcon: isSubmitDisabled && (
                                <CircularProgress
                                    color="info"
                                    size={theme.layout.loader.sizes.small}
                                />
                            ),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};
