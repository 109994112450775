import useApiFetch, {ErrorReturn} from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import eventService from 'src/data/services/eventService';
import {EmptyBody} from 'src/data/models/common/emptyBody';

interface ActivateEventDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    activateEvent: (eventId: string) => Promise<void | ErrorReturn>;
}

type ActivateEventDataWrapperProps = DataWrapperProps<
    ActivateEventDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useActivateEventDataWrapper = ({
                                                onError,
                                                onSuccess,
                                            }: Omit<ActivateEventDataWrapperProps, 'children'>) => {
    const [{data, loading, errors, resetData}, handleActivateEvent] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const activateEventCall = (eventId: string) => {
        return handleActivateEvent(eventService.activateEvent(eventId), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'event has been activated',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        activateEvent: activateEventCall,
        data,
        loading,
        errors,
        resetData,
    };
};
