import urlService, {defaultCustomFields} from 'src/app/services/urlService';
import {
    filterOptionsToQueryParams,
    mapKeyStringObjectToFilterOptions,
    mapKeyStringObjectToSortingOptions,
} from 'src/app/utilities/helpers/filter';
import eventTabs from 'src/app/constants/constants/routing/eventTabs';
import {TableColumnSortingDirection} from 'src/view/components/table/table/Types';
import {PURCHASE_STATUS} from 'src/data/enums/purchase';
import {ORDERLINE_STATUS} from 'src/data/enums/orderline';
import {ORDER_STATUS} from 'src/data/enums/order';

interface TicketsFilters {
    purchaseId?: string;
    orderId?: string;
    splitId?: string;
    ticketId?: string;
    available?: boolean;
    purchaseStatus?: PURCHASE_STATUS[];
}

const ticketFiltersDefault: TicketsFilters = {
    available: true,
    purchaseStatus: [PURCHASE_STATUS.Confirmed, PURCHASE_STATUS.Completed],
};

interface TicketsSortings {
    split?: TableColumnSortingDirection;
}

const ticketSortingsDefault: TicketsSortings = {
    split: 'asc',
};

interface OrdersFilters {
    orderId?: string;
    status?: ORDER_STATUS[];
}

const ordersFiltersDefault: OrdersFilters = {
    status: [ORDER_STATUS.Active],
};

interface OrderlinesFilters {
    orderId?: string;
    status?: ORDERLINE_STATUS[];
}

const orderlinesFiltersDefault: OrderlinesFilters = {
    status: [ORDERLINE_STATUS.UNKNOWN, ORDERLINE_STATUS.ACTIVE],
};

interface SplitsFilters {
    seatingPlanCategoryId?: string;
    status?: PURCHASE_STATUS[];
    orderId?: string;
}

const splitsFiltersDefault: SplitsFilters = {
    status: [PURCHASE_STATUS.Confirmed, PURCHASE_STATUS.Completed],
};

interface PurchasesFilters {
    status?: PURCHASE_STATUS[];
}

const purchasesFiltersDefault: PurchasesFilters = {
    status: [PURCHASE_STATUS.Confirmed, PURCHASE_STATUS.Completed],
};

export const splitsDefaultFilters = mapKeyStringObjectToFilterOptions(splitsFiltersDefault);

const EventUrlService = {
    tickets(eventId: string, filters?: TicketsFilters, sortings?: TicketsSortings) {
        return urlService.getEvent(
            eventId,
            `/tickets${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(filters || ticketFiltersDefault),
                sortingOptions: mapKeyStringObjectToSortingOptions(
                    sortings || ticketSortingsDefault
                ),
                customFields: defaultCustomFields,
                suffix: 'tickets',
            })}`
        );
    },
    details(eventId: string) {
        return urlService.getEvent(eventId, eventTabs.DETAILS);
    },
    orderlines(eventId: string, filters?: OrderlinesFilters) {
        return urlService.getEvent(
            eventId,
            `${eventTabs.ORDER_LINES}${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(
                    filters || orderlinesFiltersDefault
                ),
                customFields: defaultCustomFields,
                suffix: 'orderLine',
            })}`
        );
    },
    orders(eventId: string, filters?: OrdersFilters) {
        return urlService.getEvent(
            eventId,
            `/orders${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(filters || ordersFiltersDefault),
                customFields: defaultCustomFields,
                suffix: 'orders',
            })}`
        );
    },
    autoAssign(eventId: string) {
        return urlService.getEvent(eventId, `/auto-assign`);
    },
    splits(eventId: string, filters?: SplitsFilters) {
        return urlService.getEvent(
            eventId,
            `/splits${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(filters || splitsFiltersDefault),
                customFields: defaultCustomFields,
                suffix: 'splits',
            })}`
        );
    },
    purchases(eventId: string, filters?: PurchasesFilters) {
        return urlService.getEvent(
            eventId,
            `/purchases${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(
                    filters || purchasesFiltersDefault
                ),
                customFields: defaultCustomFields,
                suffix: 'purchases',
            })}`
        );
    },
};

export default EventUrlService;
