import {useMutation, useQueryClient} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import {deleteVenue} from 'src/data/services/venuesService';
import {FETCH_VENUES_QUERY_KEY} from './useFetchVenues';

export const useDeleteVenue = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteVenue,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_VENUES_QUERY_KEY],
            });

            Toaster.toast('Venue deleted successfully', {variant: 'success'});
        },
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
};
