import {makeStyles} from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
    },
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    new: {
        color: theme.colors.grey,
    },
}));

export enum ORDERLINE_SHIPPING_STATUSES {
    SENT = 'Sent',
    PARTIALLY_SENT = 'PartiallySent',
    NOT_SENT = 'NotSent',
    UNKNOWN = 'Unknown',
}

interface ShippingStatusProps {
    status: string;
}

export default function ShippingStatus({status}: ShippingStatusProps) {
    const classes = useStyles();

    function getColorClass() {
        let colorClass = '';

        if (status === ORDERLINE_SHIPPING_STATUSES.NOT_SENT) {
            colorClass = classes.error;
        }

        if (status === ORDERLINE_SHIPPING_STATUSES.SENT) {
            colorClass = classes.success;
        }

        if (status === ORDERLINE_SHIPPING_STATUSES.PARTIALLY_SENT) {
            colorClass = classes.warning;
        }

        if (status === ORDERLINE_SHIPPING_STATUSES.UNKNOWN) {
            colorClass = classes.new;
        }

        return colorClass;
    }

    return <span className={classnames(classes.bold, getColorClass())}>{status}</span>;
}
