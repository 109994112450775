import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LOADER_SIZES} from 'src/shared/theme/enums';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    '@keyframes loading': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    loadingSpinnerAnimation: {
        animation: `$loading 1s ${theme.transitions.easing.easeInOut} infinite`,
    },
    loadingSpinner: (props: Partial<LoadingOverlayProps>) => ({
        height: props.size,
        width: props.size,
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `4px solid transparent`,
        borderRight: `4px solid transparent`,
        borderRadius: '50%',
    }),
}));

interface LoadingOverlayProps {
    size?: LOADER_SIZES;
}

export default function LoadingSpinner({
                                           size = LOADER_SIZES.LARGE,
                                       }: LoadingOverlayProps): JSX.Element {
    const classes = useStyles({size});

    return (
        <div className={classnames(classes.loadingSpinnerAnimation, classes.loadingSpinner)}></div>
    );
}
