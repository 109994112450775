import {AxiosError} from 'axios';
import {mapApiErrors} from 'src/data/mappers/mapApiErrors';
import Toaster from './Toaster';

/** This function will be removed very soon once we clean up DataWrappers.
 * Is only used in 11 small files. */
export function toastApiErrors(e: unknown) {
    if (e instanceof AxiosError) {
        const errors = mapApiErrors(e);
        Toaster.toastErrors(errors);
    } else {
        Toaster.toast('Something went wrong.', {
            variant: 'error',
        });
    }
}
