import {useQuery} from '@tanstack/react-query';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {fetchEventTickets} from 'src/data/services/ticketsService';

export const FETCH_EVENT_TICKETS_QUERY_KEY = 'FETCH_EVENT_TICKETS';

export const useFetchEventTickets = (eventId: string, options: BaseFetchOptions) => {
    return useQuery({
        queryKey: [FETCH_EVENT_TICKETS_QUERY_KEY, eventId, options],
        queryFn: () => fetchEventTickets(eventId, options),
    });
};
