import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const SEATING_PLAN_CATEGORY_PROPERTIES_GET_PROPERTIES_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/seating-plan-category-properties`;

export const SEATING_PLAN_CATEGORY_PROPERTIES_GET_PROPERTY_ENDPOINT = (
    seatingPlanCategoryPropertyId: string
) => `/${ENDPOINT_VERSIONS.V2}/seating-plan-category-properties/${seatingPlanCategoryPropertyId}`;

export const SEATING_PLAN_CATEGORY_PROPERTIES_CREATE_PROPERTY_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/seating-plan-category-properties/create`;

export const SEATING_PLAN_CATEGORY_PROPERTIES_UPDATE_PROPERTY_ENDPOINT = (
    seatingPlanCategoryPropertyId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-category-properties/${seatingPlanCategoryPropertyId}/update`;

export const SEATING_PLAN_CATEGORY_PROPERTIES_DELETE_PROPERTY_ENDPOINT = (
    seatingPlanCategoryPropertyId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-category-properties/${seatingPlanCategoryPropertyId}/delete`;
