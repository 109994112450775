import {zodResolver} from '@hookform/resolvers/zod';
import {Alert} from '@mui/material';
import {useConfirm} from 'material-ui-confirm';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {FetchOrdersDataWrapper} from 'src/app/components/data-wrappers/event/FetchOrdersDataWrapper';
import {useRevokeTicketsForOrderDataWrapper} from 'src/app/components/data-wrappers/order/RevokeTicketsForOrderDataWrapper';
import {useUpdateOrderDataWrapper} from 'src/app/components/data-wrappers/order/UpdateOrderDataWrapper';
import SendEventOrdersActionFeature from 'src/app/components/features/event/SendEventOrdersActionFeature';
import ChangePaymentStatusFeature from 'src/app/components/features/orders/ChangePaymentStatusFeature';
import {TableHeaderTotalFeature} from 'src/app/components/features/tables/TableHeaderTotalFeature';
import EditOrderForm, {CreateOrderFormValues, validationSchema as createOrderFormValidationSchema,} from 'src/app/components/forms/EditOrderForm';
import EventOrdersFiltersForm from 'src/app/components/forms/OrdersFiltersForm';
import {RevokeTicketsForm} from 'src/app/components/forms/order/RevokeTicketsForm';
import OrdersOverviewTable, {ORDER_TABLE_VARIANTS, OrderTableDataResolver,} from 'src/app/components/tables/OrdersOverviewTable';
import {config} from 'src/app/constants/config/config';
import {useFetchOrderById} from 'src/app/hooks/orders/useFetchOrderById';
import useApiFetch from 'src/app/hooks/useApiFetch';
import {useFetchSeatingPlanCategories} from 'src/app/hooks/useFetchSeatingPlanCategories';
import useQueryParams from 'src/app/hooks/useQueryParams';
import FilterOption from 'src/data/api/common/FilterOption';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {EmptyBody} from 'src/data/models/common/emptyBody';
import orderService from 'src/data/services/orderService';
import {useSpacingStyles} from 'src/shared/styles/spacingStyles';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal, {ModalBody} from 'src/view/components/modal/Modal';
import {OptionsList} from 'src/view/components/options-list/OptionsList';
import {TableSplitAction} from 'src/view/components/table-toolbar/TableToolbar';
import {RowIdResolver} from 'src/view/components/table/table/Table';
import {toSeatingPlanCategoryFilterOptions} from '../../data-wrappers/orderline/OrderlinesFiltersDataWrapper';
import {PopUpForm} from '../../pop-up-form/pop-up-form';
import {useGetBookingOptionsDropdownOptions} from '../event-booking-options/hooks/use-get-booking-options-dropdown-options';

export interface OrdersTableFeatureProps {
    eventId?: string;
    invalidatedAt?: number;
    variant?: ORDER_TABLE_VARIANTS;
}

export default function OrdersTableFeature({
                                               eventId,
                                               invalidatedAt,
                                               variant,
                                           }: OrdersTableFeatureProps): JSX.Element {
    const confirm = useConfirm();
    const spacingClasses = useSpacingStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [activeOrder, setActiveOrder] = useState<string | undefined>();
    const [ordersInvalidatedAt, setOrdersInvalidatedAt] = useState<number | undefined>();
    const [orderIds, setOrderIds] = useState<RowIdResolver<OrderTableDataResolver>[]>([]);
    const [openSendingOptionsModal, setOpenSendingOptionsModal] = useState(false);
    const [openConfirmRevokeTickets, setOpenConfirmRevokeTickets] = useState(false);
    const [openChangePayment, setOpenChangePayment] = useState(false);
    const [showSendOrdersModal, setShowSendOrdersModal] = useState(false);
    const [showSendOrdersManuallyModal, setShowSendOrdersManuallyModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState<RowIdResolver<OrderTableDataResolver>[]>([]);
    const selectedRowIds = useMemo(() => selectedRows.map((row) => row.id), [selectedRows]);

    const {updateOrder, loading: updateOrderIsLoading} = useUpdateOrderDataWrapper({
        orderId: activeOrder || '',
        onSuccess: () => setOrdersInvalidatedAt(Date.now()),
    });

    const {
        errors: revokeTicketsErrors,
        revokeTickets,
        loading: revokeTicketsIsLoading,
        resetData: resetRevokeTicketsData,
    } = useRevokeTicketsForOrderDataWrapper({
        onSuccess: () => {
            setOrdersInvalidatedAt(Date.now());
            setOpenSendingOptionsModal(false);
            setOpenConfirmRevokeTickets(false);
        },
    });

    const [{loading: sendTicketsloading}, handleSendTickets] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const [{loading: revokingTicketsLoading}, handleRevokeManuallySentTickets] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const {
        values: {filterOptions, customFields},
        setFilterOptions,
        setCustomFields,
    } = useQueryParams('orders');

    const createOrderForm = useForm<CreateOrderFormValues>({
        mode: 'onChange',
        resolver: zodResolver(createOrderFormValidationSchema),
    });

    useEffect(() => {
        if (!invalidatedAt) return;

        setOrdersInvalidatedAt(invalidatedAt);
    }, [invalidatedAt]);

    const sendTickets = (orderIds: RowIdResolver<OrderTableDataResolver>[]) => {
        if (!eventId) return;

        handleSendTickets(
            orderService.sendTicketsForOrder(
                eventId,
                orderIds.map((o) => o.id)
            ),
            {
                onSuccess: () => {
                    setOrderIds([]);
                    enqueueSnackbar('tickets have been sent', {variant: 'success'});
                    setOrdersInvalidatedAt(Date.now());
                    setOpenSendingOptionsModal(false);
                },
                useDefaultErrorHandler: true,
            }
        );
    };

    const sendTicketsManually = (orderIds: RowIdResolver<OrderTableDataResolver>[]) => {
        if (!eventId) return;

        handleSendTickets(
            orderService.markTicketsAsManuallySent(
                eventId,
                orderIds.map((o) => o.id)
            ),
            {
                onSuccess: () => {
                    setOrderIds([]);
                    enqueueSnackbar('tickets have been sent manually', {
                        variant: 'success',
                    });
                    setOrdersInvalidatedAt(Date.now());
                },
            }
        );
    };

    const ordersCannotSendAutomatically = useCallback(
        (selectedRows: RowIdResolver<OrderTableDataResolver>[]) => {
            return selectedRows.some((r) => !r.data?.canSendAutomatically);
        },
        []
    );

    const ordersSentStatusCannotBeMarkedAsSent = useCallback(
        (selectedRows: RowIdResolver<OrderTableDataResolver>[]) => {
            return selectedRows.some((r) => !r.data?.canMarkAsSent);
        },
        []
    );

    const sendSplitActions = (
        selectedRows: RowIdResolver<OrderTableDataResolver>[]
    ): TableSplitAction => ({
        disabled: selectedRows.length === 0,
        options: [
            {
                label: 'Send',
                callback: () => {
                    setShowSendOrdersModal(true);
                    setSelectedRows(selectedRows);
                },
                disabled: ordersCannotSendAutomatically(selectedRows),
            },
            {
                label: 'Send manually',
                disabled: ordersSentStatusCannotBeMarkedAsSent(selectedRows),
                callback: () => {
                    setSelectedRows(selectedRows);
                    setShowSendOrdersManuallyModal(true);
                },
            },
        ],
    });

    const renderSendEventOrdersTickets = () => {
        return (
            <Modal
                open={showSendOrdersModal}
                title="Are you sure you want to send the tickets for the selected order(s)?"
                onClose={() => setShowSendOrdersModal(false)}
            >
                <>
                    <Alert severity="info" className={spacingClasses.spacingBottom}>
                        Only orders that are <b>ready</b> can be send
                    </Alert>
                    <SendEventOrdersActionFeature
                        eventId={eventId || ''}
                        orderIds={selectedRowIds}
                        onSucceed={() => {
                            setOrdersInvalidatedAt(Date.now());
                            setShowSendOrdersModal(false);
                        }}
                    />
                </>
            </Modal>
        );
    };

    const renderSendEventOrdersTicketsManually = () => {
        return (
            <Modal
                open={showSendOrdersManuallyModal}
                title="Are you sure you want to mark the tickets for the selected order(s) as manually sent?"
                onClose={() => setShowSendOrdersManuallyModal(false)}
            >
                <SendEventOrdersActionFeature
                    eventId={eventId || ''}
                    orderIds={selectedRowIds}
                    onSucceed={() => {
                        setOrdersInvalidatedAt(Date.now());
                        setShowSendOrdersManuallyModal(false);
                    }}
                    manual
                />
            </Modal>
        );
    };

    const onRevokeManuallySentTickets = (orderId: string) => {
        handleRevokeManuallySentTickets(orderService.revokeManuallySentTicketsByOrderId(orderId), {
            onSuccess: () => {
                setOrdersInvalidatedAt(Date.now());
            },
            defaultSuccessMessage: 'Tickets have been revoked successfully.',
        });
    };

    const {data: orderData, isLoading: isFetchingOrder} = useFetchOrderById(activeOrder || '', {
        enabled: !!activeOrder,
    });

    const {data: seatingplanCategoriesData} = useFetchSeatingPlanCategories(eventId || '');

    const {bookingOptionsDropdownOptions} = useGetBookingOptionsDropdownOptions(eventId || '');

    const categoryOptions = useMemo(() => {
        return toSeatingPlanCategoryFilterOptions(seatingplanCategoriesData?.data.data.categories);
    }, [seatingplanCategoriesData]);

    return (
        <>
            {renderSendEventOrdersTickets()}
            {renderSendEventOrdersTicketsManually()}
            <EventOrdersFiltersForm
                categoryOptions={categoryOptions}
                bookingOptions={bookingOptionsDropdownOptions}
                isEventOverview={!!eventId}
                initialOptions={filterOptions}
                initialSearchTerm={customFields.q}
                onChangeFilterOptions={(options: FilterOption[]) => setFilterOptions(options)}
                onChangeSearchTerm={(q: string) => {
                    setCustomFields({
                        ...customFields,
                        q: q?.length > 0 ? q : '',
                    });
                }}
            />

            <FetchOrdersDataWrapper
                eventId={eventId}
                filter={filterOptions}
                q={customFields.q}
                invalidatedAt={ordersInvalidatedAt}
                page={Number(customFields['page'] || 1)}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
            >
                {({loading, data}) => (
                    <>
                        {loading && <LoadingOverlay/>}
                        <TableHeaderTotalFeature
                            title="Orders"
                            totalResults={data?.meta.total}
                            priceTotals={data?.meta.totalPriceSumPerCurrency}
                        />
                        <OrdersOverviewTable
                            tableToolbarSplitActions={(selectedRows) => [
                                sendSplitActions(selectedRows),
                            ]}
                            variant={variant}
                            eventId={eventId}
                            orders={data?.data || []}
                            loading={loading}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({...customFields, page: page.toString()}),
                            }}
                            onClickSend={async (orderIds) => {
                                await confirm({
                                    title: 'Are you sure you want to send the ticket(s)?',
                                    description: '',
                                });

                                sendTickets(orderIds);
                            }}
                            onClickSendingOptions={(orderIds) => {
                                setOrderIds(orderIds);
                                setOpenSendingOptionsModal(true);
                            }}
                            onClickSendManually={async (orderIds) => {
                                await confirm({
                                    title: 'Are you sure you want to mark the tickets for these orders as manually sent?',
                                    description: '',
                                });

                                sendTicketsManually(orderIds);
                            }}
                            onChangePaymentStatus={(orderIds) => {
                                setOpenChangePayment(true);
                                setOrderIds(orderIds);
                            }}
                            onChangeSelectedRows={setSelectedRows}
                            enableCheckboxes={!!eventId}
                            onClickDetails={(id) => setActiveOrder(id)}
                        />
                    </>
                )}
            </FetchOrdersDataWrapper>

            {activeOrder && (
                <PopUpForm
                    open
                    title="Order Details"
                    formState={createOrderForm.formState}
                    onClose={() => {
                        setActiveOrder(undefined);
                    }}
                >
                    <>
                        {isFetchingOrder && <LoadingOverlay/>}

                        {orderData && (
                            <EditOrderForm
                                form={createOrderForm}
                                defaultValues={{
                                    internalNotes: orderData?.data.data.internalNotes,
                                }}
                                order={orderData?.data.data}
                                onRevokeManuallySentTickets={() => {
                                    onRevokeManuallySentTickets(activeOrder);
                                }}
                                onFormSubmit={(values) => updateOrder(values.internalNotes || '')}
                                actionLoading={updateOrderIsLoading || revokingTicketsLoading}
                            />
                        )}
                    </>
                </PopUpForm>
            )}

            {orderIds.length > 0 ? (
                <Modal
                    open={openChangePayment}
                    onClose={() => setOpenChangePayment(false)}
                    title="Change payment status"
                >
                    <ChangePaymentStatusFeature
                        orderId={orderIds[0].id}
                        onSuccess={() => {
                            setOrdersInvalidatedAt(Date.now());
                            setOpenChangePayment(false);
                        }}
                    />
                </Modal>
            ) : (
                <></>
            )}

            <Modal
                title="Choose an option"
                open={openSendingOptionsModal}
                onClose={() => setOpenSendingOptionsModal(false)}
            >
                <ModalBody>
                    <OptionsList
                        items={[
                            {
                                label: 'Resend tickets',
                                onClick: async () => {
                                    await confirm({
                                        title: 'Are you sure you want to resend the ticket(s)?',
                                        description: '',
                                    });
                                    sendTickets(orderIds);
                                },
                            },
                            {
                                label: 'Revoke sent tickets',
                                onClick: () => setOpenConfirmRevokeTickets(true),
                            },
                        ]}
                    />
                </ModalBody>
            </Modal>

            <Modal
                open={openConfirmRevokeTickets}
                title="Revoke previously sent order"
                onClose={() => {
                    setOpenConfirmRevokeTickets(false);
                    resetRevokeTicketsData();
                }}
            >
                <ModalBody>
                    <div className={spacingClasses.spacingBottom}>
                        Are you sure you want to revoke the ticket(s) of the previously sent order?
                        This cannot be undone.
                    </div>

                    <div className={spacingClasses.spacingVertical}>
                        <RevokeTicketsForm
                            forceMode={revokeTicketsErrors.length > 0}
                            onSubmit={() =>
                                eventId &&
                                revokeTickets(
                                    eventId,
                                    orderIds[0].id,
                                    revokeTicketsErrors.length > 0
                                )
                            }
                            loading={revokeTicketsIsLoading}
                            errors={revokeTicketsErrors}
                        />
                    </div>
                </ModalBody>
            </Modal>

            {sendTicketsloading && <LoadingOverlay/>}
        </>
    );
}
