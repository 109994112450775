import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderlineService, {AutoAssignResultResponse} from 'src/data/services/orderlineService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {DataWrapperCallback} from 'src/app/interfaces/dataWrapperCallback';

interface FetchAutoAssignResultDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<AutoAssignResultResponse>> {
    fetchAutoAssignResult: (eventId: string) => Promise<ApiResponseBody<AutoAssignResultResponse>>;
}

type FetchAutoAssignResultDataWrapperProps = DataWrapperProps<
    FetchAutoAssignResultDataWrapperChildrenParams,
    ApiResponseBody<AutoAssignResultResponse>
> &
    BaseFetchOptions;

export const useFetchAutoAssignResultDataWrapper = ({
                                                        onError,
                                                        onSuccess,
                                                    }: Omit<FetchAutoAssignResultDataWrapperProps, 'children'>) => {
    const [{loading, data, errors, resetData}, handleFetchAutoAssignResult] =
        useApiFetch<ApiResponseBody<AutoAssignResultResponse>>();

    const fetchAutoAssignResult = (
        eventId: string,
        callbacks?: DataWrapperCallback<ApiResponseBody<AutoAssignResultResponse>>
    ) => {
        return handleFetchAutoAssignResult(orderlineService.getAutoAssignResult({eventId}), {
            useDefaultErrorHandler: true,
            onFail: () => {
                onError?.();
                callbacks?.onFail?.();
            },
            onSuccess: (data) => {
                onSuccess?.();
                callbacks?.onSuccess?.(data);
            },
        });
    };

    return {
        fetchAutoAssignResult,
        data,
        loading,
        errors,
        resetData,
    };
};
