import {useMutation} from '@tanstack/react-query';
import supplierService, {CreateSupplierDto} from 'src/data/services/supplierService';

type TData = Awaited<ReturnType<typeof supplierService.createSupplier>>;

const useCreateSupplier = (onSuccess?: (data: TData) => void) => {
    return useMutation({
        mutationFn: (dto: CreateSupplierDto) => {
            return supplierService.createSupplier(dto);
        },
        onSuccess,
    });
};

export default useCreateSupplier;
