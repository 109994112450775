import {useQuery} from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import {parseErrors} from 'src/app/utilities/helpers/errors';
import ticketsService from 'src/data/services/ticketsService';

type TData = Awaited<ReturnType<typeof ticketsService.fetchTicketById>>;

type Options = {
    enabled: boolean;
    onSuccess: (data: TData) => void;
};

export const GET_TICKET_BY_ID_QUERY_KEY = 'GET_TICKET_BY_ID';

export const useGetTicketById = (ticketId: string, options?: Options) => {
    return useQuery({
        queryKey: [GET_TICKET_BY_ID_QUERY_KEY, ticketId],
        queryFn: () => ticketsService.fetchTicketById(ticketId),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
        ...options,
    });
};
