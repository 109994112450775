import Grid from '@material-ui/core/Grid';
import {QuestionMarkOutlined} from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import {useCustomConfirm} from 'src/app/hooks/useCustomConfirm';
import {type BookingOptionType} from 'src/data/services/booking-options-service';
import {dateFormat} from 'src/shared/date';
import {SIZES} from 'src/shared/theme/enums';
import Popover from 'src/view/components/popover/Popover';
import IconComponent from 'src/view/icons/IconComponent';
import useDeleteBookingOption from './hooks/use-delete-booking-option';

export const BookingOption = ({
                                  option,
                                  onClickEdit,
                                  readonly,
                              }: {
    onClickEdit?: () => void;
    option: BookingOptionType;
    readonly?: boolean;
}) => {
    const startDate = moment(option.startDate).format(dateFormat);
    const endDate = option.endDate && moment(option.endDate).format(dateFormat);

    const {mutate: deleteBookingOption} = useDeleteBookingOption();

    const confirm = useCustomConfirm();

    const period = endDate ? `${startDate} - ${endDate}` : startDate;

    return (
        <Grid item xs={12}>
            <Box width="100%" height="100%" border={1} borderRadius={2} padding={1} display="flex">
                {option.isDefault && (
                    <Box
                        sx={{
                            width: '10px',
                            marginRight: 1,
                            borderRadius: 2,
                            display: 'flex',
                            background: (theme) => theme.palette.success.main,
                        }}
                    ></Box>
                )}
                <Box>
                    <Typography variant="body2" fontWeight="bold" margin={0}>
                        {option.name}
                        <span
                            style={{
                                color: option.isDefault ? 'green' : 'grey',
                            }}
                        >
                            {option.isDefault && ' (Default)'}
                        </span>
                    </Typography>
                    <div>
                        <span>{period}</span>
                    </div>
                </Box>

                {!readonly && (
                    <Box marginLeft="auto" display="flex">
                        <IconComponent isButton Icon={Edit} color="black" onClick={onClickEdit}/>

                        {option.isDefault ? (
                            <DefaultBookingOptionExplanation/>
                        ) : (
                            <IconComponent
                                Icon={Delete}
                                isButton
                                error
                                onClick={async () => {
                                    const hasAccepted = await confirm({
                                        title: 'Delete Booking Option',
                                        description:
                                            'Are you sure you want to delete this booking option?',
                                    });

                                    if (hasAccepted) {
                                        deleteBookingOption(option.id);
                                    }
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </Grid>
    );
};

const DefaultBookingOptionExplanation = () => {
    return (
        <Popover text={<IconComponent Icon={QuestionMarkOutlined} size={SIZES.MEDIUM}/>}>
            <p>Set another option as default to be able to delete this option</p>
        </Popover>
    );
};
