import {AutoCompleteOption} from 'src/view/components/auto-complete/interfaces';
import z from 'zod';

export const zodRequiredDropdownSelectSchema = z
    .object<AutoCompleteOption>(
        {
            label: z.string(),
            value: z.string(),
        },
        {
            invalid_type_error: 'This field is required!',
        }
    )
    .required();
