import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const SERIES_GROUPS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/series-groups`;

export const SERIES_GROUPS_GET_BY_ID_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/series-groups/${id}`;

export const SERIES_GROUPS_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/series-groups/create`;

export const SERIES_GROUPS_UPDATE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/series-groups/${id}/update`;

export const SERIES_GROUPS_DELETE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/series-groups/${id}/delete`;
