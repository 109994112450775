import {ArrowUpward} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';
import classNames from 'classnames';
import {TableColumnSortingDirection} from 'src/view/components/table/table/Types';

interface Props {
    currentDirection: TableColumnSortingDirection | null;
    handleDirectionChange: (direction: TableColumnSortingDirection) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        opacity: 0.75,
        '&:hover': {
            opacity: 1,
        },
    },
    active: {
        color: theme.palette.primary.main,
        transitionDelay: '100ms',
        transitionProperty: 'transform',
        transform: 'rotate(0)',
    },
    disabled: {
        color: theme.colors.grey,
        opacity: 0.3,
        '&:hover': {
            opacity: 1,
        },
    },
    rotateDown: {
        transitionDelay: '100ms',
        transitionProperty: 'transform',
        transform: 'rotate(-180deg)',
    },
}));

export default function TableColumnSortingIconButton({
                                                         currentDirection,
                                                         handleDirectionChange,
                                                     }: Props): JSX.Element {
    const classes = useStyles();

    let icon: JSX.Element = <></>;

    switch (currentDirection) {
        case null:
            icon = (
                <ArrowUpward
                    className={classNames(classes.disabled, classes.rotateDown)}
                    onClick={() => {
                        handleDirectionChange('asc');
                    }}
                />
            );
            break;
        case 'asc':
            icon = (
                <ArrowUpward
                    className={classNames(classes.active, classes.rotateDown)}
                    onClick={() => {
                        handleDirectionChange('desc');
                    }}
                />
            );
            break;
        case 'desc':
            icon = (
                <ArrowUpward
                    className={classNames(classes.active)}
                    onClick={() => {
                        handleDirectionChange(null);
                    }}
                />
            );
            break;
    }

    return <div className={classes.root}>{icon}</div>;
}
