import {ENDPOINT_VERSIONS} from './_endpoint-versions';

export const BOOKING_OPTIONS_CREATE_FOR_EVENT = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/booking-options/${eventId}/create`;

export const BOOKING_OPTIONS_GET_EVENT_BOOKING_OPTIONS = (eventId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/events/${eventId}/booking-options`;

export const BOOKING_OPTIONS_GET_BOOKING_OPTION = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/booking-options/${id}`;

export const BOOKING_OPTIONS_CREATE_BOOKING_OPTION = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/booking-options/${id}/create`;

export const BOOKING_OPTIONS_UPDATE_BOOKING_OPTION = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/booking-options/${id}/update`;

export const BOOKING_OPTIONS_DELETE_BOOKING_OPTION = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/booking-options/${id}/delete`;
