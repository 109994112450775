import {useEffect} from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import {SeatingPlanCategoryProperty} from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import seatingPlanCategoryPropertyService from 'src/data/services/seatingPlanCategoryPropertyService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';

interface FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<SeatingPlanCategoryProperty>> {
    fetchSeatingplanCategoryPropertyById: () => Promise<
        ApiResponseBody<SeatingPlanCategoryProperty>
    >;
}

interface FetchSeatingPlanCategoryPropertyDataWrapperProps
    extends DataWrapperProps<FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams> {
    propertyId: string;
}

export const useFetchSeatingPlanPropertyByIdDataWrapper = ({
                                                               onError,
                                                               onSuccess,
                                                               propertyId,
                                                               invalidatedAt,
                                                           }: Omit<FetchSeatingPlanCategoryPropertyDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{data, loading, errors, resetData}, handleFetchSeatingplanCategoryProperties] =
        useApiFetch<ApiResponseBody<SeatingPlanCategoryProperty>>();

    useEffect(() => {
        fetchSeatingplanCategoryPropertyById();
    }, [propertyId, invalidatedAt]);

    const fetchSeatingplanCategoryPropertyById = () => {
        return handleFetchSeatingplanCategoryProperties(
            seatingPlanCategoryPropertyService.getPropertyById(propertyId),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    return {
        fetchSeatingplanCategoryPropertyById,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchSeatingplanCategoryPropertyByIdDataWrapper = (
    props: FetchSeatingPlanCategoryPropertyDataWrapperProps & BaseFetchOptions
) => {
    const {children} = props;
    const hookData = useFetchSeatingPlanPropertyByIdDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
