import Modal, {ModalBody} from 'src/view/components/modal/Modal';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import purchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import {OptionsList} from 'src/view/components/options-list/OptionsList';
import LaunchIcon from '@mui/icons-material/Launch';
import {EditPurchasePriceFormValuesProps} from '../../forms/purchase/EditPurchasePricesForm';

interface PostEditPurchaseFollowUpModalProps {
    seatingPlanCategories: EditPurchasePriceFormValuesProps[];
    purchaseId: string;
    isOpen: boolean;
    onCloseModal: () => void;
}

const PostEditPurchaseFollowUpModal = ({
                                           seatingPlanCategories,
                                           purchaseId,
                                           isOpen,
                                           onCloseModal,
                                       }: PostEditPurchaseFollowUpModalProps) => {
    const availableEventsWithTickets = seatingPlanCategories
        .filter((spc) => spc.quantity > 0)
        .map((spc) => ({
            label: spc.eventName,
            actions: [
                {
                    icon: <LaunchIcon fontSize="small"/>,
                    url: EventUrlService.tickets(spc.eventId, {
                        purchaseId,
                    }),
                },
            ],
        }));

    return (
        <Modal
            open={isOpen}
            title="Purchase updated successfully"
            subTitle="Where do you want to redirect to?"
            onClose={onCloseModal}
        >
            <ModalBody>
                <OptionsList
                    items={[
                        {
                            label: 'Purchases overview',
                            actions: [
                                {
                                    icon: <LaunchIcon fontSize="small"/>,
                                    url: purchaseUrlService.overview(),
                                },
                            ],
                        },
                    ]}
                />

                {availableEventsWithTickets.length > 0 && (
                    <>
                        <h3>Go to event tickets</h3>
                        <OptionsList items={availableEventsWithTickets}/>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

export default PostEditPurchaseFollowUpModal;
