import TableHeader from 'src/view/components/table/table-header/TableHeader';
import Chip from 'src/view/components/chip/Chip';
import Popover from 'src/view/components/popover/Popover';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import {CancelOutlined} from '@mui/icons-material';
import TicketUploadedFilesTable, {
    TicketUploadedFilesTableProps,
} from 'src/app/components/tables/TicketUploadedFilesTable';
import makeStyles from '@mui/styles/makeStyles';
import {Dropzone, DropzoneComponentProps} from 'src/view/components/dropzone-input/Dropzone';
import {DropzoneProps} from 'react-dropzone';
import TicketFile from 'src/data/models/common/ticketFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import file from 'src/app/constants/constants/file-types/file';

const useStyles = makeStyles((theme) => ({
    fileUploadContainer: {
        flex: 1,
        height: 31,
        padding: theme.spacing(1),
        '& p': {
            fontSize: '.625rem',
        },
        '& svg': {
            height: 12,
        },
    },
    fileUploadFormContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    popoverContainer: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
}));

export interface TicketUploadFilesFormProps {
    ticketFiles?: TicketFile[];
    dropzoneProps?: DropzoneProps & DropzoneComponentProps;
    onDeleteAll?: () => void;
    ticketUploadedFilesTableProps: TicketUploadedFilesTableProps;
    onUpload: (files: File[]) => void;
}

export const TicketUploadFilesForm = ({
                                          ticketFiles,
                                          dropzoneProps,
                                          ticketUploadedFilesTableProps,
                                          onDeleteAll,
                                      }: TicketUploadFilesFormProps) => {
    const classes = useStyles();

    const ticketsDataFiles = ticketFiles || [];

    const renderFilesPopover = () => {
        return (
            <Popover
                trigger="click"
                boxPadding={0}
                text={
                    <Chip
                        variant="outlined"
                        clickable
                        rounded
                        tinyChip
                        label={<div>Files uploaded ({ticketsDataFiles.length})</div>}
                        icon={<AttachFileOutlinedIcon/>}
                        deleteIcon={<CancelOutlined style={{marginLeft: 2, marginRight: 2}}/>}
                        onDelete={onDeleteAll}
                    />
                }
            >
                <>
                    <TableHeader
                        title={`Files uploaded (${ticketFiles?.length})`}
                        variant="small"
                    />
                    <TicketUploadedFilesTable
                        files={ticketsDataFiles}
                        {...ticketUploadedFilesTableProps}
                    />
                </>
            </Popover>
        );
    };

    const hasFilesUploaded = ticketsDataFiles.length > 0;

    return (
        <div className={classes.fileUploadFormContainer}>
            {hasFilesUploaded && (
                <div className={classes.popoverContainer}>{renderFilesPopover()}</div>
            )}

            <Dropzone
                uploadText={!hasFilesUploaded ? "Drag 'n drop files here" : <FileUploadIcon/>}
                dragActiveText={!hasFilesUploaded ? 'Drop file(s)...' : <FileUploadIcon/>}
                className={classes.fileUploadContainer}
                accept={file.ticketFilesAcceptedMimeTypes}
                {...dropzoneProps}
            />
        </div>
    );
};
