import {Chip as MUIChip, ChipProps as MUIChipProps, Theme} from '@mui/material';
import {CSSProperties, makeStyles} from '@mui/styles';
import classNames from 'classnames';

export interface ChipProps extends MUIChipProps {
    tinyChip?: boolean;
    rounded?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    chip: (props: ChipProps) => {
        let chipObj: Record<string, CSSProperties | number | string> = {
            borderRadius: theme.layout.borderRadius.regular,
        };

        if (props.rounded) {
            chipObj = {
                borderRadius: 30,
                padding: theme.spacing(0.3, 0.8),
            };
        }

        if (props.tinyChip || props.rounded) {
            chipObj = {
                height: '1.4rem',
                padding: theme.spacing(0.1, 0.1),
                fontSize: '.7rem',
                '& .MuiSvgIcon-root': {
                    width: '.9rem',
                    height: '.9rem',
                    marginRight: 0,
                },
                '& .MuiChip-label': {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
                ...chipObj,
            };
        }

        return chipObj;
    },
}));

export default function StyledChip({
                                       tinyChip,
                                       rounded,
                                       className,
                                       ...props
                                   }: ChipProps): JSX.Element {
    const classes = useStyles({tinyChip, rounded});

    return <MUIChip {...props} className={classNames(classes.chip, className)}/>;
}
